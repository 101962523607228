export const communityMasterPROD = {
  Client: "EMBARK",
  Table: "CommunityModelAddress",
  Rows: [
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14672 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14728 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14755 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14756 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14847 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypoint Coastal",
      Str: "14818 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14839 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14854 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14588 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14632 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14822 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14621 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14647 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14846 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14628 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14664 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14797 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14636 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14604 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14768 Kingfsher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14608 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14676 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14752 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14684 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14747 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14831 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14667 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14600 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14652 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14842 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14668 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14772 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14640 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1500,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14867 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14612 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14740 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2240,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14708 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14835 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14818 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14834 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14624 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14875 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14692 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14680 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14625 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14644 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14851 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14826 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14751 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14629 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14855 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14759 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14648 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14895 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14859 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14704 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14688 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14830 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14807 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14601 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14732 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14815 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14656 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14712 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14819 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14635 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14620 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14748 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14814 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14655 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14850 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14744 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14760 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14605 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14811 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14613 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14736 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14572 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14871 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14643 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14838 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14716 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14843 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14616 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14803 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14810 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14660 Kingfisher Loop",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14891 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14863 Loggerhead Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Annapolis Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Boston Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Concord Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Atlanta",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Columbia Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Atlanta Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Annapolis Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Albany Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Boston Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "343000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Columbia Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Concord Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "391000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Annapolis Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Estates",
      Mo: "Atlanta Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Richmond Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Vanderbilt",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Trenton Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Hartford Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Raleigh Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Providence Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Columbia",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Dover Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Columbia",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "364000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Harrisburg Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "296000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Providence Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "384000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Vanderbilt",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2073,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Raleigh Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "437000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Hartford Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Richmond Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "503000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Executives",
      Mo: "Trenton Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "544000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Naples Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1528,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Madrid Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1764,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Vanderbilt Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Vanderbilt Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Naples Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1528,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Madrid Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1764,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Madrid Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1764,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "311000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Vanderbilt Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2073,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Manors",
      Mo: "Naples Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1528,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "276000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Townhomes",
      Mo: "Hampton  Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Townhomes",
      Mo: "Glenmoor Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Townhomes",
      Mo: "Hampton  Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Townhomes",
      Mo: "Hampton  Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "324000"
    },
    {
      Bid: "22680",
      Co: "Abbott Square:the Townhomes",
      Mo: "Glenmoor Ii",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Acacia Groves:woodbridge Collection",
      Mo: "Orchard",
      Str: "340 Sw Saint And Sw 194 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2665,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Acacia Groves:woodbridge Collection",
      Mo: "Elm",
      Str: "340 Sw Saint And Sw 194 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1827,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Acacia Groves:woodbridge Collection",
      Mo: "Hickory",
      Str: "340 Sw Saint And Sw 194 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2183,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Acacia Groves:woodbridge Collection",
      Mo: "Spruce",
      Str: "340 Sw Saint And Sw 194 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2987,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Acacia Groves:woodbridge Collection",
      Mo: "Laurel",
      Str: "340 Sw Saint And Sw 194 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2439,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Acacia Groves:woodbridge Collection",
      Mo: "Birch",
      Str: "340 Sw Saint And Sw 194 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1732,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22374",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "902 Honey Petal Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "899 Asterfield Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Addison Landing - D60",
      Mo: "Oakleaf",
      Str: "867 Asterfield Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2794,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Highgate",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "259000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Mystique",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Yorkshire",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Mystique Grand",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Cedar",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "233000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Whitestone",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Vence",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Casis",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Vence",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Monte Carlo",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Bandol",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Monte Carlo",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1489,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Bandol",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1334,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Casis",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1414,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Reserve",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1545,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Altamira:andalucia Collection",
      Mo: "Vence",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1668,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Avignon",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Bordeaux",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Marsielle",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Lorient",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Dijon",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Marsielle",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1828,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Lorient",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1828,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Avignon",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1334,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Bordeaux",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1443,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Altamira:castellon Collection",
      Mo: "Dijon",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1697,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Lucena",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Zamora",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Avila",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Toledo",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2399,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Oviedo",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2161,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Zamora",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Zamora",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2656,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Lucena",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Oviedo",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2161,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Toledo",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2399,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Altamira:granada Collection",
      Mo: "Avila",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Voyage",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3105,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Starfish",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2915,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Harbor",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2527,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Compass",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2217,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Anchor",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Ballast",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Harbor",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2527,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Voyage",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3105,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Starfish",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2915,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Anchor",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1982,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Compass",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2217,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Altamira:malaga Collection",
      Mo: "Ballast",
      Str: "336 Sw Saint And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2189,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Benton",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Camden",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "323000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Haddock",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Jasmine",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Elliot",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2344,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Mabel",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Schooner",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2565,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Talbot",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Medina",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Yellowstone",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Winthrop",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "677000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Cresswind",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Heston",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Tilden",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "541000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Ashby",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Easley",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Ashby Grand",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "532000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Easley Grand",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "692000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Monroe",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "509000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Clubview",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Nobility",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Pinnacle",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "854000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Stonewater",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "1001000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Camelot",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1149000"
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Almeria",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Ibiza",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3401,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Majorca",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3676,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Alicante",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2420,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia:the Coastal Collection",
      Mo: "Cadiz",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3004,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Eventide Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Daybreak Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Daybreak",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Halos Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Argent Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Eventide",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Argent",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Daybreak",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Eventide Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2611,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Daybreak Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Eventide",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2611,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Argent Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Halos",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Halos Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Estates",
      Mo: "Argent",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Dawning Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Morningtide Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Morningtide",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Meridian Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Meridian Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Morningtide",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Splendor",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Morningtide Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Dawning Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Sunrise Ii*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Sunrise Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Aurora Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Dayspring Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Aurora Ii*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Dayspring Ii*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Aurora Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1747,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Sunrise Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1398,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Sunrise Ii*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1398,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Dayspring Ii",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1616,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Dayspring Ii*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1616,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:active Adult Villas",
      Mo: "Aurora Ii*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1747,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Annapolis",
      Str: "State Route 52",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Harrisburg",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Annapolis",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Providence",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Trenton",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Dover",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Hartford",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Annapolis",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Raleigh",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Dover",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Hartford",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Richmond",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Richmond",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Raleigh",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Providence",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Harrisburg",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Estates",
      Mo: "Trenton",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Manors",
      Mo: "Annapolis",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Manors",
      Mo: "Columbia",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Manors",
      Mo: "Boston",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Manors",
      Mo: "Atlanta",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Manors",
      Mo: "Concord",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Estates",
      Mo: "St. Thomas Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1787,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Estates",
      Mo: "St. Thomas Ii",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1666,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Capri Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Marisol Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Verona Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2466,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Palermo Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Capri Ii",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1801,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Marisol Ii",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2319,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Palermo Ii",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2251,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Town Executives",
      Mo: "Verona Ii",
      Str: "10065 Gentle Rain Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2466,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Hampton Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Windsor Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Glenmoor Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Glenmoor Ii",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Glenmoor Ii",
      Str: "17516 Nectar Flume Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Hampton Ii",
      Str: "17516 Nectar Flume Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Angeline:the Townhomes",
      Mo: "Windsor Ii",
      Str: "17516 Nectar Flume Dr",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1513,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Crosswater",
      Mo: "Anna Maria",
      Str: "110 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Crosswater",
      Mo: "Julington",
      Str: "402 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Crosswater",
      Mo: "Julington",
      Str: "157 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3854,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Crosswater",
      Mo: "Anna Maria",
      Str: "54 Anthem Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3233,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Crosswater",
      Mo: "Julington",
      Str: "94 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Crosswater",
      Mo: "Julington",
      Str: "34 Revere Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3926,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "San Tropez",
      Str: "65 Anthem Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "411 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3779,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "230 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Anna Maria",
      Str: "460 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3700,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Roseberry",
      Str: "256 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2608,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "471 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3926,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "501 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Anna Maria",
      Str: "387 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Roseberry",
      Str: "69 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "San Tropez",
      Str: "141 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3107,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "195 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4115,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "501 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Anna Maria",
      Str: "31 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "377 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "58 Revere Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "San Tropez",
      Str: "43 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Roseberry Elite",
      Str: "59 Revere Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "83 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Roseberry Elite",
      Str: "178 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Roseberry Elite",
      Str: "483 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "164 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "471 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "72 Anthem Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "San Tropez",
      Str: "64 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Roseberry Elite",
      Str: "474 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "San Tropez",
      Str: "416 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3657,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Anna Maria",
      Str: "242 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "164 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "San Tropez",
      Str: "324 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3569,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge At Nocatee",
      Mo: "Julington",
      Str: "377 Anthem Ridge Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3779,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Marsala",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Marsala",
      Str: "19611 Tortuga Cay Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Venice",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Marsala",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Venice",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Venice",
      Str: "19611 Tortuga Cay Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Trevi",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Trevi",
      Str: "19611 Tortuga Cay Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Amalfi",
      Str: "11490 Burgundy Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Venice",
      Str: "11490 Burgundy Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Trevi",
      Str: "11490 Burgundy Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Capri",
      Str: "11490 Burgundy Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Monte Carlo",
      Str: "11490 Burgundy Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Antigua At Wellen Park",
      Mo: "Marsala",
      Str: "11490 Burgundy Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Prosperity",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Mystique",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Palmary",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "273000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Mainstay",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Prestige",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Stellar",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Reverence",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Renown",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "394000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Stardom",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Scenic",
      Mo: "Contour",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "197000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Scenic",
      Mo: "Compass",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "215000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Scenic",
      Mo: "Hallmark",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "232000"
    },
    {
      Bid: "22680",
      Co: "Arborwood Preserve 35",
      Mo: "Milan",
      Str: "11730 Arborwood Preserve Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden Park North:executive Collection",
      Mo: "Wekiva  (3 Car)",
      Str: "1618 Lake Sims Parkway",
      Ct: "Ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3711,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Arden Park:estate Collection",
      Mo: "Inverness Ii",
      Str: "2022 Lake Sims Parkway",
      Ct: "Ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Morris",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "368000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Trailside",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "372000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Henley",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Drayton",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Crestwood",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "201000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 50'",
      Mo: "Mystique",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "299000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Daisy",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Lili",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Sunflower",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2973,
      Sto: "1",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Periwinkle",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2677,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Tulip",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Daffodil",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1947,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Sunflower",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2973,
      Sto: 1,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Dahlia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1826,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Dahlia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1826,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Lili",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2379,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Iris",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2156,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Daffodil",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1947,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Iris",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2138,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Periwinkle",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2677,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Camellia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1611,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Daisy",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2256,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Lili",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2379,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Arcadia Collection",
      Mo: "Tulip",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Basil",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2566,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Rosemary",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Sage",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2648,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Rosemary",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Tarragon",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Coriander",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2200,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Sage",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Sage",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2648,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Caraway",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2324,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Lili - Model Only",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2379,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Rosemary",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2373,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Coriander",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2200,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Rosemary",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2373,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Tarragon",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2868,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Sage",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2648,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Dahlia - Model Only",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1826,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Tarragon",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2868,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Lavender",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3755,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Peppercorn",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2998,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Coriander",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2200,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Basil",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2566,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Lili - Model Only",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2379,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Saffron",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4045,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Providence Collection",
      Mo: "Dahlia - Model Only",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1826,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Acacia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Lyonia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Cocoplum",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2760,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Lyonia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Holly",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3325,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Acacia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Holly",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Acacia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2631,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Lyonia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3360,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Acacia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2631,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Holly",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Cocoplum",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2760,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Holly",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3325,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Cocoplum",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2760,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Stanton Collection",
      Mo: "Lyonia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3360,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Twin Homes Collection",
      Mo: "Oakleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1816,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Broadleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3112,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Flora",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3444,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Hart",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3730,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Broadleaf",
      Str: "992 Castaway Ct",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3112,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Lotus",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4003,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Broadleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3112,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Hart",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3730,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Lotus",
      Str: "992 Castaway Ct",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4003,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Flora",
      Str: "992 Castaway Ct",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3444,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Lotus",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4003,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Broadleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3110,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Lotus",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4012,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Flora",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3444,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "810000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Broadleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3112,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Flora",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3444,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "810000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Hart",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3730,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "22680",
      Co: "Arden:the Waterford Collection",
      Mo: "Hart",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3730,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Aventura",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "532000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Regalia",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2713,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "543000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Layton",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "604000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Madeira",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Sanibel",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "877000"
    },
    {
      Bid: "6701",
      Co: "Armen Groves",
      Mo: "Other",
      Str: "124 Smith Rd",
      Ct: "Merritt Island",
      St: "FL",
      Zip: 32953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2581 Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2528 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2548 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling",
      Str: "2593  Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2568 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling",
      Str: "2575 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling Elite",
      Str: "2594 Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling Elite",
      Str: "2564 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2575 Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2572 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2587 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2588  Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2560 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2556 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2569 Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2583 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2584 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1050000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2557 Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2563 Ocean Breeze Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2532 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2579 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2520 Cobalt Shores Lane",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trial",
      Mo: "Willet",
      Str: "2544 Cobalt Shores Ln",
      Ct: "Clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3395,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6701",
      Co: "Aspire At East Lake",
      Mo: "Stetson ",
      Str: "2961 Se Union Park Drive",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "Aspire At East Lake Sf",
      Mo: "Stetson",
      Str: "2364 Se Union Park Dr",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1612,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Aspire At East Lake Sf",
      Mo: "Other",
      Str: "2388 Se Union Park Drive",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At Hawks Ridge",
      Mo: "Fullerton V",
      Str: "668 Ne Waters Edge Ln",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "113000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Hawks Ridge",
      Mo: "Other",
      Str: "678 Ne Waters Edge Lane",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At Marion Oaks",
      Mo: "Other",
      Str: "Sw 132nd Place",
      Ct: "Ocala",
      St: "FL",
      Zip: 34473,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At Palm Bay",
      Mo: "Other",
      Str: "1834 Emerson Dr Se",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At Palm Coast",
      Mo: "Other",
      Str: "370 Parkview Drive",
      Ct: "Palm Coast",
      St: "FL",
      Zip: 32164,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St Lucia",
      Mo: "Palmera",
      Str: "1525 Sw Paar Dr",
      Ct: "Port St Lucia",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St Lucie",
      Mo: "Palmera",
      Str: "2431 Sw Brescia St",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St Lucie",
      Mo: "Other",
      Str: "642 Sw Tulip Blvd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont",
      Str: "2574 Sw Cameo Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont",
      Str: "3984 Sw Laffite St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont",
      Str: "1137 Sw East Louise Cir",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera",
      Str: "1486 Sw Herder Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera",
      Str: "1717 Sw Alberca Ln",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont",
      Str: "1117 Sw Kapok Ave",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera",
      Str: "4633 Sw Savona Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont Aspire",
      Str: "2574 Sw Cameo Blvd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera",
      Str: "3414 Sw Princeton St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont",
      Str: "4549 Sw Scope St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera",
      Str: "4598 Sw Scope St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Dupont",
      Str: "4000 Sw Mccrory St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera Extra Suite Plus",
      Str: "1470 Sw Cellini Ave",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Palmera",
      Str: "4454 Sw Idlewild St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Port St. Lucie",
      Mo: "Other",
      Str: "2961 Sw Savona Boulevard",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At The Links At Calusa Springs",
      Mo: "Ashmere",
      Str: "6817 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6701",
      Co: "Aspire At The Links Of Calusa Springs",
      Mo: "Dupont",
      Str: "6879 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Aspire At The Links Of Calusa Springs",
      Mo: "Ivanhoe",
      Str: "6825 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2743,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Victoria Parc",
      Mo: "Other",
      Str: "Sw Village Parkway And",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Emerald",
      Str: "5218 Armina Pl",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Eden",
      Str: "5161 Armina Place",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Lelia",
      Str: "5109 Armina Pl",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Eden",
      Str: "5114 Armina Place",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "240000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Eden",
      Str: "5162 Armina Pl",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Eden",
      Str: "5169 Armina Place",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Lelia",
      Str: "5125 Armina Pl",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Aspire At Waterstone",
      Mo: "Other",
      Str: "Waterstone Boulevard",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Minori",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1834,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Amalfi",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Sienna",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Sienna",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Amalfi",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Minori",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1834,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Sienna",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1873,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Astonia:chateau At Astonia",
      Mo: "Amalfi",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1689,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Providence",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Allentown",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Allentown",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Freedom",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Providence",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Freedom",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Allentown",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1853,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Buffer Site",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Astonia:estate Collection",
      Mo: "Parking Site",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Concord",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Atlanta",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Columbia",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Buffer Site",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Concord",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Atlanta",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Columbia",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Astonia:manor Collection",
      Mo: "Parking Site",
      Str: "2704 Pierr St",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Columbia",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Atlanta",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Boston",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Annapolis",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Annapolis",
      Str: "14165 Lilac Sky Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Columbia",
      Str: "14165 Lilac Sky Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Atlanta",
      Str: "14165 Lilac Sky Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:patio Homes",
      Mo: "Boston",
      Str: "14165 Lilac Sky Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:townhomes",
      Mo: "Bennett",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:townhomes",
      Mo: "Avery",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:townhomes",
      Mo: "Bennett",
      Str: "14165 Lilac Sky Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1347,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Aurora At Lakewood Ranch:townhomes",
      Mo: "Avery",
      Str: "14165 Lilac Sky Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1405,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Autumn Palm 18 Th",
      Mo: "Windsor",
      Str: "Autumn Palm Dr",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22680",
      Co: "Autumn Palm 18 Th",
      Mo: "Hampton",
      Str: "Autumn Palm Dr",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "Autumn Palm 18 Th",
      Mo: "Glenmoor",
      Str: "Autumn Palm Dr",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Cedar",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Ashby",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Easley",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Whitestone",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "507000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Yorkshire",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Oakhurst",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "673000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Roseland",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "748000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Valencia",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4342,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Upton",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "912000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Creekview",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Mystique",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Highgate",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Layton",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Layton Grande",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park At Ave Maria",
      Mo: "Mystique Grand",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Lotus",
      Str: "Sw 129th Ave And Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1878,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Clover",
      Str: "Sw 129th Ave And Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Orchid",
      Str: "Sw 129th Ave And Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Juniper",
      Str: "Sw 129th Ave And Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1654,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Ivy",
      Str: "Sw 129th Ave And Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Trails:villas",
      Mo: "Larkspur",
      Str: "14660 Three Ponds Trail",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33446,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Garden",
      Mo: "Candlewood",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Garden",
      Mo: "Daylen",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Garden",
      Mo: "Beachwood",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Townhomes",
      Mo: "Ivy",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Townhomes",
      Mo: "Rowan",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Vista",
      Mo: "Sealine",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti At Waterside - Vista",
      Mo: "Coastview",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9366 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Maddington",
      Str: "9213 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3330,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9204 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9120 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9174 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9225 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Maddington",
      Str: "9171 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9142 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Maddington",
      Str: "9104 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Maddington",
      Str: "9167 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Maddington",
      Str: "9353 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Shirley",
      Str: "9121 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9113 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9154 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9166 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9124 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9378 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Maddington",
      Str: "9175 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-watermark-70's",
      Mo: "Jennings",
      Str: "9358 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Roland",
      Str: "12724 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12436 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12458 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12424 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12407 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3019,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12492 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2821,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Mckenna",
      Str: "12471 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Sabrina",
      Str: "12444 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2351,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12415 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12728 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12712 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2959,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12432 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12704 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12431 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12715 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12692 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Roland",
      Str: "12483 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12496 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12707 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Mckenna",
      Str: "12491 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Sabrina",
      Str: "12408 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Torrance",
      Str: "12462 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3051,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12500 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Roland",
      Str: "12472 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Edmonton",
      Str: "12495 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Roland",
      Str: "12476 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3157,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-windgate-50's",
      Mo: "Mckenna",
      Str: "12423 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Avesa",
      Mo: "Giada",
      Str: "10044 Corso Milano Dr",
      Ct: "Tampa",
      St: "FL",
      Zip: 33625,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2262,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Avila Bay",
      Mo: "Shoreline",
      Str: "5572 Mercado Drive",
      Ct: "Port Richey",
      St: "FL",
      Zip: 34668,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Classic",
      Mo: "Mainstay",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2095,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "419000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Classic",
      Mo: "Fifth Avenue",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Classic",
      Mo: "Prestige",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Classic",
      Mo: "Mystique",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2019,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "404000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Classic",
      Mo: "Riverwalk",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "536000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Estate",
      Mo: "Stardom",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Estate",
      Mo: "Stellar",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Estate",
      Mo: "Reverence",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Estate",
      Mo: "Renown",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Estate",
      Mo: "Whitestone",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Avondale At Avenir- Estate",
      Mo: "Millstone",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3427,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "686000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Alabaster",
      Str: "5504 Nw 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1441,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Sandstone",
      Str: "5504 Nw 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Marigold",
      Str: "5504 Nw 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Crimson",
      Str: "5504 Nw 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1684,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9268 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2437,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "9069 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9248 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9101 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2352,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "9260 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9284 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9264 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Cayman",
      Str: "9297 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9256 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "9050 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9273 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Cayman",
      Str: "9288 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "9272 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9070 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2487,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "8906 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Cayman",
      Str: "9066 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2188,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9073 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Sabel",
      Str: "9269 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2075,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "8778 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3780,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9086 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "9296 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Sabel",
      Str: "9078 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2192,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9047 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9063 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9074 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9293 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Cayman",
      Str: "9059 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9109 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9046 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9077 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9043 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2548,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "8969 Mustique Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "8920 Mustique Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9280 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2352,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Cayman",
      Str: "8945 Mustique Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Massiano",
      Str: "9292 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9058 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2578,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Stefano",
      Str: "9055 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "9062 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "8782 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1190000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate",
      Mo: "Serino",
      Str: "8976 Mustique Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-estate ",
      Mo: "Massiano",
      Str: "8914 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Massiano",
      Str: "9253 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1060000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9277 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9257 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Massiano",
      Str: "9113 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4302,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1040000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9089 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2553,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9085 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2456,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1080000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9114 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2486,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9093 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "905000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Stefano",
      Str: "9094 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Stefano",
      Str: "9102 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2960,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Massiano",
      Str: "9105  Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9261 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2659,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9110 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9098 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1130000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Massiano",
      Str: "9245 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9109 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9249 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Sabel",
      Str: "9106 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2075,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9265 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9241 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Massiano",
      Str: "9281 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-ests",
      Mo: "Serino",
      Str: "9090 Montserrat Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2552,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "9047 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Kingsdale",
      Str: "9002 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Athena Elite",
      Str: "8995 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "8990 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9046 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Carver Elite",
      Str: "9015 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "9006 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Carver",
      Str: "9035 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9027 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9030 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Athena Elite",
      Str: "9105 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Athena",
      Str: "9007 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9043 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9067 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9011 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "8986 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "9108 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "9050 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Athena Elite",
      Str: "9039 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "9031 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Athena Elite",
      Str: "9093 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Avery",
      Str: "9056 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-herita",
      Mo: "Piper",
      Str: "9071 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Aragon",
      Str: "8757 St. Lucia",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3228,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Aragon",
      Str: "8754 St.lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Aragon",
      Str: "8791 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "San Giorgio",
      Str: "8770 St.lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Dalenna",
      Str: "8787 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Sandpiper",
      Str: "8795 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Aragon",
      Str: "8777 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4379,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-signat",
      Mo: "Aragon",
      Str: "8799 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9016 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "8982 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9075 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "8893 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9047 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9032 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "8905 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "8979 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "8833 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "9087 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "8821 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "8829 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9020 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9008 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9083 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9003 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "9020 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9130 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "8983 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "8958 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9091 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9028 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9024 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9095 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill Elite",
      Str: "9076 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9096 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9024 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "8889 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9031 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9004 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9008 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9012 Saint Lucia Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9088 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "8986 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "285000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "8971 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9118 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Heron",
      Str: "8943 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Heron",
      Str: "9080 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9122 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Stillwater",
      Str: "8825 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9092 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9036 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9126 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "8931 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9146 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9035 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Baypointe",
      Str: "9039 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "8991 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lakes-villa",
      Mo: "Sandhill",
      Str: "9079 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9138 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9084 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9056 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9224 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Stillwater",
      Str: "9162 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9044 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9175 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9158 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Sandhill",
      Str: "9154 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9068 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9016 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9044 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9186 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Sandhill Elite",
      Str: "9194 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9060 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9220 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9036 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9179 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9048 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9052 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9174 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9060 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9072 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9209 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Sandhill Elite",
      Str: "9076 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9166 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Stillwater",
      Str: "9170 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9056 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9182 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9084 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9228 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9213 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Sandhill",
      Str: "9040 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9040 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9052 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9150 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9204 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9178 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe Elite",
      Str: "9080 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9012 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9048 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9142 Cayman Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9064 Saint Lucia Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Baypointe",
      Str: "9068 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Azure At Hacienda Lks-villa",
      Mo: "Sandhill",
      Str: "9088 Mayreau Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9104 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9120 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1060000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena",
      Str: "9089 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9092 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1095000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena",
      Str: "9096 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9081 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1170000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver Elite",
      Str: "9109 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9121 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver Elite",
      Str: "9117 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1355000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9097 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "910000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena",
      Str: "9023 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Athena Elite",
      Str: "9112 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver",
      Str: "9051 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver",
      Str: "9085 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver",
      Str: "9019 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Piper",
      Str: "9088 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver Elite",
      Str: "9116 Caicos Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1030000"
    },
    {
      Bid: "6703",
      Co: "Azure@hacienda Lks-heritage",
      Mo: "Carver Elite",
      Str: "9060 Redonda Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "995000"
    },
    {
      Bid: "22680",
      Co: "Babcock National:estate Homes",
      Mo: "Westwind Ii",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:estate Homes",
      Mo: "Aster",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:executive Homes",
      Mo: "Remington",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:executive Homes",
      Mo: "Calusa",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:executive Homes",
      Mo: "Cypress",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:executive Homes",
      Mo: "Cypress",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 42'",
      Mo: "Contour",
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 42'",
      Mo: "Hallmark",
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 42'",
      Mo: "Compass",
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Prosperity",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Mystique",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "356000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Mainstay",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Prestige",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "369000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Stardom",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Stellar",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "438000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Renown",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "471000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Reverence",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "456000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Layton",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "569000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Woodview",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3411,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Arbordale",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1980,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "351000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Cobblestone",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2212,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Oakview",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3654,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Summerwood",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "266000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Oasis",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Citrus Grove",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "404000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Palm",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Dockside",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "421000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Mariner",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Summerwood Loft",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "649000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Distinctive",
      Mo: "Prosperity",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Distinctive",
      Mo: "Mystique",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Distinctive",
      Mo: "Palmary",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Distinctive",
      Mo: "Prestige",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Distinctive",
      Mo: "Mystique Grande",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Distinctive",
      Mo: "Whitestone",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Ecehlon",
      Mo: "Stardom",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Ecehlon",
      Mo: "Stellar",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "368000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Ecehlon",
      Mo: "Reverence",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Ecehlon",
      Mo: "Renown",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Ecehlon",
      Mo: "Layton",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Babcock Terrawalk Villa",
      Mo: "Ellenwood",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "222000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Richmond Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Raleigh Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Hartford Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Harrisburg Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Dover Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Providence Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Trenton Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Celeste",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Nova",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Dawn",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Aura",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1313,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Trenton Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Richmond Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Honor",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Hartford Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Bloom",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "195000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Harrisburg Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Dover Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Providence Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Eclipse",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Estates",
      Mo: "Raleigh Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Concerto",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Hartford Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Cheyenne",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Olympia",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Santa Fe",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Lincoln",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Helena",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2999,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Phoenix",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Olympia",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3354,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Phoenix",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2443,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Helena",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2999,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Cheyenne",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3255,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "538000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Santa Fe",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2576,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Hartford Ii",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Concerto",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3868,
      Sto: 2,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Balm Grove:the Executives",
      Mo: "Lincoln",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2278,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "249 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Barkley",
      Str: "96 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "83 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "204 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4773,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "178 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "259 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "76 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "77 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "52 Red Rock Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "224 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4461,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "115 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "167 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3999,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "210 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "63 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "41 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "33 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "320 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "217 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "59 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3821,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "179 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "107 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "61 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3498,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "99 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4833,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "240 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "199 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "850000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "289 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "203 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "68 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "74 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4201,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "32 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "40 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "101 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "172 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "111 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "114 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4333,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "82 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Barkley",
      Str: "26 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "86 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "210 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4333,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "29 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "93 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "157 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "183 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "40 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4074,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "162 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "41 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3445,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "46 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "216 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "137 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "67 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "70 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "75 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "312 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "62 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3483,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "195 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "30 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4310,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "204 Hickory Ranch Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4159,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "129 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "121 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "69 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3498,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "295 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "248 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "114 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "102 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "47 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "90 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4701,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "313 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "53 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "272 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4315,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "91 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "199 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Canyon",
      Str: "58 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "194 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "159 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4701,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "219 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "191 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "96 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "134 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "323 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "140 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4768,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "105 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "160 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "49 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3744,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "146 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "249 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "57 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4310,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "150 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "42 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "124 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4470,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "56 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4790,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "82 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "115 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "205 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4296,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Canyon",
      Str: "212 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "151 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4387,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "25 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "175 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3896,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "105 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4759,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "34 Red Rock Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "233 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "53 Red Rock Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "117 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5126,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "128 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "54 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4387,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "213 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4767,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "128 Butler Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "75 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "304 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "57 Sunset Ridge Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "245 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4831,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1095000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "14 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "35 Red Rock Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "31 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4737,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore",
      Str: "87 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "58 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "114 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "288 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "26 Hillendale Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "93 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3472,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "24 Tradesman Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "275 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "256 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3999,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "25 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4521,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore",
      Str: "130 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2457,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "230 Morning Mist Lane",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "198 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "91 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "161 Ranch Land Circle",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5007,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1065000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "217 Hickory Ranch Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Trevi",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "The Summerville Ii",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Sorrento",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Sorrento",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "The Summerville Ii",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Monte Carlo",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Trevi",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Distinctive",
      Mo: "Mystique",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Distinctive",
      Mo: "Palmary",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Distinctive",
      Mo: "Prestige",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Distinctive",
      Mo: "Prosperity",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Echelon",
      Mo: "Stardom",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Echelon",
      Mo: "Stellar",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Echelon",
      Mo: "Reverence",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "539000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Echelon",
      Mo: "Renown",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Echelon",
      Mo: "Layton Grande",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Echelon",
      Mo: "Layton",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Scenic",
      Mo: "Contour",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Scenic",
      Mo: "Compass",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Scenic",
      Mo: "Hallmark",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Vista",
      Mo: "Cascadia",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "279000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk By Manasota Key Vista",
      Mo: "Seagrove",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22680",
      Co: "Beacon At Galiano Pointe",
      Mo: "Centuri",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1827,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Beacon At Galiano Pointe",
      Mo: "Argento",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1732,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Beacon At Galiano Pointe",
      Mo: "Florent",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2183,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Beacon At Galiano Pointe",
      Mo: "Olmeto",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2665,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Beacon At Galiano Pointe",
      Mo: "Lumio",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2439,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Beacon At Galiano Pointe",
      Mo: "Piani",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2987,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22680",
      Co: "Bellasera:bellisima Collection",
      Mo: "Majorca",
      Str: "1304 Brinely Place",
      Ct: "Royal Palm Beach",
      St: "FL",
      Zip: 33411,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3701,
      Sto: "2",
      Be: "5",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22689",
      Co: "Belle Haven",
      Mo: "Daylen",
      Str: "2440 Belle Haven Way",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Belle Haven",
      Mo: "Mill Run",
      Str: "2440 Belle Haven Way",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Belle Haven",
      Mo: "Talon",
      Str: "2440 Belle Haven Way",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Belle Haven Th",
      Mo: "Marigold",
      Str: "1234 Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22689",
      Co: "Belle Haven Th",
      Mo: "Springdale",
      Str: "1234 Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "279000"
    },
    {
      Bid: "22680",
      Co: "Bellevue At Estates At Cherry Lake",
      Mo: "Dover",
      Str: "945 Laurel View Way",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Belmont",
      Mo: "Hartford",
      Str: "10141 Count Fleet Dr",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Belmont",
      Mo: "Virginia 2017",
      Str: "10141 Count Fleet Dr",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3773,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Belmont:belmont Townhomes",
      Mo: "Riverview Lakes 6-unit",
      Str: "9741 Lemon Drop Loop",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 11804,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1555000"
    },
    {
      Bid: "22680",
      Co: "Belmont:the Estates",
      Mo: "Do Not Use Moved To 1517676",
      Str: "14373 Easy Goer St",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1375000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Concord",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Columbia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "6",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Jefferson",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2463,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Bloom",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1487,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Georgia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Jefferson",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Bloom",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Celeste",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Azalea",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1500,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Albany",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1635,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Columbia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2409,
      Sto: 2,
      Be: 6,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Concord",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2587,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Georgia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Dover",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "The Islamorada",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1480,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "The Seaview",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1630,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Concord",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2587,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Dover",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Gardens Collection",
      Mo: "Columbia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2409,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Concord",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Columbia",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Royal",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2025,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "The Atlantic",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1931,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "The Lago",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1347,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Azalea",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1500,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Brilliance",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1640,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Albany",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Majestic",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1805,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Imperial",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Denver",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1635,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Columbia",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Juniper",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2541,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Scarlet",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2731,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Cypress",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1827,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Holly",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2094,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Harrisburg",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Aqua",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1966,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Dover",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Brook",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Bent Creek:the Meadows Collection",
      Mo: "Concord",
      Str: "797 Carlyle Way",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Dover",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Richmond Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Providence Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Providence",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Providence",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Harrisburg",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Harrisburg Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Trenton Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Richmond",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Raleigh Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Dover Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Hartford",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Raleigh",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Dover Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Hartford Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Trenton",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Hartford",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Trenton",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Trenton Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Hartford",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Richmond",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Dover Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Hartford Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Dover",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Providence",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Harrisburg Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Richmond Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Harrisburg",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Raleigh",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Raleigh Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Providence Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates",
      Mo: "Trenton",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates Ii",
      Mo: "Providence Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates Ii",
      Mo: "Harrisburg Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Estates Ii",
      Mo: "Hartford Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Executives",
      Mo: "Concerto",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Executives",
      Mo: "Santa Fe",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Executives",
      Mo: "Phoenix",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Executives",
      Mo: "Lincoln",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Executives",
      Mo: "Cheyenne",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Baltimore Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1486,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Chicago Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Seattle Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Dallas Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2217,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Houston Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Chicago Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Baltimore Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1486,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Seattle Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Dallas Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2217,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Chicago Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Berry Bay:the Manors",
      Mo: "Houston Ii",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Morris",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "403000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Trailside",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Whitestone",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Driftwood",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2470,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Mystique",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2056,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "458000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Yorkshire",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22680",
      Co: "Bexley 2b Th",
      Mo: "Wilshire",
      Str: "4464 Slipstream Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1620,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Big Connerton 50 Ads",
      Mo: "Trenton",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Big Connerton 50 Ads",
      Mo: "Harrisburg",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Big Cypress Stof",
      Mo: "Ayla",
      Str: "Snake Road And I75",
      Ct: "Clewiston",
      St: "FL",
      Zip: 33440,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Marsala",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Trevi",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Venice",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Capri",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1677,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Trevi",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Monte Carlo",
      Str: "15992 Honey Suckle St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Milan",
      Str: "15992 Honey Suckle St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1903,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Capri",
      Str: "15992 Honey Suckle St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Trevi",
      Str: "15992 Honey Suckle St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Venice",
      Str: "15992 Honey Suckle St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:executive Homes",
      Mo: "Marsala",
      Str: "15992 Honey Suckle St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Medallion",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Tivoli",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Trevi",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Medallion",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Trevi",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Princeton",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Princeton",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "Tivoli",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Boatman Hammock",
      Mo: "Other",
      Str: "Boatman Street",
      Ct: "Village Of Palm Springs",
      St: "FL",
      Zip: 33461,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22374",
      Co: "Bonavie Cove Villas",
      Mo: "Largo",
      Str: "10350 Bonavie Cove Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Cedar",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Mystique",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Prestige",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Mystique Grande",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Whitestone",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Yorkshire",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Ashby",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Oakhurst",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Easley Grande",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Easley",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Roseland",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Hallmark",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Trailside",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Morris",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Contour",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bonita National:coach Homes",
      Mo: "Bay Creek",
      Str: "28123 Wicklow Ct.",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2110,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Bonita National:coach Homes",
      Mo: "Arrowhead",
      Str: "28123 Wicklow Ct.",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1741,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Cedar",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Highgate",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "314000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Mystique",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "326000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Ashby",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Tower",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "336000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Trailside Select",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Easley",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "371000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Whitestone",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Ashby Grand",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "478000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Yorkshire",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Oakhurst",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "554000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Easley Grand",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "612000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Cedar",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "329000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Highgate",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Spruce",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "351000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Tower",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Ashby",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "381000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Whitestone",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Easley",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "391000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Capri",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "284000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Juniper",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "301000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Monterey",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2195,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "308000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Windsor",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Hickory",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "287000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Sfh",
      Mo: "Magnolia",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Sfh",
      Mo: "Atlanta",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Sfh",
      Mo: "Compasso",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2104,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Sfh",
      Mo: "Radiante",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2707,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Sfh",
      Mo: "Luxo",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Sfh",
      Mo: "Luxo",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village Th",
      Mo: "C",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1832,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Bridgehaven At Citrus Park",
      Mo: "Liberty",
      Str: "6617 Citrus Creek Lane",
      Ct: "Tampa",
      St: "FL",
      Zip: 33625,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1807,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:estate Collection",
      Mo: "Discovery",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:estate Collection",
      Mo: "Miramar",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:estate Collection",
      Mo: "Aspen",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:executive Collection",
      Mo: "Inverness",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2847,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:executive Collection",
      Mo: "Eastham",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:executive Collection",
      Mo: "Bonita",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:executive Collection",
      Mo: "Bloomfield",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:executive Collection",
      Mo: "Bloomfield",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Harmony",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2671,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Glenwood",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Aurora",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Solana Ii",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2186,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Autumn",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Glenwood",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2455,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Buffer Site",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1785000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Parking Site",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1785000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Lexington",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1982,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Aurora",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2052,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Harmony",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2671,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Autumn",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Asheville",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2471,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Rio",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2634,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Solona Ii",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2186,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Delray",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2455,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:manor Alley Collection",
      Mo: "Raleigh",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2204,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Oakley",
      Str: "20128 Se Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Aventura",
      Str: "20128 Se Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3796,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1100000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Madeira",
      Str: "20128 Se Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3988,
      Sto: "2",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1200000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Layton",
      Str: "20128 Se Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4020,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1210000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Livingston",
      Str: "20128 Se Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5376,
      Sto: "2",
      Be: "6",
      Ba: "6.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1400000"
    },
    {
      Bid: "22680",
      Co: "Bridgewater At Viera:classics",
      Mo: "Riviera",
      Str: "2371 Caravan Place",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Marsala",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Venice",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Capri",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Amalfi",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Venice",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Trevi",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Marsala",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:executive Homes",
      Mo: "Monte Carlo",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:manor Homes",
      Mo: "Sorrento",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:manor Homes",
      Mo: "Tivoli",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:manor Homes",
      Mo: "Sorrento",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:manor Homes",
      Mo: "Summerville Ii",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:manor Homes",
      Mo: "Tivoli",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:manor Homes",
      Mo: "Princeton Ii",
      Str: "18807 Marlin Kite Ln",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2245,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:cottage Alley Collection",
      Mo: "Danali",
      Str: "1801 Rider Rain Lane",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:cottage Alley Collection",
      Mo: "Rainier",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2023,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:cottage Alley Collection",
      Mo: "Sonora Ii",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1827,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:cottage Alley Collection",
      Mo: "Danali",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:executive Collection",
      Mo: "Estero",
      Str: "1801 Rider Rain Lane",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:executive Collection",
      Mo: "Fairmont",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3216,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:executive Collection",
      Mo: "Estero",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2532,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:executive Collection",
      Mo: "Independence",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3468,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:executive Collection",
      Mo: "Largo Ii",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2387,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:trail Townhomes",
      Mo: "Montara",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2000,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:trail Townhomes",
      Mo: "Wilshire",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1615,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:trail Townhomes",
      Mo: "Landcaster",
      Str: "1801 Rider Rain Ln",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1755,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Manors",
      Mo: "Maryland",
      Str: "8795 Captstone Ranch Drive",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Town Estates",
      Mo: "Verona",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2466,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Town Estates",
      Mo: "Marisol",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Town Estates",
      Mo: "Capri",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Town Estates",
      Mo: "Palermo",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Townes",
      Mo: "Windsor Ii",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "90000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Townes",
      Mo: "Glenmoor Ii",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "94000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:the Townes",
      Mo: "Hampton Ii",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "97000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Raleigh",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Alexia",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1719,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Columbia",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Harrisburg",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Providence",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Providence",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Hartford",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Harrisburg",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Providence",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2582,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Hartford",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1937,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Raleigh",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2902,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Celeste",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Alexia",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1719,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Heritage Collection",
      Mo: "Columbia",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2409,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Annapolis",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Concord",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Alexia",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1719,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Atlanta",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Columbia",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Annapolis",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Columbus",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Georgia",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Atlanta",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1873,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Alexia",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1719,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Columbia",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2409,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Brystol At Wylder:the Palms Collection",
      Mo: "Concord",
      Str: "6205 Nw Sweetwood Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2587,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "The Summerville Ii",
      Str: "4204 Villa Rapallo Way",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "Trevi",
      Str: "4204 Villa Rapallo Way",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "Oakmont Ii",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2361,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "The Summerville Ii",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "Trevi",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Cadence At Tradition 50'",
      Mo: "Cascades",
      Str: "14135 Sw Safi Ter",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Cadence At Tradition 50'",
      Mo: "Caledon",
      Str: "14143 Sw Safi Ter",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Cadence At Tradition 50'",
      Mo: "Gateway",
      Str: "10246 Sw Orana Dr",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Cadence At Tradition 50'",
      Mo: "Caledon",
      Str: "14055 Sw Safi Ter",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:executive Homes",
      Mo: "Angelina",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2061,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:executive Homes",
      Mo: "Isabella",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2246,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:executive Homes",
      Mo: "Victoria",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1850,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:executive Homes",
      Mo: "Maria",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2247,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:terrace Condominiums",
      Mo: "Carolina",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1301,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:terrace Condominiums",
      Mo: "Birkdale (end 4th Floor)",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1154,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:terrace Condominiums",
      Mo: "Arbor (4th Floor)",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1120,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:veranda Condominiums",
      Mo: "Arabella Ii",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1569,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:veranda Condominiums",
      Mo: "Diangelo Ii",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1366,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Calusa Country Club:veranda Condominiums",
      Mo: "Bromelia Ii",
      Str: "14544 Arbor Green Trl",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1355,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Spruce",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Tower",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Whitestone",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Highgate",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Cedar",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Hamden",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Daniel",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Mcnair",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Tybee",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22680",
      Co: "Cantabria Townhomes",
      Mo: "Scheme I",
      Str: "Fl64 And 48th Street Ct Ne",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Cantabria Townhomes",
      Mo: "Scheme Iii",
      Str: "Fl64 And 48th Street Ct Ne",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Browning",
      Str: "716 Sw 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Chapman",
      Str: "716 Sw 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Hamden",
      Str: "716 Sw 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Thompson",
      Str: "716 Sw 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "438000"
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Vista",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Hartford",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Freedom",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Dover",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Hartford",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Dover",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Freedom",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Parking Site",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Buffer Site",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Cascades:grand Collection",
      Mo: "Vista",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2015,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Columbia",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Annapolis",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Concord",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Columbia",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Atlanta",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Annapolis",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Parking Site",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Atlanta",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Buffer Site",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Columbia",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cascades:legacy Collection",
      Mo: "Concord",
      Str: "2011 Punch Bowl Ave",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Atlanta",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Boston",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Annapolis",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Columbia",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Atlanta",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Atlanta",
      Str: "Skye Ranch Blvd",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Columbia",
      Str: "Skye Ranch Blvd",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Annapolis",
      Str: "Skye Ranch Blvd",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Cassia At Skye Ranch",
      Mo: "Boston",
      Str: "Skye Ranch Blvd",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Cassia Commons",
      Mo: "Cobalt",
      Str: "3010 Hibiscus Avenue",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1547,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Cassia Commons",
      Mo: "Latitude",
      Str: "3010 Hibiscus Avenue",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Fifth Avenue",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Hamden",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Hanover",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Thompson",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "473000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Chapman",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Brixton",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Regency",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Brixton Grande",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3318,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Regency Grande",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Cedar Grove At The Woodlands",
      Mo: "Highgate",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "288000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove At The Woodlands",
      Mo: "Mystique",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove At The Woodlands",
      Mo: "Trailside",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove At The Woodlands",
      Mo: "Whitestone",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove At The Woodlands",
      Mo: "Yorkshire",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove At The Woodlands",
      Mo: "Cedar",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Vence",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Bandol",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Vence",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Bandol",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Reserve",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Casis",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:cypress Collection",
      Mo: "Monte Carlo",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:evergreen Collection",
      Mo: "Lorient",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:evergreen Collection",
      Mo: "Dijon",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:evergreen Collection",
      Mo: "Marseille",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:evergreen Collection",
      Mo: "Lorient",
      Str: "28805 Sw 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Ashby",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "421000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Easley",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Yorkshire",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Ashby Grand",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "491000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Oakhurst",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Easley Grand",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Roseland",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Highgate",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "351000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Mystique",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "359000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Tower",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Whitestone",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Yorkshire",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22374",
      Co: "Celebration _ Island Village",
      Mo: "Other",
      Str: "7413 Levee Lane",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Celebration - D55",
      Mo: "Marathon",
      Str: "7573 Estuary Lake Loop",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2909,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22374",
      Co: "Celebration - Island Village",
      Mo: "Heron",
      Str: "7405 Levee Lane",
      Ct: "Celebration",
      St: "FL",
      Zip: 32747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2437,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7410 Barrier Cove Way",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7456  Estuary Lake Loop",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hatteras",
      Str: "7430 Barrier Cove Way",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hatteras",
      Str: "2264 Celebration Blvd",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Heron",
      Str: "7418 Barrier Cove Way",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7569 Estuary Lake Loop",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "1833 Coastal Court",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Anastasia",
      Str: "1863 Coastal Court",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "2232 Celebration Blvd",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Amelia",
      Str: "1859 Coastal Court",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "2216 Celebration Blvd",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Amelia",
      Str: "2208 Celebration Blvd",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "2252 Celebration Blvd",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "1829 Coastal Court",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Celestina:celestina Elite Collection",
      Mo: "Haddington",
      Str: "20 Lunetta Ct",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3592,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Celestina:celestina Elite Collection",
      Mo: "Crawford",
      Str: "20 Lunetta Ct",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Celestina:celestina Royal Collection",
      Mo: "Stratford",
      Str: "20 Lunetta Ct",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2617,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Sante Fe",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Cheyenne",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3252,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Wyoming",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Helena",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3007,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Lincoln",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Wyoming",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3726,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Santa Fe",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2576,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Helena",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3021,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Cheyenne",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3247,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "Lincoln",
      Str: "12190 Sw Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2278,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Centris:diamond Collection",
      Mo: "Ruby",
      Str: "8259 Sw 120 Terr.",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4152,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1585000"
    },
    {
      Bid: "22680",
      Co: "Centris:diamond Collection",
      Mo: "Niagara",
      Str: "8259 Sw 120 Terr.",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4032,
      Sto: "2",
      Be: "5",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "22680",
      Co: "Centris:diamond Collection",
      Mo: "Silverstone",
      Str: "8259 Sw 120 Terr.",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4336,
      Sto: "2",
      Be: "5",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1660000"
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:champions Pointe Townhomes",
      Mo: "Azalea - Unit A",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:champions Pointe Townhomes",
      Mo: "Nandina - Unit D",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1988,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:champions Pointe Townhomes",
      Mo: "Firethorn - Unit B",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1954,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:champions Pointe Townhomes Ii",
      Mo: "Nandina",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1988,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:champions Pointe Townhomes Ii",
      Mo: "Firethorn - Unit B",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1954,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:champions Pointe Townhomes Ii",
      Mo: "Azalea - Unit A",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate 2 40 Resort",
      Mo: "Majesty Palm",
      Str: "Barry Road",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club",
      Mo: "Sand Dollar",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2460,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club",
      Mo: "Freedom",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club",
      Mo: "Newcastle",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club Ii",
      Mo: "Bourne",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1971,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club Ii",
      Mo: "Eastham",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2065,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club Ii",
      Mo: "Hideaway",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:country Club Ii",
      Mo: "Nicholas",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:luxury Resort Townhomes",
      Mo: "Sabal Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Flat",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:luxury Resort Townhomes",
      Mo: "Royale Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Flat",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:luxury Villas",
      Mo: "Alexander Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:luxury Villas",
      Mo: "Majesty Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:manors",
      Mo: "Charlestown",
      Str: "9025 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1960,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:retreat Single Family",
      Mo: "Buchanan",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3976,
      Sto: "2",
      Be: "7",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Luau",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3491,
      Sto: "2",
      Be: "7",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Maui",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Bali",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Fiji",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3348,
      Sto: "2",
      Be: "6",
      Ba: "6",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Maui",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Bali",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Championsgate:the Retreat",
      Mo: "Maui",
      Str: "9029 Sand Trap Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cherokee Cove",
      Mo: "The Egret",
      Str: "11148 Cherokee Cove Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' Th",
      Mo: "Marianna",
      Str: "8516 Primrose Willow Pl",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' Th",
      Mo: "Marianna",
      Str: "8518 Primrose Willow Pl",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' Th",
      Mo: "Marianna",
      Str: "8508 Primrose Willow Pl",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' Th",
      Mo: "Marianna",
      Str: "8345 Wildflower Glen Ave",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 20' Th",
      Mo: "Ormond",
      Str: "8520 Primrose Willow Pl",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 20' Th",
      Mo: "Ormond",
      Str: "8331 Wildflower Glen Ave",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6701",
      Co: "Co",
      Mo: "Other",
      Str: "",
      Ct: "",
      St: "",
      Zip: 0,
      Pt: "",
      Ot: "",
      Yr: 0,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Co",
      Mo: "Other",
      Str: "",
      Ct: "",
      St: "",
      Zip: 0,
      Pt: "",
      Ot: "",
      Yr: 0,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-ambassador",
      Mo: "Roseberry",
      Str: "28 Mahi Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-ambassador",
      Mo: "Julington",
      Str: "591 Mahi Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-ambassador",
      Mo: "Roseberry",
      Str: "603 Mahi Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-ambassador",
      Mo: "San Tropez",
      Str: "613 Mahi Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3514,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-heritage",
      Mo: "Captiva Elite",
      Str: "310 Gulf Stream Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-heritage",
      Mo: "Anastasia Elite",
      Str: "286 Barbados Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3292,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-heritage",
      Mo: "Mandigo",
      Str: "41 Cayman Cove",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2225,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-heritage",
      Mo: "Anastasia",
      Str: "324 Barbados Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-heritage",
      Mo: "Anna Maria",
      Str: "298 Gulfstream Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-legacy",
      Mo: "Stonebrook",
      Str: "84 Cape May Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2951,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-legacy",
      Mo: "Greenwood",
      Str: "126 Cape May Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3031,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-legacy",
      Mo: "Stonebrook",
      Str: "138 Cape May Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-legacy",
      Mo: "Franklin",
      Str: "96 Cape May Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-legacy",
      Mo: "Davenport",
      Str: "114 Cape May Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2191,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-legacy",
      Mo: "Cumberland",
      Str: "104 Cape May Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2125,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Delmonico",
      Str: "749 Old Bluff Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Westbrook",
      Str: "695 Old Bluff Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Delmonico",
      Str: "276 Gardiners Bay Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4824,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Westbrook",
      Str: "356 Port Charlotte Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4213,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Delmonico",
      Str: "827 Old Bluff Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4466,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Rinaldi",
      Str: "850 Old Bluff Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Westbrook",
      Str: "432 Port Charlotte Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4373,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Catalina (fl)",
      Str: "193 Old Bluff Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4335,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-signature",
      Mo: "Westbrook",
      Str: "203 Old Bluff Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4263,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Coco Bay 40",
      Mo: "Trevi",
      Str: "Placida Road",
      Ct: "Engelwood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Coco Bay Villas",
      Mo: "Magnolia",
      Str: "Placida Road",
      Ct: "Engelwood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Compass Landing",
      Mo: "Banyan",
      Str: "3387 Pilot Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2422,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22374",
      Co: "Compass Landing  50's",
      Mo: "Admiral",
      Str: "3415 Pilot Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1994,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "22374",
      Co: "Compass Landing  50's",
      Mo: "Admiral",
      Str: "3565 Pilot Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1994,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Hartford",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Trenton",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Providence",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Dover",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Richmond",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Raleigh",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Harrisburg",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Augusta",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2084,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "845000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Preston",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3282,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1425000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Aspen",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1844,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Carlisle",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2627,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1120000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Juneau",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2954,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1180000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Brindley",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2156,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates",
      Mo: "Mcginnis",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3230,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1475000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates Ii",
      Mo: "Mcginnis",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3230,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates Ii",
      Mo: "Preston",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3282,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Estates Ii",
      Mo: "Brindley",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives",
      Mo: "Patterson",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3068,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1415000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives",
      Mo: "Denali",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2647,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1020000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives",
      Mo: "Rainer",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3336,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1485000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives",
      Mo: "Blackburn",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2453,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "955000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives",
      Mo: "Whitney",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3629,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1540000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives Ii",
      Mo: "Whitney",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3629,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives Ii",
      Mo: "Rainer",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3336,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives Ii",
      Mo: "Blackburn",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Executives Ii",
      Mo: "Denali",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Boston",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Concord",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Annapolis",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Atlanta",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Columbia",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Atlanta",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Concord",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1140000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Boston",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1025000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Annapolis",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Manors",
      Mo: "Columbia",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1060000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Townhomes",
      Mo: "Juniper",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1851,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Townhomes",
      Mo: "Mulberry",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1601,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:the Townhomes",
      Mo: "Mulberry",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1601,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Connerton:the Townhomes",
      Mo: "Juniper",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1851,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Copper Creek:premier Collection",
      Mo: "Cheyenne",
      Str: "12667 Copper Creek Dr.",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3252,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Copperleaf 125",
      Mo: "Oakley",
      Str: "5048 Sw Sensation",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "22689",
      Co: "Copperleaf 125",
      Mo: "Aventura",
      Str: "5048 Sw Sensation",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3796,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "1140000"
    },
    {
      Bid: "22680",
      Co: "Copperlefe 40",
      Mo: "Marsala",
      Str: "11209 Copperlefe Drive",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Copperlefe 40",
      Mo: "Trevi",
      Str: "11209 Copperlefe Drive",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Copperspring",
      Mo: "Do Not Use Moved To 1528876",
      Str: "7119 Emerald Spring Loop",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34653,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Copperspring:the Estates",
      Mo: "Do Not Use Moved To 1528976",
      Str: "Emerald Spring Loop",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34653,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1410000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Wheatley",
      Str: "8480 Nw 39th Ct",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3345,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Vitale",
      Str: "9134 Nw 39th Street",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3229,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Other",
      Str: "3811 Nw 87th Way",
      Ct: "Coarl Springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2778,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6701",
      Co: "Coral Logo",
      Mo: "Edinburgh",
      Str: "8520 Nw 39th Ct",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Cordoba",
      Mo: "Carrington",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3366,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Cordoba",
      Mo: "Do Not Use Moved To 6653474",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1605000"
    },
    {
      Bid: "22680",
      Co: "Cordoba:cordoba Estates",
      Mo: "Avila",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3033,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Cordoba:cordoba Estates",
      Mo: "Ventura",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5596,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Trailside",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Trailside Select",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Whitestone",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Mystique Grand",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "442000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Yorkshire",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Mystique",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Aventura",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Regalia",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2713,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Layton",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "5",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Oakley",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "743000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Sanibel",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "987000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Madeira",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Mystique",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Palmary",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Prosperity",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Prestige",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Estate",
      Mo: "Stellar",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Estate",
      Mo: "Stardom",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Garden",
      Mo: "Compass",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Garden",
      Mo: "Hallmark",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Garden",
      Mo: "Contour",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Villas",
      Mo: "Colton",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1322,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "186000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Halle",
      Str: "610 Nw 5th Lane",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Trevi",
      Str: "610 Nw 5th Lane",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Elan",
      Str: "610 Nw 5th Lane",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Halle",
      Str: "610 Nw 5th Ln",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Trevi",
      Str: "610 Nw 5th Ln",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Elan",
      Str: "610 Nw 5th Ln",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing",
      Mo: "Harrisburg",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing",
      Mo: "Hartford",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 30",
      Mo: "Atlanta",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 30",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 30",
      Mo: "Boston",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 50",
      Mo: "Santa Fe",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2606,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 50",
      Mo: "Helena",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2985,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 50",
      Mo: "Cheyenne",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3246,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing Townhomes",
      Mo: "Bennett",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing Townhomes",
      Mo: "Avery",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Harrisburg",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Hartford",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Dover",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Trenton",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Raleigh",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Raleigh",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "437000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Harrisburg",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Hartford",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "308000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Dover",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1551,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "266000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:executive Homes",
      Mo: "Trenton",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Helena",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2985,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Phoenix",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2485,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Sante Fe",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2606,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Cheyenne",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3246,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Sante Fe",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2606,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "403000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Phoenix",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2485,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Helena",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2985,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "468000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:manor Homes",
      Mo: "Cheyenne",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3246,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Columbia",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Atlanta",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Boston",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Atlanta",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Columbia",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:patio Homes",
      Mo: "Boston",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:townhomes",
      Mo: "Avery",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:townhomes",
      Mo: "Bennett",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:townhomes",
      Mo: "Avery",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1405,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Crane Landing:townhomes",
      Mo: "Bennett",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1347,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "Creekside@twin Creeks:creekside 63' Imperial Collection",
      Mo: "Medallion Bonus",
      Str: "236 Switchgrass Rd",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Arlington",
      Str: "Orange Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3036,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Scottsdale",
      Str: "Orange Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3719,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1125000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Manor",
      Str: "Orange Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1000000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Wrangler",
      Str: "Orange Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4080,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1150000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Wrangler",
      Str: "Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4080,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1150000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Arlington",
      Str: "Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3036,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Scottsdale",
      Str: "Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3719,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1125000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Manor",
      Str: "Dr And Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3210,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1000000"
    },
    {
      Bid: "22690",
      Co: "Cypress Cay",
      Mo: "Marigold",
      Str: "4511 Cypress Cay Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22690",
      Co: "Cypress Cay",
      Mo: "Springdale",
      Str: "4511 Cypress Cay Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22680",
      Co: "Cypress Creek 18 Th",
      Mo: "6-unit Common Building-acc",
      Str: "Common Building 0113-0118",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9618,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1205000"
    },
    {
      Bid: "22691",
      Co: "Cypress Falls Classic",
      Mo: "Prosperity",
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22691",
      Co: "Cypress Falls Classic",
      Mo: "Prestige",
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "364000"
    },
    {
      Bid: "22691",
      Co: "Cypress Falls Classic",
      Mo: "Mystique",
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 40'",
      Mo: "Daniel",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 40'",
      Mo: "Mcnair",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 40'",
      Mo: "Tybee",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "399000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Cedar",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Spruce",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "387000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Tower",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Whitestone",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Highgate",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "369000"
    },
    {
      Bid: "22680",
      Co: "Cypress Mill:the Estates",
      Mo: "Do Not Use Moved To 1519276",
      Str: "Cypress Village Blvd",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1365000"
    },
    {
      Bid: "22680",
      Co: "Cypress Park Estates",
      Mo: "Dover",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cypress Park Estates",
      Mo: "Hartford",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cypress Park Estates",
      Mo: "Providence",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Dawn",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Raleigh Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Dover Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Honor",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2754,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Hartford Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Providence Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Trenton Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Richmond Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Harrisburg Ii",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Celeste",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Georgia",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Eclipse",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Nova",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3041,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Aura",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1343,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "170000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Columbia",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Estates",
      Mo: "Bloom",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Belmont",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Annapolis",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "185000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Atlanta",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Concord",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Albany",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Edison",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Abbey",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1263,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Boston",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Columbus",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Jefferson",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Georgia",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Cypress Point At Lake Parker:the Manors",
      Mo: "Columbia",
      Str: "873 Hour Glass Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Maple",
      Str: "Sw 31st Ave And 50th St",
      Ct: "Dania Beach",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Holly",
      Str: "Sw 31st Ave And 50th St",
      Ct: "Dania Beach",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Oak",
      Str: "Sw 31st Ave And 50th St",
      Ct: "Dania Beach",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1854,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Oak",
      Str: "4900 Sw 31st Ave",
      Ct: "Fort Lauderdale",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1854,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Holly",
      Str: "4900 Sw 31st Ave",
      Ct: "Fort Lauderdale",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1689,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Maple",
      Str: "4900 Sw 31st Ave",
      Ct: "Fort Lauderdale",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1848,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Azure",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1330,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Azure",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1330,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Sunglow",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Sunglow",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Magenta",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Azure",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1330,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Magenta",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1414,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:horizon Collection",
      Mo: "Sunglow",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1545,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Surfside",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1813,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Neptune",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Breezeway",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1338,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Hurley",
      Str: "35605 Sw 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1694,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Hurley",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1694,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Neptune",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1815,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Breezeway",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1338,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Surfside",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1813,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Del Mar:seaview Collection",
      Mo: "Caspian",
      Str: "35605 Sw 180 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1423,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Distinctive",
      Mo: "Palmary",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Distinctive",
      Mo: "Mainstay",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Distinctive",
      Mo: "Prestige",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Distinctive",
      Mo: "Mystique",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Distinctive",
      Mo: "Prosperity",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Echelon",
      Mo: "Stardom",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Echelon",
      Mo: "Stellar",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Echelon",
      Mo: "Reverence",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Echelon",
      Mo: "Renown",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Echelon",
      Mo: "Stellar Grand",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Scenic",
      Mo: "Compass",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Scenic",
      Mo: "Hallmark",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Scenic",
      Mo: "Contour",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb At Viera - Villa",
      Mo: "Ellenwood",
      Str: "8926 Coventina Way",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Distinctive",
      Mo: "Prosperity",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "391000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Distinctive",
      Mo: "Mystique",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Distinctive",
      Mo: "Mainstay",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "408000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Distinctive",
      Mo: "Mystique Grand",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "476000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Distinctive",
      Mo: "Prestige",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Echelon",
      Mo: "Stardom",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "483000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Echelon",
      Mo: "Stellar",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "493000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Echelon",
      Mo: "Renown",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "507000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Echelon",
      Mo: "Stellar Grand",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Scenic",
      Mo: "Contour",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "339000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Scenic",
      Mo: "Compass",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "341000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Scenic",
      Mo: "Hallmark",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Villa",
      Mo: "Ellenwood",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Bayview Villa",
      Mo: "Kendrick",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mystique Grand",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mainstay Grand",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "467000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Prosperity",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mystique",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "362000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Palmary",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "372000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mainstay",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Stellar",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "473000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Renown",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2710,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "488000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Stellar Grand",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "568000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Prestige",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "398000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Stardom",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "436000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Garden",
      Mo: "Contour",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "269000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Garden",
      Mo: "Compass",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Garden",
      Mo: "Hallmark",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "346000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Prosperity",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Palmary",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Mainstay",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Prestige",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Mystique",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "456000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Stardom",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Stellar",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Mainstay Grand",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Renown",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "542000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Mystique Grand",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Stellar Grand",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Reverence",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "533000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Scenic",
      Mo: "Contour",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "281000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Scenic",
      Mo: "Compass",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "294000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Scenic",
      Mo: "Hallmark",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Heron",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1457,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Egret",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1538,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "105000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Spoonbill",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "110000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Cormorant",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1868,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "115000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples- Villas",
      Mo: "Seagrove",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples- Villas",
      Mo: "Cascadia",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples- Villas",
      Mo: "Kendrick",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-distinctive",
      Mo: "Summerwood",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "261000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-distinctive",
      Mo: "Dolce",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1570,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "249000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-distinctive",
      Mo: "Prosperity",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-distinctive",
      Mo: "Mystique",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-distinctive",
      Mo: "Palmary",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "288000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-distinctive",
      Mo: "Prestige",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-echelon",
      Mo: "Stardom",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-echelon",
      Mo: "Stellar",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "348000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-echelon",
      Mo: "Reverence",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-echelon",
      Mo: "Renown",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "394000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-echelon",
      Mo: "Layton",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-echelon",
      Mo: "Layton Grande",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-scenic",
      Mo: "Kendrick",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "237000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-scenic",
      Mo: "Hallmark",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "244000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-scenic",
      Mo: "Contour",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "337000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-scenic",
      Mo: "Compass",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "339000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Prosperity",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1599,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Mystique",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "362000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Palmary",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "372000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Mainstay",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Mystique Grand",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Prestige",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "443000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Stardom",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "436000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Stellar",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "477000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Renown",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2710,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "487000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Stellar Grand",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3361,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "572000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee Villas",
      Mo: "Ellenwood",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1529,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "263000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Prosperity",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mystique",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Palmary",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mainstay",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mainstay Grand",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mystique Grand",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Prestige",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Stardom",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Stellar",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Reverence",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "534000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Renown",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Stellar Grand",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Villas",
      Mo: "Ellenwood",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Prosperity",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mystique",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Palmary",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mainstay",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Prestige",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mainstay Grand",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2682,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "511000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mystique Grand",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "521000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Stardom",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Stellar",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Reverence",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Renown",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "551000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Stellar Grand",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "612000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Scenic",
      Mo: "Contour",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Scenic",
      Mo: "Compass",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "324000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Scenic",
      Mo: "Hallmark",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Villas",
      Mo: "Ellenwood",
      Str: "6209 Citrus Grove Ct",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mystique",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "362000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Palmary",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "372000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mainstay",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Prestige",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "384000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mainstay Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mystique Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "468000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Prosperity",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "319000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Stardom",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "438000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Stellar",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Renown",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "473000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Stellar Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "571000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Contour",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "268000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Compass",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Hallmark",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "351000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Compass Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1984,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "356000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Villa",
      Mo: "Ellenwood",
      Str: "128 Eureka Court",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1529,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails Sfh 45s",
      Mo: "Jacaranda",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails Sfh 45s",
      Mo: "Willow",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Villas",
      Mo: "Orchid",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1305,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Villas",
      Mo: "Hibiscus",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Villas",
      Mo: "Larkspur",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Willow",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Magnolia",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Beech",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1410,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Cypress",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Sycamore",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2345,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Jacaranda",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Willow",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2509,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Magnolia",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1695,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Cypress",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1507,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Jacaranda",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1610,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Jacaranda Do Not Use",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1610,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Sycamore",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2353,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Delray Trails:the Woods",
      Mo: "Beech",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1410,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Pollock",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3539,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Kinkade",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3895,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "2693",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "3",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Miramar Iii",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3993,
      Sto: "3",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Anna Maria",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Roarke Ii",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3514,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "557000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: 2693,
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2705,
      Sto: 3,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Pollock",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3539,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Miramar",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Anna Maria",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2261,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Ayden",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1914,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Miramar Iii",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3993,
      Sto: 3,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Kinkade",
      Str: "Selling Offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3895,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Cedar",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Highgate",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Mystique",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "323000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Trailside Select",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Whitestone",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Yorkshire",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "458000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Mystique Grand",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "413000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Ashby",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Easley",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "364000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Ashby Grand",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Oakhurst",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "517000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Easley Grand",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "571000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 52'",
      Mo: "Prosperity",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 52'",
      Mo: "Mainstay",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 52'",
      Mo: "Prestige",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 52'",
      Mo: "Mystique",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 52'",
      Mo: "Palmary",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 66'",
      Mo: "Stardom",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "452000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 66'",
      Mo: "Stellar",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 66'",
      Mo: "Reverence",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "534000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Tradition 66'",
      Mo: "Renown",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "562000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Traditions Villas",
      Mo: "Cressida",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "Dw @ Traditions Villas",
      Mo: "Ellenwood",
      Str: "12914 Sw Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "Dw Lakewood Ranch 65'",
      Mo: "Renown",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "628000"
    },
    {
      Bid: "22691",
      Co: "Dw Lakewood Ranch 65'",
      Mo: "Stardom",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "511000"
    },
    {
      Bid: "22691",
      Co: "Dw Lakewood Ranch 65'",
      Mo: "Reverence",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "601000"
    },
    {
      Bid: "22691",
      Co: "Dw Lakewood Ranch 65'",
      Mo: "Stellar",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "559000"
    },
    {
      Bid: "22680",
      Co: "Eagle Creek Village L P-load",
      Mo: '0067a - Messina End "a"',
      Str: "10197 Eagle Creek Center Blvd  FL 32832",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1702,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:classic Collection",
      Mo: "Springsteen",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2000,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:classic Collection",
      Mo: "Walsh",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1845,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:classic Collection",
      Mo: "Joplin",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Trevi",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Halle",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Nina",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2338,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Springsteen",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2000,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Hudson",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1815,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Newlin",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1474,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Medallion",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2654,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Ramsey",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1667,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Oxford",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Littleton",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Walsh",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1845,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Ryder",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2117,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Tivoli",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Princeton",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Hadley",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2503,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Paxton",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2033,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Eagle's Ridge:watermill Collection",
      Mo: "Joplin",
      Str: "4665 Eagles Ridge Rd",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Econ Trails",
      Mo: "Sabal",
      Str: "9806 Red Eagle Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32825,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1742,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 50s",
      Mo: "Trevi",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 50s",
      Mo: "Charle",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 50s",
      Mo: "Trevi Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 60s",
      Mo: "Tivoli",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 60s",
      Mo: "Medallion",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 60s",
      Mo: "Tivoli Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 60s",
      Mo: "Princeton",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail:edenbrooke At Hyland Trail 60s",
      Mo: "Princeton Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Independence Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2250,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Charle",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Sierra Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2257,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Trevi",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Halle",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Independence",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Trevi Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Sierra",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Charle Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 50s",
      Mo: "Elan",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Medallion Bonus Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3016,
      Sto: 1,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Princeton Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "361000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Medallion Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Tivoli Ii",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Medallion",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Princeton",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Tivoli",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke At Hyland Trail|active Adult:edenbrooke At Hyland Trail 60s",
      Mo: "Medallion Bonus",
      Str: "3108 Tupelo Loop",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3152,
      Sto: 1,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Edgewood At Everlands:edgewood",
      Mo: "Carlisle",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2629,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Edgewood At Everlands:edgewood",
      Mo: "Hartford",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Edgewood At Everlands:edgewood",
      Mo: "Camellia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9986 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "10026 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9910 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "10010 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9568 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9998 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9978 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "9567 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9585 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Finch",
      Str: "9934 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9819 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9926 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "9970 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "10018 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Finch",
      Str: "9918 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "9831 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9543 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9837 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9843 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "9879 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9825 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9950 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9849 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9586 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9813 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9807 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9555 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Finch",
      Str: "9885 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9873 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9867 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9942 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "9861 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9544 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9580 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9956 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow",
      Str: "9561 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9855 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Petunia",
      Str: "9962 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Sparrow Elite",
      Str: "9562 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison East-executive",
      Mo: "Lavender",
      Str: "9573 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "10053 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "9969 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "10071 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "10005 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "10065 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "9939 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9951 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "10029 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "9933 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "10017 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9644 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "10023 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "9963 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9993 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "9891 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "9999 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "9897 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "10059 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "9927 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9903 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "10083 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9909 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9638 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "10041 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "10077 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "10047 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "10095 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9981 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "10011 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret Elite",
      Str: "9945 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Cardinal Elite",
      Str: "10035 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9987 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9603 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9921 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison East-villa",
      Mo: "Egret",
      Str: "9957 Filament Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10306 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10414 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3967,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10510 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10247 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10384 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10324 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10445 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "11475 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10415 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10277 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3532,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10288 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11428 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11470 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3725,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10379 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10480 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10486 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "10294 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3445,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10498 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10235 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10312 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3417,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "10241 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10433 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "11464 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3177,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10456 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10169 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10402 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10516 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "11476 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "10421 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10378 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "11446 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10270 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11434 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11499 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4262,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10403 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10390 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3436,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11487 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "810000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "11500 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10468 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3402,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11493 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10439 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10457 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10474 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3595,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "11458 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3303,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10264 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10462 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Roseberry Elite",
      Str: "11506 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3418,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10067 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "11440 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Roseberry Elite",
      Str: "10391 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3188,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "11481 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3199,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10385 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10504 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10427 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Captiva Elite",
      Str: "10492 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3775,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10408 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "10055 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Julington",
      Str: "11463 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10397 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "11457 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "San Tropez",
      Str: "11469 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3399,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10223 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3310,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10396 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "11452 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anastasia Elite",
      Str: "10451 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison-ambassador",
      Mo: "Anna Maria",
      Str: "10187 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10127 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4856,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1205000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10528 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4005,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10529 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4542,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10086 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4006,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10499 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Clarkson",
      Str: "10133 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Clarkson",
      Str: "10103 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Clarkson",
      Str: "10564 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4617,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Westbrook",
      Str: "10522 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3896,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Westbrook",
      Str: "10366 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3958,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10558 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Clarkson",
      Str: "10331 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4366,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Westbrook",
      Str: "10091 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10475 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4293,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10085 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Clarkson",
      Str: "10505 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10570 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Edison",
      Str: "10348 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4081,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10469 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4786,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10552 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Westbrook",
      Str: "10323 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4289,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10523 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5024,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "905000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10158 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4252,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10540 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10485 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4167,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10547 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10511 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4789,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10535 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4697,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10541 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10110 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10092 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1010000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10463 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Westbrook",
      Str: "10546 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10073 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4861,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10104 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4248,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10074 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4632,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10256 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4593,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "11289 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4487,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10420 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Clarkson",
      Str: "10176 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4954,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10311 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4526,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10493 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10534 Silverbrook Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4828,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Westbrook",
      Str: "10079 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3871,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10111 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4542,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1005000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10119 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4320,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Abigail",
      Str: "10098 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4459,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "910000"
    },
    {
      Bid: "6703",
      Co: "Edison-estates",
      Mo: "Delmonico",
      Str: "10080 Koster Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4265,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "10178 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10146 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "11409 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2662,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10009 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11361 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2776,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "11355 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2879,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "11343 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2986,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10141 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "9968 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10086 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10149 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10110 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "11433 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3097,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10003 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10092 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3084,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "11374 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10040 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "11320 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11314 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10062 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3219,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10056 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "9962 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11379 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10034 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "11445 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10010 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2990,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10152 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "11326 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10165 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2859,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10050 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "11412 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2709,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10022 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11439 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10166 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11397 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11405 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11427 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "9956 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2866,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10134 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10107 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "9950 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "11385 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10068 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2943,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "11331 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10104 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2709,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "11380 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3274,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10157 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10185 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "9957 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "10123 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2805,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10021 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2866,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "11417 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10080 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2916,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "9981 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "9951 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2759,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "9980 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11403 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11319 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "11406 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10044 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "9945 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "9963 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10046 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10160 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10028 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Captiva",
      Str: "9974 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10140 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3197,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10128 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10179 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11337 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11418 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "9944 Exhibition Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "11350 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10116 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3113,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10172 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2966,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10095 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11424 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "10074 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11338 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11423 Dell Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3041,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10098 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Anastasia",
      Str: "11325 Madelynn Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Mandigo Elite",
      Str: "10173 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Edison-heritage",
      Mo: "Terrano",
      Str: "10122 Illumination Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2966,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "22689",
      Co: "Emerald Pointe",
      Mo: "Springdale",
      Str: "482 Beryl Cove",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "Emerald Pointe",
      Mo: "Marigold",
      Str: "482 Beryl Cove",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "344000"
    },
    {
      Bid: "22374",
      Co: "Emery",
      Mo: "Aspen",
      Str: "10729 Sw Estella Ln",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22374",
      Co: "Emery",
      Mo: "Briar",
      Str: "12640 Eleanor Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Emory",
      Mo: "Dalia",
      Str: "12840 Sw Elenor Drive",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2300,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Grayton",
      Str: "8370 Nw 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Grayton Grand",
      Str: "8370 Nw 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Seacrest",
      Str: "8370 Nw 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Seacrest Grand",
      Str: "8370 Nw 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22680",
      Co: "Enclave At Aloma",
      Mo: "Anna Maria G",
      Str: "4100 Bear Gully Rd.",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Enclave At Boca Dunes",
      Mo: "Null",
      Str: "9476 Kinley Pl",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2475,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6701",
      Co: "Enclave At Boca Dunes",
      Mo: "Bonaire Ii",
      Str: "10161 Brickhill Dr",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Enclave At Boca Dunes",
      Mo: "Other",
      Str: "10222 Akenside Dr",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "Enclave At Boca Dunes",
      Mo: "Bonaire Ii",
      Str: "9424 Kinley Place",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Enclave At Sherwood Park",
      Mo: "Mainstay",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "22689",
      Co: "Enclave At Sherwood Park",
      Mo: "Mystique",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "22689",
      Co: "Enclave At Sherwood Park",
      Mo: "Palmary",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "22689",
      Co: "Enclave At Sherwood Park",
      Mo: "Prestige",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "22689",
      Co: "Epperson Bay",
      Mo: "Mcnair",
      Str: "7913 Yale Harbor Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Citrus Grove",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Sandhill",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3222,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Dockside Grand",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4274,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Dockside",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "424000"
    },
    {
      Bid: "22689",
      Co: "Epperson Signature",
      Mo: "Summerwood",
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Epperson Signature",
      Mo: "Summerwood Grand",
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Epperson Signature",
      Mo: "Citrus Grove",
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22680",
      Co: "Epperson:the Townhomes",
      Mo: "Constitution",
      Str: "31819 Blue Passing Loop",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1760,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22689",
      Co: "Esplanade Lake Club",
      Mo: "Ellenwood",
      Str: "11661 Venetian Lagoon Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22680",
      Co: "Estancia",
      Mo: "Florence",
      Str: "4587 Tivoli Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2122,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Estancia",
      Mo: "Milan",
      Str: "4587 Tivoli Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Estancia:cortona",
      Mo: "Do Not Use Moved To 6651974",
      Str: "29242 Picana Lane",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Estancia:santeri Villas At Estancia",
      Mo: "Newport",
      Str: "4375 Barletta Court",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2117,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Estancia:savona At Estancia",
      Mo: "Crescent",
      Str: "4587 Tivoli Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2509,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Easley",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "592000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Whitestone",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "612000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Renown",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "614000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Oakhurst",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "681000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Ashby Grand",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "688000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Roseland",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "716000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Easley Grand",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "716000"
    },
    {
      Bid: "22689",
      Co: "Estates At Lakeview Preserve",
      Mo: "Ashby",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "583000"
    },
    {
      Bid: "22690",
      Co: "Estates Of Lake Florence",
      Mo: "Hanover",
      Str: "1067 Michelangelo Lane",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Everbe 20 Th",
      Mo: "Palisade Elev A Craftsman",
      Str: "11309 Rising Sun St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Everbe 20 Th",
      Mo: "Telluride Elev A Craftsman",
      Str: "11309 Rising Sun St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1807,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Foxtail Exterior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "267000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Foxtail Interior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "267000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Trailwood Exterior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Trailwood Interior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Adelaide",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "261000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Benton",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "285000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Haddock",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Caden",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Cedar",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Highgate",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Coral",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1935,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Garnet",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2166,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "353000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Ruby",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2883,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Coral Grand",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "456000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Imperial",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2563,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "411000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Scarlett",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3575,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "573000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Beacon",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1648,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "294000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Dylan",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Kelsey",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2006,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Mabel",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "387000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Talbot",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Mahogany",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "377000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Merlot",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "423000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Monroe",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "466000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Mahogany Grand",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3126,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "473000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Merlot Grand",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3938,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "596000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Tahoe",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2071,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Sonora",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1821,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Brookside",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Autumn",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Westwood",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1647,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Brookside",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Sonora",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1821,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Tahoe",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2071,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Autumn",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Everbe:cottage Alley Collection",
      Mo: "Westwood",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1647,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Everbe:venture Townhomes",
      Mo: "Palisade",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1611,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Everbe:venture Townhomes",
      Mo: "Telluride",
      Str: "6571 Eastwood Cross Dr",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1807,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "Sorento",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "Ayla",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "Sante Fe",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "The Summerville Ii",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "Hartford",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "Emerson",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2152,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "Bellamy",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2079,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Ashby",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Easley",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "376000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Whitestone",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Ashby Grand",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Yorkshire",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Spruce",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "303000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Easley Grand",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Roseland",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "599000"
    },
    {
      Bid: "22374",
      Co: "Forest Park At Wildlight",
      Mo: "Bristol",
      Str: "425 Sweetgum Street",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22689",
      Co: "Forest Park At Wildlight",
      Mo: "Highgate",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Forest Park At Wildlight",
      Mo: "Spruce",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Forest Park At Wildlight",
      Mo: "Tower",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Forest Park At Wildlight",
      Mo: "Whitestone",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Forest Park At Wildlight",
      Mo: "Yorkshire",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "482000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando",
      Mo: "Sandpiper",
      Str: "7644 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando",
      Mo: "Balfour",
      Str: "7793 Four Seasons Blvd",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1599,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando",
      Mo: "Tobago",
      Str: "7764 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando",
      Mo: "Null",
      Str: "7649 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7718 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2129,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Dominica",
      Str: "7720 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2432,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Dominica",
      Str: "7716 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7744 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Other",
      Str: "2159 Limestone Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Thomas",
      Str: "1578 Whitewood Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Dominica",
      Str: "7740 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7739 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Lucia",
      Str: "1570 Whitewood Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Tobago",
      Str: "1590 Whitewood Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Stella",
      Str: "7765 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2118,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Thomas",
      Str: "7733 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "465000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Saint Thomas",
      Str: "7753 Four Seasons Boulevard",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2029,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando 50s",
      Mo: "Other",
      Str: "2117 Antilles Club Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "1619 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "2006 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "121000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "7620 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1995 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "103000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1610 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "2104 Antilles Club Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "7645 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1623 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1634 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "2038 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "114000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1626 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1699 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1659 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1683 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "1663 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1675 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1687 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "7641 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "7600 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1695 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1630 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "105000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "7612 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "1667 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1650 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "7608 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "1651 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Sandpiper",
      Str: "2042 Key Bay Trail",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "7624 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando Villas",
      Mo: "Emerie",
      Str: "7628 Sand Pierre Court",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Parkland",
      Mo: "Cerelia",
      Str: "9019 Leon Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Parkland",
      Mo: "Everett",
      Str: "8956 Leon Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Parkland",
      Mo: "Everett",
      Str: "8995 Leon Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Parkland",
      Mo: "Everett",
      Str: "11748 Fortress Run",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Parkland",
      Mo: "Coral",
      Str: "12130 Bastile Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons Of Orlando",
      Mo: "St Lucia",
      Str: "2051 Flora Pass Place",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1967,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons Orlando",
      Mo: "Emerie",
      Str: "1908 Flora Pass Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Fox North 40's Resort",
      Mo: "Alexander Palm",
      Str: "Westside Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Fox North 40's Resort",
      Mo: "Alexander Palm",
      Str: "Westside Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Fox North 40's Resort",
      Mo: "Majesty Palm",
      Str: "Westside Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "198 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "74 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "218 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "235 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "111 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "165 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "176 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "182 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "143 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "164 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "150 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "226 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "252 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "179 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "242 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "247 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "193 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "58 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "115 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "89 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "203 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2668,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "170 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "158 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "256 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "149 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "139 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "204 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "185 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "119 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "211 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "212 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "97 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "190 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "248 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "105 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "157 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2682,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "66 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "255 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "217 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "223 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Tulip",
      Str: "76 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "67 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "19 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "108 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "47 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "90 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Tulip-traditional",
      Str: "76 Liberty Bell Road",
      Ct: "Ponte Verde",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Tulip",
      Str: "94 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "98 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "84 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "36 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "42 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "31 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "98 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "83 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "39 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2820,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Tulip",
      Str: "59 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "25 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Begonia",
      Str: "53 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "75 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "90 Liberty Bell Rd",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Rose",
      Str: "118 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "50 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square At Crosswater",
      Mo: "Lily",
      Str: "28 Carousel Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:phase One",
      Mo: "Medallion",
      Str: "9332 Sw 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:phase One",
      Mo: "Tivoli",
      Str: "9332 Sw 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:phase One",
      Mo: "Trevi",
      Str: "9332 Sw 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:phase One",
      Mo: "Charle",
      Str: "9332 Sw 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:phase One",
      Mo: "Brio",
      Str: "9332 Sw 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Cerelia",
      Str: "11915 Vivar Run",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Other",
      Str: "8839 Leon Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2641,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Flora",
      Str: "8874 Leon Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Cerelia",
      Str: "8848 Leon Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Azure",
      Str: "8985 Leon Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Cerelia",
      Str: "11886 Vivar Run",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Cerelia",
      Str: "11795 Leon Circle South",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2417,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Everett",
      Str: "11806 Vivar Run",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Cerelia",
      Str: "8824 Leon Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Cerelia",
      Str: "8899 Leon Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s",
      Mo: "Other",
      Str: "Loxahatchee Rd",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s On 70s",
      Mo: "Flora",
      Str: "9409 Porto Way",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2525,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s On 70s",
      Mo: "Flora",
      Str: "9137 Porto Way",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 50s On 70s",
      Mo: "Flora",
      Str: "8912 Bastille Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 60s",
      Mo: "Flora",
      Str: "9312 Cantal Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "560000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 60s",
      Mo: "Cerelia",
      Str: "11909 Cantal Circle South",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 60s",
      Mo: "Vera",
      Str: "11925 Cantal Circle South",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2604,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 70s",
      Mo: "Coral",
      Str: "12155 Bastille Circle South",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 70s",
      Mo: "Coral",
      Str: "8867 Bastille Circle East",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "685000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 70s",
      Mo: "Verna",
      Str: "9072 Porto Way",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3615,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 70s",
      Mo: "Coral",
      Str: "9233 Porto Way",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6701",
      Co: "Fs At Parkland 70s",
      Mo: "Other",
      Str: "Loxahatchee Rd",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Fs At Wylder 40s",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Fs At Wylder 50s",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Fs At Wylder Duplex Villas",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22689",
      Co: "Gc Everglades 67'",
      Mo: "Stonewater W/ Loft",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Gc Everglades 67'",
      Mo: "Pinnacle",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "567000"
    },
    {
      Bid: "22689",
      Co: "Gc Everglades 67'",
      Mo: "Stonewater",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Gc Everglades 67'",
      Mo: "Tangerly Oak",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Cascade",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Grove",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Grove",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Cascade",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Parking Site",
      Str: "4113 Jespersen St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1345000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Grove",
      Str: "4113 Jespersen St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2135,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Buffer Site",
      Str: "4113 Jespersen St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1345000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:cottage Collection",
      Mo: "Cascade",
      Str: "4113 Jespersen St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2023,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:estate Collection",
      Mo: "Joshua",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2130,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:estate Collection",
      Mo: "Allison",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2781,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:estate Collection",
      Mo: "Allison",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2781,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:estate Collection",
      Mo: "Douglas",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:estate Collection",
      Mo: "Aspen",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Alexia",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Boone",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Hailey",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1950,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Hailey",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Kate",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Alexia Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Boone",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Elan Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Hailey Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Kate Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1774,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Alexia",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s",
      Mo: "Boone Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2089,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Boone Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2089,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Hailey",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Alexia",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Hailey Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Kate",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Boone",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 40s Phase 3",
      Mo: "Alexia Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Brio",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Charle Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Trevi Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Charle Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Trevi",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Charle",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Halle",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Trevi Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Halle Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Trevi Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Live-work-learn",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2259,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Trevi Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Charle",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Halle",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Brio Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Halle Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Brio",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Charle Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 50s",
      Mo: "Trevi",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Princeton Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Tivoli Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Tivoli",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Medallion Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Tivoli",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Medallion",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Princeton",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Medallion",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Medallion Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Princeton Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Princeton",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Medallion",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Patriot",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3004,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Tivoli",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:granary Park 60s",
      Mo: "Tivoli Ii",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Spruce",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Cedar",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Highgate",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Tower",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "412000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Whitestone",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Yorkshire",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "477000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Medallion",
      Str: "19384 Sw 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Princeton",
      Str: "19384 Sw 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Tivoli",
      Str: "19384 Sw 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Medallion",
      Str: "19384 Sw 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Princeton",
      Str: "19384 Sw 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Tivoli",
      Str: "19384 Sw 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22689",
      Co: "Grayson Square",
      Mo: "Marigold",
      Str: "2945 Clever Lane",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Grayson Square",
      Mo: "Springdale Ii",
      Str: "2945 Clever Lane",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1907,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22680",
      Co: "Greenview At Presidential:estate Collection",
      Mo: "Sommerset",
      Str: "Ne 18 Avenue And 194 Th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33179,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3995,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2030000"
    },
    {
      Bid: "22680",
      Co: "Greenview At Presidential:estate Collection",
      Mo: "Ruby",
      Str: "Ne 18 Avenue And 194 Th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33179,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4104,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2175000"
    },
    {
      Bid: "22680",
      Co: "Greenview At Presidential:presidential Collection",
      Mo: "Ruby",
      Str: "18 And 194 Th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33179,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4104,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2175000"
    },
    {
      Bid: "22374",
      Co: "Halo At Emery 50's",
      Mo: "Rowan",
      Str: "12816 Sw Eleanor Dr",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13026 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12996 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13077 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13042 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13050 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13078 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12980 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12969 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12977 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13010 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12948 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12952 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13009 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13046 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13005 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13049 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13001 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12953 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13065 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12944 Pembroke Dr",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12985 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13057 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Seagrape",
      Str: "13022 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1905,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12940 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2064,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13025 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12968 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13073 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13053 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13002 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "12976 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13081 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Buttonwood",
      Str: "13058 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13033 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13034 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12973 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13045 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1973,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13061 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13037 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12984 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13013 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13086 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12972 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12965 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13054 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13066 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Buttonwood",
      Str: "12993 Pembroke Dr",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13030 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12988 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13041 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13082 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12961 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13029 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13062 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12957 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12992 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12997 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Seagrape",
      Str: "12989 Pembroke Dr.",
      Ct: "Naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1905,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Ashby",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "466000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Easley",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "484000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Yorkshire",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Oakhurst",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Ashby Grand",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "547000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Easley Grand",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Roseland",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Highgate",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "393000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Mystique",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "399000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Tower",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Whitestone",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Mystique Grand",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Yorkshire",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes At River Ha",
      Mo: "Cedar",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "314000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes At River Ha",
      Mo: "Highgate",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes At River Ha",
      Mo: "Mystique",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes At River Ha",
      Mo: "Whitestone",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes At River Ha",
      Mo: "Yorkshire",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "584000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Venice",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Trevi",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Monte Carlo",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Marsala",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Amalfi",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Trevi",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "The Princeton Ii",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "383000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Navona",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2765,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "454000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Capri",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Monte Carlo",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Amalfi",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "The Princeton Ii",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Venice",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Marsala",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "Tivoli",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes At River Hall",
      Mo: "The Summerville Ii",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:estate Homes",
      Mo: "Oakmont Ii",
      Str: "Gasparilla Road",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:estate Homes",
      Mo: "Sunset",
      Str: "Gasparilla Road",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:estate Homes",
      Mo: "Oakmont Ii",
      Str: "8006 Lakes Edge Ln",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2361,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:estate Homes",
      Mo: "Sunset",
      Str: "8006 Lakes Edge Ln",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:executive Homes",
      Mo: "Trevi",
      Str: "Gasparilla Road",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:executive Homes",
      Mo: "Venice",
      Str: "8006 Lakes Edge Ln",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:executive Homes",
      Mo: "Marsala",
      Str: "8006 Lakes Edge Ln",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:executive Homes",
      Mo: "Trevi",
      Str: "8006 Lakes Edge Ln",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Cedar",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "391000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Prestige",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Highgate",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Burbank",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "566000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Whitestone",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Contour",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Compass",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Hallmark",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Maritime",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1927,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Seaport",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2068,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Boone",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Kate",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Chloe",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Alexia",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Boone",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Alexia",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Chloe",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Chloe",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Kate",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Boone",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Classic Collection",
      Mo: "Alexia",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Townhome Collection",
      Mo: "Lincoln",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Townhome Collection",
      Mo: "Truman",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Townhome Collection",
      Mo: "Truman",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Townhome Collection",
      Mo: "Lincoln",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Townhome Collection",
      Mo: "Lincoln",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:hardwick Farms - Townhome Collection",
      Mo: "Truman",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22374",
      Co: "Harmony 20's-th",
      Mo: "Seaside Ii End",
      Str: "11928 Sky Acres Terrace",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1720,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22374",
      Co: "Harmony 20's-th",
      Mo: "Marina Ii",
      Str: "11936 Sky Acres Terrace",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1549,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Harmony 30's-th",
      Mo: "Driftwood Ii End",
      Str: "5540 Coachwood Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1729,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22374",
      Co: "Harmony 30's-th",
      Mo: "Driftwood End",
      Str: "5557 Coachwood Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1333,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Ivy",
      Str: "14800 Sw 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Lotus",
      Str: "14800 Sw 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1878,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Ivy",
      Str: "14800 Sw 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Orchid",
      Str: "14800 Sw 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Ivy",
      Str: "14800 Sw 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony:harmony Hawthorn And Cherry Hill",
      Mo: "Provincetown",
      Str: "3326 Grande Heron Dr.",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Harmony:harmony Hawthorn And Cherry Hill",
      Mo: "Eastham",
      Str: "3326 Grande Heron Dr.",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2054,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Heston",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Medina",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Winthrop",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Tilden",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Yellowstone",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Cresswind",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Estate",
      Mo: "Bloomfield",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Estate",
      Mo: "Everly",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2703,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "446000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Mill Run",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Talon",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "432000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Whitmore",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3162,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "521000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Daylen",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Ths",
      Mo: "Marigold",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Ths",
      Mo: "Springdale",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Hawks Glen",
      Mo: "Albany 2020",
      Str: "24th St Ne Rd",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1407,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Monroe",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Mahogany Grand",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3129,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Merlot Grand",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3964,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "572000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Mahogany",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "476000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Merlot",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2635,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "493000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Roseland",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Ashby",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "421000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Easley",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Yorkshire",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Ashby Grand",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Oakhurst",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Roseland",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Easley Grand",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "729000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Bungalows",
      Mo: "Juniper",
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2038,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Bungalows",
      Mo: "Capri",
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1820,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Bungalows",
      Mo: "Monterey",
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2195,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Classics",
      Mo: "Highgate",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "266000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Classics",
      Mo: "Spruce",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Classics",
      Mo: "Tower",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Classics",
      Mo: "Whitestone",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Classics",
      Mo: "Mystique",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park At Wildlight Classics",
      Mo: "Yorkshire",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Hawthorne At Galiano Pointe:hawthorne Collection",
      Mo: "Mulberry",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2806,
      Sto: 1,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "22680",
      Co: "Hawthorne At Galiano Pointe:hawthorne Collection",
      Mo: "Willow",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3468,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "22680",
      Co: "Hawthorne At Galiano Pointe:hawthorne Collection",
      Mo: "Edlin",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2403,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "22680",
      Co: "Hawthorne At Galiano Pointe:hawthorne Collection",
      Mo: "Fontana",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2548,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "22680",
      Co: "Hawthorne At Galiano Pointe:hawthorne Collection",
      Mo: "Caraway",
      Str: "13319 Sw 184th Ter Ste 102",
      Ct: "Miami",
      St: "FL",
      Zip: 33177,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2086,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Hailey",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Jessica",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Brio",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Elan",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Trevi",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Halle Ii",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Alexia",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Brio Ii",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Halle",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Charle",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Halle Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Spirit",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1868,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Alexia",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Brio",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Halle",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Trevi",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Chloe",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Charle",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Jessica",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1503,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Alexia Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Charle Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Hailey Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "291000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Eclipse",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2449,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Hailey",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1951,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Brio Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Dawn",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2172,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Trevi Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Elan Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Enclave",
      Mo: "Elan",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Trevi",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Halle",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Charle",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Hailey",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Alexia",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Brio",
      Str: "1875 Nw 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Charle",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Alexia",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Hailey",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1951,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Spirit",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1868,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Elan Ii",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1545,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Brio",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Chloe",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Jessica",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1503,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Halle",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Elan",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:heath Preserve - The Meadows",
      Mo: "Trevi",
      Str: "1875 Nw 44th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Heathwood Reserve:single-family Homes",
      Mo: "Atlanta",
      Str: "Virtual Whc",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33463,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills Villas",
      Mo: "St. Marten",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "190000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills Villas",
      Mo: "St. Lucia",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "175000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills:heritage Hills Villas",
      Mo: "St. Lucia",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "175000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills:heritage Hills Villas",
      Mo: "St. Marten",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills:heritage Hills Villas",
      Mo: "St. Lucia",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "175000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Napoli Ii",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Agostino Ii",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2796,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Sunset",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Napoli Grande",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Napoli Grande",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3025,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Napoli Ii",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2395,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Agostino Ii",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2796,
      Sto: 3,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2799,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Sunset",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "National",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3473,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:estate Homes",
      Mo: "Toscana",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2553,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Isabella",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Maria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Angelina",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Maria",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2247,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1850,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Angelina",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2061,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:executive Homes",
      Mo: "Isabella",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2246,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "Maria",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2247,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "The Princeton",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2245,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "The Summerville",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2445,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1850,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "The Stanford",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:manor Homes",
      Mo: "The Cornell",
      Str: "14701 Heritage Landing Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3332,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Marina",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "296000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Canopy",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1972,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Driftwood",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Citrus Grove",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "397000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Tower",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Sandhill",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Highgate",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Whitestone",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Cedar",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Palm",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Easley",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "487000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Dockside",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Ashby",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "459000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Henley",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "347000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Trailside",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "411000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Crestwood",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Tropic",
      Str: "12693 Sw Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1565,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Mystique",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Palmary",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1981,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Prestige",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2114,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Ashby",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Tower",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Stellar",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Easley",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Renown",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Whitestone",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Oakhurst",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Highgate",
      Str: "9845 Sw Meridian Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Holly Cove Townhomes",
      Mo: "Jade",
      Str: "708 Holly Lakes Drive",
      Ct: "Orange Park",
      St: "FL",
      Zip: 32073,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1558,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Holly Cove Townhomes",
      Mo: "Mia",
      Str: "708 Holly Lakes Drive",
      Ct: "Orange Park",
      St: "FL",
      Zip: 32073,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1483,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Holly Cove Townhomes",
      Mo: "Mia",
      Str: "708 Holly Lakes Dr",
      Ct: "Orange Park",
      St: "FL",
      Zip: 32073,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1483,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Holly Cove Townhomes",
      Mo: "Jade",
      Str: "708 Holly Lakes Dr",
      Ct: "Orange Park",
      St: "FL",
      Zip: 32073,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1558,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6701",
      Co: "Horizon Isle",
      Mo: "Other",
      Str: "15221 Blue Peacock Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Newlin",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1474,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Oxford",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Halle",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Ramsey",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1667,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Littleton",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Trevi",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Horizon's Edge",
      Mo: "Elan",
      Str: "5466 Horizon Edge Ln",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Horse Creek At Crosswinds",
      Mo: "Atlanta",
      Str: "Selling Offsite",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Horse Creek At Crosswinds",
      Mo: "Concord",
      Str: "Selling Offsite",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Horse Creek At Crosswinds",
      Mo: "Columbia",
      Str: "Selling Offsite",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:estate Key Collections",
      Mo: "Eclipse",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:estate Key Collections",
      Mo: "Bloom",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:estate Key Collections",
      Mo: "Celeste",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:estate Key Collections",
      Mo: "Dawn",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:manor Key Collection",
      Mo: "Columbus",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:manor Key Collection",
      Mo: "Edison",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2.5,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Hunt Club Groves:manor Key Collection",
      Mo: "Jefferson",
      Str: "2862 Pennachio Dr",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Hunter's Run",
      Mo: "5510 A  - Hastings",
      Str: "4056 Longbow Dr.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2915,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Cresswind",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Heston",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Medina",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2142,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Tilden",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Easley",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2856,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Ashby Grand",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Ashby",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Yorkshire",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3459,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Marsala",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Venice",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Trevi",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Amalfi",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Venice",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Capri For 615 Only",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Marsala",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Independence",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3357,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:executive Homes",
      Mo: "Trevi",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "Tivoli",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "The Summerville Ii",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "The Princeton Ii",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "The Summerville Ii",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "The Princeton Ii",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "Tivoli",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf And Country Club:manor Homes",
      Mo: "The Summerville Ii",
      Str: "20800 Copperhead Dr",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Independence:independence Estates Rear",
      Mo: "Alameda",
      Str: "14827 Bridgewater Crossings Blvd.",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Dover",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Allentown",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Dover",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Boston",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Venice",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Marsala",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Trevi",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Venice",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Capri",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Marsala",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:executive Homes",
      Mo: "Trevi",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:manor Homes",
      Mo: "The Summerville Ii",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:manor Homes",
      Mo: "Tivoli",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:manor Homes",
      Mo: "The Princeton Ii",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:manor Homes",
      Mo: "The Princeton Ii",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:manor Homes",
      Mo: "Tivoli",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:manor Homes",
      Mo: "The Summerville Ii",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:villas",
      Mo: "Magnolia",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes At Coco Bay:villas",
      Mo: "Magnolia",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1417,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22374",
      Co: "Island Village",
      Mo: "Celebration - Aleutian",
      Str: "2204 Celebration Blvd",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22374",
      Co: "Island Village At Celebration ",
      Mo: "Anna Maria ",
      Str: "1823 Beach Ridge Rd",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1745,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Island Village Celebration",
      Mo: "Hatteras",
      Str: "7452 Estuary Lake Loop",
      Ct: "Celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Islands @ Doral - Santorini",
      Mo: "C-cayman (discontinued)",
      Str: "11301 Nw 74 St",
      Ct: "Doral",
      St: "FL",
      Zip: 33178,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1688,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell Elite",
      Str: "8331 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "17709 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1100000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Solana",
      Str: "17705 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "18220 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4481,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Fullerton",
      Str: "18236 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "955000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell Elite",
      Str: "7928 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell Elite",
      Str: "8307 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "995000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "San Giorgio",
      Str: "8409 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1260000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell Elite",
      Str: "8330 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "915000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell",
      Str: "8448 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1065000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Sandpiper",
      Str: "18208 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Sandpiper Elite",
      Str: "7948 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Fullerton",
      Str: "8272 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4600,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1250000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "17713 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "930000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "8326 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "8417 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "San Giorgio",
      Str: "8314 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Solana",
      Str: "7924 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Sandpiper",
      Str: "8239 Nevis Run",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2906,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Solana",
      Str: "8408 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Sandpiper",
      Str: "8342 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "San Giorgio",
      Str: "18228 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1135000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "8306 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "San Giorgio",
      Str: "8437 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1020000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell",
      Str: "8432 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell Elite",
      Str: "8444 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "945000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "San Giorgio",
      Str: "8268 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Sandpiper",
      Str: "8451 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "8452 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Solana",
      Str: "8252 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell Elite",
      Str: "8222 Nevis Run",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1325000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Fullerton",
      Str: "18212 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1110000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Aragon",
      Str: "8323 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Maxwell",
      Str: "8440 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-captiva",
      Mo: "Fullerton",
      Str: "8424 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8167 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8063 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8137 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Palacios",
      Str: "8213 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8110 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8134 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "7995 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "965000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Athena Elite",
      Str: "8170 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8086 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver Elite",
      Str: "8019 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8205 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8050 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8118 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Athena Elite",
      Str: "8114 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8162 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne Elite",
      Str: "7849 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "810000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8042 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8046 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8054 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8066 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne Elite",
      Str: "8098 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver",
      Str: "8062 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8209 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8078 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8126 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8147 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Athena Elite",
      Str: "8171 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8094 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "7837 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8074 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8122 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "17695 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8143 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8082 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery Elite",
      Str: "8175 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8090 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8154 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Palacios",
      Str: "8018 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Athena",
      Str: "8070 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Carver Elite",
      Str: "8059 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8195 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Biscayne",
      Str: "8150 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Palacios",
      Str: "8038 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-sanibel",
      Mo: "Avery",
      Str: "8010 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 40'",
      Mo: "Mcnair",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 40'",
      Mo: "Tybee",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 40'",
      Mo: "Daniel",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "338000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 50'",
      Mo: "Chapman",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "347000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 50'",
      Mo: "Hanover",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 50'",
      Mo: "Seabrook",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 50'",
      Mo: "Thompson",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "384000"
    },
    {
      Bid: "22690",
      Co: "Isles At Bayview 50'",
      Mo: "Wakefield",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8315 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "8428 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8264 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Maxwell",
      Str: "8432 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Maxwell Elite",
      Str: "7908 Redonda Loop",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Maxwell",
      Str: "17773 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "7944 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3293,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Sandpiper Elite",
      Str: "8429 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Solana",
      Str: "8404 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "8264 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8322 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3273,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Maxwell",
      Str: "8440 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Maxwell Elite",
      Str: "18216 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "8276 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Sandpiper Elite",
      Str: "8335 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "17729 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Solana",
      Str: "8248 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8346 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Maxwell",
      Str: "8436 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "17769 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Fullerton",
      Str: "8424 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "860000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Sandpiper",
      Str: "8338 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Solana",
      Str: "17717 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8337 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8417 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3912,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "17737 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "18220 Cayo Largo Place",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "17721 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "8416 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8443 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Solana",
      Str: "8267 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "8412 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "San Giorgio",
      Str: "8437 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1015000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Solana",
      Str: "8408 Cane Bay Court",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "17725 Lucaya Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Solana",
      Str: "8252 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-captiv",
      Mo: "Aragon",
      Str: "8323 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver",
      Str: "7841 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery Elite",
      Str: "7853 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Athena Elite",
      Str: "7861 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver Elite",
      Str: "8221 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver Elite",
      Str: "8376 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "17625 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "17679 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "17629 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver",
      Str: "7832 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "8187 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Athena Elite",
      Str: "8142 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery Elite",
      Str: "8350 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Athena Elite",
      Str: "8149 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Biscayne Elite",
      Str: "7849 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery Elite",
      Str: "8078 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery Elite",
      Str: "8130 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Kingsdale",
      Str: "8217 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery Elite",
      Str: "7836 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "7865 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "17626 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "8354 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver",
      Str: "8134 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "8166 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Palacios",
      Str: "8213 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "7856 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Biscayne",
      Str: "8158 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "17642 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Athena",
      Str: "17661 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "7845 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Kingsdale",
      Str: "17621 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3199,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver Elite",
      Str: "17696 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver",
      Str: "17671 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Athena Elite",
      Str: "8114 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "8146 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "8082 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Athena Elite",
      Str: "8138 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "8090 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Carver",
      Str: "8191 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Biscayne",
      Str: "8066 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Palacios",
      Str: "7857 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2280,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Isles At Lakewood Ranch-sanibe",
      Mo: "Avery",
      Str: "17638 Azul Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 40'",
      Mo: "Juniper",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 40'",
      Mo: "Monterey",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 40'",
      Mo: "Windsor",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2627,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "526000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 50'",
      Mo: "Mystique",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 50'",
      Mo: "Mystique Grand",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "574000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 50'",
      Mo: "Whitestone",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 50'",
      Mo: "Yorkshire",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "624000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 50'",
      Mo: "Valencia",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4342,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "656000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 60'",
      Mo: "Ashby",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 60'",
      Mo: "Easley",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 60'",
      Mo: "Ashby Grand",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "644000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 60'",
      Mo: "Oakhurst",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "731000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 60'",
      Mo: "Upton",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "751000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona 60'",
      Mo: "Easley Grand",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "798000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona Th",
      Mo: "Foxtail",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Isles Of Lake Nona Th",
      Mo: "Trailwood",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "100000"
    },
    {
      Bid: "6701",
      Co: "Ivy Trail 50s",
      Mo: "Other",
      Str: "828 Green Eng St.",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Ivy Trail 60s",
      Mo: "Other",
      Str: "828 Green Eng St.",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "57 Placid Place",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "376 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva Elite",
      Str: "119 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "15 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia Elite",
      Str: "319 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3245,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Mandigo Elite",
      Str: "49 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "445 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3180,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva Elite",
      Str: "45 Placid Place",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4024,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "59 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "306 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "388 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2924,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Mandigo",
      Str: "90 Pine Blossom Trail",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "382 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2690,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "162 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "494 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3099,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "573 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva Elite",
      Str: "293 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3484,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "408 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "402 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2961,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "215 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2721,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "269 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "131 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "508 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "281 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "383 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "326 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "565 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "549 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia Elite",
      Str: "225 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia Elite",
      Str: "542 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva Elite",
      Str: "469 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia Elite",
      Str: "580 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3245,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva Elite",
      Str: "572 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3687,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Terrano",
      Str: "235 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3066,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "564 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3109,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "71 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3421,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anna Maria",
      Str: "191 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3359,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia Elite",
      Str: "520 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "448 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva Elite",
      Str: "68 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "22 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "368 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "37 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia",
      Str: "57 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Captiva",
      Str: "338 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Mandigo",
      Str: "28 Pine Blossom Trail",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-heritage",
      Mo: "Anastasia Elite",
      Str: "56 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Terrano",
      Str: "281 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia",
      Str: "91 Placid Place",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "495 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "460 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3608,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anna Maria",
      Str: "557 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Terrano",
      Str: "331 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2891,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia",
      Str: "549 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Mandigo",
      Str: "436 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva",
      Str: "530 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "470 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "25 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anna Maria",
      Str: "81 Placid Place",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3290,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Terrano",
      Str: "558 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3221,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Mandigo Elite",
      Str: "527 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2612,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Terrano",
      Str: "71 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3268,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "517 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "433 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3402,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "424 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia",
      Str: "484 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "353 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3547,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "507 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "416 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3304,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "343 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "539 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4043,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "365 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anna Maria",
      Str: "68 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "552 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Mandigo",
      Str: "90 Pine Blossom Trail",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Captiva Elite",
      Str: "293 Pine Haven Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3484,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "520 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anastasia Elite",
      Str: "457 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-heritage",
      Mo: "Anna Maria",
      Str: "383 Pine Haven Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "768 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Captiva Elite",
      Str: "128 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "717 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "389 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "281 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "653 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "281 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3821,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Edgewater",
      Str: "104 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3223,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "80 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "116 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "462 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "142 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "741 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3356,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Cassia",
      Str: "448 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2869,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "142 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "90 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4435,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Cassia",
      Str: "263 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Captiva Elite",
      Str: "384 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3854,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "653 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "462 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "778 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Edgewater",
      Str: "186 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "341 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Cassia",
      Str: "700 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Cassia",
      Str: "329 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "118 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "532 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3407,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "54 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "679 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "90 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4437,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "364  Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4134,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "315 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Edgewater",
      Str: "102 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "364  Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4132,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Captiva Elite",
      Str: "485 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4158,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "118 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Captiva Elite",
      Str: "361 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3569,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "593 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "605 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "289 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Captiva Elite",
      Str: "463 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3828,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "353 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3974,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "665 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2990,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "San Tropez",
      Str: "250 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "600 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Cassia",
      Str: "328  Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Cassia",
      Str: "508 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Edgewater",
      Str: "674 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "116 Lakeview Pass Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Captiva Elite",
      Str: "349 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3783,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-ambassado",
      Mo: "Julington",
      Str: "341 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Abigail",
      Str: "168 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Abigail",
      Str: "245 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4777,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "385 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "27 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "223 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Abigail",
      Str: "399 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4221,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Delmonico",
      Str: "250 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4158,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Abigail",
      Str: "374 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4777,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "90 Pavati Point",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4057,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Delmonico",
      Str: "101 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4375,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Delmonico",
      Str: "93 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4078,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Westbrook",
      Str: "118 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4226,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "234 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "90 Pavati Point",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4055,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Julington",
      Str: "93 Pavati Point",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-estates",
      Mo: "Abigail",
      Str: "203 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4660,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Captiva Elite",
      Str: "612 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3992,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "30 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4241,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Captiva Elite",
      Str: "42 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Edgewater",
      Str: "158 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3529,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "703 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Edgewater",
      Str: "186 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3222,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "San Tropez",
      Str: "172 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3194,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Edgewater",
      Str: "132 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "San Tropez",
      Str: "717 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Captiva Elite",
      Str: "303 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3753,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Cassia",
      Str: "729 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "San Tropez",
      Str: "691 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "39 Lake Mist Court",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4113,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "277 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "755 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "792 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "Julington",
      Str: "144 Shadow Cove",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3594,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-ambassador",
      Mo: "San Tropez",
      Str: "741 Honey Blossom Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3356,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22680",
      Co: "Killian Grove",
      Mo: "Victoria",
      Str: "87 Sw Th Ave And Killian Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4735,
      Sto: 1,
      Be: 6,
      Ba: 6,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1940000"
    },
    {
      Bid: "22680",
      Co: "Killian Grove",
      Mo: "Niagara",
      Str: "87 Sw Th Ave And Killian Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4032,
      Sto: 2,
      Be: 5,
      Ba: 5,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1665000"
    },
    {
      Bid: "22680",
      Co: "Killian Grove",
      Mo: "Ruby",
      Str: "87 Sw Th Ave And Killian Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4104,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1725000"
    },
    {
      Bid: "22680",
      Co: "Killian Grove",
      Mo: "Silverstone",
      Str: "87 Sw Th Ave And Killian Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4336,
      Sto: 2,
      Be: 5,
      Ba: 5,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1800000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Ridley",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Collier",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1440,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Kowski",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1266,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Durbin",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1438,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Drexel",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1626,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Drexel",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1626,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Durbin",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1438,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Kowski",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1266,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Ridley",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1965,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Collier",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1440,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Providence",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Dover",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Hartford",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Durham",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Hartford",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Oakley",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Linden",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2169,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Laurel",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "La Morada:coach Homes",
      Mo: "Bay Creek",
      Str: "2371 Montserrat Ln #102",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2110,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3735 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Topsail",
      Str: "3753 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3765 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3737 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3787 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Topsail",
      Str: "3769 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3743 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3749 Maxwell Park Dr",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' Th",
      Mo: "Anclote",
      Str: "3759 Laughing Dove Ave",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Egmont",
      Str: "3726 Maxwell Park Dr",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1506,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Seabranch",
      Str: "3750 Maxwell Park Dr",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1908,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Pelican",
      Str: "3748 Maxwell Park Dr",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Egmont",
      Str: "3730 Maxwell Park Dr",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1506,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Oakton",
      Str: "12324 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3467,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Rosada",
      Str: "12107 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Rosada Ii",
      Str: "12285 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Lucerne",
      Str: "12112 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Lucerne",
      Str: "12172 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Rosada Ii Extra Suite",
      Str: "12127 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Lucerne",
      Str: "12124 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Hartwell",
      Str: "12256 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1025000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Elsinore",
      Str: "12120 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2854,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Lucerne",
      Str: "12296 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Rosada Ii Extra Suite",
      Str: "12131 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6701",
      Co: "La Terre At Avenir",
      Mo: "Other",
      Str: "12300 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22680",
      Co: "Labelle Ads",
      Mo: "Hartford",
      Str: "Archilles Terrace",
      Ct: "Labelle",
      St: "FL",
      Zip: 33935,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Hanover",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "279000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Chapman",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Thompson",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Wakefield",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22374",
      Co: "Lake County",
      Mo: "Redwood",
      Str: "2981 Amber Sweet Pl",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Lake Markham Landings",
      Mo: "Champlain",
      Str: "Sold Out",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4154,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Tea Olive",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Camellia",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Pampas",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1797,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Tea Olive",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Summerwood",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Ellenwood",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Martin Ray",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1968,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Palmary",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Prosperity",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Mainstay",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "362000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Prestige",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Stardom",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "429000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Reverence",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Renown",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Stellar",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Mystique",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "396000"
    },
    {
      Bid: "22689",
      Co: "Lakes At Waterway 50'",
      Mo: "Serenity",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1542,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore",
      Mo: "Madeira",
      Str: "15742 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore - Executive Collection",
      Mo: "Madeira",
      Str: "8228 Topsail Pl",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Chestnut",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1819,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Banyan",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1754,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Banyan",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1754,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Chestnut",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1819,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Hawthorne",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Hawthorne",
      Str: "4258 Lagoon Shore Dr",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1690,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Chestnut",
      Str: "4258 Lagoon Shore Dr",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Lakeshore At The Fountains",
      Mo: "Banyan",
      Str: "4258 Lagoon Shore Dr",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1754,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15613 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5909,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1000000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15517 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15522 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Teresina",
      Str: "15619 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3108,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Maranello",
      Str: "15468 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5317,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Bramante",
      Str: "15625 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4798,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Bramante",
      Str: "15631 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4229,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Avanti",
      Str: "15511 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Maranello",
      Str: "15481 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4264,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15669  Panther Lake Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15607 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5354,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15612 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15469 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5781,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15523 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5054,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-estates",
      Mo: "Santa Bella",
      Str: "15606 Giant Foxtail Court",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6245,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15582 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8252 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2662,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15713 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sanibel",
      Str: "15731 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8186 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15748 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sanibel",
      Str: "8253 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sebastian",
      Str: "15707 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Robellini",
      Str: "15624 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15618 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8277 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15724 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira",
      Str: "15730 Cutter Sail Pl",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3943,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8246 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8258 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4226,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8331 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15834 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8192 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "15743 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sebastian",
      Str: "15725 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "15719 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "15655 Sylvester Palm Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8259 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8157 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8198 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8264 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8247 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4019,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sabel",
      Str: "8818 Lakeshore Pointe Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2221,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sanibel",
      Str: "15738 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sebastian",
      Str: "15119 Canoe Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8234 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4137,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8228 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8180 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15606 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8222 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8806 Lakeshore Pointe Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4019,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sanibel",
      Str: "8289 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8295 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15732 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8216 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15754 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8174 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15718 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "8271 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15858 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8830 Lakeshore Pointe Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15742 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15712 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Robellini",
      Str: "15612 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Bavaro",
      Str: "8265 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15588 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "8812 Lakeshore Pointe Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Robellini",
      Str: "15737 Cutter Sail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4334,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sebastian",
      Str: "8270 Topsail Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Madeira (fl)",
      Str: "15130 Canoe Place",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15600 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Gardenia",
      Str: "15535 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-executives",
      Mo: "Sebastian",
      Str: "15810 Shorebird Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Lakeshores At The Fountains",
      Mo: "Plan B",
      Str: "4476 Fountains Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1819,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshores At The Fountains",
      Mo: "Plan A",
      Str: "4476 Fountains Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1754,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshores At The Fountains",
      Mo: "Plan C",
      Str: "4476 Fountains Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Marsala",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Venice",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Trevi",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Monte Carlo",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Trevi",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Amalfi",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Lakespur At Wellen Park",
      Mo: "Prosperity",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "234000"
    },
    {
      Bid: "22689",
      Co: "Lakespur At Wellen Park",
      Mo: "Palmary",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "273000"
    },
    {
      Bid: "22689",
      Co: "Lakespur At Wellen Park",
      Mo: "Prestige",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Lakespur At Wellen Park",
      Mo: "Whitestone",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Lakespur At Wellen Park",
      Mo: "Mystique",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Lakespur At Wellen Park",
      Mo: "Mystique Grand",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22680",
      Co: "Lakespur At Wellen Park",
      Mo: "Trevi",
      Str: "17428 Luminous Ave",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Lakespur At Wellen Park",
      Mo: "Monte Carlo",
      Str: "17428 Luminous Ave",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Lakespur At Wellen Park",
      Mo: "Marsala",
      Str: "17428 Luminous Ave",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Lakespur At Wellen Park",
      Mo: "Venice",
      Str: "17428 Luminous Ave",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Lakespur At Wellen Park",
      Mo: "Amalfi",
      Str: "17428 Luminous Ave",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Lakespur At Wellen Park",
      Mo: "Capri",
      Str: "17428 Luminous Ave",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "673 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "77 Cinnamon Teal Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "688 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "90 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "62 Cinnamon Teal Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "78 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore Elite",
      Str: "661 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "726 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "105 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Barkley",
      Str: "608 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3063,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Dakota",
      Str: "65 Cinnamon Teal Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "637 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "39 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "630 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "47 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Dakota",
      Str: "41 Cinnamon Teal Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "52 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Dakota",
      Str: "676 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Dakota",
      Str: "105 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "611 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Barkley",
      Str: "37 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "471 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "53 Cinnamon Teal Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2457,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "90 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "664 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "685 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore",
      Str: "18 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "134 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore Elite",
      Str: "59 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2920,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "703 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "135 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "124 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "89 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "78 Cinnamon Teal Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "652 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "125 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "618 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "698 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "710 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Dakota",
      Str: "34 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Barkley",
      Str: "640 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "25 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Delmore Elite",
      Str: "73 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Barkley",
      Str: "625 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Barkley",
      Str: "115 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "562 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "68 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "79 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "718 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saddlebrook",
      Str: "78 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "66 Oak Knoll Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "649 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Lakeview At Grand Oaks",
      Mo: "Saratoga",
      Str: "19 Natureland Circle",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3195,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "22689",
      Co: "Lakeview Preserve 50'",
      Mo: "Yorkshire",
      Str: "3276 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Lakeview Preserve 50'",
      Mo: "Whitestone",
      Str: "3276 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "6703",
      Co: "Laurel Pnt Lk Nona-mosaic",
      Mo: "Varen",
      Str: "9028 Santorini Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1295000"
    },
    {
      Bid: "6703",
      Co: "Laurel Pnt Lk Nona-mosaic",
      Mo: "Varen",
      Str: "9076 Santorini Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1340000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Corbeil",
      Str: "9668 English Laurel Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1120000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Dolcetto",
      Str: "9226 Santorini Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Barbera",
      Str: "9636 English Laurel Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1210000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Corbeil",
      Str: "9683 English Laurel Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1190000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Dolcetto",
      Str: "9004 Santorini Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1090000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Corbeil",
      Str: "9328 Santorini Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1410000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-collage",
      Mo: "Barbera",
      Str: "9667 English Laurel Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Discovery",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Discovery",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Dover",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Hartford",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Discovery",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Hartford",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:estate Collection",
      Mo: "Dover",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Columbia",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Annapolis",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Atlanta",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Atlanta",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Annapolis",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Boston",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:manor Collection",
      Mo: "Columbia",
      Str: "101 Kayak Ave",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Freesia",
      Str: "Sw 344th St And Sw 167th Av",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1446,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Tulip",
      Str: "Sw 344th St And Sw 167th Av",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1633,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Aster",
      Str: "Sw 344th St And Sw 167th Av",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1376,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Aster",
      Str: "344 Sw Th St And Sw 167 Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1376,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Tulip",
      Str: "344 Sw Th St And Sw 167 Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1633,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Primrose",
      Str: "344 Sw Th St And Sw 167 Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1574,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Freesia",
      Str: "344 Sw Th St And Sw 167 Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1446,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Leafside Th",
      Mo: "Windsor",
      Str: "Court St",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "22680",
      Co: "Leafside Th",
      Mo: "Hampton",
      Str: "Court St",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Mystique",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Palmary",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Prestige",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Mystique Grande",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Prosperity",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Villa",
      Mo: "Ellenwood",
      Str: "6918 Honey Bell Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "222000"
    },
    {
      Bid: "22680",
      Co: "Lennar At Burnt Store Lakes",
      Mo: "The Summerville Ii",
      Str: "4204 Villa Rapallo Way",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar At Cape Coral",
      Mo: "Hartford",
      Str: "4204 Villa Rapallo Way",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1941,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar At Cape Coral",
      Mo: "Dover",
      Str: "4204 Villa Rapallo Way",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar At Cape Coral",
      Mo: "The Summerville Ii",
      Str: "4204 Villa Rapallo Way",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar At Port Charlotte:executive Homes",
      Mo: "Hartford",
      Str: "1318 Fargo St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Lennar At Port Charlotte:executive Homes",
      Mo: "Dover",
      Str: "1318 Fargo St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Columbus Iii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1677,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Devonshire Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1930,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Canterbury",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1559,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Santa Cruz",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Freedom",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1956,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Alexander Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Royale Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Beach Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Stockton Grande",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3090,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Harrington",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2051,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Hamilton Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Manchester",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1796,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monte Carlo",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Siesta Key",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "St. Croix (rear)",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1371,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Bridgeport",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2157,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Brentwood",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1837,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Bourne",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1971,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monroe Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Berkshire",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1178,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Martinique",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monroe Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "St. John",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Bonita Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1866,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Alexander Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Solana",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2186,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Hawthorne",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Prestwick Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1582,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Hamilton",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Alameda",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Kennedy Ii",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2277,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monaco",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2441,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Brandywine",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4259,
      Sto: "2",
      Be: "6",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Fontana",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Greythorne",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Bloomfield",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Monte Carlo",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Monroe Iii",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Alexander Palm",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Monaco",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2441,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Sawgrass",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2935,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Delray",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Majesty Palm",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "6-unit Shell",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 12957,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Marina",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/mid Florida",
      Mo: "Sabal Palm",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:grand Collection",
      Mo: "Hartford",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:grand Collection",
      Mo: "Dover",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:grand Collection",
      Mo: "Hartford",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:grand Collection",
      Mo: "Atlanta",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:grand Collection",
      Mo: "Harrisburg",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Boston",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Annapolis",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Annapolis",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Atlanta",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Parking Site",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Annapolis",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Columbia",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Atlanta",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Concord",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Boston",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:legacy Collection",
      Mo: "Buffer Site",
      Str: "669 Bell Prairie Cir",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Leomas Landing:leomas Landing 45s",
      Mo: "Atlanta",
      Str: "Highway 27 And Chalet Suzanne Rd",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leomas Landing:leomas Landing 55s",
      Mo: "Hartford",
      Str: "Highway 27 And Chalet Suzanne Rd",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Ridge",
      Mo: "Freedom",
      Str: "112 Pershing Street",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Ridge",
      Mo: "Harrisburg",
      Str: "112 Pershing Street",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Ridge",
      Mo: "Harrisburg",
      Str: "112 Pershing Street",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Village:liberty Village - Phase One",
      Mo: "Trevi",
      Str: "7934 Sw 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:liberty Village - Phase One",
      Mo: "Tivoli",
      Str: "7934 Sw 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:liberty Village - Phase One",
      Mo: "Princeton",
      Str: "7934 Sw 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:liberty Village - Phase One",
      Mo: "Halle",
      Str: "7934 Sw 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:liberty Village - Phase One",
      Mo: "Elan",
      Str: "7934 Sw 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:liberty Village - Phase Two",
      Mo: "Halle",
      Str: "7934 Sw 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Dupont",
      Str: "6774 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Ivanhoe",
      Str: "36838 Precita Terrace",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "1",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Ashmere",
      Str: "6741 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Palmera",
      Str: "6762 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Dupont",
      Str: "6744 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Emerald",
      Str: "36826 Precita Terrace",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6701",
      Co: "Links At Calusa Springs",
      Mo: "Palmera",
      Str: "6978 Shelby Lynn Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Little Lk Thoma 28",
      Mo: "Capri",
      Str: "Little Lake Thomas Rd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Caladesi",
      Str: "19732 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Ballast",
      Str: "2368 White Dogwood Loop",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19752 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19776 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19855 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19896 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Ballast",
      Str: "19858 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Caladesi",
      Str: "19756 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19850 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19884 Tbd Tbd",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2332 White Dogwood Loop",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19714 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2354 White Dogwood Loop",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4650,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19766 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2340 White Dogwood Loop",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19804 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19865 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19722 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19873 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19838 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19740 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19792 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19892 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19704 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19829 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19868 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1080000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19890 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19746 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19837 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19883 Augusta Preserve Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "22680",
      Co: "Livingston Lakes 10 Plex",
      Mo: "Garden Homes 205",
      Str: "Palmer Lake Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34109,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1236,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Lincoln",
      Str: "2252 Windward Cay Lane",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Lincoln",
      Str: "Longbay Road",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Truman",
      Str: "Longbay Road",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Truman",
      Str: "2252 Windward Cay Lane",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Truman",
      Str: "2252 Windward Cay Ln",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Lincoln",
      Str: "2252 Windward Cay Ln",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "283000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Halle",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Charle",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Trevi",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Elan",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Halle",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Charle",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 50s",
      Mo: "Trevi",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:longleaf 60' Classic Collection",
      Mo: "Serenata",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2512,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Venice",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Trevi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Venice",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Monte Carlo",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Marsala",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Trevi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Trevi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Amalfi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Capri",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Venice",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Amalfi",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Independence",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3357,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "920000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Monte Carlo",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Trevi",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:executive Homes",
      Mo: "Marsala",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "The Summerville Ii",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Richmond",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2725,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Sorrento",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Liberation",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "The Princeton Ii",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "The Princeton Ii",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "The Princeton Ii",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Liberation",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3867,
      Sto: 2,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Richmond",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2725,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "The Summerville Ii",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Sorrento",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:manor Homes",
      Mo: "Tivoli",
      Str: "16325 Tradewind Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Orchid",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Magnolia",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Orchid",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Magnolia",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Orchid",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Orchid",
      Str: "15232 Lyla Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1564,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes At Lakewood Ranch:villas",
      Mo: "Magnolia",
      Str: "15232 Lyla Ter",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1417,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:estate Homes",
      Mo: "Oakmont Ii",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:estate Homes",
      Mo: "Sunset",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:estate Homes",
      Mo: "Sunset",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:estate Homes",
      Mo: "Agostino Ii",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2796,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:estate Homes",
      Mo: "National",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:estate Homes",
      Mo: "Wynwood",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lost Key Townhomes",
      Mo: "Sabal Palm",
      Str: "14178 Sable Palm Way",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Lost Key Townhomes",
      Mo: "2br Townhome Upload",
      Str: "14000 Perdido Key Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1500,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Lost Key Townhomes",
      Mo: "Sabal Palm",
      Str: "14178 Sable Palm Way",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1651,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Lynwood:the Estates",
      Mo: "Do Not Use Moved To 6658376",
      Str: "5059 White Chicory Drive",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Madeira",
      Mo: "Trevi",
      Str: "61 Pintoresco Drive",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Magnolia At Pembroke Park",
      Mo: "Lily",
      Str: "Sw 56th  Av And Hallandale Beach Blv",
      Ct: "Pembroke Park",
      St: "FL",
      Zip: 33023,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Court",
      Mo: "Hanover",
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Court",
      Mo: "Chapman",
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Court",
      Mo: "Browning",
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "326000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17121 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "970000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17140 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7544,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1160000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17110 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1360000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Abington",
      Str: "17111 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4910,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17120 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1245000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Sonoma",
      Str: "17131 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4536,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17130 Magnolia Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "22680",
      Co: "Magnolia Park",
      Mo: "Providence",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Cedar",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Highgate",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Mystique",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Trailside",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Whitestone",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "466000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Yorkshire",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "546000"
    },
    {
      Bid: "22680",
      Co: "Majestic Oaks",
      Mo: "Freedom",
      Str: "438 Three Oaks Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Majestic Oaks",
      Mo: "Hartford",
      Str: "438 Three Oaks Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Majestic Oaks",
      Mo: "Dover",
      Str: "438 Three Oaks Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Littleton",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Ramsey",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Newlin",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1474,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Oxford",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Oxford",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Littleton",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Ramsey",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1667,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Ryder",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2117,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Tivoli",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Newlin",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1474,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Paxton",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2033,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Medallion",
      Str: "4603 Carlstrom Ln",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Contour",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Compass",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Hallmark",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Trailside",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "468000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Palmary",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Prestige",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Whitestone",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "541000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Mystique",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22680",
      Co: "Maplewood Park",
      Mo: "Hartford",
      Str: "Martineau Circle",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1941,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Maplewood Park",
      Mo: "Harrisburg",
      Str: "Martineau Circle",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1791,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marbella - Sienna At Estancia",
      Mo: "Talbot",
      Str: "28739 Revaro Lane",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3649,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "22680",
      Co: "Mariner's Cove",
      Mo: "Azure",
      Str: "344 Saint Sw And Krome Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1330,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Mariner's Cove",
      Mo: "Magenta",
      Str: "344 Saint Sw And Krome Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1414,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Mariner's Cove",
      Mo: "Sunglow",
      Str: "344 Saint Sw And Krome Ave",
      Ct: "Florida City",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1545,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Marion Oaks",
      Mo: "Other",
      Str: "230 Marion Oaks Trail",
      Ct: "Ocala",
      St: "FL",
      Zip: 34473,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1282,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Elite",
      Mo: "Astoria",
      Str: "8316 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2223,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Elite",
      Mo: "Everly",
      Str: "8316 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "352000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Elite",
      Mo: "Bloomfield",
      Str: "8316 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "336000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Cresswind",
      Str: "8322 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Heston",
      Str: "8322 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Tilden",
      Str: "8322 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "319000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Winthrop",
      Str: "8322 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Yellowstone",
      Str: "8322 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Medina",
      Str: "8322 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Alexia Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Boone Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2089,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Chloe",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Hailey Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Hailey",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Hailey Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Chloe",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Alexia Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Alexia",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Boone Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2089,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 40's",
      Mo: "Boone",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Charle Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Trevi Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Halle Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Brio Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Charle Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Brio",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Charle",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Trevi Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Brio Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Halle",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 50s",
      Mo: "Halle Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Medallion Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Tivoli Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Princeton Ii",
      Str: "8319 Sw 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Trevi Ii- Model Hs 6 Only",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Princeton Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Tivoli",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Chloe -model Hs 7 Only",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Medallion",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Princeton",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Tivoli Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "339000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch 60's",
      Mo: "Medallion Ii",
      Str: "8319 Sw 46 Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch Townhomes",
      Mo: "Truman",
      Str: "Sw 82nd Place Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:marion Ranch Townhomes",
      Mo: "Lincoln",
      Str: "Sw 82nd Place Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Cresswind",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Heston",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Medina",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Tilden",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Winthrop",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22680",
      Co: "Mckinnon Townhomes",
      Mo: "Isabella",
      Str: "454 Oak Glen Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32534,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "Mckinnon Townhomes",
      Mo: "Oyster",
      Str: "454 Oak Glen Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32534,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1738,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Mckinnon Townhomes",
      Mo: "Isabella",
      Str: "454 Oak Glen Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32534,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1613,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Mckinnon Townhomes",
      Mo: "Oyster",
      Str: "454 Oak Glen Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32534,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1738,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:estate Collection",
      Mo: "Discovery",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:estate Collection",
      Mo: "Newhaven",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1708,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:estate Collection",
      Mo: "Norfolk",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1860,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:estate Collection",
      Mo: "Dover",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:estate Collection",
      Mo: "Sutton",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:legacy Collection",
      Mo: "Marco",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2447,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:legacy Collection",
      Mo: "Largo",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2359,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:legacy Collection",
      Mo: "Lakewood",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2911,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:legacy Collection",
      Mo: "Summerlin",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3174,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Meadow Pointe Hidden Ridge:legacy Collection",
      Mo: "Independence",
      Str: "187 Puma Loop",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3468,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22374",
      Co: "Meridale 60s",
      Mo: "Testmodel",
      Str: "3707 Cheyney Park Drive",
      Ct: "Charlotte",
      St: "FL",
      Zip: 28269,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Meridale 60s",
      Mo: "Testmodel",
      Str: "54 E. Sycamore St.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34231,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks",
      Mo: "Aurora",
      Str: "11562 Buoy Point Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks",
      Mo: "Other",
      Str: "11770 Globe St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - D50",
      Mo: "Talbot",
      Str: "8578 Compass Point Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2051,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - D50",
      Mo: "Pinnacle",
      Str: "7793 Clove Hitch Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11716 Globe Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina Ii",
      Str: "11639 Founders Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Aurora",
      Str: "11927 Sunsail Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina Ii",
      Str: "11746 Globe Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Aurora",
      Str: "11963 Sunsail Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina",
      Str: "11655 Founders Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11782 Globe Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina Ii",
      Str: "11752 Globe Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11660 Founders Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11747 Globe Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11618 Founders Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11818 Sunsail Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11674 Founders Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11986 Sunsail Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11890 Sunsail Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11920 Sunsail Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Belmont",
      Str: "Tbd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks R21",
      Mo: "Catalina",
      Str: "11538 Bouy Point Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "130 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "156 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "140 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "311 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "126 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "261 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "240 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "267 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "56 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "120 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "94 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "257 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "190 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "93 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "328 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "306 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "147 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "113 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "171 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "129 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "154 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "214 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "103 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "207 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "168 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "318 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "252 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "80 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "100 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "59 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "71 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "94 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "42 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "110 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "202 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "277 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "180 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "112 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "226 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "317 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "75 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "87 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "76 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "146 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Hannah",
      Str: "35 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "23 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "48 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "135 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "101 Elm Branch Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Alice",
      Str: "81 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Egan",
      Str: "329 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "68 Gap Creek Dr",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-magnolia",
      Mo: "Robin",
      Str: "58 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "184 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "165 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "296 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "176 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "157 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "290 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "44 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "251 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "105 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "64 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "20 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "20 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "45 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "93 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "204 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "71 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "214 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "44 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "117 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "54 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "165 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "274 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "242 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "201 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "57 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "37 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "59 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "104 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "164 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "203 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Penelope",
      Str: "272 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "26 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "36 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Penelope",
      Str: "32 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "124 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "175 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "164 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "55 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "131 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "146 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "145 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "186 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "123 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "155 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "131 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "44 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "264 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "63 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "47 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "181 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Penelope",
      Str: "84 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "32 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "47 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "45 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "83 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "194 Valley Falls Way",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "54 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "74 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "139 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ella",
      Str: "29 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "67 Gap Creek Drive",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "21 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Ana",
      Str: "31 Waterwheel Ct",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Liam",
      Str: "124 Wye Road",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-meadows",
      Mo: "Penelope",
      Str: "191 Bridgeton Street",
      Ct: "Saint Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North",
      Mo: "Charle",
      Str: "1151 Kendall Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North Townhomes",
      Mo: "Lincoln",
      Str: "1672 Garden Grove Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North Townhomes",
      Mo: "Truman",
      Str: "1672 Garden Grove Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North Townhomes",
      Mo: "Lincoln",
      Str: "1672 Garden Grove Ct",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North Townhomes",
      Mo: "Truman",
      Str: "1672 Garden Grove Ct",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14825 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1490000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "14925 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1465000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14850 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14985 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1345000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "14955 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1895000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "15070 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7578,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "2240000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Callahan",
      Str: "14895 Millstone Ranches Drive",
      Ct: "Davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4705,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1390000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Ridley",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1964,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Elan",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Chloe",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Trevi",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Drexel",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1626,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Brio",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Kowski",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1266,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Princeton",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Collier",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1440,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Tivoli",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Boone",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Halle",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Kowski",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1266,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Alexia",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Medallion",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Elan",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Durbin",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1437,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Ridley",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1964,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Ridley",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1964,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Brio",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Chloe",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Rundle",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1266,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "236000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Drexel",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1626,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Halle",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Boone",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Collier",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1440,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Drexel",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1626,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Hailey",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Trevi",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Tivoli",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Enclave",
      Mo: "Princeton",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Medallion",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Halle",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Princeton",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Tivoli",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Boone",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Elan",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Trevi",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Ridley",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1964,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Brio",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Drexel",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1626,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Drexel",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1626,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Tivoli",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Medallion",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Durbin",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1437,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "180000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Kowski",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1266,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Ridley",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1964,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Hailey",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Belmont",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1428,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Brio",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Princeton",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Ridley",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1964,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Elan",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Boone",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Collier",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1440,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Trevi",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Columbus",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Drexel",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1626,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Chloe",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Abbey",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1263,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Rundle",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1266,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "160000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Halle",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Millwood:millwood Estates - The Meadows",
      Mo: "Alexia",
      Str: "3545 Ne 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Halos",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Daybreak Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Halos  Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Argent Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Eventide Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Halos",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Argent",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Eventide Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2611,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Daybreak Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Eventide",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2611,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Halos  Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Daybreak",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Estates",
      Mo: "Argent Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Morningtide Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Dawning Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Splendor",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Meridian Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Dawning Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2141,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Meridian Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2141,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Morningtide Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Splendor",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Morningtide",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Sunrise Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Dayspring Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Dayspring Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Dayspring Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Sunrise Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Aurora Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Sunrise Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Aurora Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Aurora Ii*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Sunrise Ii",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Aurora Ii*",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1747,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Sunrise Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1398,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Dayspring Ii*",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1616,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Sunrise Ii*",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1398,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Dayspring Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1616,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:active Adult Villas",
      Mo: "Aurora Ii",
      Str: "31800 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1747,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Trenton Ii",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Richmond Ii",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Raleigh Ii",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Hartford",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Harrisburg Ii",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Richmond",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Trenton",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Hartford Ii",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Raleigh Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Harrisburg",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Raleigh",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Harrisburg Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Trenton Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "592000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Dover Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Hartford Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "384000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Richmond",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Providence",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Richmond Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "544000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Providence Ii",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Trenton",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Dover",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Estates Ii",
      Mo: "Hartford",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Boston",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Atlanta",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Concord",
      Str: "31826 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Annapolis",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Concord",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Columbia",
      Str: "31826 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Atlanta",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Annapolis",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Boston",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Manors Ii",
      Mo: "Concord",
      Str: "32109 Hawthorne Cottage Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Estates",
      Mo: "St. Thomas Ii",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Estates",
      Mo: "St. Thomas Ii",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Estates",
      Mo: "St. Thomas Ii",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1666,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1787,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "294000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Palermo",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Palermo",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Capri",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Marisol",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Verona",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2466,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Marisol",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2319,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Verona",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2466,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Capri",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1801,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Town Executives",
      Mo: "Palermo",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2251,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Hampton",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Glenmoor",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Hampton",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Windsor",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Hampton",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Windsor",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1513,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "267000"
    },
    {
      Bid: "22680",
      Co: "Mirada:the Townhomes",
      Mo: "Glenmoor",
      Str: "32076 Pop Ash Pl",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "267000"
    },
    {
      Bid: "22680",
      Co: "Montclair",
      Mo: "Laguna A - 303a",
      Str: "8895 N Military Trail  Suite 101b",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33410,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1799,
      Sto: "3",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Bourne",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1971,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Lexington (40' Model)",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Peabody",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Lexington (40' Model)",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 60",
      Mo: "Marina",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Nature's  Cove",
      Mo: "Plan 2337",
      Str: "1356 Areca Palm Drive",
      Ct: "Gulf Breeze",
      St: "FL",
      Zip: 32563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2337,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Hartford",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "The Summerville Ii",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Hartford",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Dover",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1551,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "Sunset",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Cape Coral:americana Series",
      Mo: "The Summerville Ii",
      Str: "306 Burnt Store Rd",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33991,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Hartford",
      Str: "4016 4th St. West",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Dover",
      Str: "4016 4th St. West",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Dover",
      Str: "4016 4th St. West",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Bloom",
      Str: "4016 4th St W",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Dover",
      Str: "4016 4th St W",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1551,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Hartford",
      Str: "4016 4th St W",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Celeste",
      Str: "4016 4th St W",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Lehigh Acres",
      Mo: "Celeste",
      Str: "4016 4th St W",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Port Charlotte:executive Homes",
      Mo: "Dover",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Port Charlotte:executive Homes",
      Mo: "Hartford",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Port Charlotte:executive Homes",
      Mo: "Dover",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1551,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Port Charlotte:executive Homes",
      Mo: "The Summerville Ii",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "New Homes In Port Charlotte:executive Homes",
      Mo: "Hartford",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "New Homes In West Vero",
      Mo: "Delano",
      Str: "8980 20th St",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1468,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "New Homes In West Vero",
      Mo: "Georgia",
      Str: "8980 20th St",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11351 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11338 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11398 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11405 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11363 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11390 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11378 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11384 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11367 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11357 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11370 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11358 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11360 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11366 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11382 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11396 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11389 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11380 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11392 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11350 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11393 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11409 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11342 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11371 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11344 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11394 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11403 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11372 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11340 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11369 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11336 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11368 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11356 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11391 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11365 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11354 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11395 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11352 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11412 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11334 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11407 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Ibis",
      Str: "11355 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Macaw",
      Str: "11353 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Newton-townhomes",
      Mo: "Lark",
      Str: "11414 Newtonian Blvd",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22690",
      Co: "Normandy Pines",
      Mo: "Landmark",
      Str: "10122 Deep Pine Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1526,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22690",
      Co: "Normandy Pines",
      Mo: "Charter",
      Str: "10122 Deep Pine Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Hanover",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "305000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Seabrook",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "314000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Thompson",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Wakefield",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Yellowstone",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Medina",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "324000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Chapman",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Thompson",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "351000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Bayshore",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "356000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Wakefield",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Medina",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "354000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Everly",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2703,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "379000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Arbor",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2223,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "353000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates",
      Mo: "Dover",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Harrisburg",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Providence",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Richmond",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Dover",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Columbia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Harrisburg",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Richmond",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Dover",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Providence",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Harrisburg",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Richmond",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Columbia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Harrisburg",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Richmond",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Dover",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Estates Ii",
      Mo: "Providence",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives",
      Mo: "Olympia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Lincoln",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Phoenix",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Lincoln",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Olympia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Olympia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:the Executives Ii",
      Mo: "Phoenix",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Point 60",
      Mo: "Buckingham",
      Str: "4316 Summer Breeze Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3711,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Ridge Estates",
      Mo: "Freedom",
      Str: "280 Taft Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 40'",
      Mo: "Drayton",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "347000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 40'",
      Mo: "Morris",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "381000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 40'",
      Mo: "Trailside",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Wakefield",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Highgate",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Cedar",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Mystique",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Trailside",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Browning",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Chapman",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Hanover",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Seabrook",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Thompson",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Mystique",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Mystique Grand",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Whitestone",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch - Discovery",
      Mo: "Browning",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch - Discovery",
      Mo: "Thompson",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch - Discovery",
      Mo: "Chapman",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "299000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch - Discovery",
      Mo: "Hanover",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "324000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Classic",
      Mo: "Highgate",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "314000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Classic",
      Mo: "Mystique",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Classic",
      Mo: "Spruce",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Classic",
      Mo: "Whitestone",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "507000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Classic",
      Mo: "Yorkshire",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "594000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Estate",
      Mo: "Ashby",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Estate",
      Mo: "Easley",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "443000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Estate",
      Mo: "Oakhurst",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "669000"
    },
    {
      Bid: "22689",
      Co: "Northridge At Babcock Ranch Estate",
      Mo: "Roseland",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "748000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Prosperity",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Mystique",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Palmary",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Prestige",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "343000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Mainstay",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "341000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Stardom",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "363000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Stellar",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Reverence",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Layton",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Layton Grande",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Renown",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "422000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Scenic",
      Mo: "Contour",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Scenic",
      Mo: "Compass",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Scenic",
      Mo: "Hallmark",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock Preserve Townhomes",
      Mo: "Truman",
      Str: "Se 31st St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34480,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock Preserve Townhomes",
      Mo: "Lincoln",
      Str: "Se 31st St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34480,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Aura",
      Str: "1363 Blue Ash Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Bloom",
      Str: "1363 Blue Ash Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1487,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Aura",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1343,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Bloom",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Celeste",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Dawn",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:estate Key Collection",
      Mo: "Eclipse",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:executive Key Collection",
      Mo: "Honor",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2754,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:executive Key Collection",
      Mo: "Eclipse",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:executive Key Collection",
      Mo: "Celeste",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:executive Key Collection",
      Mo: "Dawn",
      Str: "1363 Blue Ash Ln",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 20' Th",
      Mo: "Seacrest",
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 24' Th",
      Mo: "Adirondack",
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 24' Th",
      Mo: "Leland",
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 40'",
      Mo: "Alexander",
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1961,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "432000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 40'",
      Mo: "Nelson",
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2168,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "478000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 40'",
      Mo: "Sienna",
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2220,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 53'",
      Mo: "Mystique",
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "476000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 53'",
      Mo: "Palmary",
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "489000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 53'",
      Mo: "Prestige",
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "524000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 67'",
      Mo: "Stardom",
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "653000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 67'",
      Mo: "Stellar",
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "713000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 67'",
      Mo: "Reverence",
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "768000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Daniel",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Hamden",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Mcnair",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "347000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Tybee",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Chapman",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Hanover",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Seabrook",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Thompson",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Wakefield",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "56 Egan Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "70 Egan Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "50 Egan Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "45 Silk Oak Court",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "33 Ficus Lane",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "51 Ficus Lane",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "27 Ficus Lane",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "47 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "19 Silk Oak Court",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "29 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "39 Silk Oak Court",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "82 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "64 Egan Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "65 Ficus Lane",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "88 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "32 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "100 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "106 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "34 Egan Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "45 Ficus Lane",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "21 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "35 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "39 Ficus Lane",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "53 Silk Oak Court",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "42 Egan Way",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "31 Silk Oak Court",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "55 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "54 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "94 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "114 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Lily",
      Str: "74 White Cypress Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee",
      Mo: "Rose",
      Str: "41 Sugar Magnolia Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood At Nocatee ",
      Mo: "Rose",
      Str: "59 Ficus Ln",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2263,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Ocean Cove",
      Mo: "Grayton",
      Str: "1822 Se Ocean Cove Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34996,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "Ocean Cove",
      Mo: "Seacrest",
      Str: "1822 Se Ocean Cove Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34996,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1814,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "363000"
    },
    {
      Bid: "6701",
      Co: "Ocoee Landings",
      Mo: "San Andres",
      Str: "1896 Ibis Bay Court",
      Ct: "Ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3000,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Old Hickory:horizon Collection",
      Mo: "Dawn",
      Str: "3971 Guernsey Bend",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34772,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22374",
      Co: "Olde Mandarin Estates 70s",
      Mo: "Clarke",
      Str: "4629 Quilting Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32257,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Independence",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3357,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Amalfi",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Monte Carlo",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Trevi",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves Villa",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Monte Carlo",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Trevi",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Venice",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Marsala",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Amalfi",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Independence",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3357,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Venice",
      Str: "2318 Avocado Ln",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Independence",
      Str: "2318 Avocado Ln",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3357,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Monte Carlo",
      Str: "2318 Avocado Ln",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Amalfi",
      Str: "2318 Avocado Ln",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Marsala",
      Str: "2318 Avocado Ln",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:executive Homes",
      Mo: "Trevi",
      Str: "2318 Avocado Ln",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:villas",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:villas",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:villas",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:villas",
      Mo: "Orchid",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:villas",
      Mo: "Magnolia",
      Str: "Oil Well Rd",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1417,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:villas",
      Mo: "Orchid",
      Str: "Oil Well Rd",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1564,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:executive Homes",
      Mo: "Trevi",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:executive Homes",
      Mo: "Monte Carlo",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:executive Homes",
      Mo: "Trevi",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:executive Homes",
      Mo: "Trevi",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:manor Homes",
      Mo: "Sorrento",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:manor Homes",
      Mo: "The Summerville Ii",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:manor Homes",
      Mo: "Liberation",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:villas",
      Mo: "Magnolia",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6701",
      Co: "Osprey Ranch 32s",
      Mo: "Other",
      Str: "15096 Field Daisy Dr",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Osprey Ranch 50s",
      Mo: "Other",
      Str: "15096 Field Daisy Dr",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Osprey Ranch Ths",
      Mo: "Other",
      Str: "Avalon Road",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove",
      Mo: "Destan",
      Str: "3498 Oyster Catcher Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove Townhomes Rl 20s",
      Mo: "Rialta",
      Str: "3492 Oystercatcher Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1595,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove Townhomes Rl 20s",
      Mo: "Rialta",
      Str: "3490 Oystercatcher Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1595,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove Townhomes Rl 20s",
      Mo: "Destan",
      Str: "3510 Oystercatcher Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Serino",
      Str: "10111 Palazzo Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2517,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Massiano",
      Str: "10062 Palazzo Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Built Up",
      RS: "Flat",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Massiano",
      Str: "10042 Florence Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2378,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Massiano",
      Str: "10099 Palazzo Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Serino",
      Str: "10038 Florence Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Serino",
      Str: "10129 Florence Cir",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2100,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Massiano",
      Str: "10031 Florence Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2245,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Palazzo At Naples",
      Mo: "Massiano",
      Str: "10070 Palazzo Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3330,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:estate Homes",
      Mo: "Napoli Ii",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:estate Homes",
      Mo: "Sunset",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2799,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:estate Homes",
      Mo: "Sunset",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:estate Homes",
      Mo: "Napoli Ii",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2395,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Venice",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Marsala",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Trevi",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Amalfi",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Trevi",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Venice",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Monte Carlo",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:executive Homes",
      Mo: "Marsala",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:villas",
      Mo: "Orchid",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:villas",
      Mo: "Magnolia",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1417,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Palm Lake At Coco Bay:villas",
      Mo: "Orchid",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1564,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Palm Meadows:berkshire Collection",
      Mo: "6811b - Yorkshire",
      Str: "8203 Grand Prix Lane",
      Ct: "Boynton Beach",
      St: "FL",
      Zip: 33472,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3635,
      Sto: "1",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Mariner",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1516,
      Sto: "3",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Tidewater",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1912,
      Sto: "3",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Shoreline",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Shoreline",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Shoreline",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Park East 40s",
      Mo: "Boston",
      Str: "3518 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East 40s",
      Mo: "Concord",
      Str: "3518 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East 40s",
      Mo: "Columbia",
      Str: "3518 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors",
      Mo: "Annapolis",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors",
      Mo: "Columbia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors",
      Mo: "Concord",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors",
      Mo: "Atlanta",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors",
      Mo: "Boston",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors Ii",
      Mo: "Columbia",
      Str: "Rr",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors Ii",
      Mo: "Boston",
      Str: "Rr",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors Ii",
      Mo: "Atlanta",
      Str: "Rr",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors Ii",
      Mo: "Concord",
      Str: "Rr",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Park East:the Manors Ii",
      Mo: "Annapolis",
      Str: "Rr",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Parkland Bay:classic Collection",
      Mo: "Willow",
      Str: "8665 Parkland Bay Drive Ste 101",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4328,
      Sto: "2",
      Be: "4",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Parkland Bay:classic Collection",
      Mo: "Outrigger",
      Str: "8665 Parkland Bay Drive Ste 101",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4309,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Parkland Golf & Cc-monogr 65'",
      Mo: "Anzi",
      Str: "10373 Sweet Bay Manor",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4465,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "22689",
      Co: "Parkview At Hillcrest - Executive Series",
      Mo: "Brookstream",
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1906,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "507000"
    },
    {
      Bid: "22689",
      Co: "Parkview At Hillcrest - Executive Series",
      Mo: "Raritan",
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "504000"
    },
    {
      Bid: "22689",
      Co: "Parkview At Hillcrest 45'",
      Mo: "Orleans",
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1825,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "447000"
    },
    {
      Bid: "22374",
      Co: "Parkview At Long Lake 18' Th",
      Mo: "Marianna",
      Str: "2599 Everglade Way",
      Ct: "Lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22374",
      Co: "Parkview At Long Lake 18' Th",
      Mo: "Marianna",
      Str: "2325 Audubon Preserve Lane",
      Ct: "Lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Parkview At Long Lake 24' Th",
      Mo: "Sebring",
      Str: "2334 Audubon Preserve Lane",
      Ct: "Lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2243,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Parkview At Long Lake 50's",
      Mo: "Myrtle",
      Str: "19756 Blue Pond Drive",
      Ct: "Lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2983,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3947 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3843 Corona Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3831 Corona Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3975 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Underwood",
      Str: "3653 Voyager Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2506,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3955 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Bowersett",
      Str: "3943 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "245000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3980 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3983 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Doyal",
      Str: "3619 Voyager Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Doyal",
      Str: "3968 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3972 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3960 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3847 Corona Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3657 Voyager Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3939 Lightning Court",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3605 Voyager Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3623 Voyager Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 40s",
      Mo: "Trailside",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "569000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 40s",
      Mo: "Westland",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "716000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 40s",
      Mo: "Seaport",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2068,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "438000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Mystique",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "397000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Mystique Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "636000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Whitestone",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "642000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Yorkshire",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "784000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 60s",
      Mo: "Roseland",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "1034000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 60s",
      Mo: "Easley",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 60s",
      Mo: "Ashby",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Berkley",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "752000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Monroe",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "752000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Ashby Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "808000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Easley Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4214,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "927000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Berkley Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "930000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:estate Key Collection",
      Mo: "Aura",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1343,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:estate Key Collection",
      Mo: "Dawn",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:estate Key Collection",
      Mo: "Celeste",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:estate Key Collection",
      Mo: "Bloom",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:estate Key Collection",
      Mo: "Eclipse",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Dover",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Dover",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Radiance",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Freedom",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Hartford",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Radiance",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1853,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Dover",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Hartford",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Freedom",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:grand Collection",
      Mo: "Horizon",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Boston",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Haven",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1053,
      Sto: "1",
      Be: "3",
      Ba: "1",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Boston",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Atlanta",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Atlanta",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Annapolis",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Inspire",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1287,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Boston",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "443200"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Abbey",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1263,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Haven",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1053,
      Sto: 1,
      Be: 3,
      Ba: 1,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Columbia",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Annapolis",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Atlanta",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375800"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Buffer Site",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Inspire",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1287,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Jefferson",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Parking Site",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:legacy Collection",
      Mo: "Edison",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:manor Key Collection",
      Mo: "Georgia",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:manor Key Collection",
      Mo: "Columbus",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:manor Key Collection",
      Mo: "Jefferson",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:manor Key Collection",
      Mo: "Edison",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:manor Key Collection",
      Mo: "Belmont",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Preston",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Aspen",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1844,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Brindley",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Augusta",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2084,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Juneau",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2954,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Mcginnis",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3230,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Preston",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Augusta",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2084,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Mcginnis",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3230,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Brindley",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2156,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Aspen",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1844,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Carlisle",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2627,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Juneau",
      Str: "18238 Pearl Vw Pl",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2954,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22680",
      Co: "Pebblebrook At Verandah:coach Homes",
      Mo: "Osprey",
      Str: "11730 Arborwood Preserve Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1798,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:chateau Collection",
      Mo: "Independence",
      Str: "2740 Pine Sap Lane",
      Ct: "St Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3385,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:cottage Alley Collection",
      Mo: "Sierra",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2522,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:cottage Alley Collection",
      Mo: "Autumn",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:cottage Alley Collection",
      Mo: "Cascade",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:estate Collection",
      Mo: "Miramar",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:estate Collection",
      Mo: "Peabody",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:manor Alley Collection",
      Mo: "Delray",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:manor Alley Collection",
      Mo: "Aurora",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:manor Alley Collection",
      Mo: "Glenwood",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Vista:carmel Collection",
      Mo: "Monroe",
      Str: "Sw 288th St And 132nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1944,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Vista:pasadena Collection",
      Mo: "Dijon",
      Str: "Sw 288th St And 132nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Cedar",
      Str: "Lot 3 Tbd Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Highgate",
      Str: "Lot 3 Tbd Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Whitestone",
      Str: "Lot 3 Tbd Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Spruce",
      Str: "Lot 3 Tbd Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Tower",
      Str: "Lot 3 Tbd Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2397,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve Th's",
      Mo: "Foxtail",
      Str: "Lot 156 Tbd Sapling Sprout Dri",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve Th's",
      Mo: "Trailwood",
      Str: "Lot 156 Tbd Sapling Sprout Dri",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Brio Ii",
      Str: "Sw 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Trevi Ii",
      Str: "Sw 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Halle Ii",
      Str: "Sw 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Charle Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Brio",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Medallion Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Brio Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Charle",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Halle",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Halle Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 50's",
      Mo: "Trevi Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Medallion Ii",
      Str: "Sw 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Tivoli Ii",
      Str: "Sw 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Tivoli",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Princeton",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Tivoli Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Medallion",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Princeton Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:pioneer Ranch 60's",
      Mo: "Medallion Ii",
      Str: "Sw 95th Street Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Polo Run:manor Homes",
      Mo: "Sorrento",
      Str: "17013 Polo Trail",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Polo Run:manor Homes",
      Mo: "Liberation",
      Str: "17013 Polo Trail",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Venice",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Trevi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Marsala",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Amalfi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Amalfi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Venice",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Marsala",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Trevi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Monte Carlo",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Monte Carlo",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Venice",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Marsala",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Monte Carlo",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Amalfi",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Capri",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Portico:executive Homes",
      Mo: "Trevi",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Sorrento",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Tivoli",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "The Summerville Ii",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Tivoli",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "The Summerville Ii",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Sorrento",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "The Princeton Ii",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Sorrento",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "604000"
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Liberation",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3867,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "697000"
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "Tivoli",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "The Summerville Ii",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "397000"
    },
    {
      Bid: "22680",
      Co: "Portico:manor Homes",
      Mo: "The Princeton Ii",
      Str: "14019 Aledo Ct",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Positano At The Riviera",
      Mo: "Olmeto",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano At The Riviera",
      Mo: "Florent",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2183,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano At The Riviera",
      Mo: "Piani",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2987,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano At The Riviera",
      Mo: "Argento",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1732,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano At The Riviera",
      Mo: "Centuri",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1827,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano At The Riviera",
      Mo: "Lumio",
      Str: "Sw 336 St And Se 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2439,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Boston",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Atlanta",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Columbia",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Columbia",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Jefferson",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 1,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Atlanta",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Boston",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Edison",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Robin",
      Str: "166 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Robin",
      Str: "27 Arum Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Delmore Elite",
      Str: "224 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Annabella",
      Str: "311 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Robin",
      Str: "50 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Robin",
      Str: "186 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Robin",
      Str: "233 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Robin",
      Str: "316 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Delmore",
      Str: "28 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2461,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Annabella",
      Str: "212 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Preserve At Beacon Lake",
      Mo: "Annabella",
      Str: "92 Daymark Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Providence",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Dawn",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Dover",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Providence",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2583,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Dover",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Dawn",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1313,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Freedom",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2015,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Freedom",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Providence",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2583,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Hartford",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Dover",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Vista",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2015,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Dawn",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1313,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Durham",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Collection Ii",
      Mo: "Freedom",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Key Collection",
      Mo: "Bloom",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Key Collection",
      Mo: "Dawn",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Key Collection",
      Mo: "Celeste",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:estate Key Collection",
      Mo: "Eclipse",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:grand Collection",
      Mo: "Dawn",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:grand Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:grand Collection",
      Mo: "Durham",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:grand Collection",
      Mo: "Boston *model Home*",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:legacy Collection",
      Mo: "Annapolis",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Columbia",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Annapolis",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Boston",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Concord",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Columbia",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Concord",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Annapolis",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Atlanta",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Collection",
      Mo: "Boston",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Key Collection",
      Mo: "Jefferson",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Key Collection",
      Mo: "Edison",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Key Collection",
      Mo: "Columbus",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Key Collection",
      Mo: "Georgia",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Preserve At Lpga:manor Key Collection",
      Mo: "Belmont",
      Str: "1185 Sand Trap Ct",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Classic",
      Mo: "Prosperity",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Classic",
      Mo: "Mystique",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "398000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Classic",
      Mo: "Palmary",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Classic",
      Mo: "Mainstay",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Classic",
      Mo: "Prestige",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Estate",
      Mo: "Stardom",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "478000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Estate",
      Mo: "Stellar",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "493000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Estate",
      Mo: "Reverence",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "506000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Estate",
      Mo: "Renown",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "512000"
    },
    {
      Bid: "22689",
      Co: "Preserve At Waterway - Villa",
      Mo: "Ellenwood",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "307000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Eventide",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Argent",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Daybreak",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Dawning",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Halos",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Daybreak",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Eventide",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2611,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Argent",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Morningtide Ii",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Dawning",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2141,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Estates",
      Mo: "Halos",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Morningtide",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Splendor",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Morningtide",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Splendor",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Daybreak",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2141,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Villas",
      Mo: "Malibu",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1486,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:active Adult Villas",
      Mo: "Malibu",
      Str: "11938 Lilac Pearl Ln",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1486,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Trenton",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Harrisburg",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Columbia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Trenton",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Providence",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Hartford",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Raleigh",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Dover",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Harrisburg",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Hartford",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Columbia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Trenton",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Richmond",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Dover",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Raleigh",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Estates",
      Mo: "Providence",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Cheyenne",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Santa Fe",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Phoenix",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Lincoln",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Olympia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Concerto",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Cheyenne",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3255,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Santa Fe",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2576,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Phoenix",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2443,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Lincoln",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2278,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Olympia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3354,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Raleigh",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Trenton",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "549000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Concerto",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3868,
      Sto: 2,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Executives",
      Mo: "Helena",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2999,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Boston",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Annapolis",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Columbia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Atlanta",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Annapolis",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Concord",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Concord",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Boston",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Atlanta",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Boston",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Concord",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Columbia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Manors",
      Mo: "Annapolis",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Abacos",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Cozumel",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Bimini",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1993,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Bimini",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1993,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Cozumel",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2162,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Abacos",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1747,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Bimini",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1993,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Riverview Lakes 6-unit",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 11804,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1985000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Riverview Lakes 4-unit",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 7306,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1515000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Cozumel",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2162,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:the Townhomes",
      Mo: "Abacos",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1747,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Providence Camden Park",
      Mo: "Freedom",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence Camden Park",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:camden Park Executive",
      Mo: "Newcastle",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:cortland Woods",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:cortland Woods",
      Mo: "Monte Carlo",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:drayton Woods Executives",
      Mo: "Kennedy",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:estate Key Collection",
      Mo: "Dawn",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Providence:estate Key Collection",
      Mo: "Eclipse",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Providence:estate Key Collection",
      Mo: "Celeste",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Estero",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Summerlin",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Summerlin",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Inverness",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Riviera",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Bonita",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Summerlin",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3147,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Summerlin",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3147,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Parking Site",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Riviera",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Inverness",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2911,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Estero",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2532,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Buffer Site",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Bonita",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2447,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Chateau Collection",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Independence",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3385,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Freedom",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Peabody",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Peabody",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Hamilton",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Freedom",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Sheffield",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2334,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "467000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Peabody",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3291,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Independence",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3385,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "677000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Buffer Site",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Parking Site",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "22680",
      Co: "Providence:garden Hills Estate Collection",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "407000"
    },
    {
      Bid: "22680",
      Co: "Providence:lakeside",
      Mo: "Monte Carlo",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:lakeside",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:lakeside",
      Mo: "Normandy",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1909,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:lakeside",
      Mo: "Monaco",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2441,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Raintree The Grove Ii:the Grove Ii At Raintree",
      Mo: "207a - Vallejo",
      Str: "1602 Sw 114th Avenue",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33025,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Raintree The Preserve:the Preserve At Raintree",
      Mo: "Laguna",
      Str: "1602 Sw 114th Avenue",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33025,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1799,
      Sto: "3",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estate Key Collection",
      Mo: "Eclipse",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estate Key Collection",
      Mo: "Celeste",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estate Key Collection",
      Mo: "Bloom",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estate Key Collection",
      Mo: "Dawn",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Vista",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Atlanta",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Freedom",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Hartford",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Eclipse",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Hartford",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Dawn",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Atlanta",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Latitude",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2203,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Freedom",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Parking Site",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Bloom",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Buffer Site",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Celeste",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:estates Collection",
      Mo: "Vista",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2015,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Atlanta",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Concord",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Columbia",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Annapolis",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Annapolis",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Boston",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Columbia",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Columbus",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1880,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Jefferson",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2458,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Annapolis",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Columbia",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Georgia",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Belmont",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Buffer Site",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Parking Site",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Boston",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Edison",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Atlanta",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:legacy Collection",
      Mo: "Concord",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:manor Key Collection",
      Mo: "Columbus",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:manor Key Collection",
      Mo: "Jefferson",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:manor Key Collection",
      Mo: "Belmont",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:manor Key Collection",
      Mo: "Edison",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Ranches At Lake Mcleod:manor Key Collection",
      Mo: "Georgia",
      Str: "836 Timberland Dr",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Ranchette Square",
      Mo: "Grayton",
      Str: "6100 Rancho Lane",
      Ct: "Green Acres",
      St: "FL",
      Zip: 33415,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Ranchette Square",
      Mo: "Navarre",
      Str: "6100 Rancho Lane",
      Ct: "Green Acres",
      St: "FL",
      Zip: 33415,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Randal Walk - R22",
      Mo: "Marabel Iii",
      Str: "10003 Randal Walk St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1721,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Sawgrass Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2958,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Hawthorne Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Carolina Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4521,
      Sto: "1",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Briarbrooke Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3906,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir - Palms Collection",
      Mo: "Joliet",
      Str: "9948 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9994 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9987 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Mckenna",
      Str: "9960 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "10049 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9965 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9897 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9964 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9998 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Samantha",
      Str: "9917 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "10042 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "10111 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Mckenna",
      Str: "10070 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9969 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Mckenna",
      Str: "9925 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Mckenna",
      Str: "10006 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "12408 Hammock Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Joliet",
      Str: "9948 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2391,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Jesalin",
      Str: "12424 Hammock Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9949 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Samantha",
      Str: "10058 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Jesalin",
      Str: "9961 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9901 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "10083 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Jesalin",
      Str: "9913 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "10073 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9940 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Mckenna",
      Str: "10010 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "12412 Hammock Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Maya",
      Str: "9957 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Jesalin",
      Str: "9956 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms",
      Mo: "Samantha",
      Str: "12404 Hammock Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Joliet",
      Str: "9973 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2378,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Maya",
      Str: "9937 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Mckenna",
      Str: "9941 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Maya",
      Str: "9994 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Joliet",
      Str: "12420 Hammock Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2388,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Mckenna",
      Str: "10006 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Mckenna",
      Str: "9945 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Maya",
      Str: "9905 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Samantha",
      Str: "9909 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Jesalin",
      Str: "9953 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Jesalin",
      Str: "9929 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Maya",
      Str: "9901 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Samantha",
      Str: "9917 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-palms Collec",
      Mo: "Jesalin",
      Str: "9921 Regency Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9966 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Lewiston",
      Str: "9975 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9939 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9981 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9978 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Salerno",
      Str: "9944 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2627,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Salerno",
      Str: "10031 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9946 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Salerno",
      Str: "9986 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9936 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9961 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9985 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9972 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2988,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9968 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9930 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9982 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9976 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9945 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2740,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9965 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9932 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9935 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Lewiston",
      Str: "9942 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9958 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9943 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Lewiston",
      Str: "9912 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "930000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9970 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Kaley",
      Str: "9973 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Salerno",
      Str: "9980 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9938 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9940 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Isabella",
      Str: "9908 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Lewiston",
      Str: "9962 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "9964 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Lewiston",
      Str: "9934 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds",
      Mo: "Shelby",
      Str: "10027 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Salerno",
      Str: "9986 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Salerno",
      Str: "9916 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Isabella",
      Str: "9954 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Lewiston",
      Str: "9942 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Lewiston",
      Str: "9962 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Isabella",
      Str: "9940 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Isabella",
      Str: "9925 Seagrass Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Kaley",
      Str: "9957 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2740,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Kaley",
      Str: "9974 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Kaley",
      Str: "9926 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Shelby",
      Str: "9950 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Salerno",
      Str: "9977 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Kaley",
      Str: "9922 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Regency At Avenir-tradewinds C",
      Mo: "Kaley",
      Str: "9900 Timber Creek Way",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Aurora Ii",
      Str: "20440 Revival Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2098,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Riviera Ii",
      Str: "20472 Bandera Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Dawn Ii",
      Str: "20655 Ovid Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Riviera Ii",
      Str: "20470 Revival Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 60's",
      Mo: "Nantucket Ii",
      Str: "20750 Ovid Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "22374",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20703 Ovid Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20646 Galileo Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20640 Galileo Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Reserve At Alaqua",
      Mo: "4511a - Alcott",
      Str: "3109 Players View Circle",
      Ct: "Longwood",
      St: "FL",
      Zip: 32779,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3267,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Reserve At Minneola 70's",
      Mo: "4014b-somerset",
      Str: "1020 Wax Myrtle Ave.",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2567,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Reserve At Minneola 70's",
      Mo: "4013b-hampshire",
      Str: "1020 Wax Myrtle Ave.",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2021,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "255000"
    },
    {
      Bid: "6703",
      Co: "Reserve At The Ranches",
      Mo: "Villa Divina",
      Str: "17135 Reserve Court",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Reserve At The Ranches",
      Mo: "Villa Divina",
      Str: "17110 Reserve Court",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 8002,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1070000"
    },
    {
      Bid: "6703",
      Co: "Reserve At The Ranches",
      Mo: "Villa Divina",
      Str: "17140 Reserve Court",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7577,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1165000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Aurora",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Glenwood",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2462,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Harmony",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2671,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Aurora",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Aurora",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2052,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Harmony",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2671,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:estates Alley Collection",
      Mo: "Glenwood",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2462,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Summerlin",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3174,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Riverview",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Largo",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2359,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Largo",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2359,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Summerlin",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3174,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Riviera",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:legacy Collection",
      Mo: "Riverview",
      Str: "3036 Country Side Dr",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2600,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Rialto Presidential",
      Mo: "The Valenti",
      Str: "163 Darby Island Place",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4170,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club",
      Mo: "Marsala",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club",
      Mo: "The Summerville Ii",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Summerwood",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Martin Ray",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1968,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Pinnacle",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Palm",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Dockside",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Stonewater",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "499000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Abbeyville",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1671,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 70'",
      Mo: "Stellar",
      Str: "3260 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 70'",
      Mo: "Renown",
      Str: "3260 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "394000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club Villas",
      Mo: "Kendrick",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "302000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club Villas",
      Mo: "Ellenwood",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "The Princeton Ii",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "The Summerville Ii",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Marsala",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Tivoli",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Tivoli",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Trevi",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Venice",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Oakmont",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2361,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Venice",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Trevi",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "The Summerville Ii",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "The Princeton Ii",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Monte Carlo",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Amalfi",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Marsala",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Tivoli",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Capri",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "The Princeton Ii",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "349000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Navona",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2765,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "426000"
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:executive Homes",
      Mo: "Doral",
      Str: "3101 E Windsor Dr",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2553,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "River Strand:terrace Condominiums",
      Mo: "Ashbury",
      Str: "6819 Grand Estuary Trail #106",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1121,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "84000"
    },
    {
      Bid: "22680",
      Co: "River Strand:the Villas At The Sanctuary",
      Mo: "Orchid",
      Str: "7133 Quiet Creek Drive",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Garnet",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2166,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Imperial",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2564,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Coral Grand",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Ruby",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2886,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Scarlett",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3575,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Coral",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1935,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3603 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3631 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3578 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3671 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "971 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3647 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3582 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3397 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3611 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3381 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3385 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3433 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3639 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3396 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3691 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "980 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3421 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "992 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3377 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3635 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3586 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3675 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "975 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3594 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "987 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3429 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3552 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3560 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3389 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3623 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3425 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3405 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "968 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3409 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3393 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3537 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3469 Mclain Preserve Point",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot Craftsman",
      Str: "3586 Stonebriar Ln",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "976 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "979 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "972 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3417 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3607 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3536 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3615 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3465 Mclain Preserve Point",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "984 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3404 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3590 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3622 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "988 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3627 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3388 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3401 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3606 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3473 Mclain Preserve Point",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "991 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3556 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3179,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3412 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "964 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "967 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3392 Bluff Oak Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "983 Fiddle Leaf Way",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3702 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "3568 Stonebriar Lane",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3691 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3614 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3619 Arbordale Loop",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Mallard",
      Str: "237 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2302,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Vireo",
      Str: "23 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Egret",
      Str: "215 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1869,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Sandhill",
      Str: "10 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Sandhill",
      Str: "31 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2549,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Vireo",
      Str: "68 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 45s",
      Mo: "Sandhill",
      Str: "223 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Arbors 55s",
      Mo: "Wales",
      Str: "291 Silkgrass Pl",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3279,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Gardens North Rl 50s",
      Mo: "Beecher",
      Str: "892 Orange Branch Trail",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1859,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Gardens North Rl 50s",
      Mo: "Oak",
      Str: "442 Footbridge Rd",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Barron",
      Str: "226 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Sebastian",
      Str: "143 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Barron",
      Str: "198 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Whitney",
      Str: "250 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Whitney",
      Str: "133 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Sebastian",
      Str: "182 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 40s",
      Mo: "Barron",
      Str: "174 Meadow Creek Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 60s",
      Mo: "Braden",
      Str: "122 Sydney Cove",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Haven 60s",
      Mo: "Braden",
      Str: "129 Sydney Cove",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Manor 70s",
      Mo: "Cannon Ii",
      Str: "52 Terra Oaks Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2734,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22374",
      Co: "Rivertown The Manor 70s",
      Mo: "Saxon Ii",
      Str: "326 Terra Oaks Dr",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3564,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Watersong 45s",
      Mo: "Trail",
      Str: "465 Pinellas Way",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1563,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Watersong 45s",
      Mo: "Court",
      Str: "385 Pinellas Way",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1686,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Rivertown Watersong 55s",
      Mo: "Bridge",
      Str: "265 Pinellas Way",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2210,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Hartford",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Annapolis",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Dahlia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1826,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Dahlia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1826,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Camellia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Raleigh",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Hartford",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Carlisle",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2629,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Camellia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Columbia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Atlanta",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Hartford",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1937,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Dahlia",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1826,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Raleigh",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2901,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Carlisle",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2628,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Annapolis",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Atlanta",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1873,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Columbia",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2409,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Angler Collection",
      Mo: "Camelia",
      Str: "2042 Marigold Trl",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1611,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Shoals Collection",
      Mo: "Atlanta",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Shoals Collection",
      Mo: "Annapolis",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Shoals Collection",
      Mo: "Annapolis",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Shoals Collection",
      Mo: "Boston",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2218,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Shoals Collection",
      Mo: "Concord",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood At Everlands:the Shoals Collection",
      Mo: "Columbia",
      Str: "767 Veridian Circle Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6701",
      Co: "Rivington",
      Mo: "Suncrest",
      Str: "742 Costa Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Tessa",
      Str: "720 Costa Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Lila",
      Str: "616 Bayern Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Suncrest",
      Str: "731 Firerock Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Lila",
      Str: "620 Bayern Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Suncrest",
      Str: "843 Terrapin Drive",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Tessa",
      Str: "803 Firerock Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Lila",
      Str: "624 Bayern Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Nicola",
      Str: "780 Middleton St",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Nicola",
      Str: "716 Moca Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "Rivington Sf",
      Mo: "Lila",
      Str: "726 Rivington Ave",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "638 Cheval Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Peabody",
      Str: "630 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "640 Cheval Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "735 Costa Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "641 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "651 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "614 Cheval Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Peabody",
      Str: "616 Cheval Lane",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Peabody",
      Str: "635 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Peabody",
      Str: "642 Bayhawk Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "106000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Peabody",
      Str: "654 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Peabody",
      Str: "647 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "Rivington Th",
      Mo: "Loyola",
      Str: "639 Belgrove Street",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "22680",
      Co: "Rivington:cottage Alley Collection",
      Mo: "Whitney",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2135,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Rivington:cottage Alley Collection",
      Mo: "Danali",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Rivington:cottage Alley Collection",
      Mo: "Rainier",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2023,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Rivington:estate Collection",
      Mo: "Allison Ii",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2731,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Rivington:estate Collection",
      Mo: "Captiva",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2321,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Rivington:estate Collection",
      Mo: "Joshua Ii",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2130,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Rivington:estate Collection",
      Mo: "Weston",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2992,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Rivington:estate Collection",
      Mo: "Aspen",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2199,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Rivington:executive Collection",
      Mo: "Riviera",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Rivington:executive Collection",
      Mo: "Largo Ii",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2387,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Rivington:executive Collection",
      Mo: "Fairmont",
      Str: "639 Terrapin Dr",
      Ct: "Debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3216,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Cedar",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Highgate",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Mystique",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Tower",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Yorkshire",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Whitestone",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Trailside Select",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17811 Rolling Oaks Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 8014,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1180000"
    },
    {
      Bid: "6703",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17816 Rolling Oaks Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "6703",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17803 Rolling Oaks Estates Drive",
      Ct: "Southwest Ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6959,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "875000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes 40",
      Mo: "Harrisburg",
      Str: "Ingraham Blvd & Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "Hartford",
      Str: "Ingraham Blvd And Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "Harrisburg",
      Str: "Ingraham Blvd And Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "Dover",
      Str: "Ingraham Blvd And Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "The Summerville Ii",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "Harrisburg",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "Hartford",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes By Lennar",
      Mo: "Dover",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1551,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Arianna",
      Str: "10421 Angel Oak Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10691 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10335 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10631 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Sebastian",
      Str: "10715 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10522 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Arianna",
      Str: "10397 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10147 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4106,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10571 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10829 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10511 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10329 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2919,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10323 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4018,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10553 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4468,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Sebastian",
      Str: "10709 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10415 Angel Oak Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Sabel",
      Str: "10324 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10558 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3084,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (fl)",
      Str: "10498 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10317 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2309,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10480  Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10703 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4528,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10140 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Wynngate",
      Str: "10570 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2827,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10306 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4514,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10492 Royal Cypress Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2309,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10116 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4468,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10134 Royal Island Court",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7369 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1075000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Milano",
      Str: "7488 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1755000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7389 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7409 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7430 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4641,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Milano",
      Str: "7122 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1670000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2411 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7439 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1435000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Mcintosh Ii",
      Str: "2704 Northwest 71st Blvd",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4929,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2570 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Mcintosh Ii",
      Str: "7502 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5279,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "6920 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1315000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2550 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Ternbridge Ii",
      Str: "6930 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1360000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Divina",
      Str: "7230 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Divina",
      Str: "7310 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1950000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "6903 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "2740 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2235000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2755 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7309 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2510 Northwest 71st Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "2540 Northwest 71st Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "7459 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "2580 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "7321 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4841,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7239 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "7420 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3715,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "7352 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "6950 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "2640 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Divina",
      Str: "7080 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7609,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1465000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7359 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4594,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Ternbridge Ii",
      Str: "2725 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4704,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2720 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1720000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "7322 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Lucia (fl)",
      Str: "6970 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4041,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7312 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4727,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "7449 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4537,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7342 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4198,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "6940 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "6966 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1175000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "2540 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4894,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "2500 Northwest 71st Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Ternbridge Ii",
      Str: "7229 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4740,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Ternbridge Ii",
      Str: "2710 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4795,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1295000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "7302 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5002,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "6960 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1070000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7329 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Mcintosh Ii",
      Str: "7440 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1615000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "7440 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3836,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Milano",
      Str: "7330 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5400,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1695000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2750 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1100000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2723 Northwest 70th Blvd",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4642,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Mcintosh Ii",
      Str: "7480 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5274,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2010000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Lucia (fl)",
      Str: "6910 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4459,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1590000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Milano",
      Str: "7460 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5391,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1040000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7479 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2531 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2520 Northwest 71st Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "7219 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4642,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1285000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Villa Divina",
      Str: "7380 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7380,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2220000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2650 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "2730 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2530 Northwest 71st Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4689,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2780 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2045000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "San Giorgio",
      Str: "6911 Northwest 28th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3925,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "2760 Northwest 69th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1615000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Mcintosh Ii",
      Str: "7360 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5370,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Callahan",
      Str: "6910 Northwest 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "2511 Northwest 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Dalenna Ii",
      Str: "7450 Northwest 25th Terrace",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4841,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-heritage",
      Mo: "Mcintosh Ii",
      Str: "7220 Northwest 26th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1540000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "2709 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1830000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Mcintosh Ii",
      Str: "2703 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1550000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7405 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7425,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1305000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7503 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1840000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Milano",
      Str: "7506 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Mcintosh Ii",
      Str: "7300 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4929,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Mcintosh Ii",
      Str: "7300 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7360 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6964,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1690000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7207 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7342,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1745000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Lago",
      Str: "2703 Nw 71st Boulevard",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7268,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Cosenza Ii",
      Str: "2705 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6284,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1755000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Callahan",
      Str: "2701 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Mcintosh Ii",
      Str: "7480 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5112,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1955000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "2708 Nw 71st Boulevard",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7420,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "3235000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7420 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7419,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1885000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Lago",
      Str: "7404 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1385000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Callahan",
      Str: "2710 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1440000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7510 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6983,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7206 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7473,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Mcintosh Ii",
      Str: "2707 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7320 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7420,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2035000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Callahan",
      Str: "7330 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4729,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1660000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "2700 Nw 71st Boulevard",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1845000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7409 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7496,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1450000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7131 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2385000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Mcintosh Ii",
      Str: "7310 Nw 28th Way",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5153,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1750000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Lago",
      Str: "7208 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1700000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7303 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7861,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1800000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7403 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1510000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Callahan",
      Str: "2711 Nw 75th Street",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4758,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1355000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-signature",
      Mo: "Villa Divina",
      Str: "7402 Nw 27th Avenue",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6905,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1105000"
    },
    {
      Bid: "22680",
      Co: "Russell Retreat",
      Mo: "Trevi",
      Str: "2312 River Park Trce",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Russell Retreat",
      Mo: "Halle",
      Str: "2312 River Park Trce",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Russell Retreat",
      Mo: "Abaco Ii- 3 Car Version",
      Str: "2312 River Park Trce",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Russell Retreat",
      Mo: "Barbados Ii",
      Str: "2312 River Park Trce",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3223,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Russell Retreat",
      Mo: "Charle",
      Str: "2312 River Park Trce",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Russell Retreat",
      Mo: "Trinidad Ii",
      Str: "2312 River Park Trce",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3026,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:americana Series",
      Mo: "Belmont",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:americana Series",
      Mo: "Edison",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:americana Series",
      Mo: "Jefferson",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:americana Series",
      Mo: "Columbus",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:executive Homes",
      Mo: "Amalfi",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:executive Homes",
      Mo: "Trevi",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:executive Homes",
      Mo: "Monte Carlo",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:executive Homes",
      Mo: "Venice",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:executive Homes",
      Mo: "Marsala",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:manor Homes",
      Mo: "The Princeton Ii",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:manor Homes",
      Mo: "Tivoli",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:manor Homes",
      Mo: "Sorrento",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:manor Homes",
      Mo: "Richmond",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2724,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Rye Ranch:manor Homes",
      Mo: "The Summerville Ii",
      Str: "3619 Night Star Ter",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates I",
      Mo: "Columbia",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates I",
      Mo: "Hartford",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates I",
      Mo: "Dover",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates I",
      Mo: "Raleigh",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Dover",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Providence",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Providence",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Raleigh",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Trenton",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Hartford",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Columbia",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:the Estates Ii",
      Mo: "Harrisburg",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Alexia",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Chloe",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Boone",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Boone",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Boone Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2089,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Alexia Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Alexia",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "287000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Chloe",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Boone",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 40s",
      Mo: "Hailey Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Trevi Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Brio",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Halle",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Charle",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Halle",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Brio Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Trevi",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Charle",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Charle Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Halle Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Trevi",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Brio",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Halle Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Halle",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Brio Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Trevi Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Charle Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Trevi",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 50s",
      Mo: "Charle",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Serenata",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2512,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Tivoli Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Tivoli",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Tivoli",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Princeton Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Medallion Ii",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Princeton",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Princeton",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Halle- Homesite 69 Only",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Serenata",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2512,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Halle- Homesite 69 Only",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1689,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Medallion Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Trevi - Homesite 108 Only",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Princeton",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Princeton Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Tivoli Ii",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Tivoli",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:saddle Oaks 60s",
      Mo: "Serenata",
      Str: "9524 Gaynor Cir",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2512,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Hailey",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1899,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Charle",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Halle",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Sierra",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2257,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Halle",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Trevi",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Ramsey",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Oxford",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Hailey",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1899,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Littleton",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Elan",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Newlin",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1474,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Ryder",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2117,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Sierra",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Newlin",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1474,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Emerson",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1660,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Oxford",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Ramsey",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1667,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Hudson",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1815,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Elan",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Littleton",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Trevi",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Sierra",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2257,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Hailey",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1899,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Charle",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Halle",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Elan",
      Str: "6494 Paso Fino Dr",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22374",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan B",
      Str: "8878 Lake Worth Rd",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 917,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "22374",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan D",
      Str: "8878 Lake Worth Rd",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 968,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22374",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan A",
      Str: "8878 Lake Worth Rd",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 779,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Centuri",
      Str: "Sw 147th Ave And Sw 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1827,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Argento",
      Str: "Sw 147th Ave And Sw 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1732,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Olmeto",
      Str: "Sw 147th Ave And Sw 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Florent",
      Str: "Sw 147th Ave And Sw 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2183,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Piani",
      Str: "Sw 147th Ave And Sw 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2987,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Lumio",
      Str: "Sw 147th Ave And Sw 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2439,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6701",
      Co: "Salerno Reserve Sf",
      Mo: "Other",
      Str: "6791 Se Lost Pine Dr.",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22680",
      Co: "San Felasco 50s",
      Mo: "Trevi",
      Str: "San Felasco Pkwy",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "San Felasco 50s",
      Mo: "Halle",
      Str: "San Felasco Pkwy",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "San Felasco 60s",
      Mo: "Medallion",
      Str: "San Felasco Pkwy",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Valencia Ii",
      Str: "2337 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2223 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1991,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2024 Raulerson Court",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Odessa",
      Str: "2046 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Valencia Ii",
      Str: "2045 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2069 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Paloma",
      Str: "2181 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2873,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2217 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Paloma",
      Str: "2355 Emerald Springs Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2873,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian Reserve",
      Mo: "Null",
      Str: "2058 Emerald Springs",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian Reserve",
      Mo: "Andorra",
      Str: "2012 Raulerson Ct",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Violet",
      Str: "11181 Sw 92 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5122,
      Sto: "2",
      Be: "5",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2215000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Magnolia",
      Str: "11181 Sw 92 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4296,
      Sto: "1",
      Be: "5",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2080000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Magnolia",
      Str: "11181 Sw 92nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 4296,
      Sto: 1,
      Be: 5,
      Ba: 6,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2080000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Violet",
      Str: "11181 Sw 92nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 5122,
      Sto: 2,
      Be: 5,
      Ba: 6,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2215000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Addison",
      Str: "11181 Sw 92nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3608,
      Sto: 1,
      Be: 5,
      Ba: 5,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1990000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper At Deerfield Beach Community",
      Mo: "Conquina",
      Str: "1853 Spoonbill Ct",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper At Deerfield Beach Community",
      Mo: "Conquina",
      Str: "523 Spoonbill Ter",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1823 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1832 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1826 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "550 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1830 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1824 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1828 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1837 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "556 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "574 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1835 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "563 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "577 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "579 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "553 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1831 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1835 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "562 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1825 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "534 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "557 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "558 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "573 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "551 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1857 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "576 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1867 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1820 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1827 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1844 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1822 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "552 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1855 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1836 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "582 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "578 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1831 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1821 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "540 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "571 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "532 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1837 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "550 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1829 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "575 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1833 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1829 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1853 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "570 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "558 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "580 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1840 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1845 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1865 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1827 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1851 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "572 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "554 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "556 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1839 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1861 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1859 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1847 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "554 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "552 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1819 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1863 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1838 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1842 Spoonbill Court",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "560 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe",
      Mo: "Unknown",
      Str: "1859 Sandpiper Pointe Pl",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe At Deerfield Beach",
      Mo: "Coquina",
      Str: "1853 Sandpiper Pointe Place",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe At Deerfield Beach",
      Mo: "Westwind Island Colonial",
      Str: "546 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1995,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Flat",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe At Deerfield Beach",
      Mo: "La Isla",
      Str: "1847 Sandpiper Pointe Pl",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1883,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "553 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "596 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "590 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "585 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "532 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Westwind",
      Str: "531 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "610 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "543 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "542 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "544 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "536 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "563 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "1823 Sanderling Drive",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "534 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "541 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "559 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "539 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "555 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "1827 Sanderling Drive",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "632 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "636 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "618 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "594 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "589 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "536 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "616 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "653 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "563 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "1829 Sanderling Drive",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "655 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "537 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "540 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "620 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "538 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "530 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "533 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "587 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "565 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "600 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "612 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "657 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Westwind",
      Str: "542 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "540 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "1819 Sanderling Drive",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "1825 Sanderling Drive",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "583 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "592 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "538 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "569 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "598 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "534 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "561 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "567 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Westwind",
      Str: "551 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "614 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "532 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Westwind",
      Str: "561 Sanderling Circle",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "La Isla",
      Str: "530 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Coquina",
      Str: "557 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "535 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiperpointe@deerfieldbch",
      Mo: "Seabreeze",
      Str: "634 Parsons Way",
      Ct: "Deerfield Beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 42'",
      Mo: "Crestwood",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 42'",
      Mo: "Drayton",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "316000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 42'",
      Mo: "Morris",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 42'",
      Mo: "Trailside",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "442000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 52'",
      Mo: "Cedar",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 52'",
      Mo: "Highgate",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 52'",
      Mo: "Mystique",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 52'",
      Mo: "Whitestone",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "549000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 52'",
      Mo: "Yorkshire",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "581000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 52'",
      Mo: "Mystique Grand",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 62'",
      Mo: "Ashby",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 62'",
      Mo: "Easley",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 62'",
      Mo: "Oakhurst",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 62'",
      Mo: "Roseland",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "701000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 62'",
      Mo: "Upton",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "884000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point At Lakewood Ranch 62'",
      Mo: "Easley Grande",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "588000"
    },
    {
      Bid: "22680",
      Co: "Sarasota National 35",
      Mo: "Coquina",
      Str: "10373 Crooked Creek Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1761,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sarasota National 35",
      Mo: "Nautilus",
      Str: "10373 Crooked Creek Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sarasota National 35",
      Mo: "Medina",
      Str: "10373 Crooked Creek Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1593,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Trenton",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "1",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Harrisburg",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1791,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Hartford",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1941,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Harrisburg",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1791,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Raleigh",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2889,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Trenton",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 1,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Raleigh",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2889,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Celeste",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Harrisburg",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1791,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Bloom",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Dawn",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:executive Homes",
      Mo: "Hartford",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1941,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Columbia",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Atlanta",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Annapolis",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Boston",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Annapolis",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Columbia",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Edison",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Annapolis",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Belmont",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Atlanta",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:patio Homes",
      Mo: "Boston",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Mystique",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Mystique Grand",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Monroe",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Yorkshire",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3415,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Easley Grand",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Medina",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Tower",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Easley",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Whitestone",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Heston",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Sawgrass At Coral Lakes",
      Mo: "Ivy",
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "240000"
    },
    {
      Bid: "22690",
      Co: "Sawgrass At Coral Lakes",
      Mo: "Alder",
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "266000"
    },
    {
      Bid: "22690",
      Co: "Sawgrass At Coral Lakes",
      Mo: "Rowan",
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22680",
      Co: "Sawgrass Bay",
      Mo: "Allentown",
      Str: "4582 Tahoe Circle",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sawgrass Estate",
      Mo: "Worthington Ii D",
      Str: "12207 Sabal Palmetto Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32824,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2937,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Victoria",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2002,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Hartford",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Alexia",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1713,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Providence",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Victoria",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2002,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Hartford",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1937,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Hailey",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1963,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Providence",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2583,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Harrisburg",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1809,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Indies",
      Mo: "Alexia",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1713,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Alexia",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1713,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Victoria",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2002,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Ashley",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Victoria",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2002,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Jessica",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1483,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Ashley",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1831,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:the Shores",
      Mo: "Alexia",
      Str: "3531 Harborside Ave",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1713,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Atlanta",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Trenton",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Harrisburg",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Dover",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Raleigh",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Hartford",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Atlanta",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Providence",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Trenton",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Richmond",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Estates",
      Mo: "Harrisburg",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Manors",
      Mo: "Concord",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:the Manors",
      Mo: "Atlanta",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Manors",
      Mo: "Columbia",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Manors",
      Mo: "Concord",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Manors",
      Mo: "Annapolis",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaire:the Manors",
      Mo: "Boston",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Halle Ii",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Halle",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Brio",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Brio Ii",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Halle",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Charle Ii",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Halle",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Trevi Ii",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Brio",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Trevi",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Charle",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Charle",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Trevi",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Trevi Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Trevi",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Charle",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Charle Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Brio",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Halle",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Brio Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 50s",
      Mo: "Halle Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Tivoli Ii",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Tivoli",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Princeton",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Trevi- Model Hs 0003 Only",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Tivoli",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Princeton",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Princeton",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Medallion",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Medallion",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Medallion Ii",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Princeton",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Princeton Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Trevi- Model Hs 0003 Only",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "317000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Medallion Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Tivoli",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Tivoli Ii",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:seaton Creek 60s",
      Mo: "Medallion",
      Str: "1418 Gwinnett Ln",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Fontana",
      Str: "29171 Sw 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2648,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Edlin",
      Str: "29171 Sw 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Caraway",
      Str: "29171 Sw 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2086,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Willow",
      Str: "29171 Sw 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3468,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Fontana",
      Str: "29171 Sw 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2648,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Mulberry",
      Str: "29171 Sw 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2806,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Seminole Tribe Of Florida Th",
      Mo: "Galveston",
      Str: "Sr-7 And Sunset Dr",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Seminole Tribe Of Florida Th",
      Mo: "Lanai",
      Str: "Sr-7 And Sunset Dr",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Felicity",
      Str: "11535 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Felicity",
      Str: "10162 Sw Corvus Ln",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Nirvana",
      Str: "11590 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1376,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Felicity",
      Str: "11599 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Eden",
      Str: "10218 Sw Corvus Ln",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Felicity",
      Str: "11606 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Eden",
      Str: "11639 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Felicity",
      Str: "11631 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Eden",
      Str: "10170 Sw Corvus Ln",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Eden",
      Str: "11582 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Eden",
      Str: "11703 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Serenity At Telaro 35'",
      Mo: "Felicity",
      Str: "11519 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Sereno Grove",
      Mo: "Bougainvillea",
      Str: "14595 Nicholas Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34109,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2800,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Whitestone",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Mystique Grand",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Prosperity",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Mystique",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Yorkshire",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Tower",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Easley",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Ashby Grand",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Oakhurst",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Easley Grand",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Ashby",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Morris",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Henley",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Drayton",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Trailside",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Settlement At Twenty Mile",
      Mo: "Braydon",
      Str: "317 Parkbluff Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Settlement At Twenty Mile",
      Mo: "Rowen",
      Str: "54 Parkbluff Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2947,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Settlement At Twenty Mile",
      Mo: "Rowen",
      Str: "388 Parkbluff Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2931,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Settlement At Twenty Mile",
      Mo: "Knollwood",
      Str: "571 Parkbluff Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Settlement At Twenty Mile",
      Mo: "Braydon",
      Str: "51 Parkbluff Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2888,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Settlement At Twenty Mile",
      Mo: "Braydon",
      Str: "219 Parkbluff Circle",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2888,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "250 Settlers Landing Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "419 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "194 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "49 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3007,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "59 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "190 Sawyer Bridge Trail",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "50 Sawyer Bridge Trail",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "223 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "140 Sawyer Bridge Trail",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "184 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2906,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "375 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "425 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "133 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "161 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "176 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "181 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "230 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "147 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "222 Settlers Landing Dr",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "124 Sawyer Bridge Trail",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "62 Sawyer Bridge Trail",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "169 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "93 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "311 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "238 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "206 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "265 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "155 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "85 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "395 Sawyer Bridge Tr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "212 Settlers Landing Dr.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "198 Sawyer Bridge Trail",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "131 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anastasia Elite",
      Str: "36 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Crestview",
      Str: "140 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2977,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anastasia Elite",
      Str: "78 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Crestview",
      Str: "25 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "217 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Julington",
      Str: "231 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4187,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Crestview",
      Str: "98 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "205 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3150,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Crestview",
      Str: "73 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "175 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3523,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anastasia Elite",
      Str: "88 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "159 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Julington",
      Str: "160 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "22 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3522,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anastasia Elite",
      Str: "116 Permit Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Anna Maria",
      Str: "105 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3307,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Crestview",
      Str: "119 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-ambassador",
      Mo: "Crestview",
      Str: "191 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2843,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Built Up",
      RS: "Flat",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "42 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "151 Anclote Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Seabrooke",
      Str: "137 Anclote Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Anastasia",
      Str: "57 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Seabrooke",
      Str: "54 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Seabrooke",
      Str: "74 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Captiva",
      Str: "94 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "404 Windley Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2843,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "163 Anclote Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2977,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "66 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "432 Windley Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "82 Grady Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "79 Bowery Avenue",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Captiva",
      Str: "175 Anclote Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, Tb At-heritage",
      Mo: "Crestview",
      Str: "116 Anclote Way",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:affordable Luxury Townhomes",
      Mo: "Lincoln",
      Str: "391 Rosemont Drive",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Royal Collection",
      Mo: "Trevi",
      Str: "28 Bluffton Ct",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Charle",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Trevi",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Brio",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra Bonus",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra Bonus",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2663,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Brio",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Trevi",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Charle Ii",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra Bonus Ii",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2610,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra Ii",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2257,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Charle",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Sierra",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Trevi Ii",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater - Single Family",
      Mo: "Brio Ii",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater 24ft Townhomes",
      Mo: "Osprey",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1494,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "120000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater 24ft Townhomes",
      Mo: "Osprey",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1494,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater 24ft Townhomes",
      Mo: "Mallard",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2005,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater 24ft Townhomes",
      Mo: "Wigeon",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1799,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:shearwater Townhomes",
      Mo: "Lincoln - Affordable Housing",
      Str: "27 Appleton Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:traditional Townhomes",
      Mo: "Truman",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:traditional Townhomes",
      Mo: "Truman",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:traditional Townhomes",
      Mo: "Lincoln",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:traditional Townhomes",
      Mo: "Lincoln",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:traditional Townhomes",
      Mo: "Truman",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:traditional Townhomes",
      Mo: "Lincoln",
      Str: "30 Neighbor Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Shoreline",
      Mo: "Adirondack",
      Str: "354 Surfside Ave Ne",
      Ct: "Saint Petersburg",
      St: "FL",
      Zip: 33716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "372000"
    },
    {
      Bid: "22689",
      Co: "Shoreline",
      Mo: "Leland",
      Str: "354 Surfside Ave Ne",
      Ct: "Saint Petersburg",
      St: "FL",
      Zip: 33716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "6703",
      Co: "Shores @whippoorwill Lake-esta",
      Mo: "Corbeil",
      Str: "12040 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Shores @whippoorwill Lake-esta",
      Mo: "Corbeil",
      Str: "12058 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1085000"
    },
    {
      Bid: "6703",
      Co: "Shores @whippoorwill Lake-esta",
      Mo: "Grenache",
      Str: "12034 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3667,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Shores At Lake Whippoorwill Â€“ Estates",
      Mo: "Bordeaux",
      Str: "12028 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2976,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-est",
      Mo: "Corbeil",
      Str: "12016 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1650000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-est",
      Mo: "Corbeil",
      Str: "12028 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-est",
      Mo: "Corbeil",
      Str: "12052 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-est",
      Mo: "Barbera",
      Str: "12010 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1195000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-est",
      Mo: "Barbera",
      Str: "12022 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1420000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-est",
      Mo: "Grenache",
      Str: "12064 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3667,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-sig",
      Mo: "Varen",
      Str: "12051 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2645000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-sig",
      Mo: "Varen",
      Str: "12061 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2100000"
    },
    {
      Bid: "6703",
      Co: "Shores@whippoorwill Lk-sig",
      Mo: "Cabris",
      Str: "12041 Sprite Lane",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2200000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Summerwood",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2007,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Pinnacle",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Tangerly Oak",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "583000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Stonewater",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "642000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Stonewater",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "642000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Southampton",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2903,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "654000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Nobility",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Clubview",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "693000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Camelot",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3203,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Vence",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Casis",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Bandol",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Vence",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Monte Carlo",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Bandol",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Casis",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Monte Carlo",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Reserve",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Vence",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1668,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Monte Carlo",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1489,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Casis",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1414,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Barclay",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1370,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Reserve",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1545,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:adora Collection",
      Mo: "Bandol",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1334,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Genoa",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1792,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Lorient",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Bordeaux",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Avignon",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Marsielle",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Dijon",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Avignon",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Dijon",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Marsielle",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Lorient",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Dijon",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1697,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Lorient",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1828,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Genoa",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1792,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Bordeaux",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1443,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Marsielle",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1828,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:del Mesa Collection",
      Mo: "Avignon",
      Str: "12705 Sw 232 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1334,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:fontaine Collection",
      Mo: "Palmdale",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3221,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:fontaine Collection",
      Mo: "Ayla",
      Str: "12705 Sw 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1827,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Hamden",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Mill Run",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Talon",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Whitmore",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Cresswind",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Medina",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Tilden",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Winthrop",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Yellowstone",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Heston",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Silverland Estates",
      Mo: "Fontana",
      Str: "197 Sw Th Ave And Sw 320 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2648,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Silverland Estates",
      Mo: "Mulberry",
      Str: "197 Sw Th Ave And Sw 320 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2806,
      Sto: 1,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "Silverland Estates",
      Mo: "Edlin",
      Str: "197 Sw Th Ave And Sw 320 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2403,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Silverland Estates",
      Mo: "Voyage",
      Str: "197 Sw Th Ave And Sw 320 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3105,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "621000"
    },
    {
      Bid: "22680",
      Co: "Silverland Estates",
      Mo: "Willow",
      Str: "197 Sw Th Ave And Sw 320 St",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3468,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 34'",
      Mo: "Benton",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "339000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 34'",
      Mo: "Haddock",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 34'",
      Mo: "Caden",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "424000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Coral Grand",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3017,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Cardinal",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1958,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Coral",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2006,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Imperial",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Scarlett",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3643,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "647000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Ruby",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2883,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "523000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks Th",
      Mo: "Orchid",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1553,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks Th",
      Mo: "Dahlia",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks Th",
      Mo: "Julep",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "353000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Trevi",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Charle",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Independence",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Sierra",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Sierra Bonus",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2663,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Brio",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 50s At Silverleaf",
      Mo: "Trevi",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Princeton",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Kingsley Iii",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Princeton",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Kingsley Iii",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3381,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Katalina",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2683,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Medallion",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Tivoli",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Medallion Bonus",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3152,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Patriot",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3004,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Kataline Bonus",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3202,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls 60s At Silverleaf",
      Mo: "Leeward",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2957,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls Townhomes At Silverleaf",
      Mo: "Truman",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Falls Townhomes At Silverleaf",
      Mo: "Lincoln",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 53s",
      Mo: "Sierra",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 53s",
      Mo: "Chloe-- Hs 549 Only",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 53s",
      Mo: "Sierra Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Tivoli",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Patriot",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3004,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Medallion Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Medallion",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2654,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Patriot",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3004,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Kingsley Iii",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Princeton",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Tivoli",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Kingsley Iii",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Katalina",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Katalina Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Landing 63s",
      Mo: "Princeton",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Sierra Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Trevi",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Sierra",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Charle",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Sierra Bonus",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2663,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Sierra",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Brio",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Trevi",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 50s",
      Mo: "Charle",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Medallion Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Kingsley Iii",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Barton",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Medallion",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Tivoli",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Medallion Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Patriot",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3004,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Sierra-homesite 36 Only",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Barton",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2404,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Medallion",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Medallion Bonus",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3152,
      Sto: 1,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Tivoli",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Kingsley Iii",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3381,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows 60s",
      Mo: "Princeton",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows Villas",
      Mo: "Sienna",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1623,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows Villas",
      Mo: "Aurora",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1522,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silver Meadows Villas",
      Mo: "Topaz",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Boone",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Kate",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Alexia",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Boone",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Alexia",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Kate",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Boone",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Kate",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Silverleaf:silverfalls 40s At Silverleaf",
      Mo: "Alexia",
      Str: "39 Simonson Run",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Silverwood Estates",
      Mo: "Burgandy",
      Str: "5376 Hypoluxo Road",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33463,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2046,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Smith Station",
      Mo: "Plan 1730",
      Str: "41 N Jefferson St  # 108",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32502,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1730,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Smith Station",
      Mo: "Plan 1850",
      Str: "41 N Jefferson St  # 108",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32502,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "6701",
      Co: "Sola Vista",
      Mo: "Null",
      Str: "5367 Jubiloso Dr",
      Ct: "St Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3636,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "570000"
    },
    {
      Bid: "6701",
      Co: "Sola Vista",
      Mo: "Sherrington",
      Str: "2175 Brillante Drive",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "Sola Vista",
      Mo: "Alvarez Ii",
      Str: "2183 Brillante Dr",
      Ct: "St Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2762,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8927 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8903 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "8913 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "1799 Tropical Palms Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8919 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "8925 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "8917 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8947 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8923 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8901 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "1570 Mermaid Cove Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "8907 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3543,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "8929 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu Ii",
      Str: "1609 Tropical Palms Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "8909 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna Ii",
      Str: "1566 Mermaid Cove Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22374",
      Co: "Solara Resort",
      Mo: "Malibu Ii",
      Str: "8931 Coconut Breeze Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34737,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1042 Boardwalk Place",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Glades",
      Str: "1189 Lakeshore Breeze Place",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1106 Boardwalk Place",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "9051 Coral Cape Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Solis Grande",
      Mo: "Rowan",
      Str: "27869 Solis Grande Dr",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "232000"
    },
    {
      Bid: "22689",
      Co: "Solis Grande",
      Mo: "Alder",
      Str: "27869 Solis Grande Dr",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "255000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Dunnet",
      Str: "18527 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Rosehall",
      Str: "18535 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Rosehall",
      Str: "18484 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Dunnet",
      Str: "18543 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Dunnet",
      Str: "18508 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Dunnet",
      Str: "18492 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-villa",
      Mo: "Rosehall",
      Str: "18516 Dayspring Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Flora",
      Str: "12794 Pinnacle Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Flora",
      Str: "12787 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Sanderling Elite",
      Str: "12815 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Pelican",
      Str: "12829 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Sanderling Elite",
      Str: "12857 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Pelican",
      Str: "12770 Pinnacle Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-summit",
      Mo: "Sanderling",
      Str: "12830 Pinnacle Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-sunbeam",
      Mo: "Sollas",
      Str: "12732 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-sunbeam",
      Mo: "Sollas",
      Str: "18635 Midsummer Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-sunbeam",
      Mo: "Sollas",
      Str: "18645 Midsummer Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-sunbeam",
      Mo: "Aviemore",
      Str: "12756 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-sunbeam",
      Mo: "Augustus",
      Str: "12912 Morning Mist Place",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Solstice@wellen Park-sunbeam",
      Mo: "Castlebay",
      Str: "12705 Pinnacle Lane",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Stonewater W Loft",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "752000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Stonewater",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "571000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Garden Vista",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "1538000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Tangerly Oak",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "518000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Oaks",
      Mo: "Augusta",
      Str: "4397 Sonoma Oaks Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1901,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "267000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Oaks",
      Mo: "Easton",
      Str: "4397 Sonoma Oaks Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2018,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "283000"
    },
    {
      Bid: "22680",
      Co: "South Creek:estates I",
      Mo: "Hartford",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Celeste",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Honor",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2754,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Nova",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3041,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Dawn",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Eclipse",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Bloom",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Estates",
      Mo: "Aura",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1343,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "170000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Executives",
      Mo: "Aspire",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1843,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Executives",
      Mo: "Crest",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2383,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Executives",
      Mo: "Heritage",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2587,
      Sto: 1,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Executives",
      Mo: "Frontier",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2033,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Executives",
      Mo: "Summit",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2950,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "South Creek:the Executives",
      Mo: "Bravo",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2205,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "South Fork:stone Walk Executive I",
      Mo: "Providence",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:stone Walk Executive I",
      Mo: "Santa Fe",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:stone Walk Executive I",
      Mo: "Lincoln",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:sunstone Ridge Estates",
      Mo: "Do Not Use Moved To 724076",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:sunstone Ridge Executives",
      Mo: "Santa Fe",
      Str: "11711 Sunburst Marble Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove By Lennar",
      Mo: "Sunset",
      Str: "8160 Wiltshire Drive",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove By Lennar",
      Mo: "Oakmont Ii",
      Str: "8160 Wiltshire Drive",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove By Lennar",
      Mo: "Sunset",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove By Lennar",
      Mo: "The Summerville Ii",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove By Lennar",
      Mo: "Oakmont Ii",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2361,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove By Lennar",
      Mo: "Trevi",
      Str: "8160 Wiltshire Dr",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Morningtide",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Meridian",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Dawning",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Meridian",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Morningtide",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Meridian",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Dawning",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Morningtide",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Cottages",
      Mo: "Dawning",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Argent",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Beacon",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Solstice",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3890,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Halos",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Daybreak",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Halos",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Argent",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Daybreak",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Beacon",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Argent",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Daybreak",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Dawning",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Meridian",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Halos",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Beacon",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2775,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Solstice",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3890,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "22680",
      Co: "Southern Hills:southern Hills Manors",
      Mo: "Solstice",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3890,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Illinois",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Ohio",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2865,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Georgia",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2725,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Alabama",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Estates",
      Mo: "Eventide Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Estates",
      Mo: "Argent Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Estates",
      Mo: "Daybreak Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Estates",
      Mo: "Halos",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Estates",
      Mo: "Halos Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Grand Estates",
      Mo: "Ray Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2785,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Grand Estates",
      Mo: "Daybreak",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Grand Estates",
      Mo: "Soleil Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2761,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Dawning Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Meridian Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Morningtide Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Sunburst Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Splendor Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Splendor",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1783,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Morningtide Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Morningtide",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1683,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Dawning",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Meridian Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Sunburst",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1959,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Meridian",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2366,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Manors",
      Mo: "Dawning Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2145,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Villas",
      Mo: "Sunrise- Do Not Use",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1396,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Villas",
      Mo: "Sunrise Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1395,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Villas",
      Mo: "Aurora Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1744,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Villas",
      Mo: "Dayspring Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1613,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:active Adult Villas",
      Mo: "Sunrise Ii",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1395,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Sandestin Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Sandestin Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Biscayne Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Caladesi Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2501,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Anna Maria Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2544,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Sandestin Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2875,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Caladesi Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2501,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Venice Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1986,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:pembroke Bay",
      Mo: "Biscayne Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2403,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Do Not Use Moved To 727874",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Venice Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Sandestin Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Sandestin Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Venice Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Caladesi Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2501,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Biscayne Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Anna Maria Ii",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2544,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Caladesi Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2501,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Sandestin Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2875,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Venice Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1986,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Anna Maria Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2544,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:somerset Preserve",
      Mo: "Biscayne Ii",
      Str: "3079 Bermuda Sloop Cir",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2403,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:the Townes",
      Mo: "Mainsail",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2572,
      Sto: "4",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Spencer Creek:the Estates",
      Mo: "Dover",
      Str: "Tiger Tooth Place",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "865 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "741 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1082 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "811 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "908 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "968 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1052 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "1070 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "999 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "734 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "993 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "950 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "753 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "921 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "1034 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "759 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "728 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "944 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "883 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "1058 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "992 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "747 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "927 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "986 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle Palms ",
      Mo: "Venice",
      Str: "710 Spring Palms Loop",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Boone",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Alexia Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Alexia",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "2.5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Alexia",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Hailey",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Chloe",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Alexia Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Hailey",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Boone Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2089,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Hailey Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Chloe",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Boone",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Kate",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Boone",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Hailey Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Kate Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1744,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Alexia Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Chloe",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Jessica",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1503,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Boone Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2089,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Hailey",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1951,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 40s",
      Mo: "Alexia",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Brio",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Spirit Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2257,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Charle Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Brio Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Halle Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Halle",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Trevi",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra W/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Chloe- Model Only Hs 69",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Trevi",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Charle",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra Ii W/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Charle",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Elan Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Trevi Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Halle",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra W/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Trevi",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Brio",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Boone",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2088,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Alexia Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Kate Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1744,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Brio Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Boone Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2089,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Trevi Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Charle Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Halle Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Elan Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Alexia",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Chloe",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2260,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Spirit Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Kate",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Jessica",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1503,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra Ii W\\/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2610,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Hailey Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Halle",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Charle",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra Ii",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2257,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Sierra W\\/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2663,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:st Augustine Lakes 50s",
      Mo: "Hailey",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1951,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Stagecoach",
      Mo: "Plan 1815",
      Str: "801 West Romana Suite A",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Stillmont Townhomes",
      Mo: "Evergreen",
      Str: "13036 Stillmont Place",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1451,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Stillmont Townhomes",
      Mo: "Sycamore",
      Str: "13036 Stillmont Place",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1762,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Stillmont Townhomes",
      Mo: "Ashe",
      Str: "13036 Stillmont Place",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1818,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater - Villas",
      Mo: "Sienna",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1623,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater - Villas",
      Mo: "Topaz",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater - Villas",
      Mo: "Topaz",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1891,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater - Villas",
      Mo: "Aurora",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1522,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater - Villas",
      Mo: "Sienna",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1623,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Kate",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Hailey",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1950,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Jessica",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Alexia Ii",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Alexia",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Hailey Ii",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Kate Ii",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1744,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Jessica",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1503,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Hailey Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1897,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Alexia Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1663,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Annapolis - Hs 431 Only",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1438,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Kate",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1875,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Hailey",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Alexia",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1714,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (40s) - Royal Collection",
      Mo: "Kate Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1744,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Elan",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Charle",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Trevi Ii",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Halle",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Independence Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2250,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Independence",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Sierra Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2257,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Sierra",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Elan Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Charle Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Trevi",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Elan",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Halle",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Charle",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Halle Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:stillwater (50s) - Royal Collection",
      Mo: "Trevi Ii",
      Str: "64 Round Robin Run",
      Ct: "St Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6955 S. Stillwater Shores Dr.",
      Ct: "Davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6895 S. Stillwater Shores Dr.",
      Ct: "Davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1135000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6894 N. Stillwater Shores Dr.",
      Ct: "Davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1480000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Santangelo Ii",
      Str: "6925 S. Stillwater Shores Dr.",
      Ct: "Davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1030000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6924 N. Stillwater Shores Dr.",
      Ct: "Davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1185000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Dalenna Ii",
      Str: "6834 N. Stillwater Shores Dr.",
      Ct: "Davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1235000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:stillwater - Villas",
      Mo: "Sienna",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1623,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:stillwater - Villas",
      Mo: "Topaz",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:stillwater (40s) - Royal Collection",
      Mo: "Kate",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:stillwater (40s) - Royal Collection",
      Mo: "Alexia",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:stillwater (40s) - Royal Collection",
      Mo: "Hailey",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1950,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:stillwater (50s) - Royal Collection",
      Mo: "Elan",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Stof Lakeland",
      Mo: "Summerville Ii",
      Str: "Moore Road",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Stof Lakeland",
      Mo: "Sorrento",
      Str: "Moore Road",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Stof Lakeland",
      Mo: "Bellamy",
      Str: "Moore Road",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2085,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Prosperity",
      Str: "9676 Sw 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Mystique",
      Str: "9676 Sw 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Mainstay",
      Str: "9676 Sw 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Prestige",
      Str: "9676 Sw 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Mystique Grand",
      Str: "9676 Sw 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Stellar Grand",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3361,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "471000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Stellar",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "348000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Renown",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "394000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Stardom",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Daylen",
      Str: "9293 Sw 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "241000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Beachwood",
      Str: "9293 Sw 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "226000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Candlewood",
      Str: "9293 Sw 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Blackwell",
      Str: "9293 Sw 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "226000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Drayton",
      Str: "9293 Sw 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "241000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Crestwood",
      Str: "9293 Sw 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Passport",
      Mo: "Highgate",
      Str: "6320 Sw 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "276000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Passport",
      Mo: "Cresswind",
      Str: "6320 Sw 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "272000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Passport",
      Mo: "Heston",
      Str: "6320 Sw 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "278000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Scenic",
      Mo: "Contour",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "247000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Scenic",
      Mo: "Compass",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "251000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Scenic",
      Mo: "Hallmark",
      Str: "6320 Sw 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "262000"
    },
    {
      Bid: "22680",
      Co: "Stonechase",
      Mo: "Hadley",
      Str: "6307 Buckthorn Cir",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2503,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Stonechase",
      Mo: "Mccartney",
      Str: "6307 Buckthorn Cir",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2294,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Stonechase",
      Mo: "Paxton",
      Str: "6307 Buckthorn Cir",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2033,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Stonechase",
      Mo: "Tivoli",
      Str: "6307 Buckthorn Cir",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Stonechase",
      Mo: "Nina",
      Str: "6307 Buckthorn Cir",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2338,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Stonechase",
      Mo: "Ryder",
      Str: "6307 Buckthorn Cir",
      Ct: "Pace",
      St: "FL",
      Zip: 32571,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2117,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Preston",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Aspen",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1844,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Brindley",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Augusta",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2084,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Aspen",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1844,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Brindley",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2156,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Juneau",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2954,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Mcginnis",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3230,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Preston",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Carlisle",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2627,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Estates",
      Mo: "Augusta",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2084,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Denali",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Patterson",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3068,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Preston",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3282,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Rainer",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3336,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Whitney",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3629,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Blackburn",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Symphony",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Patterson",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3068,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Denali",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2647,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Blackburn",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2453,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Preston",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3282,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Symphony",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3868,
      Sto: 2,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Rainer",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3336,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Executives",
      Mo: "Whitney",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3629,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Manors",
      Mo: "Greylock",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Manors",
      Mo: "Stowe",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2492,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Manors",
      Mo: "Vail",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2776,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Manors",
      Mo: "Greylock",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2189,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Manors",
      Mo: "Vail",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2776,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Manors",
      Mo: "Stowe",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2492,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Town Estates",
      Mo: "St. Kitts Ii",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1787,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:the Town Estates",
      Mo: "St. Thomas Ii",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1666,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Stonehill Manor",
      Mo: "The Summerville Ii",
      Str: "10508 Mineral Ct",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonehill Manor",
      Mo: "Sorrento",
      Str: "10508 Mineral Ct",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stoneybrook Hills:magnolia Gardens",
      Mo: "Inverness",
      Str: "6551 Stoneybrook Parkway",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Heathcliff",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Durham",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Dover",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Hartridge",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Allentown",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Catherine",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Baxter",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3178,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Parking Site",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1665000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Bailey",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Heathcliff",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Dover",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Allentown",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1853,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Durham",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Hartridge",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1850,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Heritage",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Buffer Site",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1665000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:estate Collection",
      Mo: "Lucas",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Columbia",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Sheffield",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Sheffield",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Annapolis",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Alexander",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Columbia",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Janeway",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1263,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Albany",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1267,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Albany",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Hansen",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "362000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Sheffield",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2334,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "356000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Alexander",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2380,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Janeway",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1263,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Dawson",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2350,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Lofton",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1950,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Annapolis",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Saratoga",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Ethan",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2547,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Buffer Site",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1665000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Columbia",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Storey Creek:manor Collection",
      Mo: "Parking Site",
      Str: "2009 Myrtle Pine St",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1665000"
    },
    {
      Bid: "22680",
      Co: "Storey Grove:estate Collection",
      Mo: "Simmitano",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:estate Collection",
      Mo: "Simmitano",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:estate Collection",
      Mo: "Douglas",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:estate Collection",
      Mo: "Orleans Ii",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2727,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:manor Collection",
      Mo: "Residence 3",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:manor Collection",
      Mo: "Residence 1",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:manor Collection",
      Mo: "Residence 2",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:manor Collection",
      Mo: "Sage",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2502,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:manor Collection",
      Mo: "Residence 2",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:townhome Collection",
      Mo: "Montara",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:townhome Collection",
      Mo: "Wilshire",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:townhome Collection",
      Mo: "Landcaster",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:townhome Collection",
      Mo: "Wilshire",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:townhome Collection",
      Mo: "Montara",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections Estate Homes",
      Mo: "Bourne Ii",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections Estate Homes",
      Mo: "Eastham Ii",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections Executive Homes",
      Mo: "Thornebrook",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3907,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections Executive Homes",
      Mo: "Bloomfield",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections Executive Homes",
      Mo: "Marina",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections: Manor Homes",
      Mo: "Sheffield",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections: Manor Homes",
      Mo: "Charlestown",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1960,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:reflections: Manor Homes",
      Mo: "Plymouth",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2074,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:the Cove Resort Single Family",
      Mo: "Gatsby",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3795,
      Sto: "2",
      Be: "6",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:the Cove Resort Single Family",
      Mo: "Santiago",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4305,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:the Cove Resort Townhomes",
      Mo: "Beach Th Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:the Cove Resort Townhomes",
      Mo: "Sabal Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:vacation Townhomes",
      Mo: "Beach Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:vacation Townhomes",
      Mo: "Beach Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:vacation Townhomes",
      Mo: "Royale Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:vacation Townhomes",
      Mo: "Sabal Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:vacation Villas",
      Mo: "Alexander Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:vacation Villas",
      Mo: "Majesty Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Riviera",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Wesley",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3321,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Harwich Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3957,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Parking Site",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Bourne Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Independence Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3931,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Greythorne",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:executive Collection",
      Mo: "Bloomfield",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:hemingway Townhomes",
      Mo: "Wilshire",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:hemingway Townhomes",
      Mo: "Montara",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:hemingway Townhomes",
      Mo: "Landcaster",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Cottage Collection",
      Mo: "Lexington",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Cottage Collection",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Cottage Collection",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Cottages",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Independence Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3951,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Provincetown Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Peabody",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3288,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Harwich Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3903,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Harwich Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3903,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Mashpee Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Eastham Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Bourne Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Provincetown Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Estate Collection",
      Mo: "Eastham Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Executive Collection",
      Mo: "Greythorne",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Executive Collection",
      Mo: "Bloomfield",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Executive Collection",
      Mo: "Independence Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3931,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Manor Collection",
      Mo: "Rio",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2634,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Manor Collection",
      Mo: "Delray",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Manor Collection",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Manors",
      Mo: "Delray",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:innovation Manors",
      Mo: "Rio",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2634,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Cottages",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Cottages",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Cottages",
      Mo: "Lexington",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Estates",
      Mo: "Mashpee Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Estates",
      Mo: "Peabody",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3288,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Estates",
      Mo: "Eastham Ii",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Manors",
      Mo: "Delray",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Manors",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Manors",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:storey Park Manors",
      Mo: "Rio",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2634,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:the Hemingway Townhomes",
      Mo: "Landcaster",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:the Hemingway Townhomes",
      Mo: "Montara",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Prosperity",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mystique",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Palmary",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "359000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mainstay",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "359000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Prestige",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mainstay Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "409000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mystique Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "414000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Stardom",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "429000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Stellar",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Renown",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "464000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Stellar Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "487000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Contour",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "296000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Compass",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "302000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Hallmark",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "312000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Compass Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1984,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "348000"
    },
    {
      Bid: "22680",
      Co: "Summerlake:summerlake Estate Homes",
      Mo: "Harwich Ii",
      Str: "14891 Winter Stay Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3957,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Chapman",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Hanover",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Seabrook",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Wakefield",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Thompson",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Browning",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1477,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Sunglow At Emery 35's",
      Mo: "Aspen",
      Str: "12720 Sw Eleanor Dr",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Sunglow At Emery 35's",
      Mo: "Aspen",
      Str: "12672 Sw Eleanor Dr",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Sunglow At Emery 35's",
      Mo: "Aspen",
      Str: "10724 Sw Gloriana St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Sunglow At Emery 35's",
      Mo: "Aspen",
      Str: "10717 Sw Gloriana St",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "22374",
      Co: "Sunglow At Emery 35's",
      Mo: "Briar",
      Str: "12744 Sw Eleanor Dr",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  50's",
      Mo: "Dawn",
      Str: "5746 Long Shore Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  50's",
      Mo: "Riviera Ii",
      Str: "5742 Long Shore Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  50's",
      Mo: "Dawn",
      Str: "8536 September Sky Court",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  50's",
      Mo: "Dayspring",
      Str: "8717 Sundance Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  50's",
      Mo: "Dawn",
      Str: "5737 Long Shore Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  50's",
      Mo: "Dayspring",
      Str: "8725 Sundance Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  60's",
      Mo: "Jubilee",
      Str: "5783 Long Shore Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "22374",
      Co: "Sunrise Jv -  60's",
      Mo: "Jubilee",
      Str: "8681 Sundance Loop",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "22680",
      Co: "Sunset Grove",
      Mo: "Desoto B",
      Str: "1420 Benevento Street",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2686,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Grove",
      Mo: "Desoto B",
      Str: "1420 Benevento Street",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2686,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Marsielle",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Dijon",
      Str: "132 Sw 185th Way",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Bordeaux",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Lorient",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Ashby Grand",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3284,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Ashby",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Easley",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1.5",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Renown",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Oakhurst",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Berkley",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Easley Grand",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Berkley Grand",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "593000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Roseland",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "609000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Upton",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5336,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "748000"
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Althea",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1362,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Rose",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1961,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Gardenia",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1794,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Cassia",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1465,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Rose",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1974,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Daphne",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1594,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Freesia",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1706,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Cassia",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1465,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Gardenia",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1794,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Daphne",
      Str: "4610 Nw 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1594,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Cassia",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1465,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Freesia",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1706,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Sunstone",
      Mo: "Glades",
      Str: "17953 Grand Prosperity Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Sunstone At Wellen Park 30' Th",
      Mo: "Topsail",
      Str: "12497 Somatic Ct",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Sunstone At Wellen Park 30' Th",
      Mo: "Anclote",
      Str: "12394 Asana Ct",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Sunstone At Wellen Park 50's",
      Mo: "Pinnacle",
      Str: "18081 Grand Prosperity Dr",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Sylvan Crossing",
      Mo: "Patriot",
      Str: "18825 Noble Caspian Dr",
      Ct: "Lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1597,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Mystique",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Palmary",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Prestige",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Whitestone",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "522000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Prosperity",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Stardom",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Reverence",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Renown",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Layton",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "604000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Stellar",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Contour",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Compass",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Hallmark",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Morris",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "441000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Trailside",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "523000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Vista",
      Mo: "Seagrove",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Vista",
      Mo: "Cascadia",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "321000"
    },
    {
      Bid: "22680",
      Co: "Tara Baywood",
      Mo: "Lincoln",
      Str: "16443 Nw 144th St",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1707,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Tara Baywood",
      Mo: "Truman",
      Str: "16443 Nw 144th St",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Tara Forest East:tara Forest East 50s",
      Mo: "Eclipse",
      Str: "Nw County Road 241",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2449,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Tara Forest East:tara Forest East 50s",
      Mo: "Dawn",
      Str: "Nw County Road 241",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2172,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Tara Forest East:tara Forest East 50s",
      Mo: "Celeste",
      Str: "Nw County Road 241",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1821,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Tara Forest East:tara Forest East 50s",
      Mo: "Bravo",
      Str: "Nw County Road 241",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2202,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Tara Forest East:tara Forest East 50s",
      Mo: "Aspire",
      Str: "Nw County Road 241",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1841,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Tara Forest East:tara Forest East 50s",
      Mo: "Frontier",
      Str: "Nw County Road 241",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2031,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Tarramor:tarramor Estates",
      Mo: "Ventura",
      Str: "6162 Marsh Trail Drive",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5596,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "22680",
      Co: "Tarramor:tarramor Premier",
      Mo: "Seabrook",
      Str: "6162 Marsh Trail Drive",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4439,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Tb At Bella Collina-vista",
      Mo: "Cabris",
      Str: "15954 Pendio Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1500000"
    },
    {
      Bid: "6703",
      Co: "Tb At Bella Collina-vista",
      Mo: "Montech",
      Str: "15938 Pendio Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1855000"
    },
    {
      Bid: "6703",
      Co: "Tb At Bella Collina-vista",
      Mo: "Cabris",
      Str: "15650 Pendio Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1545000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Pelican",
      Str: "108 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "577 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "549 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora Elite",
      Str: "604 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora Elite",
      Str: "112 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "596 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Pelican",
      Str: "548 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Pelican",
      Str: "580 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling",
      Str: "541 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "612 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora Elite",
      Str: "609 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Pelican",
      Str: "569 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "136 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling",
      Str: "109 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "564 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora Elite",
      Str: "568 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "553 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora Elite",
      Str: "573 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "561 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling",
      Str: "601 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "441 Baneberry Court",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Pelican",
      Str: "572 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "589 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Flora",
      Str: "545 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Tb At Venice Woodlands",
      Mo: "Pelican",
      Str: "585 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22374",
      Co: "Telaro At Tradition",
      Mo: "Eden",
      Str: "11671 Sw Viridian Blvd",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Iris",
      Str: "Sw 172nd Ave And Sw 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1671,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Carnation",
      Str: "Sw 172nd Ave And Sw 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Poppy",
      Str: "Sw 172nd Ave And Sw 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1746,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Water Lilly",
      Str: "Sw 172nd Ave And Sw 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Carnation",
      Str: "17175 Sw 336 St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1649,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Iris",
      Str: "17175 Sw 336 St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1671,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Water Lilly",
      Str: "17175 Sw 336 St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1804,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Poppy",
      Str: "17175 Sw 336 St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1746,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Mystique",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "376000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Palmary",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Prestige",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "437000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Whitestone",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "468000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Stardom",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "511000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Stellar",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "559000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Renown",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "601000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Layton",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Oakhurst",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "661000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Scenic",
      Mo: "Contour",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Scenic",
      Mo: "Hallmark",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Scenic",
      Mo: "Trailside",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Giulia",
      Str: "Virtual Whc",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2236,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Angelo",
      Str: "Virtual Whc",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2013,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Poppi",
      Str: "Virtual Whc",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3066,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Lucca",
      Str: "Virtual Whc",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2624,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Vito",
      Str: "Virtual Whc",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3198,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Lucca",
      Str: "713 Se Fascino Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2624,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Giulia",
      Str: "713 Se Fascino Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2236,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447200"
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Vito",
      Str: "713 Se Fascino Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3222,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Poppi",
      Str: "713 Se Fascino Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3066,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "Angelo",
      Str: "713 Se Fascino Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2013,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22374",
      Co: "Testcommunity2",
      Mo: "Testmodel",
      Str: "27 Ocala Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Testcommunity3",
      Mo: "Testmodel2",
      Str: "27 Ocala Street",
      Ct: "Orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2062 Packing District Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Sampson",
      Str: "2058 Packing District Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Nova Elite",
      Str: "2142 Whitney Marsh Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2054 Packing District Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Sampson",
      Str: "2257 Valentine Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2154 Whitney Marsh Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2430 Addalia Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2383 Brix St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Sampson",
      Str: "2377brix St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Sampson",
      Str: "2353 Brix St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Nova Elite",
      Str: "2158 Whitney Marsh Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Nova Elite",
      Str: "2066 Packing District Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Nova Elite",
      Str: "2422 Addalia Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2146 Whitney Marsh Aly",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thepacking District",
      Mo: "Citrine",
      Str: "2359 Brix St",
      Ct: "Orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "The Enclave At Twin Rivers:the Manors",
      Mo: "Dawning",
      Str: "14330 17th Ct E",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Wyndham",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "354000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Fox Hollow",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1846,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Summerwood",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Alexander",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1963,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Sienna",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2220,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Fifth Avenue",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Riverwalk",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Park Place",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves At Woodmont",
      Mo: "Citrus Grove",
      Str: "7910 Nw 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "22689",
      Co: "The Fields 24' Th",
      Mo: "Seacrest",
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "363000"
    },
    {
      Bid: "22689",
      Co: "The Fields 24' Th",
      Mo: "Adirondack",
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "The Fields 24' Th",
      Mo: "Leland",
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Fox Hollow",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1901,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Fifth Avenue",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Riverwalk",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Citrus Grove",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "566000"
    },
    {
      Bid: "22689",
      Co: "The Fields 50'",
      Mo: "Summerwood",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "The Fields 50'",
      Mo: "Canopy",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1972,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "The Fields 50'",
      Mo: "Pompeii",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2104,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "The Fields 65'",
      Mo: "Pinnacle",
      Str: "4652 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "498000"
    },
    {
      Bid: "22689",
      Co: "The Fields 65'",
      Mo: "Stonewater",
      Str: "4652 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "571000"
    },
    {
      Bid: "22680",
      Co: "The Groves At Big Cypress",
      Mo: "The Hartford",
      Str: "Little Cypress Way And W Boundry Rd",
      Ct: "Big Cypress",
      St: "FL",
      Zip: 33440,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1949,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "The Groves At Big Cypress",
      Mo: "The Ayla",
      Str: "Little Cypress Way And W Boundry Rd",
      Ct: "Big Cypress",
      St: "FL",
      Zip: 33440,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Estates",
      Mo: "Trenton",
      Str: "154 Argyll Dr",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Columbus",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Columbia",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Abbey",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "160000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Georgia",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Atlanta",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Concord",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Edison",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Boston",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Jefferson",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Annapolis",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "The Isle Of Avalon:the Manors",
      Mo: "Belmont",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "185000"
    },
    {
      Bid: "6703",
      Co: "The Isles At Lakewood Ranch",
      Mo: "Avery Elite",
      Str: "8130 Redonda Loop",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Isles At Lakewood Ranch Captiva Collection",
      Mo: "Sandpiper",
      Str: "8138 Nevis Run",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "204 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "395 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "230 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "106 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "78 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "92 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "105 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "66 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "195 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "480 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "89 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "336 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "106 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "411 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "35 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "201 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "352 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3681,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "42 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "192 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "484 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "62 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "143 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "67 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "136 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "425 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4198,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "300 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4303,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "110 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "156 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "388 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "372 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "88 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "63 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "32 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "50 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "117 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4104,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "49 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4308,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "456 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "242 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "98 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "116 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "95 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "472 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3686,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "53 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3646,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "490 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "159 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "440 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "115 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "141 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "54 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "194 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3789,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "188 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "46 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "422 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "173 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "92 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3681,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "25 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "111 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "111 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "254 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "445 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "39 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "73 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "120 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "127 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "129 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "172 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "81 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "105 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "82 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3213,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "156 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "187 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "95 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke",
      Str: "268 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "168 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3250,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "284 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3460,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Spencer",
      Str: "379 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "77 Awlleaf Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "500 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "94 Hydrilla Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4308,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "182 Snowbell Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Luke Elite",
      Str: "406 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "318 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3460,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "The Landing At Beacon Lake",
      Mo: "Edison",
      Str: "209 Loosestrife Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Bougainvillea",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2800,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Napoli Grande",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "National",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Napoli Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Lakeside",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Napoli Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Napoli Grande",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3025,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Napoli Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2395,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Toscana",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2553,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Lakeside",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2689,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Bougainvillea",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2800,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "National",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3473,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:estate Homes",
      Mo: "Bougainvillea Ii",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2799,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Isabella",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Angelina",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Maria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Victoria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Maria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Victoria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1850,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Maria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2247,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Angelina",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2061,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "The National Golf And Country Club:executive Homes",
      Mo: "Isabella",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2246,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2473 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2521 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2616 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Welsford",
      Str: "2576 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2610 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2803 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Bridgton",
      Str: "2640 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2520 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2569 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Jessup",
      Str: "2514 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2572 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2485 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2695 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2736 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2701 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2762 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2491 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2208,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2508 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Welsford",
      Str: "2545 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2587 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3571,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2599 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Bridgton",
      Str: "2443 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2551 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2431 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2497 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2646 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Jessup",
      Str: "2437 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2491 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Welsford",
      Str: "2509 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2556 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2467 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Welsford",
      Str: "2671 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2635 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2611 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2659 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2454 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2653 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2629 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2665 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2658 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2563 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2665 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2809 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2419 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2581 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2461 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2533 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2562 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2575 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2676 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2425 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2593 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2677 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2682 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2634 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2580 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2797 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2761 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2509 Cedar Rose Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Welsford",
      Str: "2515 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Bridgton",
      Str: "2539 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2594 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2628 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2647 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2472 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Bridgton",
      Str: "2478 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2604 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Bridgton",
      Str: "2413 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Bridgton",
      Str: "2584 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Welsford",
      Str: "2617 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2605 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2588 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Brookton",
      Str: "2568 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Ferncroft",
      Str: "2785 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Oaks At Kelly Park",
      Mo: "Holden",
      Str: "2641 Park Ridge Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "The Oaks At Moss Park:estates",
      Mo: "Provincetown",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Oaks At Moss Park:estates",
      Mo: "Eastham Ii",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Oaks At Moss Park:the Oaks At Moss Park Executives",
      Mo: "Marina",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Newcastle",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Phoenix",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Lincoln",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Parking Site",
      Str: "3989 Willowbrook Dr",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1320000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Phoenix",
      Str: "3989 Willowbrook Dr",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2443,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Santa Fe",
      Str: "3989 Willowbrook Dr",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2576,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Buffer Site",
      Str: "3989 Willowbrook Dr",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1320000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Newcastle",
      Str: "3989 Willowbrook Dr",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2599,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:chateau Collection",
      Mo: "Lincoln",
      Str: "3989 Willowbrook Dr",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2278,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:grand Collection",
      Mo: "Hartford",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:grand Collection",
      Mo: "Radiance",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:grand Collection",
      Mo: "Dover",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:grand Collection",
      Mo: "Hartford",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:grand Collection",
      Mo: "Miramar Ii",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "The Parks At Edgewater:grand Collection",
      Mo: "Freedom",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "The Place At Corkscrew:executive Homes",
      Mo: "Monte Carlo",
      Str: "17067 Ashcomb Way",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Arabella Ii",
      Str: "207 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Iii",
      Str: "250 Preserve Trail North",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Iii",
      Str: "222 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Iii",
      Str: "1410 Gopher Terrace",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "144000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Arabella Ii",
      Str: "248 Preserve Trail North",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "223 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "144000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "213 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "215 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "224 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Iii",
      Str: "234 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "226 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Arabella Ii",
      Str: "208 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "138000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Arabella Ii",
      Str: "220 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "1408 Gopher Terrace",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "155000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "221 Preserve Trail South",
      Ct: "Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "229 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Fullerton Ii",
      Str: "236 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Arabella Ii",
      Str: "230 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "The Preserve At Avonlea",
      Mo: "Arabella Ii",
      Str: "206 Preserve Trail South",
      Ct: "City Of Stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Classics",
      Mo: "Prestige",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Classics",
      Mo: "Mystique",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "434000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Classics",
      Mo: "Trailside Select",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Classics",
      Mo: "Whitestone",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "486000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Classics",
      Mo: "Mystique Grand",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "502000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Classics",
      Mo: "Yorkshire",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Gardens",
      Mo: "Drayton",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1509,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Gardens",
      Mo: "Henley",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1720,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Gardens",
      Mo: "Morris",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2093,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes - Gardens",
      Mo: "Trailside",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "438000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes-estates",
      Mo: "Ashby",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "474000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes-estates",
      Mo: "Easley",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "516000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes-estates",
      Mo: "Oakhurst",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes-estates",
      Mo: "Ashby Grand",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3105,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes-estates",
      Mo: "Easley Grand",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "22689",
      Co: "The Preserve At Bannon Lakes-estates",
      Mo: "Roseland",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4117,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "623000"
    },
    {
      Bid: "22680",
      Co: "The Promenade At Lake Park:lake Park 70s",
      Mo: "Barrington",
      Str: "18209 Pine Hammock Blvd",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5367,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "675000"
    },
    {
      Bid: "22680",
      Co: "The Riviera:rio Collection",
      Mo: "Bordeaux",
      Str: "1532 Se 27th Terrace",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Riviera:rio Collection",
      Mo: "Avignon",
      Str: "1532 Se 27th Terrace",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "The Strand At Cedar Grove",
      Mo: "Cedar",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "233000"
    },
    {
      Bid: "22689",
      Co: "The Strand At Cedar Grove",
      Mo: "Mystique",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "The Strand At Cedar Grove",
      Mo: "Yorkshire",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "The Strand At Cedar Grove",
      Mo: "Whitestone",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "The Strand At Cedar Grove",
      Mo: "Trailside",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "The Strand At Cedar Grove",
      Mo: "Highgate",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "259000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Summerville Ii",
      Str: "2213 Antarus Drive Nww",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2468,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Treviso",
      Str: "2213 Antarus Drive Nww",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Venice",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2807,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Summerville",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2470,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Treviso",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2583,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Riviera",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2638,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Grand Collection",
      Mo: "Paris",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2325,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Freeport",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2182,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Key Largo",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Sophia",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2040,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Freeport",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2182,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Freeport",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2182,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Sophia",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Isles Collection",
      Mo: "Key Largo",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Victoria",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1955,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Kate",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Hailey",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1963,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Alexia",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1713,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Ashley",
      Str: "2213 Antarus Drive Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1835,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Hailey",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1963,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Ashley",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1831,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Kate",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Victoria",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2002,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "The Timbers At Everlands:the Woods Collection",
      Mo: "Alexia",
      Str: "2213 Antarus Dr Nw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1713,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Cross Creek",
      Mo: "Constitution",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1760,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Cross Creek",
      Mo: "Declaration",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1807,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Cross Creek",
      Mo: "Allegiance",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1597,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Cross Creek",
      Mo: "Allegiance",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1597,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Cross Creek",
      Mo: "Constitution",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1760,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Cross Creek",
      Mo: "Declaration",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1807,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Fish Hawk",
      Mo: "Mulberry",
      Str: "5753 Hawks Town Wy",
      Ct: "Lithia",
      St: "FL",
      Zip: 33547,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1601,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "101000"
    },
    {
      Bid: "22680",
      Co: "The Townes At Fish Hawk",
      Mo: "Mulberry",
      Str: "5753 Hawks Town Wy",
      Ct: "Lithia",
      St: "FL",
      Zip: 33547,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1601,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "22680",
      Co: "Tidewater Preserve 50",
      Mo: "Boretto",
      Str: "Tidewater Preserve Blvd",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Tidewater Preserve 50",
      Mo: "Asti",
      Str: "Tidewater Preserve Blvd",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Providence",
      Str: "1845 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Dover",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Hartford",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1845 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Providence",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1845 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Columbia",
      Str: "1845 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Denver",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Hartford",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Dover",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Providence",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2582,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Boston",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2218,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Raleigh",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2902,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Denver",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1635,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:heritage At Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Boston",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2218,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Columbia",
      Str: "C-8 & C-9",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Annapolis",
      Str: "C-8 & C-9",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Columbia",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Annapolis",
      Str: "1815 Diablo Circle Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Concord",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2587,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "389000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Atlanta",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1873,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Annapolis",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Columbia",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2409,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:the Palms",
      Mo: "Boston",
      Str: "1815 Diablo Cir Sw",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2218,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Cedar",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "296000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Highgate",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Spruce",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "321000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Tower",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Ashby",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Easley",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Whitestone",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "The National",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Sunset",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Wynwood",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Sunset",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Oakmont Ii",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2361,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Sunset",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Agostino Ii",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2796,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Sawgrass",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2926,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Doral",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2553,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Oakmont",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2361,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "Wynwood",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3945,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:estate Homes",
      Mo: "The National",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3473,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Amalfi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Monte Carlo",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "1450 Plan For 615 Only",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Monte Carlo",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Capri For 615 Only",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Independence",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3357,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Marsala",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Amalfi",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "Venice",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:executive Homes",
      Mo: "1450 Plan For 615 Only",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "The Summerville Ii",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Sorrento",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "The Summerville Ii",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Sorrento",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Richmond",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2724,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Sorrento",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Tivoli",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "The Summerville Ii",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Richmond",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2725,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "Richmond",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2724,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:manor Homes",
      Mo: "The Princeton Ii",
      Str: "11581 Timber Creek Dr",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Timber Creek:villas",
      Mo: "Orchid",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Adelaide",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Benton",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Dylan",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Camden",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Haddock",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Hazel",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1906,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "347000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Jasmine",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "369000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Mabel",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "398000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Talbot",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Cedar",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Highgate",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "398000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Palmary",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Mystique",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Tower",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2383,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "427000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Whitestone",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "456000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Mystique Grand",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "492000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Garden",
      Mo: "Drayton",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "356000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Garden",
      Mo: "Morris",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "382000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Garden",
      Mo: "Trailside",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "461000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Prosperity",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Palmary",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "396000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mystique",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mainstay",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mainstay Grand",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mystique Grand",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "487000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Villas",
      Mo: "Ellenwood",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Townhomes",
      Mo: "Orchid",
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1553,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Townhomes",
      Mo: "Dahlia",
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "298000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Townhomes",
      Mo: "Julep",
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "306000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottage Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Autumn",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Autumn",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Sierra",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2522,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Sierra",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2522,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Parking Site",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2000000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Buffer Site",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2000000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2023,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:cottages Collection",
      Mo: "Autumn",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estate Collection",
      Mo: "Miramar",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estate Collection",
      Mo: "Eastham Ii",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estate Collection",
      Mo: "Freedom",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Eastham Ii",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Peabody",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Bourne Ii",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Eastham Ii",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Freedom",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Bourne Ii",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Simmitano",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2795,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Buffer Site",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2000000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Bourne Ii",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2082,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Parking Site",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2000000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Miramar Ii",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Simmitano",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2795,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Eastham Ii",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2139,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Peabody",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3291,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:estates Collection",
      Mo: "Freedom",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Landcaster",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Montara",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Wilshire",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Landcaster",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Montara",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Wilshire",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Shell 25rl - 7 Plex",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 12399,
      Sto: 2,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1550000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Shell 25rl - 7 Plex",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 12399,
      Sto: 2,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1550000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Landcaster",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1755,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Parking Site",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2000000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Buffer Site",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2000000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Wilshire",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1615,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Montara",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2000,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Shell 25rl - 4 Plex",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 6740,
      Sto: 2,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1498000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Shell 25rl - 6 Plex",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 10740,
      Sto: 2,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1805000"
    },
    {
      Bid: "22680",
      Co: "Tohoqua:townhome Collection",
      Mo: "Shell 25rl - 5 Plex",
      Str: "2396 Volunteer Ave",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 8740,
      Sto: 2,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2146000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "560 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling",
      Str: "613 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling",
      Str: "124 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "616 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Pelican",
      Str: "592 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling",
      Str: "584 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Starling",
      Str: "116 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2160,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Pelican",
      Str: "617 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling",
      Str: "576 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora Elite",
      Str: "608 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "540 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "556 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling",
      Str: "552 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "101 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora Elite",
      Str: "593 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora Elite",
      Str: "128 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Pelican",
      Str: "557 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling Elite",
      Str: "605 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Sanderling",
      Str: "105 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "588 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "120 Daylily Boulevard",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Pelican",
      Str: "581 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers At Venice Woodla",
      Mo: "Flora",
      Str: "597 Mistiflower Circle",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Touchstone",
      Mo: "Do Not Use Moved To 1504176",
      Str: "4223 Globe Thistle Dr",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Hampton",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Hampton",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Rd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Hampton",
      Str: "21415 Darter Rd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Hampton",
      Str: "21415 Darter Rd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Townes At Lake Thomas:the Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Rd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Townes At Manhattan Crossing",
      Mo: "Flagler",
      Str: "4511 S Manhattan Ave",
      Ct: "Tampa",
      St: "FL",
      Zip: 33611,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2410,
      Sto: 3,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Townes At Manhattan Crossing",
      Mo: "Plant",
      Str: "4511 S Manhattan Ave",
      Ct: "Tampa",
      St: "FL",
      Zip: 33611,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2192,
      Sto: 3,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Townes At Manhattan Crossing",
      Mo: "Carnegie",
      Str: "4511 S Manhattan Ave",
      Ct: "Tampa",
      St: "FL",
      Zip: 33611,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 3,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Glenmoor Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Hampton Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Windsor Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Hampton Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Glenmoor Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Windsor Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1513,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Windsor Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1513,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Hampton Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1634,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Townes At Southshore Pointe",
      Mo: "Glenmoor Ii",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1541,
      Sto: 2,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Townes At Vdara",
      Mo: "Other",
      Str: "273 Severn Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32803,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22374",
      Co: "Tradition",
      Mo: "Telaro",
      Str: "11717 Sw Aquila Way",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Tradition - Emery",
      Mo: "Briar",
      Str: "10744 Sw Estella Ln",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Tradition - Emery",
      Mo: "Briar",
      Str: "10645 Sw Gloriana St",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Tradition - Emery",
      Mo: "Rowan",
      Str: "3472 Sw Coquina Cove Way",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Tradition -emery",
      Mo: "Briar Coastal",
      Str: "12792 Sw Eleanor Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10733 Sw Gloriana",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Dahlia",
      Str: "12777 Sw Eleanor Dr",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2300,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10709 Sw Gloriana St",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10708 Sw Gloriana",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Tradition-emery",
      Mo: "Yarrow",
      Str: "10700 Sw Gloriana St",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2279,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Traditions:estates",
      Mo: "Prestwick Ii",
      Str: "3019 Caneel Street.",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1582,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Traditions:executive",
      Mo: "Devonshire Ii",
      Str: "3019 Caneel Street",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1930,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Traditions:executive",
      Mo: "Monroe Iii",
      Str: "3019 Caneel Street",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge At Babcock Ranch:executive Homes",
      Mo: "Sylvester",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge At Babcock Ranch:executive Homes",
      Mo: "Foxtail",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge At Babcock Ranch:manor Homes",
      Mo: "Majestica",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge At Babcock Ranch:manor Homes",
      Mo: "Palmetto",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge At Babcock Ranch:villas",
      Mo: "Queen",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Medallion",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Trevi",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Brio",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Halle",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Princeton",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Charle",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Tivoli",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Trevi",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Independence Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2250,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Tivoli",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Brio",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Princeton",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Patriot Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2967,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Charle Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Charle",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Trevi Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Medallion",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Halle",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Elan Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 50s",
      Mo: "Halle Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Patriot Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2967,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Princeton",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Tivoli Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Trevi",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Medallion",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Tivoli",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Tivoli",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Independence Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2250,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Medallion",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Trevi",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Serenata",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2512,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Tivoli Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Princeton Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Charle - Hs#3 Model",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Medallion Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Princeton",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:trailhead Landing 60s",
      Mo: "Patriot Ii",
      Str: "13525 Nw Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2967,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Charle",
      Str: "74 Bloomfield Way",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Brio Ii",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2360,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Halle Ii",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1703,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Trevi Ii",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1943,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Brio",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Independence",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Charle",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Charle Ii",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2106,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Trevi",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Live-work-learn",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2259,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "Liberty",
      Str: "12 Wrensong Pl",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2695,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailmark - 50s",
      Mo: "Trevi",
      Str: "74 Bloomfield Way",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Trailmark - 50s",
      Mo: "Charle",
      Str: "74 Bloomfield Way",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22374",
      Co: "Tranquility At Telaro 50'",
      Mo: "Haven",
      Str: "12023 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Tranquility At Telaro 50'",
      Mo: "Haven",
      Str: "11724 Sw Aquila Way",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Tranquility At Telaro 50'",
      Mo: "Clarion",
      Str: "11927 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2351,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Tranquility At Telaro 50'",
      Mo: "Sage",
      Str: "11935 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Tranquility At Telaro 50'",
      Mo: "Sage",
      Str: "11711 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Tranquility At Telaro 50'",
      Mo: "Haven",
      Str: "11951 Sw Viridian Blvd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Charle",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Trevi",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Sierra",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Trevi",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Halle",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Halle",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Elan",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Sierra",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Halle",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1712,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Charle",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Trevi",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Independence",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2270,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 50's",
      Mo: "Elan",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1544,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Medallion Bonus",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Princeton",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Princeton",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Medallion",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Medallion",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Tivoli",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Medallion Bonus",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3152,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Medallion",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Charle- Model Hs 287 Only",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Tivoli",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Tributary:lakeview At Tributary 60's",
      Mo: "Princeton",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Classic Collection",
      Mo: "Live-work-learn",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2259,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Classic Collection",
      Mo: "Charle",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2124,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Classic Collection",
      Mo: "Trevi",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2028,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Classic Collection",
      Mo: "Brio",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2358,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Classic Collection",
      Mo: "Sierra",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2295,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Classic Collection",
      Mo: "Cordova",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2050,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Princeton Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Tivoli Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Medallion Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Medallion Bonus Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3016,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Princeton Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Tivoli",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Tivoli Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2173,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Princeton Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2226,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Ermont Iii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3005,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Medallion",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2657,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Medallion Bonus",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3152,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Medallion Bonus Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3016,
      Sto: 2,
      Be: 4,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Medallion Ii",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2571,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Princeton",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2268,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Tributary:tributary Royal Collection",
      Mo: "Serenata",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2512,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Providence",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Hartford",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Allentown",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Allentown",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Freedom",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Dover",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:estate Collection",
      Mo: "Harrisburg",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Kennedy Iii",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2359,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Allentown (model Use Only)",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Inverness Ii",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Peabody",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Buffer Site",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1500000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Inverness Ii",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2911,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Peabody",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3291,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Newcastle",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2599,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Bonita Ii",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2441,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Independence",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3385,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Parking Site",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1500000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Kennedy Iii",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2359,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:executive Collection",
      Mo: "Allentown (model Use Only)",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1853,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Triple Creek Classic - 50's",
      Mo: "Pelican",
      Str: "11935 Streambed Drive",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates",
      Mo: "Harrisburg",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates",
      Mo: "Providence",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates",
      Mo: "Dover",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates",
      Mo: "Hartford",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates",
      Mo: "Trenton",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates",
      Mo: "Raleigh",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates Ii",
      Mo: "Blackburn",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates Ii",
      Mo: "Harrisburg",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Estates Ii",
      Mo: "Trenton",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Blackburn",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Whitney",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3629,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Symphony",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Rainer",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3336,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Denali",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Symphony",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3868,
      Sto: 2,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Whitney",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3629,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Patterson",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3068,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Rainer",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3336,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Denali",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2647,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Executives",
      Mo: "Blackburn",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2453,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Concord",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Atlanta",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Concord",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Columbia",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Columbia",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Boston",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Annapolis",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Albany",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Annapolis",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Concord",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Columbia",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Atlanta",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Triple Creek:the Manors",
      Mo: "Boston",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:executive Homes",
      Mo: "Sylvester",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:executive Homes",
      Mo: "Areca",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:executive Homes",
      Mo: "Sago",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3357,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:executive Homes",
      Mo: "Foxtail",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:executive Homes",
      Mo: "Coconut",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:executive Homes",
      Mo: "Alexander",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:manor Homes",
      Mo: "Palmetto",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:manor Homes",
      Mo: "Majestica",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:manor Homes",
      Mo: "Montgomery",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:manor Homes",
      Mo: "Washingtonia",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:villas",
      Mo: "Bismark",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:villas",
      Mo: "Queen",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:executive Homes",
      Mo: "Sago",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3357,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:executive Homes",
      Mo: "Areca",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:executive Homes",
      Mo: "Alexander",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:executive Homes",
      Mo: "Foxtail",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:executive Homes",
      Mo: "Sylvester",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:executive Homes",
      Mo: "Coconut",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:manor Homes",
      Mo: "Majestica",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:manor Homes",
      Mo: "Royal",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2245,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:manor Homes",
      Mo: "Palmetto",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:manor Homes",
      Mo: "Montgomery",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:manor Homes",
      Mo: "Washingtonia",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3867,
      Sto: 2,
      Be: 6,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:townhomes",
      Mo: "Ava",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:townhomes",
      Mo: "Berkly",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:villas",
      Mo: "Bismark",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1417,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Tuckers Cove:villas",
      Mo: "Queen",
      Str: "15330 Jadestone Dr",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1564,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Dahlia",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1799,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Eden",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Phoenix",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Blossom",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Aurora",
      Str: "15987 Sw 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1455,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Aurora",
      Str: "15987 Sw 2nd Pl",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1455,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Eden",
      Str: "15987 Sw 2nd Pl",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1828,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Dahlia",
      Str: "15987 Sw 2nd Pl",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1799,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Blossom",
      Str: "15987 Sw 2nd Pl",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1697,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Phoenix",
      Str: "15987 Sw 2nd Pl",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1828,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Columbia",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Harrisburg",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Trenton",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Providence",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Dover",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Raleigh",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Hartford",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Richmond",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Dover",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Providence",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "423000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Columbia",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "384000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Trenton",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Richmond",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Harrisburg",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Hartford",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Estates",
      Mo: "Raleigh",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Atlanta",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Boston",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Annapolis",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Concord",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Columbia",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Boston",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Annapolis",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Atlanta",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Concord",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Two Rivers:the Manors",
      Mo: "Columbia",
      Str: "35127 Brackett Bnd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Ca Skyview",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Cg Skyview",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2374,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Ce",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1605,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Ce Skyview",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2116,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Ca",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Cc Skyview",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Cb",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1485,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Cg",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1898,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-story Townhomes",
      Mo: "Model Cc",
      Str: "8370 Nw 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1596,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Cresswind",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Heston",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "298000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Winthrop",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Medina",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "323000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Tilden",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Yellowstone",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "626000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Heston",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Winthrop",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Medina",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Tilden",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "358000"
    },
    {
      Bid: "6701",
      Co: "Vdara",
      Mo: "Other",
      Str: "273 Severn Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32803,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "22680",
      Co: "Venetian:carriage Homes",
      Mo: "Tbd",
      Str: "130 Bella Vista Terrace Unit B",
      Ct: "North Venice",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Venetian:carriage Homes",
      Mo: "Monaco",
      Str: "130 Bella Vista Terrace Unit B",
      Ct: "North Venice",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Venetian:carriage Homes",
      Mo: "Corsica",
      Str: "130 Bella Vista Terrace Unit B",
      Ct: "North Venice",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1710,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6703",
      Co: "Venice Woodlands",
      Mo: "Flora Elite",
      Str: "608 Mistiflower Cir",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Mystique",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Prestige",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Trailside",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "523000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Whitestone",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Yorkshire",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "598000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Cedar",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Highgate",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Mystique",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Palmary",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "387000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Prestige",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Whitestone",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Tower",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 66'",
      Mo: "Prestige",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 66'",
      Mo: "Ashby",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 66'",
      Mo: "Easley",
      Str: "432 Se Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Veranda Landing",
      Mo: "Honey",
      Str: "870 Se Grovebush Ln",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1820,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Landing",
      Mo: "Cocco",
      Str: "870 Se Grovebush Ln",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand",
      Mo: "Key Largo - Model Only",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand",
      Mo: "Paris",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2325,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand",
      Mo: "Treviso",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Paris",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2324,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Venice",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2807,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Summerville Ii",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2468,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Riviera",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Treviso",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Summerville",
      Str: "726 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2470,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Venice",
      Str: "726 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2807,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "561400"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Treviso",
      Str: "726 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2583,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "516000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Paris",
      Str: "726 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2325,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "464800"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Grand East",
      Mo: "Riviera",
      Str: "726 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2638,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles",
      Mo: "Jacaranda - Model Only",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles",
      Mo: "Freeport",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2181,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles",
      Mo: "Magnolia - Model Only",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles",
      Mo: "Sophia",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2040,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles",
      Mo: "Key Largo",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Key Largo",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Sophia",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2040,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Magnolia",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Jacaranda",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Key Largo",
      Str: "714 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Jacaranda",
      Str: "714 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1610,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Sophia",
      Str: "714 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:the Isles East",
      Mo: "Magnolia",
      Str: "714 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1694,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:twin Homes",
      Mo: "Azalea",
      Str: "726 Se Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1757,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:twin Homes",
      Mo: "Oakleaf",
      Str: "714 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1816,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:twin Homes",
      Mo: "Azalea",
      Str: "714 Se Woodbine Rd",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1758,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Richmond Ii",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Columbia",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Harrisburg Ii",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Providence Ii",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Raleigh Ii",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Hartford Ii",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Raleigh Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Richmond Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Trenton Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Columbia",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Providence Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Dover Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1551,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Hartford Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Estates",
      Mo: "Harrisburg Ii",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Annapolis",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Concord",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Boston",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Columbia",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Atlanta",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Georgia",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Atlanta",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Belmont",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "185000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Columbus",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1870,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Abbey",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "160000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Boston",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Annapolis",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1448,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Jefferson",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Albany",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1267,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Concord",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2580,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Columbia",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Verano:the Manors",
      Mo: "Edison",
      Str: "11447 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2215,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Stardom",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "496000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Stellar",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Renown",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "522000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Layton Grande",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "1",
      Be: "4",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Roseland",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Upton",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "751000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Layton",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "4",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Prosperity",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "413000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Mystique",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "422000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Prestige",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "434000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Whitestone",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "524000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Yorkshire",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "567000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Scenic",
      Mo: "Contour",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Scenic",
      Mo: "Hallmark",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Scenic",
      Mo: "Trailside",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "444000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Agostino Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2796,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Doral",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2553,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Sunset",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Wynwood",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Sunset",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Oakmont Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "Wynwood",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:estate Homes",
      Mo: "The National",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Marsala",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Marsala",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Venice",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Amalfi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Monte Carlo",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Venice",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Marsala",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Amalfi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Venice",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Capri",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1677,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:executive Homes",
      Mo: "Monte Carlo",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Tivoli",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Summerville Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Richmond",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2724,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Princeton Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Princeton Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Summerville Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Summerville Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Richmond",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2724,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Tivoli",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2267,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Richmond",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2724,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Summerville Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2444,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3283,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:manor Homes",
      Mo: "The Princeton Ii",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2244,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:villa Homes",
      Mo: "Magnolia",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:villa Homes",
      Mo: "Orchid",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:villa Homes",
      Mo: "Magnolia",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1417,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Verdana Village:villa Homes",
      Mo: "Orchid",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1564,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Classic",
      Mo: "Winthrop",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "433000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Classic",
      Mo: "Yellowstone",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Classic",
      Mo: "Cresswind",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Classic",
      Mo: "Heston",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "311000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Classic",
      Mo: "Medina",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Garden",
      Mo: "Candlewood",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Garden",
      Mo: "Daylen",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Garden",
      Mo: "Mill Run",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "343000"
    },
    {
      Bid: "22689",
      Co: "Verde At Babcock Ranch - Garden",
      Mo: "Talon",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Dover",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Dover",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Freedom",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Dawn",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Hartford",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Vista Bay:the Townes",
      Mo: "Windsor",
      Str: "Hwy 301",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Bay:the Townes",
      Mo: "Glenmoor",
      Str: "Hwy 301",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Bay:the Townes",
      Mo: "Hampton",
      Str: "Hwy 301",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Wildblue:executive Homes",
      Mo: "Catalina",
      Str: "13464 Blue Bay Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2805,
      Sto: "2",
      Be: "3",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Wildblue:executive Homes",
      Mo: "Maria",
      Str: "13464 Blue Bay Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vistas 4 Story Condo",
      Mo: "Brookside",
      Str: "1400 Masters Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1232,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vivant:the Boulevard Collection",
      Mo: "Terrasse",
      Str: "Sw 280 St And 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33031,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1599,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Boulevard Collection",
      Mo: "Lumiere",
      Str: "Sw 280 St And 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33031,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1367,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Boulevard Collection",
      Mo: "Paradis",
      Str: "Sw 280 St And 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33031,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1416,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Boulevard Collection",
      Mo: "Citadelle",
      Str: "Sw 280 St And 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33031,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1333,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Boulevard Collection",
      Mo: "Atrium",
      Str: "Sw 280 St And 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33031,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1286,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Promenade Collection",
      Mo: "Topaz",
      Str: "276 Sw Th St And Sw 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1668,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Promenade Collection",
      Mo: "Magenta",
      Str: "276 Sw Th St And Sw 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1414,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Promenade Collection",
      Mo: "Sunglow",
      Str: "276 Sw Th St And Sw 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1545,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Promenade Collection",
      Mo: "Onyx",
      Str: "276 Sw Th St And Sw 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1489,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Vivant:the Promenade Collection",
      Mo: "Azure",
      Str: "276 Sw Th St And Sw 157 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1330,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Volanti 20' Th",
      Mo: "Ormond",
      Str: "25976 Woven Wicker Bend",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1888,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Volanti 20' Th",
      Mo: "Ormond",
      Str: "25972 Woven Wicker Bend",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Volanti 24' Th",
      Mo: "Venice",
      Str: "4221 Cloud Hopper Way",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Volanti 24' Th",
      Mo: "Venice",
      Str: "26063 Woven Wicker Bend",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2234 Satin Leaf Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2109 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2214 Satin Leaf Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2093 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Gateway",
      Str: "2971 Ambersweet Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2952 Wild Mulberry Drive",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2089 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2061 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Gateway",
      Str: "2101 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2113 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "1977 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2105 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2110 Valencia Blossom Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D60",
      Mo: "Sarasota",
      Str: "3297 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3325,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - R20",
      Mo: "Selena Iii",
      Str: "2129 Emil Jahna Road",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - R20",
      Mo: "Francesca Iii",
      Str: "2125 Emil Jahna Road",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1631,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3296 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3263 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2681 Spider Lily Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3244 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3276 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3272 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2653 Spider Lily Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3280 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2680 Spider Lily Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3288 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2673 Mandevilla Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3292 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3264 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2654 Mandevilla Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2601 Royal Jasmine Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2650 Mandevilla Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2662 Mandevilla Ct",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3248 Canna Lily Place",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Watercrest Meridian",
      Mo: "Wilmington A",
      Str: "11201 Watercrest Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3417,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Watercrest Vista",
      Mo: "4510a - Kensington",
      Str: "11201 Watercrest Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2397,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9204 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Maddington",
      Str: "9146 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9117 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "875000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9113 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9320 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9225 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9233 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1115000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9350 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "850000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9198 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9261 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1095000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9217 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9257 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "970000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9109 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9240 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1075000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9179 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9374 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9334 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9186 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9265 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9194 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "965000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9346 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Shirley",
      Str: "9178 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3559,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9205 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1015000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9209 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Maddington",
      Str: "9116 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9248 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1015000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9228 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9170 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9216 Crestview Cir",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9108 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1055000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9120 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9232 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9264 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9237 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1170000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Maddington",
      Str: "9229 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "875000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9292 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9342 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Maddington",
      Str: "9354 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9300 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9245 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1055000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9221 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Clearwater",
      Str: "9338 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9366 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9362 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9182 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Watermark At Avenir",
      Mo: "Jennings",
      Str: "9158 Crestview Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3802,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 40s",
      Mo: "Harvey",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 40s",
      Mo: "Williston",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 40s",
      Mo: "Sonora",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Merlot Grand",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Roseland",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Mahogany",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2547,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Mahogany Grand",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3365,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Merlot",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2635,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Scarlett",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:coach Homes",
      Mo: "Arrowhead",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1741,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:coach Homes",
      Mo: "Bay Creek",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2110,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Aster Grande",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Westwind Ii",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Lakeside",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Lakeside",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2689,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Coquina",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2553,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Aster",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2395,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Aster Grande",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3025,
      Sto: 2,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:estate Homes",
      Mo: "Westwind Ii",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2799,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Calusa",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Remington",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Cypress",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Azalea",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Azalea",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2061,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Calusa",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2247,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Cypress",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2246,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:executive Homes",
      Mo: "Remington",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1850,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Birkdale (end 4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Arbor (4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1120,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Arbor (4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1120,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Carolina",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1301,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Birkdale (end 4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Arbor (4th Floor)",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1120,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Carolina",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1301,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:terrace Condominiums",
      Mo: "Birkdale (end 4th Floor)",
      Str: "15099 Longs Ln",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1154,
      Sto: 1,
      Be: 2,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:veranda Condominiums",
      Mo: "Arabella Ii",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1569,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:veranda Condominiums",
      Mo: "Bromelia Ii",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:veranda Condominiums",
      Mo: "Bromelia Ii",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:veranda Condominiums",
      Mo: "Diangelo Ii",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1366,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:veranda Condominiums",
      Mo: "Diangelo Ii",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1366,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:estate Homes",
      Mo: "Napoli Ii",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:estate Homes",
      Mo: "Sunset",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:executive Homes",
      Mo: "Victoria",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:executive Homes",
      Mo: "Maria",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:executive Homes",
      Mo: "Maria",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf And Country Club:executive Homes",
      Mo: "Catalina",
      Str: "17121 Jadestone Ct",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2835,
      Sto: 2,
      Be: 3,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf And Country Club:terrace Condominiums",
      Mo: "Birkdale (end)",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf And Country Club:terrace Condominiums",
      Mo: "Birkdale (end)",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf And Country Club:terrace Condominiums",
      Mo: "Birkdale (end)",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Wellen Park Sunstone",
      Mo: "Seabranch",
      Str: "18046 Grand Prosperity Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1908,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Wellen Park, Sunstone",
      Mo: "Anclote",
      Str: "12405 Asana Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Summrlin",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3174,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Inverness",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Estero",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Bloomfield",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Summrlin",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3174,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Riviera",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2650,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Parking Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Estero",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2532,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Buffer Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Inverness",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2911,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Bloomfield",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3791,
      Sto: 2,
      Be: 5,
      Ba: 4,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Summerlin",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3174,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Bonita",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2447,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:chateau Collection",
      Mo: "Independence",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3385,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Cascade",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Brookside",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Autumn",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Westwood",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1647,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Cascade",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2023,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Sage",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2486,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Brookside",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1782,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Autumn",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1795,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Westwood",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1647,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Parking Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:cottage Collection",
      Mo: "Buffer Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Douglas",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Discovery",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Weston",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2992,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Douglas",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Aspen",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Independence",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3385,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Aspen",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Allison",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2781,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Parking Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Allison",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2781,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Weston",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2992,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Independence",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3385,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Aspen",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2199,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Discovery",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Douglas",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3332,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:estates Collection",
      Mo: "Buffer Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Atlanta",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Columbia",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Annapolis",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Buffer Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Annapolis",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1444,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Atlanta",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Columbia",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:legacy Collection",
      Mo: "Parking Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Delray",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Delray",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Solona Ii",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2186,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Rio",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2643,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Rio",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2643,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Delray",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2455,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:manor Collection",
      Mo: "Solona Ii",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2186,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:overlook Townhomes",
      Mo: "Amalfi",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2027,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:overlook Townhomes",
      Mo: "Minori",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1834,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:overlook Townhomes",
      Mo: "Sienna",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Landcaster",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Wilshire",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Montara",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Buffer Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1420000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Shell Th-25rl 4-plex",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 7125,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Wilshire",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1615,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Landcaster",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1755,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Parking Site",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 9999,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1420000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Montara",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2000,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:trail Townhomes",
      Mo: "Shell Th-25rl 6-plex",
      Str: "2786 Fitness St",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 10740,
      Sto: 1,
      Be: null,
      Ba: null,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1595000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13124 Holsinger Boulevard",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Barron",
      Str: "12060 Elderbank Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Whitney",
      Str: "12072 Elderbank Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Whitney",
      Str: "11994 Elderbank Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13149 Dunwick Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Barron",
      Str: "12096 Elderbank Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "12078 Elderbank Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 50s",
      Mo: "Linden",
      Str: "12016 Cabana Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2696,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 50s",
      Mo: "Aspen",
      Str: "11990 Cabana Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1776,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 50s",
      Mo: "Aspen",
      Str: "12062 Cabana Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1776,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek Th 20s",
      Mo: "Cove",
      Str: "11972 Calvesta St",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek Th 20s",
      Mo: "Spring",
      Str: "11971 Calvesta St",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek Th 20s",
      Mo: "Cove",
      Str: "11975 Calvesta St",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Chapman",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Hanover",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Mcnair",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2093,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Thompson",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Tybee",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Wakefield",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Hamden",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "285000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Chapman",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "292000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Hanover",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "301000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Oasis",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Seabrook",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Thompson",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Wakefield",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "Wesley Reserve At Chapel Crossing",
      Mo: "Evergreen",
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1451,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "279000"
    },
    {
      Bid: "22690",
      Co: "Wesley Reserve At Chapel Crossing",
      Mo: "Sycamore",
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1762,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22690",
      Co: "Wesley Reserve At Chapel Crossing",
      Mo: "Ashe",
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1818,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Bordeaux",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Carano",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2478,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "496000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Noir",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2877,
      Sto: "2",
      Be: "2",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "548000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Carano Ii",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "582000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park In Sunbridge",
      Mo: "Glen",
      Str: "3131 Vanguard Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4092,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park In Sunbridge",
      Mo: "Larue",
      Str: "6354 Trailblaze Bend",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2816,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park In Sunbridge",
      Mo: "Davey",
      Str: "6443 Trailblaze Bend",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3437,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park In Sunbridge",
      Mo: "Glen",
      Str: "6394 Trailblaze Bend",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4042,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "270 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "750 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "65 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "315 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "293 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "55 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "46 Skycrest Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "285 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "804 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "726 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "278 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "782 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "208 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "79 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "80 Skycrest Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "96 Skycrest Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "137 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "718 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "306 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "292 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "322 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "85 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "101 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "298 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "45 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "117 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret",
      Str: "774 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "766 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "744 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "185 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "262 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "812 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "734 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "708 Nocatee Village Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "84 Skycrest Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "100 Skycrest Drive",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal Elite",
      Str: "216 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "277 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Cardinal",
      Str: "314 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "West End At Town Center",
      Mo: "Egret Elite",
      Str: "307 Cool Springs Avenue",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Westhaven At Ovation-singles",
      Mo: "Grenada",
      Str: "0117 Tbd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3237,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Westhaven At Ovation-towns",
      Mo: "Crowley",
      Str: "20 Tbd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1863,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Westhaven@ovation-bungalows",
      Mo: "Montpelier",
      Str: "7 Tbd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2142,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Ii",
      Mo: "Atlanta",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Ii",
      Mo: "Atlanta",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Ii",
      Mo: "Columbia",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2370,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Ii",
      Mo: "Concord",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Discovery",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Atlanta",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Hartford",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Hartford",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Discovery",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2109,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Atlanta",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1879,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Sutton",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2575,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Iii",
      Mo: "Dover",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key I",
      Mo: "Columbus",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key I",
      Mo: "Belmont",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Ii",
      Mo: "Georgia",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2326,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Ii",
      Mo: "Columbus",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1874,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Ii",
      Mo: "Edison",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2112,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Ii",
      Mo: "Jefferson",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2463,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Ii",
      Mo: "Belmont",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1429,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Iii",
      Mo: "Eclipse",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2451,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Iii",
      Mo: "Dawn",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Iii",
      Mo: "Bloom",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1487,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Westview:aden South Key Iii",
      Mo: "Celeste",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1824,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Bordeaux",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Marsielle",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Lorient",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Avignon",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Avignon",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Avignon",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Lorient",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Dijon",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Dijon",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:nantucket Collection",
      Mo: "Dijon",
      Str: "2361 Nw 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:overlook Townhomes",
      Mo: "Amalfi",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Westview:overlook Townhomes",
      Mo: "Minori",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1834,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Westview:overlook Townhomes",
      Mo: "Sienna",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "99000"
    },
    {
      Bid: "22680",
      Co: "Westview:overlook Townhomes",
      Mo: "Amalfi",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1689,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Westview:overlook Townhomes",
      Mo: "Minori",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1834,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Westview:overlook Townhomes",
      Mo: "Sienna",
      Str: "5638 Loggia Ln",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1873,
      Sto: 2,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Monte Carlo",
      Str: "2361 Nw 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Reserve",
      Str: "2361 Nw 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Vence",
      Str: "2361 Nw 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Reserve",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Casis",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Casis",
      Str: "2361 Nw 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Vence",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Bandol",
      Str: "2361 Nw 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:provence Collection",
      Mo: "Bandol",
      Str: "2601 Nw 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Halle",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Trevi",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Charle",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Tivoli- Model Only Hs 2",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Charle",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Brio",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Halle",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Brio",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Elan",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 50s",
      Mo: "Trevi",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 60s",
      Mo: "Princeton",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 60s",
      Mo: "Medallion",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 60s",
      Mo: "Tivoli",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 60s",
      Mo: "Barton",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:wexford Reserve 60s",
      Mo: "Tivoli",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Whaley's Creek:estate Collection",
      Mo: "Boca Raton",
      Str: "3698 Vega Creek Drive",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34772,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Ashby",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Easley",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Oakhurst",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Ashby Grand",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "544000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Easley Grand",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Roseland",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Daylen",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Mill Run",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Sonora",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Talon",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Whitmore",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "443000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Harvey",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Cresswind",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Heston",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Medina",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Tilden",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "414000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Winthrop",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "457000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Yellowstone",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "483000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Stonewater",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "514000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Summerwood",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Pinnacle",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Tangerly Oak",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 52'",
      Mo: "Summerwood",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Stonewater",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Stonewater W/ Loft",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Pinnacle",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Tangerly Oak",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "611000"
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Lakeside",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Lantana",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3542,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Baneberry",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3178,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Laurel",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3659,
      Sto: "2",
      Be: "3",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Iris",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2964,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Lakeside",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wildblue",
      Mo: "Iris",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2964,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Mystique",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Tower",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Mystique Grand",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Whitestone",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Yorkshire",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3415,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Ashby",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Easley",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Renown",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Ashby Grand",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Oakhurst",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Easley Grand",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Roseland",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Willow:executive Homes",
      Mo: "Marsala",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2201,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Willow:executive Homes",
      Mo: "Venice",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1849,
      Sto: 1,
      Be: 3,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Willow:executive Homes",
      Mo: "Monte Carlo",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3231,
      Sto: 2,
      Be: 5,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Willow:executive Homes",
      Mo: "Trevi",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2032,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Willow:executive Homes",
      Mo: "Amalfi",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2529,
      Sto: 2,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Willow:patio Homes",
      Mo: "Boston",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Willow:patio Homes",
      Mo: "Annapolis",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1450,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Willow:patio Homes",
      Mo: "Boston",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2216,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Willow:patio Homes",
      Mo: "Atlanta",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1871,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Willow:patio Homes",
      Mo: "Columbia",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2382,
      Sto: 1,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Ashby",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Easley",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "467000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Yorkshire",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "502000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Ashby Grand",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "533000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Oakhurst",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Easley Grand",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Roseland",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Mystique",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2056,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "403000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Hartford",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Hartford",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Trenton",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Raleigh",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Harrisburg",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Providence",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Dover",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Richmond",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "507000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Richmond",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3092,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "507000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Nova",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Providence",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Eclipse",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Trenton",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Hartford",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Harrisburg",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Hartford",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Providence",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2584,
      Sto: 2,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Trenton",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 3326,
      Sto: 2,
      Be: 6,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Harrisburg",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1817,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Honor",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Dover",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Columbia",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2389,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Dawn",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2174,
      Sto: 1,
      Be: 4,
      Ba: 3,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Raleigh",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "462000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Celeste",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1936,
      Sto: 1,
      Be: 4,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Bloom",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "195000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Aura",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1313,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Raleigh",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 2896,
      Sto: 2,
      Be: 5,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "462000"
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:the Estates",
      Mo: "Dover",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2025,
      Sft: 1555,
      Sto: 1,
      Be: 3,
      Ba: 2,
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Sabrina",
      Str: "12696 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12704 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Sabrina ",
      Str: "12404 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "12488 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Roland",
      Str: "12680 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12716 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Roland",
      Str: "12428 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12614 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "3 Crestview Cir",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Sabrina",
      Str: "12538 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2561,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12700 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2900,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12432 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12672 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12712 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2959,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "12626 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12561 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3019,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12553 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12720 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12707 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Sabrina ",
      Str: "9373 Crestview Cir",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12545 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12581 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1000000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12688 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Roland",
      Str: "12534 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12541 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "12420 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "2 Crestview Cir",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12657 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2991,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "12504 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12656 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "12471 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12499 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12508 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12715 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Roland",
      Str: "12412 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12475 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Roland",
      Str: "12483 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Sabrina",
      Str: "12557 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12692 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Roland",
      Str: "5 Crestview Cir",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3157,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12480 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Mckenna",
      Str: "12468 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12602 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Edmonton",
      Str: "12668 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Windgate At Avenir",
      Mo: "Torrance",
      Str: "12594 Nautilus Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Xavier",
      Str: "10551 Petrillo Way",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "3",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Tobago",
      Str: "15327 Gilligan Court",
      Ct: "Winter Gardens",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Other",
      Str: "15975 Pantuso Alley",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 32s",
      Mo: "Suncrest",
      Str: "16001 Micelli Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 32s",
      Mo: "Tessa",
      Str: "15943 Pantuso Alley",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 32s",
      Mo: "Suncrest",
      Str: "15959 Pantuso Alley",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 50s",
      Mo: "Tobago",
      Str: "10663 Petrillo Way",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 50s",
      Mo: "Grayson",
      Str: "10508 Petrillo Way",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 50s",
      Mo: "Tompkins Ii",
      Str: "10496 Petrillo Way",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay Ii 50s",
      Mo: "Xavier",
      Str: "10532 Petrillo Way",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Jasmine",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Catamaran",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2296,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Camden",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Schooner",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2565,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Benton",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Dylan",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Haddock",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "336000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Mabel",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Highgate",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Tower",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Yorkshire",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "512000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Whitestone",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "506000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Coral Grand",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Imperial",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Coral",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1946,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Scarlett",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3602,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Estate",
      Mo: "Easley",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "433000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Estate",
      Mo: "Monroe",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "493000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Estate",
      Mo: "Easley Grand",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4214,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Mystique",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Palmary",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Prestige",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Whitestone",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 70'",
      Mo: "Ashby",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 70'",
      Mo: "Stellar",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong Th",
      Mo: "Navarre",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong Th",
      Mo: "Grayton",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Windsong:estate Collection",
      Mo: "Allentown",
      Str: "26056 Maidencane Way",
      Ct: "Leesburg",
      St: "FL",
      Zip: 34748,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Winstone",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Lakeshore",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Hideaway",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3127,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Seashore",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3286,
      Sto: "2",
      Be: "7",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 50'",
      Mo: "Clearwater",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "9",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 50'",
      Mo: "Clearwater Grand",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "10",
      Ba: "8",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 50'",
      Mo: "Baymont",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "8",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay Th",
      Mo: "Castaway - Interior",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay Th",
      Mo: "Castaway - End",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2276,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Winstone",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2491,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Lakeshore",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "503000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Hideaway",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3117,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "592000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Seashore",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3281,
      Sto: "2",
      Be: "7",
      Ba: "5.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 50'",
      Mo: "Baymont",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "8",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "721000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 50'",
      Mo: "Clearwater",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4398,
      Sto: "2",
      Be: "9",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort Th",
      Mo: "Castaway - End",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2265,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "43400"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort Th",
      Mo: "Castaway - Interior",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2263,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "434000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Elan",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Trevi",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Hailey",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1899,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Elan",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Halle",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1735,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Charle",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Sierra",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2257,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Halle",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Emerson",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1660,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Elan",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Trevi",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Hudson",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Mystique",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2140,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Ashby",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "412000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Trailside Select",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Easley",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2622,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Whitestone",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Ashby Grand",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Yorkshire",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3459,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Easley Grand",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4099,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "22680",
      Co: "Wyndham Lakes:harrington Pointe Townhomes",
      Mo: "Marisol",
      Str: "3402 Rodrick Circle",
      Ct: "Orlando",
      St: "FL",
      Zip: 32824,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    }
  ],
  id: "4407afb9-c0ea-45b1-b66c-80e7db2d8a5d",
  _rid: "+NEfAO8P-RkjAQAAAAAAAA==",
  _self: "dbs/+NEfAA==/colls/+NEfAO8P-Rk=/docs/+NEfAO8P-RkjAQAAAAAAAA==/",
  _etag: '"0701b553-0000-0100-0000-6784ef280000"',
  _attachments: "attachments/",
  _ts: 1736765224
}
