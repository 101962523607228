export const communityMasterTEST = {
  Client: "EMBARK",
  Table: "CommunityModelAddress",
  Rows: [
    {
      Bid: "15305",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "902 honey petal lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Bonavie Cove Villas",
      Mo: "Largo",
      Str: "10350 bonavie cove drive",
      Ct: "fort myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7410 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "1833 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8516 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Emery",
      Mo: "Aspen",
      Str: "10729 sw estella ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "15305",
      Co: "Emery",
      Mo: "Briar",
      Str: "12640 eleanor drive",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Halo at Emery 50's",
      Mo: "ROWAN",
      Str: "12816 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3735 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Topsail",
      Str: "3753 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Lake County",
      Mo: "Redwood",
      Str: "2981 amber sweet pl",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11716 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11660 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11747 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "15305",
      Co: "Parkview at Long Lake 18' TH",
      Mo: "Marianna",
      Str: "2599 everglade way",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Mallard",
      Str: "237 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2302,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Vireo",
      Str: "23 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Egret",
      Str: "215 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1869,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "10 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Gardens North RL 50s",
      Mo: "Beecher",
      Str: "892 orange branch trail",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1859,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "226 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Sebastian",
      Str: "143 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 60s",
      Mo: "Braden",
      Str: "122 sydney cove",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan B",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 917,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "15305",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan D",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 968,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11535 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8927 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8913 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "15305",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1042 boardwalk place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "15305",
      Co: "Soleil - D50",
      Mo: "Glades",
      Str: "1189 lakeshore breeze place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "865 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "741 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1082 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "811 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "10724 sw gloriana st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  60's",
      Mo: "Jubilee",
      Str: "5783 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "15305",
      Co: "Sunstone at Wellen Park 30' TH",
      Mo: "Topsail",
      Str: "12497 somatic ct",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "Sunstone at Wellen Park 50's",
      Mo: "Pinnacle",
      Str: "18081 grand prosperity dr",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "15305",
      Co: "TestCommunity2",
      Mo: "TestModel",
      Str: "27 ocala street",
      Ct: "orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Tradition -Emery",
      Mo: "Briar Coastal",
      Str: "12792 sw eleanor dr",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Tradition-Emery",
      Mo: "Yarrow",
      Str: "10700 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2279,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "12023 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "15305",
      Co: "Volanti 24' TH",
      Mo: "Venice",
      Str: "4221 cloud hopper way",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2109 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2214 satin leaf street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3296 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13124 holsinger boulevard",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek TH 20s",
      Mo: "Cove",
      Str: "11972 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Anna Maria",
      Str: "110 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "402 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trial",
      Mo: "Willet",
      Str: "2544 cobalt shores ln",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3395,
      Sto: "2",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate ",
      Mo: "Massiano",
      Str: "8914 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8791 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Roseberry",
      Str: "28 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Julington",
      Str: "591 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Captiva Elite",
      Str: "310 gulf stream way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anastasia Elite",
      Str: "286 barbados drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3292,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Stonebrook",
      Str: "84 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2951,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Greenwood",
      Str: "126 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3031,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "749 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "695 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore - Executive Collection",
      Mo: "Madeira",
      Str: "8228 topsail pl",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9668 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1120000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Dolcetto",
      Str: "9226 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1230000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Barbera",
      Str: "9636 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1210000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17110 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "1360000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Abington",
      Str: "17111 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4910,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14850 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1610000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "15070 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7578,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "2240000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10111 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2517,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10062 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "FLat",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Parkland Golf & CC-Monogr 65'",
      Mo: "Anzi",
      Str: "10373 sweet bay manor",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4465,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Delmore Elite",
      Str: "224 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "311 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "50 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir - Palms Collection",
      Mo: "Joliet",
      Str: "9948 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17135 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7239,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper at Deerfield Beach Community",
      Mo: "Conquina",
      Str: "1853 spoonbill ct",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "Coquina",
      Str: "1853 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "317 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Rowen",
      Str: "388 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2931,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Knollwood",
      Str: "571 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12016 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1650000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Varen",
      Str: "12051 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2645000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18527 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18535 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "FLora",
      Str: "12787 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling Elite",
      Str: "12815 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Pelican",
      Str: "12829 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "18635 midsummer place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6894 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1480000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Santangelo II",
      Str: "6925 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1030000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6924 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1185000"
    },
    {
      Bid: "90002",
      Co: "TB at Bella Collina-Vista",
      Mo: "Cabris",
      Str: "15954 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1500000"
    },
    {
      Bid: "90002",
      Co: "The Isles at Lakewood Ranch",
      Mo: "Avery Elite",
      Str: "8130 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Glen",
      Str: "3131 vanguard court",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4092,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "90002",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Larue",
      Str: "6354 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2816,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Westhaven at Ovation-Singles",
      Mo: "Grenada",
      Str: "0117 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3237,
      Sto: "2",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model A",
      Str: "1651 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model B",
      Str: "1652 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34748,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model C",
      Str: "1653 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34749,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3229,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model D",
      Str: "1654 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34750,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model E",
      Str: "1655 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34751,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model F",
      Str: "1656 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34752,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2118,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model G",
      Str: "1657 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34753,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90003",
      Co: "Dreamland",
      Mo: "Model H",
      Str: "1658 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34754,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Plan I",
      Str: "2012 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Plan II",
      Str: "2013 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2778,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Plan III",
      Str: "2014 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "205000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model J",
      Str: "2015 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2475,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model K",
      Str: "2016 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "235000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model M",
      Str: "2017 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32717,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model O",
      Str: "2018 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32718,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "310000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model P",
      Str: "2019 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32719,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "285000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model Q",
      Str: "2020 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1612,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "285000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model S",
      Str: "2021 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32721,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "340000"
    },
    {
      Bid: "90003",
      Co: "Coastal Cove",
      Mo: "Model T",
      Str: "2022 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32722,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "795000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Plan A",
      Str: "12120 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "245000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Plan B",
      Str: "12121 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33413,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Plan D",
      Str: "12122 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33414,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Plan E",
      Str: "12123 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33415,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Plan F",
      Str: "12124 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33416,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Plan G",
      Str: "12125 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33417,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Model 1",
      Str: "12126 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33418,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Model 2",
      Str: "12127 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33419,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Model 3",
      Str: "12128 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33420,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90003",
      Co: "Beachside Village",
      Mo: "Model 4",
      Str: "12129 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33421,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "15428",
      Co: "Aspire at East Lake",
      Mo: "Stetson ",
      Str: "2961 se union park drive",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Hawks Ridge",
      Mo: "Fullerton V",
      Str: "668 ne waters edge ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "113000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "1117 sw kapok ave",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4633 sw savona blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Emerald",
      Str: "5218 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5161 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "255000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Lelia",
      Str: "5109 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "285000"
    },
    {
      Bid: "15428",
      Co: "Coral Lago",
      Mo: "Wheatley",
      Str: "8480 nw 39th ct",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3345,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "495000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando",
      Mo: "Sandpiper",
      Str: "7644 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "280000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7718 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2129,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7720 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2432,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "2006 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "121000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1610 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Cerelia",
      Str: "9019 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "8956 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons of Orlando",
      Mo: "St Lucia",
      Str: "2051 flora pass place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1967,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "290000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons Orlando",
      Mo: "Emerie",
      Str: "1908 flora pass way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Flora",
      Str: "8874 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8848 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "690000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Azure",
      Str: "8985 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 60s",
      Mo: "Flora",
      Str: "9312 cantal circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 60s",
      Mo: "Cerelia",
      Str: "11909 cantal circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gambrel",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "12155 bastille circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "815000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Oakton",
      Str: "12324 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3467,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada",
      Str: "12107 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada II",
      Str: "12285 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "825000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12112 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada II EXTRA SUITE",
      Str: "12127 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "935000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Dupont",
      Str: "6774 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "260000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Ivanhoe",
      Str: "36838 precita terrace",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gambrel",
      CovA: "385000"
    },
    {
      Bid: "15428",
      Co: "Ocoee Landings",
      Mo: "San Andres",
      Str: "1896 ibis bay court",
      Ct: "ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3000,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Tessa",
      Str: "720 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "385000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "616 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Suncrest",
      Str: "731 firerock lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "638 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "1619 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Oakton",
      Str: "12324 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3467,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "630 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "207 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "250 preserve trail north",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "2006 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "121000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 50s",
      Mo: "Tobago",
      Str: "10663 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 60s",
      Mo: "Flora",
      Str: "9312 cantal circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "560000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7718 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2129,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "640 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Valencia II",
      Str: "2337 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "11915 vivar run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "222 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "15428",
      Co: "Aspire at East Lake",
      Mo: "Stetson ",
      Str: "2961 se union park drive",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7620 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2223 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1991,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "1410 gopher terrace",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "144000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1995 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "103000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "248 preserve trail north",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay",
      Mo: "Xavier",
      Str: "10551 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "3",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St Lucie",
      Mo: "Palmera",
      Str: "2431 sw brescia st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1610 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Cerelia",
      Str: "9019 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "223 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "144000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7720 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2432,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "735 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons Orlando",
      Mo: "Emerie",
      Str: "1908 flora pass way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "2104 antilles club drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "15428",
      Co: "Ocoee Landings",
      Mo: "San Andres",
      Str: "1896 ibis bay court",
      Ct: "ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3000,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Emerald",
      Str: "5218 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7645 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5161 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1623 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1634 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Dupont",
      Str: "6774 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Other",
      Str: "8839 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2641,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Flora",
      Str: "8874 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "2038 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "114000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "2574 sw cameo blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s on 70s",
      Mo: "Flora",
      Str: "9409 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2525,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1626 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1699 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 32s",
      Mo: "Suncrest",
      Str: "16001 micelli drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "641 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "651 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "213 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "8956 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Lelia",
      Str: "5109 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "285000"
    },
    {
      Bid: "15428",
      Co: "Coral Lago",
      Mo: "Wheatley",
      Str: "8480 nw 39th ct",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3345,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "12155 bastille circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando",
      Mo: "Sandpiper",
      Str: "7644 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons of Orlando",
      Mo: "St Lucia",
      Str: "2051 flora pass place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1967,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5114 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "240000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8848 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Tessa",
      Str: "720 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 50s",
      Mo: "Grayson",
      Str: "10508 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 60s",
      Mo: "Cerelia",
      Str: "11909 cantal circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Ivanhoe",
      Str: "36838 precita terrace",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "1",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "215 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Azure",
      Str: "8985 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "8867 bastille circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "685000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2024 raulerson court",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "3984 sw laffite st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "614 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "11886 vivar run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada",
      Str: "12107 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "1137 sw east louise cir",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1659 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "616 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5162 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "11795 leon circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2417,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1683 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada II",
      Str: "12285 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "224 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7716 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "1663 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 32s",
      Mo: "Tessa",
      Str: "15943 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "1486 sw herder rd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "Sola Vista",
      Mo: "null",
      Str: "5367 jubiloso dr",
      Ct: "st cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3636,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "570000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "1717 sw alberca ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Suncrest",
      Str: "731 firerock lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Hawks Ridge",
      Mo: "Fullerton V",
      Str: "668 ne waters edge ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "113000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "1117 sw kapok ave",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12112 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12172 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada II EXTRA SUITE",
      Str: "12127 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4633 sw savona blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1675 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12124 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Ashmere",
      Str: "6741 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Palmera",
      Str: "6762 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "234 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7744 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15428",
      Co: "Aspire at the Links of Calusa Springs",
      Mo: "Dupont",
      Str: "6879 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando",
      Mo: "Balfour",
      Str: "7793 four seasons blvd",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1599,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont Aspire",
      Str: "2574 sw cameo blvd",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "3414 sw princeton st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Everett",
      Str: "11806 vivar run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "620 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Other",
      Str: "2159 limestone trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Suncrest",
      Str: "843 terrapin drive",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s on 70s",
      Mo: "Flora",
      Str: "9137 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1687 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Sola Vista",
      Mo: "Sherrington",
      Str: "2175 brillante drive",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "226 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 32s",
      Mo: "Suncrest",
      Str: "15959 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "4549 sw scope st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7641 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "208 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "138000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "220 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "7600 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "616 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "1408 gopher terrace",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "155000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Thomas",
      Str: "1578 whitewood court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Odessa",
      Str: "2046 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "15428",
      Co: "Aspire at the Links of Calusa Springs",
      Mo: "Ivanhoe",
      Str: "6825 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2743,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Tessa",
      Str: "803 firerock lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "221 preserve trail south",
      Ct: "stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "229 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7740 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St Lucie",
      Mo: "Other",
      Str: "642 sw tulip blvd",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4598 sw scope st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay",
      Mo: "Tobago",
      Str: "15327 gilligan court",
      Ct: "winter gardens",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Dupont",
      Str: "6744 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay",
      Mo: "Other",
      Str: "15975 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando",
      Mo: "Tobago",
      Str: "7764 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "Sola Vista",
      Mo: "Alvarez II",
      Str: "2183 brillante dr",
      Ct: "st cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2762,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s on 70s",
      Mo: "Flora",
      Str: "8912 bastille circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1695 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "635 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "642 bayhawk street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "106000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7739 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Emerald",
      Str: "36826 precita terrace",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "654 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 50s",
      Mo: "Tompkins II",
      Str: "10496 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 70s",
      Mo: "Verna",
      Str: "9072 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3615,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "1570 whitewood court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Tobago",
      Str: "1590 whitewood court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian Reserve",
      Mo: "NULL",
      Str: "2058 emerald springs",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "236 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "15428",
      Co: "Winding Bay II 50s",
      Mo: "Xavier",
      Str: "10532 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "8995 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5169 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "15428",
      Co: "Coral Lago",
      Mo: "Vitale",
      Str: "9134 nw 39th street",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3229,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St Lucia",
      Mo: "Palmera",
      Str: "1525 sw paar dr",
      Ct: "port st lucia",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "624 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Stella",
      Str: "7765 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2118,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1630 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "105000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "647 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7612 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Coral Lago",
      Mo: "Other",
      Str: "3811 nw 87th way",
      Ct: "coarl springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2778,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "15428",
      Co: "Coral Logo",
      Mo: "Edinburgh",
      Str: "8520 nw 39th ct",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "Enclave at Boca Dunes",
      Mo: "NULL",
      Str: "9476 kinley pl",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2475,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "11748 fortress run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15428",
      Co: "Enclave at Boca Dunes",
      Mo: "Bonaire II",
      Str: "10161 brickhill dr",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8824 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Thomas",
      Str: "7733 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "465000"
    },
    {
      Bid: "15428",
      Co: "Aspire at East Lake SF",
      Mo: "Stetson",
      Str: "2364 se union park dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1612,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Hartwell",
      Str: "12256 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1025000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Valencia II",
      Str: "2045 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "1667 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1650 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Aspire at The Links at Calusa Springs",
      Mo: "Ashmere",
      Str: "6817 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "15428",
      Co: "Enclave at Boca Dunes",
      Mo: "Other",
      Str: "10222 akenside dr",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "15428",
      Co: "Rivington",
      Mo: "Suncrest",
      Str: "742 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "4000 sw mccrory st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "9233 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Parkland",
      Mo: "Coral",
      Str: "12130 bastile circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2069 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera EXTRA SUITE PLUS",
      Str: "1470 sw cellini ave",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8899 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Thomas",
      Str: "7753 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2029,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Nicola",
      Str: "780 middleton st",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Paloma",
      Str: "2181 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2873,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "15428",
      Co: "Enclave at Boca Dunes",
      Mo: "Bonaire II",
      Str: "9424 kinley place",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Nicola",
      Str: "716 moca lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "15428",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "639 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2217 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7608 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian",
      Mo: "Paloma",
      Str: "2355 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2873,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4454 sw idlewild st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Lelia",
      Str: "5125 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "15428",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "726 rivington ave",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "230 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Elsinore",
      Str: "12120 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2854,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "15428",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "206 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "15428",
      Co: "Links at Calusa Springs",
      Mo: "Palmera",
      Str: "6978 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "San Sebastian Reserve",
      Mo: "Andorra",
      Str: "2012 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons At Orlando",
      Mo: "NULL",
      Str: "7649 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1651 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "2042 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "7624 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "7628 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12296 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Rosada II EXTRA SUITE",
      Str: "12131 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "15428",
      Co: "Marion Oaks",
      Mo: "Other",
      Str: "230 marion oaks trail",
      Ct: "ocala",
      St: "FL",
      Zip: 34473,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1282,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 60s",
      Mo: "Vera",
      Str: "11925 cantal circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2604,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15428",
      Co: "La Terre at Avenir",
      Mo: "Other",
      Str: "12300 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "FS at Wylder 50s",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "FS at Wylder duplex villas",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "FS at Wylder 40s",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at East Lake SF",
      Mo: "Other",
      Str: "2388 SE Union Park Drive",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Victoria Parc",
      Mo: "Other",
      Str: "SW Village Parkway and",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 50s",
      Mo: "Other",
      Str: "Loxahatchee Rd",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "FS at Parkland 70s",
      Mo: "Other",
      Str: "Loxahatchee Rd",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Other",
      Str: "2117 Antilles Club Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Osprey Ranch 50s",
      Mo: "Other",
      Str: "15096 Field Daisy Dr",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Waterstone",
      Mo: "Other",
      Str: "Waterstone Boulevard",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Horizon Isle",
      Mo: "Other",
      Str: "15221 Blue Peacock Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Osprey Ranch 32s",
      Mo: "Other",
      Str: "15096 Field Daisy Dr",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Salerno Reserve SF",
      Mo: "Other",
      Str: "6791 SE Lost Pine Dr.",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Ivy Trail 60s",
      Mo: "Other",
      Str: "828 Green Eng St.",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Boatman Hammock",
      Mo: "Other",
      Str: "Boatman Street",
      Ct: "Village of Palm Springs",
      St: "FL",
      Zip: 33461,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Port St. Lucie",
      Mo: "Other",
      Str: "2961 SW Savona Boulevard",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Palm Bay",
      Mo: "Other",
      Str: "1834 Emerson Dr SE",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Armen Groves",
      Mo: "Other",
      Str: "124 Smith Rd",
      Ct: "Merritt Island",
      St: "FL",
      Zip: 32953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Osprey Ranch THs",
      Mo: "Other",
      Str: "Avalon Road",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Marion Oaks",
      Mo: "Other",
      Str: "SW 132nd Place",
      Ct: "Ocala",
      St: "FL",
      Zip: 34473,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Ivy Trail 50s",
      Mo: "Other",
      Str: "828 Green Eng St.",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Palm Coast",
      Mo: "Other",
      Str: "370 Parkview Drive",
      Ct: "Palm Coast",
      St: "FL",
      Zip: 32164,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Aspire at Hawks Ridge",
      Mo: "Other",
      Str: "678 NE Waters Edge Lane",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Vdara",
      Mo: "Other",
      Str: "273 Severn Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32803,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Townes at Vdara",
      Mo: "Other",
      Str: "273 Severn Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32803,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "15428",
      Co: "Community",
      Mo: "Other",
      Str: "",
      Ct: "",
      St: "",
      Zip: 0,
      Pt: "",
      Ot: "",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: ""
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "1833 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "865 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Sunstone at Wellen Park 30' TH",
      Mo: "Topsail",
      Str: "12497 somatic ct",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "Emery",
      Mo: "Aspen",
      Str: "10729 sw estella ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11716 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "226 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "902 honey petal lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3296 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Bonavie Cove Villas",
      Mo: "Largo",
      Str: "10350 bonavie cove drive",
      Ct: "fort myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11660 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "15305",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1042 boardwalk place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "15305",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan B",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 917,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "15305",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "12720 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Volanti 24' TH",
      Mo: "Venice",
      Str: "4221 cloud hopper way",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8927 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "741 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3263 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8903 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "15305",
      Co: "TestCommunity2",
      Mo: "TestModel",
      Str: "27 ocala street",
      Ct: "orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3735 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Mallard",
      Str: "237 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2302,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7410 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7456  estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11747 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Topsail",
      Str: "3753 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Tradition-Emery",
      Mo: "Yarrow",
      Str: "10700 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2279,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2234 satin leaf street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "15305",
      Co: "Emery",
      Mo: "Briar",
      Str: "12640 eleanor drive",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Halo at Emery 50's",
      Mo: "ROWAN",
      Str: "12816 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3765 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Lake County",
      Mo: "Redwood",
      Str: "2981 amber sweet pl",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11618 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "15305",
      Co: "Parkview at Long Lake 18' TH",
      Mo: "Marianna",
      Str: "2599 everglade way",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2109 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3737 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 60s",
      Mo: "Braden",
      Str: "122 sydney cove",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1082 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "12672 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  60's",
      Mo: "Jubilee",
      Str: "5783 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2214 satin leaf street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2681 spider lily ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan D",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 968,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "15305",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "10724 sw gloriana st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Volanti 24' TH",
      Mo: "Venice",
      Str: "26063 woven wicker bend",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek TH 20s",
      Mo: "Cove",
      Str: "11972 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11818 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "15305",
      Co: "Soleil - D50",
      Mo: "Glades",
      Str: "1189 lakeshore breeze place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "811 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3244 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3276 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Vireo",
      Str: "23 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "15305",
      Co: "Sunstone at Wellen Park 50's",
      Mo: "Pinnacle",
      Str: "18081 grand prosperity dr",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "12023 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Sebastian",
      Str: "143 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "908 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Tradition -Emery",
      Mo: "Briar Coastal",
      Str: "12792 sw eleanor dr",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  60's",
      Mo: "Jubilee",
      Str: "8681 sundance loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13124 holsinger boulevard",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Egret",
      Str: "215 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1869,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "968 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Gardens North RL 50s",
      Mo: "Beecher",
      Str: "892 orange branch trail",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1859,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8516 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Meridale 60s",
      Mo: "TestModel",
      Str: "3707 cheyney park drive",
      Ct: "charlotte",
      St: "FL",
      Zip: 28269,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11674 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11535 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "10 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8913 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "1799 tropical palms circle",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "15305",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10733 sw gloriana",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Cadence at Tradition 50'",
      Mo: "Cascades",
      Str: "14135 sw safi ter",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8919 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3272 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Pablo Cove Townhomes RL 20s",
      Mo: "Rialta",
      Str: "3492 oystercatcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1595,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Randal Walk - R22",
      Mo: "Marabel III",
      Str: "10003 randal walk st",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1721,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "10717 sw gloriana st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1052 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "1070 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Volanti 20' TH",
      Mo: "Ormond",
      Str: "25976 woven wicker bend",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1888,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 50s",
      Mo: "Linden",
      Str: "12016 cabana road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2696,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8518 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8925 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2653 spider lily ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Wellen Park Sunstone",
      Mo: "Seabranch",
      Str: "18046 grand prosperity drive",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1908,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8508 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "198 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "999 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2093 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3280 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 20' TH",
      Mo: "Ormond",
      Str: "8520 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "11724 sw aquila way",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Catalina II",
      Str: "11639 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3787 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Island Village",
      Mo: "Celebration - Aleutian",
      Str: "2204 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Aurora",
      Str: "11927 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Catalina II",
      Str: "11746 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2680 spider lily ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Forest Park at Wildlight",
      Mo: "Bristol",
      Str: "425 sweetgum street",
      Ct: "yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11986 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle Palms ",
      Mo: "Venice",
      Str: "710 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "15305",
      Co: "Tradition - Emery",
      Mo: "Briar",
      Str: "10744 sw estella ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Wellen Park, Sunstone",
      Mo: "Anclote",
      Str: "12405 asana court",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15305",
      Co: "Pablo Cove",
      Mo: "Destan",
      Str: "3498 oyster catcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "15305",
      Co: "Renaissance 60's",
      Mo: "Nantucket II",
      Str: "20750 ovid lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Gateway",
      Str: "2971 ambersweet place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "15305",
      Co: "Cadence at Tradition 50'",
      Mo: "Caledon",
      Str: "14143 sw safi ter",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Aurora",
      Str: "11963 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Topsail",
      Str: "3769 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Manor 70s",
      Mo: "Cannon II",
      Str: "52 terra oaks dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2734,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "734 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Cadence at Tradition 50'",
      Mo: "Gateway",
      Str: "10246 sw orana dr",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Meridale 60s",
      Mo: "TestModel",
      Str: "54 e. sycamore st.",
      Ct: "sarasota",
      St: "FL",
      Zip: 34231,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11890 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Whitney",
      Str: "250 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "10162 sw corvus ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Nirvana",
      Str: "11590 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1376,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "993 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Triple Creek Classic - 50's",
      Mo: "Pelican",
      Str: "11935 streambed drive",
      Ct: "riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek TH 20s",
      Mo: "Spring",
      Str: "11971 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Hatteras",
      Str: "7430 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 50s",
      Mo: "Aspen",
      Str: "11990 cabana road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1776,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "15305",
      Co: "Compass Landing",
      Mo: "Banyan",
      Str: "3387 pilot circle",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2422,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "15305",
      Co: "Olde Mandarin Estates 70s",
      Mo: "Clarke",
      Str: "4629 quilting cir",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32257,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8917 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "15305",
      Co: "Tradition Emery",
      Mo: "Dahlia",
      Str: "12777 sw eleanor dr",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2300,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2952 wild mulberry drive",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "Compass Landing  50's",
      Mo: "Admiral",
      Str: "3415 pilot circle",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1994,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 40's",
      Mo: "Egmont",
      Str: "3726 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1506,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Parkview at Long Lake 18' TH",
      Mo: "Marianna",
      Str: "2325 audubon preserve lane",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Sunglow at Emery 35's",
      Mo: "BRIAR",
      Str: "12744 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3288 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1106 boardwalk place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 40's",
      Mo: "Seabranch",
      Str: "3750 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1908,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - D50",
      Mo: "Talbot",
      Str: "8578 compass point ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2051,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Hatteras",
      Str: "2264 celebration blvd",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "15305",
      Co: "Renaissance 50's",
      Mo: "Aurora II",
      Str: "20440 revival lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2098,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "950 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Tradition - Emery",
      Mo: "Briar",
      Str: "10645 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "RiverTown WaterSong 55s",
      Mo: "Bridge",
      Str: "265 pinellas way",
      Ct: "st. johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2210,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11599 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks",
      Mo: "Aurora",
      Str: "11562 buoy point place",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "15305",
      Co: "Pablo Cove Townhomes RL 20s",
      Mo: "Rialta",
      Str: "3490 oystercatcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1595,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Tradition",
      Mo: "Telaro",
      Str: "11717 sw aquila way",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Anastasia",
      Str: "1863 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "15305",
      Co: "Harmony 20's-TH",
      Mo: "Seaside II End",
      Str: "11928 sky acres terrace",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1720,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 55s",
      Mo: "Wales",
      Str: "291 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3279,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "753 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "2232 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11920 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2673 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Barron",
      Str: "12060 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Whitney",
      Str: "12072 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Manor 70s",
      Mo: "Saxon II",
      Str: "326 terra oaks dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3564,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8947 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "15305",
      Co: "Volanti 20' TH",
      Mo: "Ormond",
      Str: "25972 woven wicker bend",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2089 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15305",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20703 ovid lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "10218 sw corvus ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Clarion",
      Str: "11927 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2351,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8345 wildflower glen ave",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2061 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "31 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2549,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3292 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Amelia",
      Str: "1859 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Renaissance 50's",
      Mo: "Riviera II",
      Str: "20472 bandera place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11606 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "921 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Gateway",
      Str: "2101 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2113 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "2216 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8923 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Whitney",
      Str: "11994 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "Renaissance 50's",
      Mo: "Dawn II",
      Str: "20655 ovid lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Whitney",
      Str: "133 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8901 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  50's",
      Mo: "Dawn",
      Str: "5746 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "15305",
      Co: "Harmony 20's-TH",
      Mo: "Marina II",
      Str: "11936 sky acres terrace",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1549,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - D50",
      Mo: "Pinnacle",
      Str: "7793 clove hitch way",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "15305",
      Co: "Parkview at Long Lake 50's",
      Mo: "Myrtle",
      Str: "19756 blue pond drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2983,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3264 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Catalina",
      Str: "11655 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Solara Resort",
      Mo: "Malibu II",
      Str: "8931 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34737,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "1034 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "15305",
      Co: "Telaro at Tradition",
      Mo: "Eden",
      Str: "11671 sw viridian blvd",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Heron",
      Str: "7418 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Sebastian",
      Str: "182 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "759 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Tradition - Emery",
      Mo: "Rowan",
      Str: "3472 sw coquina cove way",
      Ct: "palm city",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2654 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13149 dunwick road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Amelia",
      Str: "2208 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "1570 mermaid cove way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "728 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Celebration _ Island Village",
      Mo: "Other",
      Str: "7413 levee lane",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "15305",
      Co: "Compass Landing  50's",
      Mo: "Admiral",
      Str: "3565 pilot circle",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1994,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11782 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks",
      Mo: "Other",
      Str: "11770 globe st",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "15305",
      Co: "Parkview at Long Lake 24' TH",
      Mo: "Sebring",
      Str: "2334 audubon preserve lane",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2243,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Cadence at Tradition 50'",
      Mo: "Caledon",
      Str: "14055 sw safi ter",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks R21",
      Mo: "Catalina",
      Str: "11538 bouy point place",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8907 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3543,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "944 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Sage",
      Str: "11935 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Harmony 30's-TH",
      Mo: "Driftwood II END",
      Str: "5540 coachwood cove",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1729,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "15305",
      Co: "Sunstone at Wellen Park 30' TH",
      Mo: "Anclote",
      Str: "12394 asana ct",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "174 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "RiverTown The Haven 60s",
      Mo: "Braden",
      Str: "129 sydney cove",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "15305",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10709 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "RiverTown WaterSong 45s",
      Mo: "Trail",
      Str: "465 pinellas way",
      Ct: "st. johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1563,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "9051 coral cape street",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "1977 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "15305",
      Co: "Pablo Cove Townhomes RL 20s",
      Mo: "Destan",
      Str: "3510 oystercatcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "11639 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8929 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  50's",
      Mo: "Riviera II",
      Str: "5742 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2601 royal jasmine ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Barron",
      Str: "12096 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Celebration - D55",
      Mo: "Marathon",
      Str: "7573 estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2909,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3743 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "883 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2105 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "1609 tropical palms circle",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3749 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Vireo",
      Str: "68 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8909 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D60",
      Mo: "Sarasota",
      Str: "3297 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3325,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "15305",
      Co: "Emory",
      Mo: "Dalia",
      Str: "12840 sw elenor drive",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2300,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 40's",
      Mo: "Pelican",
      Str: "3748 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "1058 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - R20",
      Mo: "Selena III",
      Str: "2129 emil jahna road",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Island Village Celebration",
      Mo: "Hatteras",
      Str: "7452 estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "15305",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7569 estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3759 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "15305",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20646 galileo place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "RiverTown WaterSong 45s",
      Mo: "Court",
      Str: "385 pinellas way",
      Ct: "st. johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1686,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11631 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Sage",
      Str: "11711 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "15305",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "1566 mermaid cove way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "992 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "La Paloma Preserve 40's",
      Mo: "Egmont",
      Str: "3730 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1506,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R21",
      Mo: "Catalina II",
      Str: "11752 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2650 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "2252 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "747 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "Addison Landing - D60",
      Mo: "Oakleaf",
      Str: "867 asterfield lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2794,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "15305",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "899 asterfield lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Gardens North RL 50s",
      Mo: "Oak",
      Str: "442 footbridge rd",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "10170 sw corvus ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "11582 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  50's",
      Mo: "Dawn",
      Str: "8536 september sky court",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "15305",
      Co: "TestCommunity3",
      Mo: "TestModel2",
      Str: "27 ocala street",
      Ct: "orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "11703 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Sunstone",
      Mo: "Glades",
      Str: "17953 grand prosperity dr",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek TH 20s",
      Mo: "Cove",
      Str: "11975 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "15305",
      Co: "Harmony 30's-TH",
      Mo: "Driftwood END",
      Str: "5557 coachwood cove",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1333,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2662 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "927 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 50s",
      Mo: "Aspen",
      Str: "12062 cabana road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1776,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "1829 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "15305",
      Co: "Citron Grove 20' TH",
      Mo: "Ormond",
      Str: "8331 wildflower glen ave",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "15305",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20640 galileo place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "12078 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "15305",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "223 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "15305",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan A",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 779,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "15305",
      Co: "Celebration - Island Village",
      Mo: "Heron",
      Str: "7405 levee lane",
      Ct: "celebration",
      St: "FL",
      Zip: 32747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2437,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "15305",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "986 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  50's",
      Mo: "Dayspring",
      Str: "8717 sundance loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "15305",
      Co: "Meridian Parks - R40",
      Mo: "Belmont",
      Str: "tbd",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  50's",
      Mo: "Dawn",
      Str: "5737 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "15305",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "11951 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "15305",
      Co: "Renaissance 50's",
      Mo: "Riviera II",
      Str: "20470 revival lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "15305",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10708 sw gloriana",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - R20",
      Mo: "Francesca III",
      Str: "2125 emil jahna road",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1631,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11519 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2110 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "15305",
      Co: "Island village at celebration ",
      Mo: "Anna Maria ",
      Str: "1823 beach ridge rd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1745,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "15305",
      Co: "Sunrise JV -  50's",
      Mo: "Dayspring",
      Str: "8725 sundance loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "15305",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3248 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "15305",
      Co: "Community",
      Mo: "Other",
      Str: "",
      Ct: "",
      St: "",
      Zip: 0,
      Pt: "",
      Ot: "",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: ""
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14825 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1490000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18527 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Flora",
      Str: "12794 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "695000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "12732 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "TB at Bella Collina-Vista",
      Mo: "Cabris",
      Str: "15954 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1500000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6955 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17121 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "970000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper at Deerfield Beach Community",
      Mo: "Conquina",
      Str: "1853 spoonbill ct",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Glen",
      Str: "3131 vanguard court",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4092,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "166 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "14925 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1465000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "27 arum court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14850 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1610000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14985 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1345000"
    },
    {
      Bid: "90002",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17135 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "317 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Anna Maria",
      Str: "110 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8757 st. lucia",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3228,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18535 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Delmore Elite",
      Str: "224 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Rowen",
      Str: "54 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2947,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12016 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1650000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Captiva Elite",
      Str: "310 gulf stream way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "311 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17110 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 8002,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1070000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6895 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1135000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9668 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1120000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8754 st.lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Parkland Golf & CC-Monogr 65'",
      Mo: "Anzi",
      Str: "10373 sweet bay manor",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4465,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17140 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7577,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1165000"
    },
    {
      Bid: "90002",
      Co: "Westhaven at Ovation-Singles",
      Mo: "Grenada",
      Str: "0117 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3237,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Laurel Pnt Lk Nona-Mosaic",
      Mo: "Varen",
      Str: "9028 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1295000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "50 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Rowen",
      Str: "388 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2931,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8791 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Flora",
      Str: "12787 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Stonebrook",
      Str: "84 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2951,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "14955 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1895000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trial",
      Mo: "Willet",
      Str: "2544 cobalt shores ln",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3395,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Roseberry",
      Str: "28 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "15070 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7578,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "2240000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling Elite",
      Str: "12815 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Varen",
      Str: "12051 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2645000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Pelican",
      Str: "12829 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "749 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Dolcetto",
      Str: "9226 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1230000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir - Palms Collection",
      Mo: "Joliet",
      Str: "9948 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "402 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anastasia Elite",
      Str: "286 barbados drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3292,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17140 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7544,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1160000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Barbera",
      Str: "9636 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1210000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Knollwood",
      Str: "571 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9683 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1190000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10111 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2517,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling Elite",
      Str: "12857 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17110 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1360000"
    },
    {
      Bid: "90002",
      Co: "Shores At Lake Whippoorwill â€“ Estates",
      Mo: "Bordeaux",
      Str: "12028 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2976,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper at Deerfield Beach Community",
      Mo: "Conquina",
      Str: "523 spoonbill ter",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Shores @Whippoorwill Lake-Esta",
      Mo: "Corbeil",
      Str: "12040 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "The Isles at Lakewood Ranch",
      Mo: "Avery Elite",
      Str: "8130 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate ",
      Mo: "Massiano",
      Str: "8914 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6894 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1480000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Santangelo II",
      Str: "6925 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1030000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Julington",
      Str: "591 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Abington",
      Str: "17111 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4910,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10062 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Built Up",
      RS: "Flat",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "18635 midsummer place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Larue",
      Str: "6354 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2816,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "186 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Greenwood",
      Str: "126 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3031,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "695 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Dolcetto",
      Str: "9004 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1090000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "51 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2888,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6924 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1185000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore - Executive Collection",
      Mo: "Madeira",
      Str: "8228 topsail pl",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "Coquina",
      Str: "1853 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "233 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18484 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "276 gardiners bay drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4824,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12028 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Westhaven@Ovation-Bungalows",
      Mo: "Montpelier",
      Str: "7 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2142,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore",
      Mo: "Madeira",
      Str: "15742 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Stonebrook",
      Str: "138 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Shores @Whippoorwill Lake-Esta",
      Mo: "Corbeil",
      Str: "12058 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1085000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12052 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "18645 midsummer place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Venice Woodlands",
      Mo: "Flora Elite",
      Str: "608 mistiflower cir",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Varen",
      Str: "12061 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2100000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Aviemore",
      Str: "12756 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "THE ISLES AT LAKEWOOD RANCH CAPTIVA COLLECTION",
      Mo: "Sandpiper",
      Str: "8138 nevis run",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Davey",
      Str: "6443 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3437,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Cabris",
      Str: "12041 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2200000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18543 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Augustus",
      Str: "12912 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Glen",
      Str: "6394 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4042,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Roseberry",
      Str: "603 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Barbera",
      Str: "12010 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1195000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "316 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17120 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1245000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18508 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10042 florence circle",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2378,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "San Giorgio",
      Str: "8770 st.lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "356 port charlotte drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4213,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Westhaven at Ovation-Towns",
      Mo: "Crowley",
      Str: "20 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1863,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "827 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4466,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Pelican",
      Str: "12770 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "Westwind Island Colonial",
      Str: "546 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1995,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Flat",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "TB at Bella Collina-Vista",
      Mo: "Montech",
      Str: "15938 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1855000"
    },
    {
      Bid: "90002",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17811 rolling oaks estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 8014,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1180000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Franklin",
      Str: "96 cape may ave",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Rinaldi",
      Str: "850 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pointe",
      Mo: "Unknown",
      Str: "1859 sandpiper pointe pl",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling",
      Str: "12830 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Davenport",
      Str: "114 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2191,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Sonoma",
      Str: "17131 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4536,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Barbera",
      Str: "12022 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1420000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9328 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1410000"
    },
    {
      Bid: "90002",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17130 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee ",
      Mo: "Rose",
      Str: "59 ficus ln",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2263,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Delmore",
      Str: "28 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2461,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "157 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3854,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Anna Maria",
      Str: "54 anthem circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3233,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10099 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10038 florence circle",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Millstone Ranches",
      Mo: "Callahan",
      Str: "14895 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4705,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1390000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "432 port charlotte drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4373,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "212 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "CastleBay",
      Str: "12705 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "TB at Bella Collina-Vista",
      Mo: "Cabris",
      Str: "15650 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1545000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Mandigo",
      Str: "41 cayman cove",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2225,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "90002",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17816 rolling oaks estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18492 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Dalenna",
      Str: "8787 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "La Isla",
      Str: "1847 sandpiper pointe pl",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1883,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Catalina (FL)",
      Str: "193 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4335,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17803 rolling oaks estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6959,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "875000"
    },
    {
      Bid: "90002",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Barbera",
      Str: "9667 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10129 florence cir",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2100,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10031 florence circle",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2245,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Stillwater Shores",
      Mo: "Dalenna II",
      Str: "6834 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1235000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anastasia",
      Str: "324 barbados drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anna Maria",
      Str: "298 gulfstream way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "219 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2888,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Laurel Pnt Lk Nona-Mosaic",
      Mo: "Varen",
      Str: "9076 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1340000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "94 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Sandpiper",
      Str: "8795 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Ambassador",
      Mo: "San Tropez",
      Str: "613 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3514,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Shores @Whippoorwill Lake-Esta",
      Mo: "Grenache",
      Str: "12034 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3667,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "203 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4263,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Grenache",
      Str: "12064 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3667,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18516 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "34 revere circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3926,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8777 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4379,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "710000"
    },
    {
      Bid: "90002",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10070 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3330,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "92 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8799 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Coastal Oaks-Legacy",
      Mo: "Cumberland",
      Str: "104 cape may ave",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2125,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2581 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2528 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2548 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Sanderling",
      Str: "2593  ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2568 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Sanderling",
      Str: "2575 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Sanderling Elite",
      Str: "2594 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Sanderling Elite",
      Str: "2564 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2575 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2572 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2587 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2588  cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2560 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2556 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2569 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2583 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2584 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1050000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2557 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2563 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2532 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2579 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2520 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14672 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14728 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14755 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14756 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14847 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypoint Coastal",
      Str: "14818 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14839 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14854 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14588 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14632 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14822 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14621 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14647 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14846 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14628 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14664 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14797 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14636 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14604 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14768 kingfsher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14608 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14676 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14752 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14684 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14747 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14831 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14667 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14600 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14652 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14842 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14668 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14772 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14640 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1500,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14867 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14612 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14740 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2240,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14708 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14835 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14818 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14834 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14624 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14875 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14692 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14680 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14625 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14644 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14851 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14826 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14751 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14629 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14855 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14759 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14648 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14895 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14859 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14704 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14688 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14830 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14807 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14601 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14732 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14815 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14656 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14712 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14819 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14635 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14620 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14748 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14814 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14655 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14850 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14744 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14760 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14605 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14811 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14613 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14736 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14572 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14871 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14643 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14838 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14716 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14843 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14616 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14803 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14810 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14660 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14891 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14863 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "249 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Barkley",
      Str: "96 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "83 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "204 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4773,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "178 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "259 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "76 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "77 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "52 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "224 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4461,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "115 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "167 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3999,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "210 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "63 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "41 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "33 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "320 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "217 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "59 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3821,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "179 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "107 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "61 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3498,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "99 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4833,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "240 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "199 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "850000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "289 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "203 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "68 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "74 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4201,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "32 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "40 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "101 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "172 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "111 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "114 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4333,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "82 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Barkley",
      Str: "26 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "86 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "210 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4333,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "29 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "93 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "157 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "183 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "40 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4074,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "162 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "41 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3445,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "46 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "216 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "137 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "67 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "70 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "75 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "312 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "62 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3483,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "195 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "30 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4310,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "204 hickory ranch dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4159,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "129 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "121 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "69 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3498,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "295 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "248 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "114 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "102 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "47 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "90 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4701,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "313 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "53 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "272 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4315,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "91 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "199 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Canyon",
      Str: "58 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "194 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "159 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4701,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "219 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "191 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "96 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "134 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "323 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "140 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4768,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "105 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "160 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "49 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3744,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "146 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "249 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "57 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4310,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "150 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "42 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "124 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4470,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "56 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4790,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "82 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "115 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "205 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4296,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Canyon",
      Str: "212 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "151 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4387,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "25 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "175 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3896,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "105 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4759,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "34 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "233 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "53 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "117 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5126,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "128 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "54 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4387,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "213 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4767,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "128 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "75 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "304 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "57 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "245 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4831,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1095000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "14 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "35 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "31 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4737,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmore",
      Str: "87 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "58 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "114 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "288 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "26 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "93 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3472,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "24 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "275 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "256 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3999,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "25 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4521,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Delmore",
      Str: "130 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2457,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "230 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "198 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "91 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "161 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5007,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1065000"
    },
    {
      Bid: "90002",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "217 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10127 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4856,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1205000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10528 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4005,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10529 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4542,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10086 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4006,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10499 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10133 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10103 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10564 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4617,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10522 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3896,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10366 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3958,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10558 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10331 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4366,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10091 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10475 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4293,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10085 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10505 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10570 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Edison",
      Str: "10348 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4081,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10469 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4786,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10552 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10323 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4289,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10523 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5024,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "905000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10158 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4252,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10540 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10485 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4167,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10547 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10511 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4789,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10535 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4697,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10541 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10110 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10092 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1010000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10463 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10546 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10073 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4861,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10104 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4248,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10074 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4632,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10256 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4593,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "11289 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4487,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10420 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10176 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4954,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10311 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4526,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10493 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10534 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4828,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10079 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3871,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10111 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4542,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1005000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10119 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4320,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10098 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4459,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "910000"
    },
    {
      Bid: "90002",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10080 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4265,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13026 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12996 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13077 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13042 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13050 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13078 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12980 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12969 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12977 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13010 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12948 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12952 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13009 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13046 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13005 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13049 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13001 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12953 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13065 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12944 pembroke dr",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12985 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13057 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Seagrape",
      Str: "13022 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1905,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12940 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2064,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13025 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12968 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13073 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13053 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13002 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "12976 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13081 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Buttonwood",
      Str: "13058 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13033 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13034 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12973 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13045 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1973,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13061 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13037 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12984 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13013 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13086 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12972 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12965 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13054 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13066 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Buttonwood",
      Str: "12993 pembroke dr",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13030 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12988 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13041 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13082 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12961 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13029 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13062 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12957 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12992 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12997 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Hamilton Place",
      Mo: "Seagrape",
      Str: "12989 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1905,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3947 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3843 corona court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3831 corona court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3975 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Underwood",
      Str: "3653 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2506,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3955 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Bowersett",
      Str: "3943 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "245000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3980 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3983 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Doyal",
      Str: "3619 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Doyal",
      Str: "3968 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3972 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3960 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3847 corona court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3657 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3939 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3605 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3623 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3603 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3631 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3578 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3671 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "971 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3647 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3582 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3397 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3611 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3381 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3385 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3433 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3639 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3396 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3691 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "980 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3421 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "992 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3377 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3635 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3586 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3675 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "975 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3594 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "987 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3429 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3552 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3560 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3389 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3623 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3425 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3405 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "968 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3409 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3393 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3537 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3469 mclain preserve point",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot Craftsman",
      Str: "3586 stonebriar ln",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "976 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "979 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "972 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3417 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3607 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3536 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3615 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3465 mclain preserve point",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "984 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3404 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3590 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3622 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "988 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3627 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3388 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3401 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3606 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3473 mclain preserve point",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "991 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3556 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3179,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3412 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "964 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "967 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3392 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "983 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3702 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "3568 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3691 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3614 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3619 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "10178 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10146 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11409 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2662,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10009 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11361 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2776,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "11355 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2879,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11343 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2986,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10141 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9968 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10086 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10149 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10110 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11433 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3097,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10003 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10092 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3084,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11374 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10040 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11320 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11314 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10062 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3219,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10056 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9962 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11379 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10034 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11445 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10010 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2990,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10152 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11326 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10165 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2859,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10050 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "11412 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2709,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10022 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11439 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10166 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11397 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11405 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11427 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "9956 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2866,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10134 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10107 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9950 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "11385 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10068 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2943,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11331 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10104 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2709,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11380 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3274,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10157 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10185 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9957 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10123 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2805,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10021 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2866,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11417 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10080 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2916,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "9981 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "9951 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2759,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "9980 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11403 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11319 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11406 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10044 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "9945 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "9963 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10046 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10160 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10028 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "9974 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10140 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3197,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10128 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10179 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11337 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11418 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9944 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11350 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10116 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3113,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10172 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2966,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10095 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11424 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10074 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11338 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11423 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3041,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10098 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11325 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "10173 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10122 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2966,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Caladesi",
      Str: "19732 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Ballast",
      Str: "2368 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19752 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19776 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19855 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19896 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Ballast",
      Str: "19858 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "985000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Caladesi",
      Str: "19756 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19850 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19884 tbd tbd",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2332 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19714 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2354 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4650,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19766 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "925000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2340 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19804 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19865 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19722 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19873 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19838 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "775000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19740 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19792 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19892 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19704 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19829 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19868 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1080000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19890 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19746 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "870000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19837 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "720000"
    },
    {
      Bid: "90002",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19883 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11351 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11338 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11398 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11405 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11363 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11390 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11378 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11384 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11367 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11357 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11370 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11358 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11360 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11366 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11382 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11396 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11389 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11380 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11392 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11350 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11393 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11409 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11342 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11371 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11344 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11394 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11403 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11372 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11340 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11369 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11336 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11368 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11356 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11391 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11365 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11354 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11395 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11352 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11412 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11334 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11407 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11355 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11353 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11414 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10053 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9969 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10071 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10005 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10065 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9939 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9951 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10029 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9933 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10017 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9644 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10023 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9963 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9993 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9891 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9999 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9897 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10059 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9927 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9903 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10083 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9909 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9638 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10041 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10077 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10047 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10095 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9981 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10011 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9945 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10035 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9987 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9603 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9921 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9957 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10306 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10414 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3967,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10510 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10247 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10384 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10324 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10445 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "11475 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10415 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10277 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3532,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10288 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11428 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11470 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3725,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10379 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10480 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10486 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "10294 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3445,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10498 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10235 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10312 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3417,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "10241 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10433 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11464 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3177,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10456 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10169 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10402 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10516 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "11476 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "10421 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10378 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11446 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10270 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11434 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11499 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4262,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10403 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10390 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3436,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11487 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "810000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11500 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10468 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3402,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11493 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10439 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10457 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10474 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3595,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11458 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3303,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10264 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10462 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Roseberry Elite",
      Str: "11506 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3418,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10067 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11440 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Roseberry Elite",
      Str: "10391 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3188,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11481 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3199,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10385 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10504 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10427 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10492 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3775,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10408 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10055 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11463 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10397 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "11457 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11469 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3399,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10223 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3310,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10396 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11452 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10451 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10187 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15613 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5909,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1000000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15517 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15522 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Teresina",
      Str: "15619 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3108,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Maranello",
      Str: "15468 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5317,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Bramante",
      Str: "15625 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4798,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Bramante",
      Str: "15631 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4229,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Avanti",
      Str: "15511 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Maranello",
      Str: "15481 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4264,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15669  panther lake drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "710000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15607 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5354,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15612 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15469 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5781,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15523 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5054,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15606 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6245,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "950000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "250 settlers landing dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "419 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "194 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "49 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3007,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "59 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "190 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "50 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "223 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "140 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "184 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2906,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "375 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "425 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "133 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "161 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "176 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "181 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "230 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "147 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "222 settlers landing dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "124 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "62 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "169 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "93 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "311 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "238 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "206 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "265 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "155 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "85 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "395 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "212 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "198 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "56 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "70 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "50 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "45 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "33 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "51 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "27 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "47 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "19 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "29 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "39 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "82 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "64 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "65 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "88 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "32 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "100 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "106 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "34 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "45 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "21 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "35 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "39 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "53 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "42 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "31 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "55 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "54 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "94 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "114 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "74 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "41 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Sabrina",
      Str: "12696 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12704 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Sabrina ",
      Str: "12404 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12488 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12680 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12716 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12428 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12614 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "3 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Sabrina",
      Str: "12538 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2561,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12700 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2900,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12432 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12672 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12712 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2959,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12626 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12561 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3019,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12553 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12720 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12707 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Sabrina ",
      Str: "9373 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12545 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12581 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1000000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12688 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12534 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12541 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12420 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "2 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12657 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2991,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12504 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12656 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12471 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12499 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12508 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12715 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12412 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12475 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12483 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Sabrina",
      Str: "12557 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12692 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "5 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3157,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12480 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12468 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12602 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12668 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12594 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9204 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9146 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9117 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "875000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9113 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9320 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "950000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9225 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9233 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1115000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9350 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "850000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9198 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9261 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1095000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9217 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9257 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "970000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9109 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9240 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1075000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9179 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9374 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9334 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9186 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9265 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9194 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "965000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9346 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Shirley",
      Str: "9178 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3559,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9205 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1015000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9209 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9116 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9248 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1015000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9228 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9170 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "980000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9216 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9108 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1055000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9120 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9232 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9264 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9237 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1170000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9229 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "875000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9292 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9342 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9354 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9300 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9245 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1055000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9221 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9338 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9366 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9362 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9182 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9158 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3802,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15582 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8252 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2662,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15713 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "15731 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8186 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15748 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "8253 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15707 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Robellini",
      Str: "15624 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15618 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8277 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15724 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira",
      Str: "15730 cutter sail pl",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3943,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8246 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8258 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4226,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8331 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15834 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8192 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "15743 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15725 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "15719 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "15655 sylvester palm drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8259 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8157 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8198 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8264 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8247 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4019,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sabel",
      Str: "8818 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2221,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "15738 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15119 canoe place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8234 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4137,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8228 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8180 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15606 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8222 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8806 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4019,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "8289 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8295 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15732 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8216 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15754 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8174 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15718 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8271 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15858 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8830 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15742 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15712 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Robellini",
      Str: "15612 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8265 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15588 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8812 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Robellini",
      Str: "15737 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4334,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "8270 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15130 canoe place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15600 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15535 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15810 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9986 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "10026 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9910 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "10010 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9568 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9998 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9978 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9567 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9585 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Finch",
      Str: "9934 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9819 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9926 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9970 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "10018 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Finch",
      Str: "9918 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9831 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9543 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9837 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9843 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9879 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9825 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9950 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9849 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9586 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9813 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9807 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9555 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Finch",
      Str: "9885 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9873 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9867 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9942 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9861 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9544 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9580 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9956 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9561 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9855 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9962 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9562 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9573 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "673 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "77 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "688 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "90 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "62 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "78 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore Elite",
      Str: "661 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "726 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "105 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "608 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3063,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "65 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "637 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "39 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "630 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "47 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "41 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "52 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "676 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "105 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "611 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "37 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "471 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "53 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2457,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "90 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "664 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "685 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "18 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "134 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore Elite",
      Str: "59 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2920,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "703 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "135 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "124 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "89 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "78 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "652 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "125 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "618 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "698 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "710 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "34 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "640 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "25 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore Elite",
      Str: "73 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "625 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "115 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "562 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "68 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "79 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "718 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "78 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "66 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "649 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "19 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3195,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Arianna",
      Str: "10421 angel oak court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10691 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10335 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10631 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Sebastian",
      Str: "10715 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10522 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Arianna",
      Str: "10397 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10147 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4106,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10571 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10829 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10511 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10329 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2919,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10323 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4018,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10553 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4468,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Sebastian",
      Str: "10709 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10415 angel oak court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Sabel",
      Str: "10324 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10558 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3084,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10498 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10317 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2309,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10480  royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10703 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4528,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10140 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Wynngate",
      Str: "10570 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2827,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10306 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4514,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10492 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2309,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10116 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4468,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10134 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "108 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "577 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "549 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "604 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "112 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "596 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "548 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "580 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling",
      Str: "541 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "612 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "609 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "569 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "136 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling",
      Str: "109 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "564 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "568 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "553 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "573 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "561 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling",
      Str: "601 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "441 baneberry court",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "572 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "589 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "545 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "585 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2473 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2521 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2616 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2576 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2610 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2803 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2640 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2520 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2569 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Jessup",
      Str: "2514 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2572 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2485 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2695 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2736 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2701 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2762 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2491 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2208,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2508 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2545 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2587 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3571,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2599 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2443 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2551 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2431 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2497 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2646 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Jessup",
      Str: "2437 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2491 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2509 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2556 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2467 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2671 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2635 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2611 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2659 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2454 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2653 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2629 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2665 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2658 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2563 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2665 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2809 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2419 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2581 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2461 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2533 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2562 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2575 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2676 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2425 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2593 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2677 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2682 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2634 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2580 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2797 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2761 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2509 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2515 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2539 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2594 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2628 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2647 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2472 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2478 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2604 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2413 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2584 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2617 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2605 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2588 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2568 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2785 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2641 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "65 anthem circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "411 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3779,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "230 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "460 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3700,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry",
      Str: "256 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2608,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "471 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3926,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "501 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "387 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry",
      Str: "69 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "141 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3107,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "195 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4115,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "501 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "31 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "377 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "58 revere circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "43 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "59 revere circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "83 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "178 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "483 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "164 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "471 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "72 anthem circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "64 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "474 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "416 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3657,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "242 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "164 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "324 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3569,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "377 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3779,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12724 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12436 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12458 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12424 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12407 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3019,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12492 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2821,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "McKenna",
      Str: "12471 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Sabrina",
      Str: "12444 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2351,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12415 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12728 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12712 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2959,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12432 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12704 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12431 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12715 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12692 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12483 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12496 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12707 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "McKenna",
      Str: "12491 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Sabrina",
      Str: "12408 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12462 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3051,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12500 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12472 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12495 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12476 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3157,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Avenir A3-Windgate-50's",
      Mo: "McKenna",
      Str: "12423 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9994 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9987 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "9960 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10049 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9965 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9897 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9964 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9998 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Samantha",
      Str: "9917 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10042 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10111 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "10070 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9969 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "9925 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "10006 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "12408 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Joliet",
      Str: "9948 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2391,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "12424 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9949 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Samantha",
      Str: "10058 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "9961 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9901 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10083 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "9913 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10073 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9940 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "10010 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "12412 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9957 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "9956 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms",
      Mo: "Samantha",
      Str: "12404 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "270 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "750 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "65 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "315 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "293 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "55 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "46 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "285 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "804 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "726 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "278 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "782 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "208 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "79 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "80 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "96 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "137 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "718 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "306 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "292 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "322 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "85 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "101 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "298 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "45 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "117 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "774 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "766 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "744 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "185 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "262 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "812 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "734 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "708 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "84 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "100 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "216 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "277 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "314 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "307 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9366 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9213 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3330,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9204 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9120 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9174 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9225 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9171 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9142 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9104 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9167 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9353 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Shirley",
      Str: "9121 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9113 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9154 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9166 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9124 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9378 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9175 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9358 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7369 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1075000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7488 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1755000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7389 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7409 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7430 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4641,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7122 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1670000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2411 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7439 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1435000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "2704 northwest 71st blvd",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4929,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2570 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7502 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5279,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6920 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1315000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2550 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "6930 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1360000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7230 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7310 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1950000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6903 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "2740 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2235000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2755 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7309 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2510 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2540 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7459 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2580 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7321 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4841,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7239 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7420 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3715,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7352 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6950 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2640 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7080 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7609,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1465000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7359 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4594,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "2725 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4704,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2720 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1720000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7322 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Lucia (FL)",
      Str: "6970 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4041,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7312 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4727,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7449 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4537,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7342 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4198,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6940 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6966 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1175000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "2540 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4894,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2500 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "7229 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4740,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "2710 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4795,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1295000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7302 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5002,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6960 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1070000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7329 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7440 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1615000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7440 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3836,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7330 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5400,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1695000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2750 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1100000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2723 northwest 70th blvd",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4642,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7480 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5274,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2010000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Lucia (FL)",
      Str: "6910 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4459,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1590000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7460 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5391,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1040000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7479 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2531 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2520 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7219 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4642,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1285000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7380 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7380,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2220000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2650 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2730 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1610000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2530 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4689,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2780 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2045000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6911 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3925,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2760 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1615000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7360 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5370,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6910 northwest 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "2511 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7450 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4841,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7220 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1540000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "184 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "165 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "296 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "176 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "157 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "290 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "44 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "251 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "105 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "64 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "20 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "20 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "45 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "93 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "204 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "71 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "214 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "44 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "117 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "54 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "165 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "274 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "242 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "201 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "57 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "37 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "59 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "104 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "164 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "203 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "272 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "26 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "36 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "32 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "124 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "175 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "164 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "55 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "131 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "146 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "145 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "186 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "123 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "155 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "131 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "44 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "264 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "63 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "47 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "181 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "84 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "32 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "47 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "45 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "83 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "194 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "54 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "74 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "139 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "29 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "67 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "21 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "31 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "124 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "191 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "2709 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1830000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "2703 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1550000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7405 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7425,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1305000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7503 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1840000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Milano",
      Str: "7506 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1610000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7300 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4929,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7300 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7360 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6964,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1690000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7207 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7342,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1745000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Lago",
      Str: "2703 nw 71st boulevard",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7268,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Cosenza II",
      Str: "2705 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6284,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1755000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "2701 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7480 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5112,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1955000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "2708 nw 71st boulevard",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7420,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "3235000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7420 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7419,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1885000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Lago",
      Str: "7404 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1385000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "2710 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1440000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7510 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6983,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7206 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7473,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "2707 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7320 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7420,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2035000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "7330 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4729,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1660000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "2700 nw 71st boulevard",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1845000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7409 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7496,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1450000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7131 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2385000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7310 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5153,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1750000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Lago",
      Str: "7208 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1700000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7303 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7861,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1800000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7403 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1510000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "2711 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4758,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1355000"
    },
    {
      Bid: "90002",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7402 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6905,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1105000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "130 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "156 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "140 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "311 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "126 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "261 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "240 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "267 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "56 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "120 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "94 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "257 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "190 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "93 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "328 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "306 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "147 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "113 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "171 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "129 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "154 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "214 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "103 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "207 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "168 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "318 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "252 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "80 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "100 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "59 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "71 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "94 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "42 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "110 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "202 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "277 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "180 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "112 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "226 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "317 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "75 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "87 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "76 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "146 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "35 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "23 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "48 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "135 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "101 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "81 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "329 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "68 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "58 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "42 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "151 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Seabrooke",
      Str: "137 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Anastasia",
      Str: "57 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Seabrooke",
      Str: "54 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Seabrooke",
      Str: "74 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Captiva",
      Str: "94 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "404 windley drive",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2843,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "163 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2977,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "66 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "432 windley drive",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "82 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "79 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Captiva",
      Str: "175 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "116 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "204 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "395 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "230 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "106 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "78 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "92 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "105 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "66 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "195 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "480 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "89 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "336 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "106 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "411 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "35 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "201 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "352 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3681,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "42 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "192 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "484 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "62 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "143 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "67 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "136 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "425 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4198,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "300 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4303,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "110 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "156 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "388 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "372 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "88 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "63 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "32 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "50 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "117 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4104,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "49 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4308,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "456 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "242 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "98 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "116 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "95 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "775000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "472 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3686,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "53 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3646,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "490 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "159 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "440 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "115 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "141 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "54 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "194 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3789,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "188 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "46 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "422 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "173 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "92 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3681,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "25 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "111 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "111 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "985000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "254 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "445 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "39 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "73 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "120 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "127 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "129 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "172 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "81 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "105 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "82 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3213,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "156 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "187 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "95 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "268 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "168 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3250,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "284 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3460,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "379 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "77 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "500 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "94 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4308,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "182 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "406 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "318 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3460,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "209 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9138 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9084 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9056 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9224 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Stillwater",
      Str: "9162 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9044 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9175 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9158 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill",
      Str: "9154 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9068 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9016 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9044 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9186 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill Elite",
      Str: "9194 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9060 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9220 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9036 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9179 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9048 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9052 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "670000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9174 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9060 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9072 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9209 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill Elite",
      Str: "9076 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9166 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Stillwater",
      Str: "9170 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9056 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9182 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9084 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9228 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9213 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill",
      Str: "9040 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9040 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9052 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9150 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9204 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9178 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe Elite",
      Str: "9080 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9012 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9048 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9142 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9064 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9068 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill",
      Str: "9088 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9104 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9120 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1060000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena",
      Str: "9089 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9092 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1095000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena",
      Str: "9096 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9081 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1170000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9109 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9121 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9117 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1355000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9097 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "910000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena",
      Str: "9023 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9112 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver",
      Str: "9051 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver",
      Str: "9085 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver",
      Str: "9019 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Piper",
      Str: "9088 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9116 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1030000"
    },
    {
      Bid: "90002",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9060 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "995000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9253 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1060000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9277 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9257 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9113 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4302,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1040000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9089 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2553,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9085 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2456,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1080000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9114 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2486,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9093 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "905000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Stefano",
      Str: "9094 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Stefano",
      Str: "9102 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2960,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9105  montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9261 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2659,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9110 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9098 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1130000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9245 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9109 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9249 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Sabel",
      Str: "9106 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2075,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9265 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9241 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9281 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9090 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2552,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "198 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "74 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "218 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "235 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "111 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "165 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "176 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "182 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "143 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "164 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "150 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "226 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "252 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "179 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "242 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "247 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "193 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "58 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "115 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "89 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "203 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2668,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "170 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "158 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "256 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "149 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "139 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "204 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "185 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "119 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "211 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "212 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "97 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "190 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "248 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "105 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "157 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2682,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "66 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "255 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "217 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "223 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8331 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "17709 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1100000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "17705 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "18220 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4481,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "18236 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "955000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "7928 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8307 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "995000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8409 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1260000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8330 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "915000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell",
      Str: "8448 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1065000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "18208 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper Elite",
      Str: "7948 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "8272 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4600,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1250000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "17713 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "930000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8326 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8417 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8314 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "7924 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "8239 nevis run",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2906,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "785000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "8408 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "8342 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "18228 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1135000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8306 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8437 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1020000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell",
      Str: "8432 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8444 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "945000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8268 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "8451 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8452 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "8252 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8222 nevis run",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1325000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "18212 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1110000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8323 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell",
      Str: "8440 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "8424 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8167 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8063 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8137 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Palacios",
      Str: "8213 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8110 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8134 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "7995 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "965000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena Elite",
      Str: "8170 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "750000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8086 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver Elite",
      Str: "8019 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8205 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8050 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "950000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8118 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena Elite",
      Str: "8114 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8162 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne Elite",
      Str: "7849 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "810000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8042 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8046 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8054 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8066 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne Elite",
      Str: "8098 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8062 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8209 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8078 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8126 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8147 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena Elite",
      Str: "8171 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8094 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "935000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "7837 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8074 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8122 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "17695 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8143 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8082 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8175 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8090 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8154 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Palacios",
      Str: "8018 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena",
      Str: "8070 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "765000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver Elite",
      Str: "8059 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "940000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8195 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8150 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Palacios",
      Str: "8038 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "90002",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8010 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "281 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia",
      Str: "91 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "495 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "460 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3608,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "557 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "331 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2891,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia",
      Str: "549 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Mandigo",
      Str: "436 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva",
      Str: "530 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "470 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "25 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "81 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3290,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "558 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3221,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Mandigo Elite",
      Str: "527 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2612,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "71 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3268,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "517 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "433 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3402,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "424 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia",
      Str: "484 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "353 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3547,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "507 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "416 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3304,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "343 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "539 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4043,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "365 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "68 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "552 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Mandigo",
      Str: "90 pine blossom trail",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "293 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3484,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "520 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "457 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "383 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "168 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "245 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4777,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "385 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "27 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "223 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "399 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4221,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Delmonico",
      Str: "250 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4158,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "374 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4777,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "90 pavati point",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4057,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Delmonico",
      Str: "101 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4375,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Delmonico",
      Str: "93 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4078,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Westbrook",
      Str: "118 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4226,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "234 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "90 pavati point",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4055,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "93 pavati point",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "203 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4660,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Captiva Elite",
      Str: "612 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3992,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "30 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4241,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Captiva Elite",
      Str: "42 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Edgewater",
      Str: "158 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3529,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "703 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Edgewater",
      Str: "186 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3222,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "172 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3194,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Edgewater",
      Str: "132 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "717 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Captiva Elite",
      Str: "303 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3753,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Cassia",
      Str: "729 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "691 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "39 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4113,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "277 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "755 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "792 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "144 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3594,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "741 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3356,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9966 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9975 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9939 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9981 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9978 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "985000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "9944 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2627,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "10031 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9946 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "9986 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9936 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9961 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9985 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9972 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2988,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9968 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9930 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9982 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "985000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9976 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9945 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2740,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9965 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9932 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9935 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9942 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9958 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9943 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9912 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "930000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9970 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9973 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "9980 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9938 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9940 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9908 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9962 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9964 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9934 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "10027 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "553 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "596 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "590 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "585 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "532 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "531 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "610 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "543 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "542 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "544 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "536 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "563 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "1823 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "534 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "541 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "559 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "539 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "555 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "1827 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "632 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "636 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "618 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "594 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "589 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "536 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "616 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "653 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "563 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "1829 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "655 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "537 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "540 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "620 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "538 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "530 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "533 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "587 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "565 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "600 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "612 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "657 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "542 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "540 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "1819 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "1825 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "583 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "592 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "538 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "569 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "598 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "534 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "561 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "567 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "551 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "614 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "532 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "561 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "530 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "557 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "535 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "634 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "131 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "36 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "140 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2977,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "78 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "25 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "217 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Julington",
      Str: "231 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4187,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "98 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "205 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3150,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "73 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "175 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3523,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "88 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "159 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Julington",
      Str: "160 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "22 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3522,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "116 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "105 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3307,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "119 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "191 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2843,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Built Up",
      RS: "Flat",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2062 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2058 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2142 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2054 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2257 valentine aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2154 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2430 addalia aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2383 brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2377brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2353 brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2158 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2066 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2422 addalia aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2146 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2359 brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9016 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8982 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9075 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8893 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9047 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9032 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8905 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8979 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8833 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "9087 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8821 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8829 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9020 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9008 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9083 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9003 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "9020 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9130 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8983 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8958 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9091 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9028 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9024 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9095 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill Elite",
      Str: "9076 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9096 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9024 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8889 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9031 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9004 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9008 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9012 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9088 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8986 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "285000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8971 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9118 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Heron",
      Str: "8943 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Heron",
      Str: "9080 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9122 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8825 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9092 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9036 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9126 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8931 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9146 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9035 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9039 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8991 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9079 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip",
      Str: "76 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "67 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "19 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "108 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "47 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "90 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip-Traditional",
      Str: "76 liberty bell road",
      Ct: "ponte verde",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip",
      Str: "94 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "98 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "84 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "36 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "42 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "31 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "98 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "83 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "39 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2820,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip",
      Str: "59 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "25 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Begonia",
      Str: "53 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "75 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "90 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "118 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "50 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "28 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "57 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "376 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "119 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "15 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "319 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3245,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Mandigo Elite",
      Str: "49 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "445 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3180,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "45 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4024,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "59 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "306 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "388 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2924,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Mandigo",
      Str: "90 pine blossom trail",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "382 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2690,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "162 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "494 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3099,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "573 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "293 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3484,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "408 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "402 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2961,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "215 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2721,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "269 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "131 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "508 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "281 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "383 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "326 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "565 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "549 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "225 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "542 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "469 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "580 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3245,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "572 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3687,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "235 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3066,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "564 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3109,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "71 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3421,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "191 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3359,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "520 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "448 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "68 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "22 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "368 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "37 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "57 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "338 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Mandigo",
      Str: "28 pine blossom trail",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "56 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9268 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2437,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9069 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9248 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9101 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2352,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9260 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9284 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9264 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9297 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9256 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9050 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9273 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9288 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9272 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9070 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2487,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8906 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9066 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2188,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9073 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Sabel",
      Str: "9269 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2075,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "8778 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3780,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1315000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9086 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9296 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Sabel",
      Str: "9078 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2192,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9047 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9063 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9074 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9293 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9059 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9109 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9046 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9077 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9043 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2548,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8969 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8920 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9280 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2352,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "8945 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9292 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9058 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2578,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9055 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9062 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8782 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1190000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8976 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9047 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Kingsdale",
      Str: "9002 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "8995 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "8990 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9046 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Carver Elite",
      Str: "9015 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9006 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Carver",
      Str: "9035 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9027 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9030 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "9105 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "950000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena",
      Str: "9007 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9043 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9067 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9011 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "8986 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9108 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9050 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "9039 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9031 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "9093 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9056 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9071 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8315 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8428 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8264 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "8432 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell Elite",
      Str: "7908 redonda loop",
      Ct: "bradenton",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "17773 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "7944 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3293,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Sandpiper Elite",
      Str: "8429 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8404 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8264 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8322 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3273,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "870000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "8440 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell Elite",
      Str: "18216 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8276 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Sandpiper Elite",
      Str: "8335 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "17729 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8248 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8346 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "8436 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "17769 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Fullerton",
      Str: "8424 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "860000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Sandpiper",
      Str: "8338 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "17717 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8337 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8417 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3912,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "17737 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "18220 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "17721 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8416 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8443 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8267 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8412 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8437 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1015000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8408 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "895000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "17725 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8252 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8323 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "7841 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "7853 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "7861 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver Elite",
      Str: "8221 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver Elite",
      Str: "8376 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17625 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17679 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17629 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "7832 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8187 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8142 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "8350 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8149 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Biscayne Elite",
      Str: "7849 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "8078 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "8130 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Kingsdale",
      Str: "8217 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "7836 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "7865 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17626 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8354 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "8134 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8166 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Palacios",
      Str: "8213 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "7856 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Biscayne",
      Str: "8158 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17642 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena",
      Str: "17661 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "7845 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Kingsdale",
      Str: "17621 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3199,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver Elite",
      Str: "17696 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "17671 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8114 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8146 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8082 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8138 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8090 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "8191 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Biscayne",
      Str: "8066 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Palacios",
      Str: "7857 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2280,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17638 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "768 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "128 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "717 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "389 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "281 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "653 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "281 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3821,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "104 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3223,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "80 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "116 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "462 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "142 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "741 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3356,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "448 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2869,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "142 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "90 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4435,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "263 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "384 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3854,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "653 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "462 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "778 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "186 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "341 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "700 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "329 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "118 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "532 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3407,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "54 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "679 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "90 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4437,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "364  lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4134,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "315 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "102 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "364  lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4132,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "485 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4158,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "118 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "361 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3569,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "593 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "605 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "289 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "463 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3828,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "353 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3974,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "665 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2990,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "250 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "600 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "328  lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "508 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "674 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "116 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "349 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3783,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "341 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Joliet",
      Str: "9973 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2378,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9937 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "McKenna",
      Str: "9941 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9994 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Joliet",
      Str: "12420 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2388,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "McKenna",
      Str: "10006 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "McKenna",
      Str: "9945 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9905 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Samantha",
      Str: "9909 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Jesalin",
      Str: "9953 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Jesalin",
      Str: "9929 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9901 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Samantha",
      Str: "9917 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Jesalin",
      Str: "9921 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Salerno",
      Str: "9986 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Salerno",
      Str: "9916 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Isabella",
      Str: "9954 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Lewiston",
      Str: "9942 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Lewiston",
      Str: "9962 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Isabella",
      Str: "9940 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Isabella",
      Str: "9925 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9957 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2740,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9974 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9926 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Shelby",
      Str: "9950 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Salerno",
      Str: "9977 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9922 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "90002",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9900 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1823 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1832 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1826 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "550 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1830 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1824 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1828 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1837 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "556 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "574 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1835 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "563 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "577 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "579 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "553 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1831 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1835 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "562 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1825 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "534 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "557 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "558 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "573 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "551 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1857 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "576 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1867 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1820 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1827 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1844 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1822 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "552 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1855 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1836 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "582 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "578 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1831 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1821 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "540 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "571 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "532 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1837 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "550 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1829 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "575 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1833 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1829 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1853 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "570 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "558 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "580 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1840 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1845 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1865 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1827 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1851 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "572 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "554 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "556 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1839 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1861 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1859 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1847 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "554 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "552 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1819 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1863 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1838 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1842 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "90002",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "560 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "560 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "613 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "124 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "616 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "592 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "584 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Starling",
      Str: "116 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2160,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "617 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "576 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora Elite",
      Str: "608 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "540 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "556 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "552 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "101 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora Elite",
      Str: "593 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora Elite",
      Str: "128 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "557 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling Elite",
      Str: "605 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "105 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "588 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "120 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "581 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "90002",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "597 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    }
  ],
  id: "4407afb9-c0ea-45b1-b66c-80e7db2d8a5d",
  _rid: "XOFNAI1IcgUHAQAAAAAAAA==",
  _self: "dbs/XOFNAA==/colls/XOFNAI1IcgU=/docs/XOFNAI1IcgUHAQAAAAAAAA==/",
  _etag: '"b017fc51-0000-0100-0000-676d3f100000"',
  _attachments: "attachments/",
  _ts: 1735212816
}
