export const communityMasterUAT = {
  Client: "EMBARK",
  Table: "CommunityModelAddress",
  Rows: [
    {
      Bid: "6698",
      Co: "Addison Landing - D50",
      "Community ID": 1000,
      Mo: "Cascades",
      "Model ID": 200,
      Str: "902 honey petal lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6698",
      Co: "Bonavie Cove Villas",
      "Community ID": 1001,
      Mo: "Largo",
      "Model ID": 201,
      Str: "10350 bonavie cove drive",
      Ct: "fort myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6698",
      Co: "Celebration - R40",
      "Community ID": 1002,
      Mo: "Hayden",
      "Model ID": 202,
      Str: "7410 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6698",
      Co: "Celebration - T24",
      "Community ID": 1003,
      Mo: "Anna Maria",
      "Model ID": 203,
      Str: "1833 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6698",
      Co: "Citron Grove 18' TH",
      "Community ID": 1004,
      Mo: "Marianna",
      "Model ID": 204,
      Str: "8516 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6698",
      Co: "Emery",
      "Community ID": 1005,
      Mo: "Aspen",
      "Model ID": 205,
      Str: "10729 sw estella ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6698",
      Co: "Emery",
      "Community ID": 1006,
      Mo: "Briar",
      "Model ID": 206,
      Str: "12640 eleanor drive",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6698",
      Co: "Halo at Emery 50's",
      "Community ID": 1007,
      Mo: "ROWAN",
      "Model ID": 207,
      Str: "12816 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6698",
      Co: "La Paloma Preserve 30' TH",
      "Community ID": 1008,
      Mo: "Anclote",
      "Model ID": 208,
      Str: "3735 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6698",
      Co: "La Paloma Preserve 30' TH",
      "Community ID": 1009,
      Mo: "Topsail",
      "Model ID": 209,
      Str: "3753 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6698",
      Co: "Lake County",
      "Community ID": 1010,
      Mo: "Redwood",
      "Model ID": 210,
      Str: "2981 amber sweet pl",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6698",
      Co: "Meridian Parks - R21",
      "Community ID": 1011,
      Mo: "Martina",
      "Model ID": 211,
      Str: "11716 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6698",
      Co: "Meridian Parks - R40",
      "Community ID": 1012,
      Mo: "Fremont",
      "Model ID": 212,
      Str: "11660 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6698",
      Co: "Meridian Parks - R40",
      "Community ID": 1013,
      Mo: "Newbury",
      "Model ID": 213,
      Str: "11747 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6698",
      Co: "Parkview at Long Lake 18' TH",
      "Community ID": 1014,
      Mo: "Marianna",
      "Model ID": 214,
      Str: "2599 everglade way",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6698",
      Co: "RiverTown Arbors 45s",
      "Community ID": 1015,
      Mo: "Mallard",
      "Model ID": 215,
      Str: "237 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2302,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6698",
      Co: "RiverTown Arbors 45s",
      "Community ID": 1016,
      Mo: "Vireo",
      "Model ID": 216,
      Str: "23 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6698",
      Co: "RiverTown Arbors 45s",
      "Community ID": 1017,
      Mo: "Egret",
      "Model ID": 217,
      Str: "215 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1869,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6698",
      Co: "RiverTown Arbors 45s",
      "Community ID": 1018,
      Mo: "Sandhill",
      "Model ID": 218,
      Str: "10 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6698",
      Co: "RiverTown Gardens North RL 50s",
      "Community ID": 1019,
      Mo: "Beecher",
      "Model ID": 219,
      Str: "892 orange branch trail",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1859,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6698",
      Co: "RiverTown The Haven 40s",
      "Community ID": 1020,
      Mo: "Barron",
      "Model ID": 220,
      Str: "226 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6698",
      Co: "RiverTown The Haven 40s",
      "Community ID": 1021,
      Mo: "Sebastian",
      "Model ID": 221,
      Str: "143 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6698",
      Co: "RiverTown The Haven 60s",
      "Community ID": 1022,
      Mo: "Braden",
      "Model ID": 222,
      Str: "122 sydney cove",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6698",
      Co: "Saddlewood Workforce Housing",
      "Community ID": 1023,
      Mo: "Plan B",
      "Model ID": 223,
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 917,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "6698",
      Co: "Saddlewood Workforce Housing",
      "Community ID": 1024,
      Mo: "Plan D",
      "Model ID": 224,
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 968,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "6698",
      Co: "Serenity at Telaro 35'",
      "Community ID": 1025,
      Mo: "Felicity",
      "Model ID": 225,
      Str: "11535 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6698",
      Co: "Solara - D40",
      "Community ID": 1026,
      Mo: "Malibu II",
      "Model ID": 226,
      Str: "8927 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6698",
      Co: "Solara - D40",
      "Community ID": 1027,
      Mo: "Laguna II",
      "Model ID": 227,
      Str: "8913 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6698",
      Co: "Soleil - D50",
      "Community ID": 1028,
      Mo: "Shenandoah",
      "Model ID": 228,
      Str: "1042 boardwalk place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6698",
      Co: "Soleil - D50",
      "Community ID": 1029,
      Mo: "Glades",
      "Model ID": 229,
      Str: "1189 lakeshore breeze place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6698",
      Co: "Spring Isle - T20",
      "Community ID": 1030,
      Mo: "Captiva",
      "Model ID": 230,
      Str: "865 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6698",
      Co: "Spring Isle - T20",
      "Community ID": 1031,
      Mo: "Venice",
      "Model ID": 231,
      Str: "741 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6698",
      Co: "Spring Isle - T20",
      "Community ID": 1032,
      Mo: "Capri",
      "Model ID": 232,
      Str: "1082 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6698",
      Co: "Spring Isle - T20",
      "Community ID": 1033,
      Mo: "Florence",
      "Model ID": 233,
      Str: "811 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6698",
      Co: "Sunglow at Emery 35's",
      "Community ID": 1034,
      Mo: "ASPEN",
      "Model ID": 234,
      Str: "10724 sw gloriana st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6698",
      Co: "Sunrise JV -  60's",
      "Community ID": 1035,
      Mo: "Jubilee",
      "Model ID": 235,
      Str: "5783 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6698",
      Co: "Sunstone at Wellen Park 30' TH",
      "Community ID": 1036,
      Mo: "Topsail",
      "Model ID": 236,
      Str: "12497 somatic ct",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6698",
      Co: "Sunstone at Wellen Park 50's",
      "Community ID": 1037,
      Mo: "Pinnacle",
      "Model ID": 237,
      Str: "18081 grand prosperity dr",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6698",
      Co: "TestCommunity2",
      "Community ID": 1038,
      Mo: "TestModel",
      "Model ID": 238,
      Str: "27 ocala street",
      Ct: "orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6698",
      Co: "Tradition -Emery",
      "Community ID": 1039,
      Mo: "Briar Coastal",
      "Model ID": 239,
      Str: "12792 sw eleanor dr",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6698",
      Co: "Tradition-Emery",
      "Community ID": 1040,
      Mo: "Yarrow",
      "Model ID": 240,
      Str: "10700 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2279,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6698",
      Co: "Tranquility at Telaro 50'",
      "Community ID": 1041,
      Mo: "Haven",
      "Model ID": 241,
      Str: "12023 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6698",
      Co: "Volanti 24' TH",
      "Community ID": 1042,
      Mo: "Venice",
      "Model ID": 242,
      Str: "4221 cloud hopper way",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6698",
      Co: "Waterbrooke - D50",
      "Community ID": 1043,
      Mo: "Redwood",
      "Model ID": 243,
      Str: "2109 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6698",
      Co: "Waterbrooke - D50",
      "Community ID": 1044,
      Mo: "Glades",
      "Model ID": 244,
      Str: "2214 satin leaf street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6698",
      Co: "Waterbrooke - S35",
      "Community ID": 1045,
      Mo: "Tuscany",
      "Model ID": 245,
      Str: "3296 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6698",
      Co: "Wells Creek 40s",
      "Community ID": 1046,
      Mo: "Sebastian",
      "Model ID": 246,
      Str: "13124 holsinger boulevard",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6698",
      Co: "Wells Creek TH 20s",
      "Community ID": 1047,
      Mo: "Cove",
      "Model ID": 247,
      Str: "11972 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Anna Maria",
      Str: "110 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "402 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trial",
      Mo: "Willet",
      Str: "2544 cobalt shores ln",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3395,
      Sto: "2",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate ",
      Mo: "Massiano",
      Str: "8914 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8791 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Roseberry",
      Str: "28 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Julington",
      Str: "591 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Captiva Elite",
      Str: "310 gulf stream way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anastasia Elite",
      Str: "286 barbados drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3292,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Stonebrook",
      Str: "84 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2951,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Greenwood",
      Str: "126 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3031,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "749 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "695 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore - Executive Collection",
      Mo: "Madeira",
      Str: "8228 topsail pl",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9668 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1120000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Dolcetto",
      Str: "9226 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Barbera",
      Str: "9636 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1210000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17110 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "1360000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Abington",
      Str: "17111 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4910,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14850 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "15070 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7578,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "2240000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10111 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2517,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10062 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "FLat",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Parkland Golf & CC-Monogr 65'",
      Mo: "Anzi",
      Str: "10373 sweet bay manor",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4465,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Delmore Elite",
      Str: "224 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "311 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "50 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir - Palms Collection",
      Mo: "Joliet",
      Str: "9948 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17135 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7239,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper at Deerfield Beach Community",
      Mo: "Conquina",
      Str: "1853 spoonbill ct",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "Coquina",
      Str: "1853 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "317 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Rowen",
      Str: "388 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2931,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Knollwood",
      Str: "571 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12016 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1650000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Varen",
      Str: "12051 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2645000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18527 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18535 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "FLora",
      Str: "12787 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling Elite",
      Str: "12815 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Pelican",
      Str: "12829 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "18635 midsummer place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6894 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1480000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Santangelo II",
      Str: "6925 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1030000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6924 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1185000"
    },
    {
      Bid: "6703",
      Co: "TB at Bella Collina-Vista",
      Mo: "Cabris",
      Str: "15954 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1500000"
    },
    {
      Bid: "6703",
      Co: "The Isles at Lakewood Ranch",
      Mo: "Avery Elite",
      Str: "8130 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Glen",
      Str: "3131 vanguard court",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4092,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Larue",
      Str: "6354 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2816,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Westhaven at Ovation-Singles",
      Mo: "Grenada",
      Str: "0117 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3237,
      Sto: "2",
      Be: "2",
      Ba: "1",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model A",
      Str: "1651 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model B",
      Str: "1652 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34748,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model C",
      Str: "1653 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34749,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3229,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model D",
      Str: "1654 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34750,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model E",
      Str: "1655 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34751,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model F",
      Str: "1656 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34752,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2118,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model G",
      Str: "1657 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34753,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6705",
      Co: "Dreamland",
      Mo: "Model H",
      Str: "1658 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34754,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Plan I",
      Str: "2012 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Plan II",
      Str: "2013 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2778,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Plan III",
      Str: "2014 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "205000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model J",
      Str: "2015 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2475,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model K",
      Str: "2016 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "235000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model M",
      Str: "2017 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32717,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model O",
      Str: "2018 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32718,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "310000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model P",
      Str: "2019 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32719,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "285000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model Q",
      Str: "2020 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1612,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "285000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model S",
      Str: "2021 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32721,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "340000"
    },
    {
      Bid: "6705",
      Co: "Coastal Cove",
      Mo: "Model T",
      Str: "2022 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32722,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "795000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Plan A",
      Str: "12120 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gambrel",
      CovA: "245000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Plan B",
      Str: "12121 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33413,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Plan D",
      Str: "12122 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33414,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Plan E",
      Str: "12123 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33415,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Plan F",
      Str: "12124 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33416,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Plan G",
      Str: "12125 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33417,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Model 1",
      Str: "12126 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33418,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Model 2",
      Str: "12127 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33419,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Model 3",
      Str: "12128 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33420,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6705",
      Co: "Beachside Village",
      Mo: "Model 4",
      Str: "12129 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33421,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6701",
      Co: "Aspire at East Lake",
      Mo: "Stetson ",
      Str: "2961 se union park drive",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Hawks Ridge",
      Mo: "Fullerton V",
      Str: "668 ne waters edge ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "113000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "1117 sw kapok ave",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4633 sw savona blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Emerald",
      Str: "5218 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5161 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Lelia",
      Str: "5109 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Wheatley",
      Str: "8480 nw 39th ct",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3345,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "495000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando",
      Mo: "Sandpiper",
      Str: "7644 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "280000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7718 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2129,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7720 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2432,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "2006 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "121000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1610 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Cerelia",
      Str: "9019 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "8956 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons of Orlando",
      Mo: "St Lucia",
      Str: "2051 flora pass place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1967,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons Orlando",
      Mo: "Emerie",
      Str: "1908 flora pass way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Flora",
      Str: "8874 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8848 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "690000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Azure",
      Str: "8985 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 60s",
      Mo: "Flora",
      Str: "9312 cantal circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 60s",
      Mo: "Cerelia",
      Str: "11909 cantal circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gambrel",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "12155 bastille circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "815000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Oakton",
      Str: "12324 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3467,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada",
      Str: "12107 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada II",
      Str: "12285 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "825000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12112 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada II EXTRA SUITE",
      Str: "12127 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "935000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Dupont",
      Str: "6774 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Ivanhoe",
      Str: "36838 precita terrace",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gambrel",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Ocoee Landings",
      Mo: "San Andres",
      Str: "1896 ibis bay court",
      Ct: "ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3000,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Tessa",
      Str: "720 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "616 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Suncrest",
      Str: "731 firerock lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "638 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "630 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Valencia II",
      Str: "2337 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2223 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1991,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2024 raulerson court",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "250 preserve trail north",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "248 preserve trail north",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "224 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "400000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Xavier",
      Str: "10551 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 32s",
      Mo: "Suncrest",
      Str: "16001 micelli drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 32s",
      Mo: "Tessa",
      Str: "15943 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Architectural Shingles",
      RS: "Gambrel",
      CovA: "410000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 50s",
      Mo: "Tobago",
      Str: "10663 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 50s",
      Mo: "Grayson",
      Str: "10508 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Clay Tile",
      RS: "Gambrel",
      CovA: "550000"
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Garden",
      "Community ID": 1000,
      Mo: "Candlewood",
      "Model ID": 200,
      Str: " Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 270000
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Garden",
      "Community ID": 1000,
      Mo: "Daylen",
      "Model ID": 200,
      Str: " Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Garden",
      "Community ID": 1000,
      Mo: "Beachwood",
      "Model ID": 200,
      Str: " Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 294000
    },
    {
      Bid: "6772",
      Co: "Normandy Pines",
      "Community ID": 1000,
      Mo: "Landmark",
      "Model ID": 200,
      Str: "10122 Deep Pine Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1526,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 270000
    },
    {
      Bid: "6772",
      Co: "Normandy Pines",
      "Community ID": 1000,
      Mo: "Charter",
      "Model ID": 200,
      Str: "10122 Deep Pine Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 280000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 50s",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 397000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 40s",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 569000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 50s",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 636000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 50s",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 642000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 40s",
      "Community ID": 1000,
      Mo: "Westland",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 716000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 70s",
      "Community ID": 1000,
      Mo: "Berkley",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 752000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 50s",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 784000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 60s",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 1034000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 60s",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 640000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 60s",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 555000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 40s",
      "Community ID": 1000,
      Mo: "Seaport",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2068,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 438000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 70s",
      "Community ID": 1000,
      Mo: "Monroe",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 752000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 70s",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 808000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 70s",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4214,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 927000
    },
    {
      Bid: "6771",
      Co: "Parkview Reserve - 70s",
      "Community ID": 1000,
      Mo: "Berkley Grand",
      "Model ID": 200,
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 930000
    },
    {
      Bid: "6771",
      Co: "Ventana Pointe",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Ventana Pointe",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Ventana Pointe",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 523000
    },
    {
      Bid: "6771",
      Co: "Ventana Pointe",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 579000
    },
    {
      Bid: "6771",
      Co: "Ventana Pointe",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 598000
    },
    {
      Bid: "6772",
      Co: "Estates of Lake Florence",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "1067 Michelangelo Lane",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 305000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 40'",
      "Community ID": 1000,
      Mo: "Juniper",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 408000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 40'",
      "Community ID": 1000,
      Mo: "Monterey",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 453000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 60'",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 460000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 40'",
      "Community ID": 1000,
      Mo: "Windsor",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2627,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 526000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 60'",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 537000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 50'",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 574000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 50'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 579000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 50'",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 624000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 60'",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 644000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 50'",
      "Community ID": 1000,
      Mo: "Valencia",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4342,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 656000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 60'",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 731000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 60'",
      "Community ID": 1000,
      Mo: "Upton",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 751000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona 60'",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 798000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona TH",
      "Community ID": 1000,
      Mo: "Foxtail",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Isles of Lake Nona TH",
      "Community ID": 1000,
      Mo: "Trailwood",
      "Model ID": 200,
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Classic",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2095,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 419000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Estate",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 454000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Estate",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Classic",
      "Community ID": 1000,
      Mo: "Fifth Avenue",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 505000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Estate",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 534000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Estate",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 562000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Estate",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 579000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Estate",
      "Community ID": 1000,
      Mo: "Millstone",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3427,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 686000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Classic",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2019,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 404000
    },
    {
      Bid: "6771",
      Co: "Avondale at Avenir- Classic",
      "Community ID": 1000,
      Mo: "Riverwalk",
      "Model ID": 200,
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 536000
    },
    {
      Bid: "6771",
      Co: "Parkview at Hillcrest - Executive Series",
      "Community ID": 1000,
      Mo: "Brookstream",
      "Model ID": 200,
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1906,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 507000
    },
    {
      Bid: "6771",
      Co: "Parkview at Hillcrest - Executive Series",
      "Community ID": 1000,
      Mo: "Raritan",
      "Model ID": 200,
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 504000
    },
    {
      Bid: "6771",
      Co: "Parkview at Hillcrest 45'",
      "Community ID": 1000,
      Mo: "Orleans",
      "Model ID": 200,
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1825,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 447000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 50'",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 347000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 50'",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 358000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 40'",
      "Community ID": 1000,
      Mo: "McNair",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 50'",
      "Community ID": 1000,
      Mo: "Seabrook",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 374000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 50'",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 384000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 40'",
      "Community ID": 1000,
      Mo: "Tybee",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 50'",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6772",
      Co: "Isles at BayView 40'",
      "Community ID": 1000,
      Mo: "Daniel",
      "Model ID": 200,
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 338000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Garden",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Garden",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 380000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Estate",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 550000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Classic",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 447000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Garden",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Classic",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 395000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Classic",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 480000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Villas",
      "Community ID": 1000,
      Mo: "Colton",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1322,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 186000
    },
    {
      Bid: "6771",
      Co: "Costa Pointe Estate",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 530000
    },
    {
      Bid: "6771",
      Co: "Addison Square",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 259000
    },
    {
      Bid: "6771",
      Co: "Addison Square",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Addison Square",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Addison Square",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Addison Square",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 233000
    },
    {
      Bid: "6771",
      Co: "Addison Square",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Classic",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 495000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Garden",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 269000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Garden",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 281000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Garden",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 346000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Classic",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 467000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Estate",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 473000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Estate",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2710,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 488000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Estate",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 568000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Classic",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 362000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Classic",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 372000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Classic",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 375000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Estate",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 398000
    },
    {
      Bid: "6773",
      Co: "Del Webb E Town Estate",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 436000
    },
    {
      Bid: "6771",
      Co: "Esplanade Lake Club",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "11661 Venetian Lagoon Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 316000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Signature",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 351000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Signature",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 359000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Signature",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 374000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Signature",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 421000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Signature",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 491000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Cedarbrook Elite",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Lakespur at Wellen Park",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 234000
    },
    {
      Bid: "6771",
      Co: "Lakespur at Wellen Park",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 273000
    },
    {
      Bid: "6771",
      Co: "Lakespur at Wellen Park",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 292000
    },
    {
      Bid: "6771",
      Co: "Lakespur at Wellen Park",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Lakespur at Wellen Park",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Lakespur at Wellen Park",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Belle Haven TH",
      "Community ID": 1000,
      Mo: "Marigold",
      "Model ID": 200,
      Str: "1234 Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 294000
    },
    {
      Bid: "6771",
      Co: "Belle Haven TH",
      "Community ID": 1000,
      Mo: "Springdale",
      "Model ID": 200,
      Str: "1234 Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 279000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Villa",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 222000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 234000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 310000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Ecehlon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 318000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Ecehlon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 368000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Ecehlon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 374000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grande",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Distinctive",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Ecehlon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 415000
    },
    {
      Bid: "6771",
      Co: "Babcock TerraWalk Ecehlon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Garden",
      "Community ID": 1000,
      Mo: "Henley",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 347000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Marina",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 296000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Canopy",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1972,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 306000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Driftwood",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Citrus Grove",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 397000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Garden",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 411000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 418000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Sandhill",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 445000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Estates",
      "Community ID": 1000,
      Mo: "Palm",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 475000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Estates",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 487000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Estates",
      "Community ID": 1000,
      Mo: "Dockside",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 530000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Garden",
      "Community ID": 1000,
      Mo: "Crestwood",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 287000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Garden",
      "Community ID": 1000,
      Mo: "Tropic",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1565,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Estates",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 459000
    },
    {
      Bid: "6771",
      Co: "Heron Preserve Classic",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 332000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Bay",
      "Community ID": 1000,
      Mo: "Daniel",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 307000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Heritage",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Bay",
      "Community ID": 1000,
      Mo: "Hamden",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 328000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Heritage",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Bay",
      "Community ID": 1000,
      Mo: "McNair",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 347000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Heritage",
      "Community ID": 1000,
      Mo: "Seabrook",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 360000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Heritage",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 360000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Bay",
      "Community ID": 1000,
      Mo: "Tybee",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 365000
    },
    {
      Bid: "6772",
      Co: "Oakfield Lakes Heritage",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Garden",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 268000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Garden",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 274000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Garden",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 351000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Garden",
      "Community ID": 1000,
      Mo: "Compass Grand",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1984,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 356000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 362000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 372000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 375000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 384000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Estate",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 438000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 440000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Estate",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 447000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 468000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Estate",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 473000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Classic",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 319000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Estate",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 571000
    },
    {
      Bid: "6773",
      Co: "Del Webb Wildlight Villa",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "128 Eureka Court",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1529,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 310000
    },
    {
      Bid: "6773",
      Co: "DW @ Traditions Villas",
      "Community ID": 1000,
      Mo: "Cressida",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 316000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 52'",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 334000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 52'",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 52'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 417000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 66'",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 452000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 66'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: 497000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 66'",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: 534000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 66'",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: 562000
    },
    {
      Bid: "6773",
      Co: "DW @ Traditions Villas",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 316000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 52'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6773",
      Co: "DW @ Tradition 52'",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 334000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 534000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 537000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 562000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 562000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Echelon",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 645000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Del Webb Oasis Villas",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 316000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 537000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 542000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 570000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 655000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 281000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 294000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 334000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 390000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 456000
    },
    {
      Bid: "6773",
      Co: "Del Webb Minneola Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 533000
    },
    {
      Bid: "6772",
      Co: "Sawgrass at Coral Lakes",
      "Community ID": 1000,
      Mo: "Ivy",
      "Model ID": 200,
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 240000
    },
    {
      Bid: "6772",
      Co: "Sawgrass at Coral Lakes",
      "Community ID": 1000,
      Mo: "Alder",
      "Model ID": 200,
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 266000
    },
    {
      Bid: "6772",
      Co: "Sawgrass at Coral Lakes",
      "Community ID": 1000,
      Mo: "Rowan",
      "Model ID": 200,
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 281000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 421000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 439000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Hawks Reserve",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 729000
    },
    {
      Bid: "6771",
      Co: "Hawks Grove",
      "Community ID": 1000,
      Mo: "Monroe",
      "Model ID": 200,
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 515000
    },
    {
      Bid: "6771",
      Co: "Hawks Grove",
      "Community ID": 1000,
      Mo: "Mahogany Grand",
      "Model ID": 200,
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3129,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 530000
    },
    {
      Bid: "6771",
      Co: "Hawks Grove",
      "Community ID": 1000,
      Mo: "Merlot Grand",
      "Model ID": 200,
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3964,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 572000
    },
    {
      Bid: "6771",
      Co: "Hawks Grove",
      "Community ID": 1000,
      Mo: "Mahogany",
      "Model ID": 200,
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 476000
    },
    {
      Bid: "6771",
      Co: "Hawks Grove",
      "Community ID": 1000,
      Mo: "Merlot",
      "Model ID": 200,
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2635,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 493000
    },
    {
      Bid: "6771",
      Co: "Hawks Grove",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 600000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Signature",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 393000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Signature",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 399000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Signature",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 415000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Signature",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 439000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 466000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Signature",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 475000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Signature",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 484000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 520000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 545000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 547000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Hammock Crest Elite",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Ardena",
      "Community ID": 1000,
      Mo: "Aventura",
      "Model ID": 200,
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 532000
    },
    {
      Bid: "6771",
      Co: "Ardena",
      "Community ID": 1000,
      Mo: "Regalia",
      "Model ID": 200,
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2713,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 543000
    },
    {
      Bid: "6771",
      Co: "Ardena",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 604000
    },
    {
      Bid: "6771",
      Co: "Ardena",
      "Community ID": 1000,
      Mo: "Madeira",
      "Model ID": 200,
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 605000
    },
    {
      Bid: "6771",
      Co: "Ardena",
      "Community ID": 1000,
      Mo: "Sanibel",
      "Model ID": 200,
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 877000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      "Community ID": 1000,
      Mo: "Crestwood",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 287000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 316000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 332000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 425000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 442000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 460000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 537000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 549000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 581000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 635000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 701000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      "Community ID": 1000,
      Mo: "Upton",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 884000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      "Community ID": 1000,
      Mo: "Easley Grande",
      "Model ID": 200,
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club Villas",
      "Community ID": 1000,
      Mo: "Kendrick",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 302000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club Villas",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 316000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Martin Ray",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1968,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Palm",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 453000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Dockside",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 455000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 499000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 55'",
      "Community ID": 1000,
      Mo: "Abbeyville",
      "Model ID": 200,
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1671,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 335000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Classic",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Classic",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 565000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Classic",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 677000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Classic",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 315000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Classic",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Classic",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 541000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 281000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 294000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 315000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 318000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 340000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 343000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 363000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 385000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 341000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Echelon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 450000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Echelon",
      "Community ID": 1000,
      Mo: "Layton Grande",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 556000
    },
    {
      Bid: "6773",
      Co: "Oak Creek- Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 422000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Classic",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 314000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Estate",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 380000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Estate",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 443000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Estate",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 669000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Classic",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 507000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Classic",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 594000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch Estate",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 748000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Estate",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Estate",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 470000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Estate",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 532000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Estate",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 692000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Estate",
      "Community ID": 1000,
      Mo: "Monroe",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 509000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Benton",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 295000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Camden",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 323000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Haddock",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Jasmine",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Elliot",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2344,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 350000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Mabel",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 365000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Schooner",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2565,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 385000
    },
    {
      Bid: "6771",
      Co: "Amelia Groves - Bungalow",
      "Community ID": 1000,
      Mo: "Talbot",
      "Model ID": 200,
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Ocean Cove",
      "Community ID": 1000,
      Mo: "Grayton",
      "Model ID": 200,
      Str: "1822 SE Ocean Cove Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34996,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 328000
    },
    {
      Bid: "6771",
      Co: "Ocean Cove",
      "Community ID": 1000,
      Mo: "Seacrest",
      "Model ID": 200,
      Str: "1822 SE Ocean Cove Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34996,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1814,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 363000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 332000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 344000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Executive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 413000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Executive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 422000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Executive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 434000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Scenic",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 444000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Executive",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 524000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Executive",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 567000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 496000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 505000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 522000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Layton Grande",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "1",
      Be: "4",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 556000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 705000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Upton",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 751000
    },
    {
      Bid: "6771",
      Co: "Verdana Village Echelon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "4",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 540000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 197000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 215000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 234000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 273000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 275000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 292000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 348000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 374000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 394000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 318000
    },
    {
      Bid: "6771",
      Co: "Arbor Oaks Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 232000
    },
    {
      Bid: "6771",
      Co: "Wild Blue 66'",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 417000
    },
    {
      Bid: "6771",
      Co: "Wild Blue 66'",
      "Community ID": 1000,
      Mo: "Stonewater w/ Loft",
      "Model ID": 200,
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 537000
    },
    {
      Bid: "6771",
      Co: "Wild Blue 66'",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 611000
    },
    {
      Bid: "6771",
      Co: "Wild Blue 66'",
      "Community ID": 1000,
      Mo: "Tangerly Oak",
      "Model ID": 200,
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 611000
    },
    {
      Bid: "6771",
      Co: "Wild Blue",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 514000
    },
    {
      Bid: "6771",
      Co: "Wild Blue",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 500000
    },
    {
      Bid: "6771",
      Co: "Wild Blue",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 611000
    },
    {
      Bid: "6771",
      Co: "Wild Blue",
      "Community ID": 1000,
      Mo: "Tangerly Oak",
      "Model ID": 200,
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 611000
    },
    {
      Bid: "6771",
      Co: "Wild Blue 52'",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 500000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3284,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 460000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1.5",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 505000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 525000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Berkley",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 555000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Berkley Grand",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 593000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 609000
    },
    {
      Bid: "6771",
      Co: "Sunset Preserve",
      "Community ID": 1000,
      Mo: "Upton",
      "Model ID": 200,
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5336,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 748000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 40'",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 281000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 40'",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 295000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 40'",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 50'",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 389000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 50'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 40'",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 468000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 50'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 541000
    },
    {
      Bid: "6771",
      Co: "Manatee Cove 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Corkscrew Estates",
      "Community ID": 1000,
      Mo: "Aventura",
      "Model ID": 200,
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Corkscrew Estates",
      "Community ID": 1000,
      Mo: "Regalia",
      "Model ID": 200,
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2713,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 611000
    },
    {
      Bid: "6771",
      Co: "Corkscrew Estates",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "5",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 680000
    },
    {
      Bid: "6771",
      Co: "Corkscrew Estates",
      "Community ID": 1000,
      Mo: "Oakley",
      "Model ID": 200,
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 743000
    },
    {
      Bid: "6771",
      Co: "Corkscrew Estates",
      "Community ID": 1000,
      Mo: "Sanibel",
      "Model ID": 200,
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 987000
    },
    {
      Bid: "6771",
      Co: "Corkscrew Estates",
      "Community ID": 1000,
      Mo: "Madeira",
      "Model ID": 200,
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 690000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Villas",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 307000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Classic",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 374000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Classic",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 396000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Classic",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Classic",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Reserve Classic",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 487000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Garden",
      "Community ID": 1000,
      Mo: "Hamden",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 318000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Garden",
      "Community ID": 1000,
      Mo: "Daniel",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Garden",
      "Community ID": 1000,
      Mo: "McNair",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Garden",
      "Community ID": 1000,
      Mo: "Tybee",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Classic",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 361000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Classic",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 379000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Classic",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Canopy Terrace Classic",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 318000
    },
    {
      Bid: "6771",
      Co: "Bridgewater",
      "Community ID": 1000,
      Mo: "Oakley",
      "Model ID": 200,
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Stucco",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: 990000
    },
    {
      Bid: "6771",
      Co: "Bridgewater",
      "Community ID": 1000,
      Mo: "Aventura",
      "Model ID": 200,
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3796,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: 1100000
    },
    {
      Bid: "6771",
      Co: "Bridgewater",
      "Community ID": 1000,
      Mo: "Madeira",
      "Model ID": 200,
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3988,
      Sto: "2",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: 1200000
    },
    {
      Bid: "6771",
      Co: "Bridgewater",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4020,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: 1210000
    },
    {
      Bid: "6771",
      Co: "Bridgewater",
      "Community ID": 1000,
      Mo: "Livingston",
      "Model ID": 200,
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5376,
      Sto: "2",
      Be: "6",
      Ba: "6.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: 1400000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1509,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Henley",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1720,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2093,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 420000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 438000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes-Estates",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 474000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes-Estates",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 516000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes-Estates",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 562000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes-Estates",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3105,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 575000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes-Estates",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 620000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes-Estates",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4117,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 623000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Classics",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 328000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Classics",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Stucco",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 434000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Classics",
      "Community ID": 1000,
      Mo: "Trailside Select",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Classics",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 486000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Classics",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 502000
    },
    {
      Bid: "6771",
      Co: "The Preserve at Bannon Lakes - Classics",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 520000
    },
    {
      Bid: "6771",
      Co: "Arden Preserve 40'",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 368000
    },
    {
      Bid: "6771",
      Co: "Arden Preserve 40'",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 372000
    },
    {
      Bid: "6771",
      Co: "Arden Preserve 40'",
      "Community ID": 1000,
      Mo: "Henley",
      "Model ID": 200,
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Arden Preserve 40'",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Arden Preserve 40'",
      "Community ID": 1000,
      Mo: "Crestwood",
      "Model ID": 200,
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 201000
    },
    {
      Bid: "6771",
      Co: "Arden Preserve 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 299000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Regal",
      "Community ID": 1000,
      Mo: "Daylen",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Regal",
      "Community ID": 1000,
      Mo: "Mill Run",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 366000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Signature",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Regal",
      "Community ID": 1000,
      Mo: "Sonora",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Signature",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 383000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Regal",
      "Community ID": 1000,
      Mo: "Talon",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 401000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Signature",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 405000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Signature",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 414000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Regal",
      "Community ID": 1000,
      Mo: "Whitmore",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 443000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Elite",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 453000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Signature",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 457000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Elite",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 470000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Signature",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 483000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Elite",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Elite",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 544000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Elite",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Elite",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Whispering Pines Regal",
      "Community ID": 1000,
      Mo: "Harvey",
      "Model ID": 200,
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 342000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Gardens",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 296000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Gardens",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 302000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Gardens",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 312000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Gardens",
      "Community ID": 1000,
      Mo: "Compass Grand",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1984,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 348000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 359000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 359000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 409000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Classics",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 414000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Estates",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 429000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Estates",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 435000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Estates",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 464000
    },
    {
      Bid: "6771",
      Co: "Summer Bay Estates",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 487000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Classics",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 266000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Classics",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 312000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Classics",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Classics",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 417000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Classics",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 322000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Classics",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Hardiplank",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Enclave at Sherwood Park",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 650000
    },
    {
      Bid: "6771",
      Co: "Enclave at Sherwood Park",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 630000
    },
    {
      Bid: "6771",
      Co: "Enclave at Sherwood Park",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 640000
    },
    {
      Bid: "6771",
      Co: "Enclave at Sherwood Park",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry Veneer",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 700000
    },
    {
      Bid: "6771",
      Co: "Grand Oaks",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 401000
    },
    {
      Bid: "6771",
      Co: "Grand Oaks",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 318000
    },
    {
      Bid: "6771",
      Co: "Grand Oaks",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 355000
    },
    {
      Bid: "6771",
      Co: "Grand Oaks",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 412000
    },
    {
      Bid: "6771",
      Co: "Grand Oaks",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 447000
    },
    {
      Bid: "6771",
      Co: "Grand Oaks",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 477000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 53'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 476000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 53'",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 489000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 53'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 524000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 67'",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 653000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 67'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 713000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 67'",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 768000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort TH",
      "Community ID": 1000,
      Mo: "Castaway - End",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2265,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 43400
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort TH",
      "Community ID": 1000,
      Mo: "Castaway - Interior",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2263,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 434000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort - 40'",
      "Community ID": 1000,
      Mo: "Winstone",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2491,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 451000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort - 40'",
      "Community ID": 1000,
      Mo: "Lakeshore",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 503000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort - 40'",
      "Community ID": 1000,
      Mo: "Hideaway",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3117,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 592000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort - 40'",
      "Community ID": 1000,
      Mo: "Seashore",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3281,
      Sto: "2",
      Be: "7",
      Ba: "5.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 605000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort - 50'",
      "Community ID": 1000,
      Mo: "Baymont",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "8",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 721000
    },
    {
      Bid: "6771",
      Co: "Windsor Island Resort - 50'",
      "Community ID": 1000,
      Mo: "Clearwater",
      "Model ID": 200,
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4398,
      Sto: "2",
      Be: "9",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 755000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 20' TH",
      "Community ID": 1000,
      Mo: "Seacrest",
      "Model ID": 200,
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 383000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 24' TH",
      "Community ID": 1000,
      Mo: "Adirondack",
      "Model ID": 200,
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 395000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 24' TH",
      "Community ID": 1000,
      Mo: "Leland",
      "Model ID": 200,
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 395000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 40'",
      "Community ID": 1000,
      Mo: "Alexander",
      "Model ID": 200,
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1961,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 432000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 40'",
      "Community ID": 1000,
      Mo: "Nelson",
      "Model ID": 200,
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2168,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 478000
    },
    {
      Bid: "6771",
      Co: "Oak Tree 40'",
      "Community ID": 1000,
      Mo: "Sienna",
      "Model ID": 200,
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2220,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Hampton Lakes at River Ha",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 314000
    },
    {
      Bid: "6771",
      Co: "Hampton Lakes at River Ha",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 322000
    },
    {
      Bid: "6771",
      Co: "Hampton Lakes at River Ha",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Hampton Lakes at River Ha",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 435000
    },
    {
      Bid: "6771",
      Co: "Hampton Lakes at River Ha",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 584000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 305000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 314000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 326000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 336000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Trailside Select",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 361000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 371000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 417000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 478000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 554000
    },
    {
      Bid: "6771",
      Co: "Bradley Creek",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 612000
    },
    {
      Bid: "6772",
      Co: "Cedar Grove at the Woodlands",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 288000
    },
    {
      Bid: "6772",
      Co: "Cedar Grove at the Woodlands",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 301000
    },
    {
      Bid: "6772",
      Co: "Cedar Grove at the Woodlands",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 367000
    },
    {
      Bid: "6772",
      Co: "Cedar Grove at the Woodlands",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6772",
      Co: "Cedar Grove at the Woodlands",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6772",
      Co: "Cedar Grove at the Woodlands",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 274000
    },
    {
      Bid: "6771",
      Co: "The Strand at Cedar Grove",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 233000
    },
    {
      Bid: "6771",
      Co: "The Strand at Cedar Grove",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "The Strand at Cedar Grove",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "The Strand at Cedar Grove",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "The Strand at Cedar Grove",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "The Strand at Cedar Grove",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 259000
    },
    {
      Bid: "6771",
      Co: "Beachwalk by Manasota Key Vista",
      "Community ID": 1000,
      Mo: "Cascadia",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 279000
    },
    {
      Bid: "6771",
      Co: "Beachwalk by Manasota Key Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 281000
    },
    {
      Bid: "6771",
      Co: "Beachwalk by Manasota Key Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 295000
    },
    {
      Bid: "6771",
      Co: "Beachwalk by Manasota Key Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 389000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 539000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 562000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 334000
    },
    {
      Bid: "6771",
      Co: "Beachwalk by Manasota Key Vista",
      "Community ID": 1000,
      Mo: "Seagrove",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 274000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Echelon",
      "Community ID": 1000,
      Mo: "Layton Grande",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 556000
    },
    {
      Bid: "6771",
      Co: "BeachWalk by Manasota Key Echelon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Cypress Falls Classic",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 345000
    },
    {
      Bid: "6773",
      Co: "Cypress Falls Classic",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 364000
    },
    {
      Bid: "6773",
      Co: "Cypress Falls Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 365000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Adelaide",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 270000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Benton",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 315000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Dylan",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Camden",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Haddock",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 345000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Hazel",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1906,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 347000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Garden",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 356000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 358000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Jasmine",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 369000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Garden",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 382000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 398000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Mabel",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 398000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 405000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Bungalows",
      "Community ID": 1000,
      Mo: "Talbot",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 420000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2383,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 427000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 456000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Garden",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 461000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Classic",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 492000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Townhomes",
      "Community ID": 1000,
      Mo: "Orchid",
      "Model ID": 200,
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1553,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 290000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Townhomes",
      "Community ID": 1000,
      Mo: "Dahlia",
      "Model ID": 200,
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 298000
    },
    {
      Bid: "6771",
      Co: "Tohoqua Townhomes",
      "Community ID": 1000,
      Mo: "Julep",
      "Model ID": 200,
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 306000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 296000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 310000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 321000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 328000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "Timber Cove Classics",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Solis Grande",
      "Community ID": 1000,
      Mo: "Rowan",
      "Model ID": 200,
      Str: "27869 Solis Grande Dr",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 232000
    },
    {
      Bid: "6771",
      Co: "Solis Grande",
      "Community ID": 1000,
      Mo: "Alder",
      "Model ID": 200,
      Str: "27869 Solis Grande Dr",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 255000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 451000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 467000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 502000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 533000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Willowbrooke",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2056,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 403000
    },
    {
      Bid: "6771",
      Co: "Marwood",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Marwood",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 315000
    },
    {
      Bid: "6771",
      Co: "Marwood",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 335000
    },
    {
      Bid: "6771",
      Co: "Marwood",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 358000
    },
    {
      Bid: "6771",
      Co: "Marwood",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Bungalows",
      "Community ID": 1000,
      Mo: "Juniper",
      "Model ID": 200,
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2038,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Bungalows",
      "Community ID": 1000,
      Mo: "Capri",
      "Model ID": 200,
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1820,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Hawthorn Park at Wildlight Bungalows",
      "Community ID": 1000,
      Mo: "Monterey",
      "Model ID": 200,
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2195,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 360000
    },
    {
      Bid: "6771",
      Co: "Grayson Square",
      "Community ID": 1000,
      Mo: "Marigold",
      "Model ID": 200,
      Str: "2945 Clever Lane",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Grayson Square",
      "Community ID": 1000,
      Mo: "Springdale II",
      "Model ID": 200,
      Str: "2945 Clever Lane",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1907,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Cassia Commons",
      "Community ID": 1000,
      Mo: "Cobalt",
      "Model ID": 200,
      Str: "3010 Hibiscus Avenue",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1547,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 366000
    },
    {
      Bid: "6771",
      Co: "Cassia Commons",
      "Community ID": 1000,
      Mo: "Latitude",
      "Model ID": 200,
      Str: "3010 Hibiscus Avenue",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "Cassia Estates",
      "Community ID": 1000,
      Mo: "Fifth Avenue",
      "Model ID": 200,
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Cassia Estates",
      "Community ID": 1000,
      Mo: "Hamden",
      "Model ID": 200,
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 365000
    },
    {
      Bid: "6771",
      Co: "Cassia Estates",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Cassia Estates",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 473000
    },
    {
      Bid: "6771",
      Co: "Cassia Estates",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 333000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Hamden",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 285000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 292000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 301000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Oasis",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 307000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Seabrook",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 312000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 335000
    },
    {
      Bid: "6772",
      Co: "Wesbridge Signature",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace THs",
      "Community ID": 1000,
      Mo: "Marigold",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 305000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace THs",
      "Community ID": 1000,
      Mo: "Springdale",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Classic",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Garden",
      "Community ID": 1000,
      Mo: "Mill Run",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 385000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Classic",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Estate",
      "Community ID": 1000,
      Mo: "Bloomfield",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 415000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Garden",
      "Community ID": 1000,
      Mo: "Talon",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 432000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Classic",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 480000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Garden",
      "Community ID": 1000,
      Mo: "Whitmore",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3162,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 521000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Garden",
      "Community ID": 1000,
      Mo: "Daylen",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 350000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Classic",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 430000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Estate",
      "Community ID": 1000,
      Mo: "Everly",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2703,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 446000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Classic",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 570000
    },
    {
      Bid: "6771",
      Co: "Hartford Terrace Classic",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 70'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "3260 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 348000
    },
    {
      Bid: "6771",
      Co: "River Hall Country Club 70'",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "3260 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 394000
    },
    {
      Bid: "6771",
      Co: "Lakeview Preserve 50'",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "3276 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Lakeview Preserve 50'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "3276 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 592000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 612000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 614000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 681000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 688000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 716000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 716000
    },
    {
      Bid: "6771",
      Co: "Estates at Lakeview Preserve",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 583000
    },
    {
      Bid: "6771",
      Co: "Forest Park at Wildlight",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 375000
    },
    {
      Bid: "6771",
      Co: "Forest Park at Wildlight",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Forest Park at Wildlight",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Forest Park at Wildlight",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 439000
    },
    {
      Bid: "6771",
      Co: "Forest Park at Wildlight",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 482000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Classics",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Classics",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 320000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Classics",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2142,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 375000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Classics",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 408000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Estates",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2856,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 450000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Estates",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 510000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Estates",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 395000
    },
    {
      Bid: "6771",
      Co: "Hyland Trail Estates",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3459,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 550000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Concrete",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Concrete",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 320000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Concrete",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 323000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Estates",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Concrete",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Trailside Select",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Concrete",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 348000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Estates",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Concrete",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 364000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Estates",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 447000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 458000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Estates",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 517000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Estates",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 571000
    },
    {
      Bid: "6771",
      Co: "Double Branch - Classics",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 413000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 305000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Seabrook",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 314000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 335000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Sterling",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 351000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Sterling",
      "Community ID": 1000,
      Mo: "Bayshore",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 356000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Sterling",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Sterling",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 354000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Sterling",
      "Community ID": 1000,
      Mo: "Everly",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2703,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 379000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Sterling",
      "Community ID": 1000,
      Mo: "Arbor",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2223,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 353000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 324000
    },
    {
      Bid: "6772",
      Co: "North Park Isle Heritage",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 294000
    },
    {
      Bid: "6771",
      Co: "Shoreline",
      "Community ID": 1000,
      Mo: "Adirondack",
      "Model ID": 200,
      Str: "354 Surfside Ave NE",
      Ct: "Saint Petersburg",
      St: "FL",
      Zip: 33716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 372000
    },
    {
      Bid: "6771",
      Co: "Shoreline",
      "Community ID": 1000,
      Mo: "Leland",
      "Model ID": 200,
      Str: "354 Surfside Ave NE",
      Ct: "Saint Petersburg",
      St: "FL",
      Zip: 33716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Jasmine",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Estate",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 433000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Estate",
      "Community ID": 1000,
      Mo: "Monroe",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 493000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Catamaran",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2296,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Camden",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 327000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Estate",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4214,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 675000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Schooner",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2565,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Benton",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 290000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Dylan",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 305000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Haddock",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 336000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 366000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Bungalow",
      "Community ID": 1000,
      Mo: "Mabel",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 415000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 418000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 512000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 506000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Coral Grand",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 520000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Imperial",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 450000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Coral",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1946,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 375000
    },
    {
      Bid: "6771",
      Co: "Winding Meadows- Classic",
      "Community ID": 1000,
      Mo: "Scarlett",
      "Model ID": 200,
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3602,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 550000
    },
    {
      Bid: "6772",
      Co: "Lake Alfred Pines",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 279000
    },
    {
      Bid: "6772",
      Co: "Lake Alfred Pines",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 270000
    },
    {
      Bid: "6772",
      Co: "Lake Alfred Pines",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 335000
    },
    {
      Bid: "6772",
      Co: "Lake Alfred Pines",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Valleybrook",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 289000
    },
    {
      Bid: "6771",
      Co: "Valleybrook",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 298000
    },
    {
      Bid: "6771",
      Co: "Valleybrook",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Valleybrook",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 323000
    },
    {
      Bid: "6771",
      Co: "Valleybrook",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 332000
    },
    {
      Bid: "6771",
      Co: "Sonoma Isles",
      "Community ID": 1000,
      Mo: "Stonewater w Loft",
      "Model ID": 200,
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 752000
    },
    {
      Bid: "6771",
      Co: "Sonoma Isles",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 571000
    },
    {
      Bid: "6771",
      Co: "Sonoma Isles",
      "Community ID": 1000,
      Mo: "Garden Vista",
      "Model ID": 200,
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 1538000
    },
    {
      Bid: "6771",
      Co: "Sonoma Isles",
      "Community ID": 1000,
      Mo: "Tangerly Oak",
      "Model ID": 200,
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 518000
    },
    {
      Bid: "6771",
      Co: "Cordova Palms",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "Cordova Palms",
      "Community ID": 1000,
      Mo: "Trailside Select",
      "Model ID": 200,
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "Cordova Palms",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 417000
    },
    {
      Bid: "6771",
      Co: "Cordova Palms",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 442000
    },
    {
      Bid: "6771",
      Co: "Cordova Palms",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Cordova Palms",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 322000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Classic",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 398000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Classic",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 401000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Classic",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 405000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Classic",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 410000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Estate",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 478000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Villa",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 307000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Estate",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 493000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Estate",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 506000
    },
    {
      Bid: "6771",
      Co: "Preserve at Waterway - Estate",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 512000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 52'",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 342000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 52'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 356000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 52'",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 361000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 52'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 369000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 42'",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 281000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 42'",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 42'",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 295000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 66'",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 418000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 66'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 438000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 66'",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 471000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Edgewater 66'",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 456000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch - Discovery",
      "Community ID": 1000,
      Mo: "Browning",
      "Model ID": 200,
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 290000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch - Discovery",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 358000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch - Discovery",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 299000
    },
    {
      Bid: "6771",
      Co: "Northridge at Babcock Ranch - Discovery",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 324000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 266000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Oasis",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 327000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Citrus Grove",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 404000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Palm",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 418000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Dockside",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 421000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Mariner",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 534000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Lake Timber",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 569000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Lake Timber",
      "Community ID": 1000,
      Mo: "Woodview",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3411,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 579000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Parkside",
      "Community ID": 1000,
      Mo: "Summerwood Loft",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 649000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Lake Timber",
      "Community ID": 1000,
      Mo: "Arbordale",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1980,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 351000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Lake Timber",
      "Community ID": 1000,
      Mo: "Cobblestone",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2212,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 360000
    },
    {
      Bid: "6771",
      Co: "Babcock Ranch Lake Timber",
      "Community ID": 1000,
      Mo: "Oakview",
      "Model ID": 200,
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3654,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 600000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 387000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 415000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 66'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 66'",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 66'",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 470000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 500000
    },
    {
      Bid: "6771",
      Co: "Veranda Gardens 52'",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 470000
    },
    {
      Bid: "6771",
      Co: "Sonoma Oaks",
      "Community ID": 1000,
      Mo: "Augusta",
      "Model ID": 200,
      Str: "4397 Sonoma Oaks Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1901,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 267000
    },
    {
      Bid: "6771",
      Co: "Sonoma Oaks",
      "Community ID": 1000,
      Mo: "Easton",
      "Model ID": 200,
      Str: "4397 Sonoma Oaks Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2018,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 283000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Classic",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 433000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Classic",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Garden",
      "Community ID": 1000,
      Mo: "Candlewood",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 270000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Classic",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 292000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Garden",
      "Community ID": 1000,
      Mo: "Daylen",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 292000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Classic",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 311000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Garden",
      "Community ID": 1000,
      Mo: "Mill Run",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 343000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Classic",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Verde at Babcock Ranch - Garden",
      "Community ID": 1000,
      Mo: "Talon",
      "Model ID": 200,
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 367000
    },
    {
      Bid: "6772",
      Co: "Cypress Cay",
      "Community ID": 1000,
      Mo: "Marigold",
      "Model ID": 200,
      Str: "4511 Cypress Cay Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 100000
    },
    {
      Bid: "6772",
      Co: "Cypress Cay",
      "Community ID": 1000,
      Mo: "Springdale",
      "Model ID": 200,
      Str: "4511 Cypress Cay Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond Bungalows",
      "Community ID": 1000,
      Mo: "Capri",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 284000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond Bungalows",
      "Community ID": 1000,
      Mo: "Juniper",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 301000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond Bungalows",
      "Community ID": 1000,
      Mo: "Monterey",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2195,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 308000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond Bungalows",
      "Community ID": 1000,
      Mo: "Windsor",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 366000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond Bungalows",
      "Community ID": 1000,
      Mo: "Hickory",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 287000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 329000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 342000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 351000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 358000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 381000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Bradley Pond",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 391000
    },
    {
      Bid: "6771",
      Co: "The Fields 50'",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "The Fields 45'",
      "Community ID": 1000,
      Mo: "Fox Hollow",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1901,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 380000
    },
    {
      Bid: "6771",
      Co: "The Fields 50'",
      "Community ID": 1000,
      Mo: "Canopy",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1972,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 395000
    },
    {
      Bid: "6771",
      Co: "The Fields 50'",
      "Community ID": 1000,
      Mo: "Pompeii",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2104,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "The Fields 45'",
      "Community ID": 1000,
      Mo: "Fifth Avenue",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 505000
    },
    {
      Bid: "6771",
      Co: "The Fields 45'",
      "Community ID": 1000,
      Mo: "Riverwalk",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "The Fields 45'",
      "Community ID": 1000,
      Mo: "Citrus Grove",
      "Model ID": 200,
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 566000
    },
    {
      Bid: "6771",
      Co: "Windsong Estates 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Windsong Estates 50'",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6771",
      Co: "Windsong Estates 50'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Windsong Estates 50'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 579000
    },
    {
      Bid: "6771",
      Co: "Bexley",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 403000
    },
    {
      Bid: "6771",
      Co: "Bexley",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 425000
    },
    {
      Bid: "6771",
      Co: "Bexley",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 505000
    },
    {
      Bid: "6771",
      Co: "Bexley",
      "Community ID": 1000,
      Mo: "Driftwood",
      "Model ID": 200,
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2470,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 350000
    },
    {
      Bid: "6771",
      Co: "Bexley",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2056,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 458000
    },
    {
      Bid: "6771",
      Co: "Bexley",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "The Fields 65'",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "4652 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 498000
    },
    {
      Bid: "6771",
      Co: "The Fields 65'",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "4652 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 571000
    },
    {
      Bid: "6771",
      Co: "The Fields 24' TH",
      "Community ID": 1000,
      Mo: "Seacrest",
      "Model ID": 200,
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 363000
    },
    {
      Bid: "6771",
      Co: "The Fields 24' TH",
      "Community ID": 1000,
      Mo: "Adirondack",
      "Model ID": 200,
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 379000
    },
    {
      Bid: "6771",
      Co: "The Fields 24' TH",
      "Community ID": 1000,
      Mo: "Leland",
      "Model ID": 200,
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 379000
    },
    {
      Bid: "6771",
      Co: "Emerald Pointe",
      "Community ID": 1000,
      Mo: "Springdale",
      "Model ID": 200,
      Str: "482 Beryl Cove",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 328000
    },
    {
      Bid: "6771",
      Co: "Emerald Pointe",
      "Community ID": 1000,
      Mo: "Marigold",
      "Model ID": 200,
      Str: "482 Beryl Cove",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 344000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2140,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 412000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Trailside Select",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 430000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2622,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 470000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 580000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3459,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 620000
    },
    {
      Bid: "6771",
      Co: "Wingate Landing",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4099,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 740000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 50'",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 361000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 50'",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 387000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 50'",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 401000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 50'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 425000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 40'",
      "Community ID": 1000,
      Mo: "Daniel",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 345000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 40'",
      "Community ID": 1000,
      Mo: "McNair",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 380000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 40'",
      "Community ID": 1000,
      Mo: "Tybee",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 399000
    },
    {
      Bid: "6771",
      Co: "Cypress Hammock 50'",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 369000
    },
    {
      Bid: "6771",
      Co: "Copperleaf 125",
      "Community ID": 1000,
      Mo: "Oakley",
      "Model ID": 200,
      Str: "5048 SW Sensation",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 900000
    },
    {
      Bid: "6771",
      Co: "Copperleaf 125",
      "Community ID": 1000,
      Mo: "Aventura",
      "Model ID": 200,
      Str: "5048 SW Sensation",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3796,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 1140000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 460000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 507000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 534000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 673000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 748000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Valencia",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4342,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 760000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Upton",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 912000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Creekview",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 450000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Layton Grande",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 556000
    },
    {
      Bid: "6771",
      Co: "Avalon Park at Ave Maria",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 480000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 306000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Martin Ray",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1968,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 344000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 360000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 362000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 408000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 429000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 455000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 470000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 430000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 396000
    },
    {
      Bid: "6771",
      Co: "Lakes at Waterway 50'",
      "Community ID": 1000,
      Mo: "Serenity",
      "Model ID": 200,
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1542,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 270000
    },
    {
      Bid: "6771",
      Co: "Magnolia Court",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Magnolia Court",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 342000
    },
    {
      Bid: "6771",
      Co: "Magnolia Court",
      "Community ID": 1000,
      Mo: "Browning",
      "Model ID": 200,
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 326000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 322000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 376000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 451000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 303000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Foothills Preserve",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 599000
    },
    {
      Bid: "6772",
      Co: "Wesley Reserve at Chapel Crossing",
      "Community ID": 1000,
      Mo: "Evergreen",
      "Model ID": 200,
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1451,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 279000
    },
    {
      Bid: "6772",
      Co: "Wesley Reserve at Chapel Crossing",
      "Community ID": 1000,
      Mo: "Sycamore",
      "Model ID": 200,
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1762,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 305000
    },
    {
      Bid: "6772",
      Co: "Wesley Reserve at Chapel Crossing",
      "Community ID": 1000,
      Mo: "Ashe",
      "Model ID": 200,
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1818,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 320000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Distinctive",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 261000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Scenic",
      "Community ID": 1000,
      Mo: "Kendrick",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 237000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 244000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 327000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 337000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 339000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 348000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 374000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 394000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples - Carriag",
      "Community ID": 1000,
      Mo: "Heron",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1457,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 100000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples - Carriag",
      "Community ID": 1000,
      Mo: "Egret",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1538,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 105000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples - Carriag",
      "Community ID": 1000,
      Mo: "Spoonbill",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 110000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples - Carriag",
      "Community ID": 1000,
      Mo: "Cormorant",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1868,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 115000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Distinctive",
      "Community ID": 1000,
      Mo: "Dolce",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1570,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 249000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 270000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 280000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 288000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 292000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples- Villas",
      "Community ID": 1000,
      Mo: "Seagrove",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 300000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples- Villas",
      "Community ID": 1000,
      Mo: "Cascadia",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 335000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples- Villas",
      "Community ID": 1000,
      Mo: "Kendrick",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 340000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Echelon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Del Webb Naples-Echelon",
      "Community ID": 1000,
      Mo: "Layton Grande",
      "Model ID": 200,
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 556000
    },
    {
      Bid: "6771",
      Co: "Ranchette Square",
      "Community ID": 1000,
      Mo: "Grayton",
      "Model ID": 200,
      Str: "6100 Rancho Lane",
      Ct: "Green Acres",
      St: "FL",
      Zip: 33415,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 375000
    },
    {
      Bid: "6771",
      Co: "Ranchette Square",
      "Community ID": 1000,
      Mo: "Navarre",
      "Model ID": 200,
      Str: "6100 Rancho Lane",
      Ct: "Green Acres",
      St: "FL",
      Zip: 33415,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 355000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 310000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 324000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 334000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay Grand",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2682,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 511000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 521000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 530000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 551000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Echelon",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 612000
    },
    {
      Bid: "6773",
      Co: "Del Webb Sunbridge Villas",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "6209 Citrus Grove Ct",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks TH",
      "Community ID": 1000,
      Mo: "Orchid",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1553,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 332000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 34'",
      "Community ID": 1000,
      Mo: "Benton",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 339000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks TH",
      "Community ID": 1000,
      Mo: "Dahlia",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 345000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks TH",
      "Community ID": 1000,
      Mo: "Julep",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 353000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 34'",
      "Community ID": 1000,
      Mo: "Haddock",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 365000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 34'",
      "Community ID": 1000,
      Mo: "Caden",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 424000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 50'",
      "Community ID": 1000,
      Mo: "Coral Grand",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3017,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 585000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 50'",
      "Community ID": 1000,
      Mo: "Cardinal",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1958,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 50'",
      "Community ID": 1000,
      Mo: "Coral",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2006,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 50'",
      "Community ID": 1000,
      Mo: "Imperial",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 50'",
      "Community ID": 1000,
      Mo: "Scarlett",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3643,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 647000
    },
    {
      Bid: "6771",
      Co: "Silverleaf Oaks - 50'",
      "Community ID": 1000,
      Mo: "Ruby",
      "Model ID": 200,
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2883,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 523000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 247000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 251000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 262000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Echelon",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3361,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 471000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 348000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 394000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 335000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Passport",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "6320 SW 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 276000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Passport",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "6320 SW 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 272000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Passport",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "6320 SW 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 278000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 281000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Vista",
      "Community ID": 1000,
      Mo: "Seagrove",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 289000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 295000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Vista",
      "Community ID": 1000,
      Mo: "Cascadia",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 321000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 389000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Scenic",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 441000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Distinctive",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 522000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Scenic",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 523000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 534000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 562000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Echelon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 604000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "Talon Preserve Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 334000
    },
    {
      Bid: "6771",
      Co: "Weslyn Park",
      "Community ID": 1000,
      Mo: "Bordeaux",
      "Model ID": 200,
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Weslyn Park",
      "Community ID": 1000,
      Mo: "Carano",
      "Model ID": 200,
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2478,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 496000
    },
    {
      Bid: "6771",
      Co: "Weslyn Park",
      "Community ID": 1000,
      Mo: "Noir",
      "Model ID": 200,
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2877,
      Sto: "2",
      Be: "2",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 548000
    },
    {
      Bid: "6771",
      Co: "Weslyn Park",
      "Community ID": 1000,
      Mo: "Carano II",
      "Model ID": 200,
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 582000
    },
    {
      Bid: "6771",
      Co: "Everbe- Ember",
      "Community ID": 1000,
      Mo: "Adelaide",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 261000
    },
    {
      Bid: "6771",
      Co: "Everbe- Ember",
      "Community ID": 1000,
      Mo: "Benton",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 285000
    },
    {
      Bid: "6771",
      Co: "Everbe- Ember",
      "Community ID": 1000,
      Mo: "Haddock",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 310000
    },
    {
      Bid: "6771",
      Co: "Everbe- Ember",
      "Community ID": 1000,
      Mo: "Caden",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 361000
    },
    {
      Bid: "6771",
      Co: "Everbe- Aurora",
      "Community ID": 1000,
      Mo: "Foxtail Exterior Unit",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 267000
    },
    {
      Bid: "6771",
      Co: "Everbe- Aurora",
      "Community ID": 1000,
      Mo: "Foxtail Interior Unit",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 267000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 287000
    },
    {
      Bid: "6771",
      Co: "Everbe- Haven",
      "Community ID": 1000,
      Mo: "Beacon",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1648,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 294000
    },
    {
      Bid: "6771",
      Co: "Everbe- Aurora",
      "Community ID": 1000,
      Mo: "Trailwood Exterior Unit",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 301000
    },
    {
      Bid: "6771",
      Co: "Everbe- Aurora",
      "Community ID": 1000,
      Mo: "Trailwood Interior Unit",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 301000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 306000
    },
    {
      Bid: "6771",
      Co: "Everbe- Haven",
      "Community ID": 1000,
      Mo: "Dylan",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 306000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Coral",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1935,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 315000
    },
    {
      Bid: "6771",
      Co: "Everbe- Haven",
      "Community ID": 1000,
      Mo: "Kelsey",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2006,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Garnet",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2166,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 353000
    },
    {
      Bid: "6771",
      Co: "Everbe- Legacy",
      "Community ID": 1000,
      Mo: "Mahogany",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 377000
    },
    {
      Bid: "6771",
      Co: "Everbe- Haven",
      "Community ID": 1000,
      Mo: "Mabel",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 387000
    },
    {
      Bid: "6771",
      Co: "Everbe- Legacy",
      "Community ID": 1000,
      Mo: "Merlot",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 423000
    },
    {
      Bid: "6771",
      Co: "Everbe- Haven",
      "Community ID": 1000,
      Mo: "Talbot",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 430000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Ruby",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2883,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 447000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Coral Grand",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 456000
    },
    {
      Bid: "6771",
      Co: "Everbe- Legacy",
      "Community ID": 1000,
      Mo: "Monroe",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 466000
    },
    {
      Bid: "6771",
      Co: "Everbe- Legacy",
      "Community ID": 1000,
      Mo: "Mahogany Grand",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3126,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 473000
    },
    {
      Bid: "6771",
      Co: "Everbe- Legacy",
      "Community ID": 1000,
      Mo: "Merlot Grand",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3938,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 596000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Imperial",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2563,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 411000
    },
    {
      Bid: "6771",
      Co: "Everbe- Forever",
      "Community ID": 1000,
      Mo: "Scarlett",
      "Model ID": 200,
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3575,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 573000
    },
    {
      Bid: "6773",
      Co: "DW Lakewood Ranch 65'",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 628000
    },
    {
      Bid: "6773",
      Co: "DW Lakewood Ranch 65'",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 511000
    },
    {
      Bid: "6773",
      Co: "DW Lakewood Ranch 65'",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: 601000
    },
    {
      Bid: "6773",
      Co: "DW Lakewood Ranch 65'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: 559000
    },
    {
      Bid: "6772",
      Co: "Wells Landing",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 290000
    },
    {
      Bid: "6772",
      Co: "Wells Landing",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6772",
      Co: "Wells Landing",
      "Community ID": 1000,
      Mo: "McNair",
      "Model ID": 200,
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2093,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6772",
      Co: "Wells Landing",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6772",
      Co: "Wells Landing",
      "Community ID": 1000,
      Mo: "Tybee",
      "Model ID": 200,
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6772",
      Co: "Wells Landing",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 450000
    },
    {
      Bid: "6771",
      Co: "Legacy Groves Villa",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "6918 Honey Bell Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 222000
    },
    {
      Bid: "6771",
      Co: "Carver Square",
      "Community ID": 1000,
      Mo: "Browning",
      "Model ID": 200,
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 295000
    },
    {
      Bid: "6771",
      Co: "Carver Square",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Carver Square",
      "Community ID": 1000,
      Mo: "Hamden",
      "Model ID": 200,
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 358000
    },
    {
      Bid: "6771",
      Co: "Carver Square",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 438000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee Villas",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1529,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 263000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 65's",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 436000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 65's",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 477000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 65's",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2710,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 487000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 65's",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3361,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 572000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 50's",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1599,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 320000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 50's",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 362000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 50's",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 372000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 50's",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 375000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 50's",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 495000
    },
    {
      Bid: "6773",
      Co: "Del Webb Nocatee 50's",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 443000
    },
    {
      Bid: "6771",
      Co: "Shoreview 66",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2007,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 451000
    },
    {
      Bid: "6771",
      Co: "Shoreview 66",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 560000
    },
    {
      Bid: "6771",
      Co: "Shoreview 66",
      "Community ID": 1000,
      Mo: "Tangerly Oak",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 583000
    },
    {
      Bid: "6771",
      Co: "Shoreview 72",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 642000
    },
    {
      Bid: "6771",
      Co: "Shoreview 66",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 642000
    },
    {
      Bid: "6771",
      Co: "Shoreview 72",
      "Community ID": 1000,
      Mo: "Southampton",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2903,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 654000
    },
    {
      Bid: "6771",
      Co: "Shoreview 72",
      "Community ID": 1000,
      Mo: "Nobility",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 655000
    },
    {
      Bid: "6771",
      Co: "Shoreview 72",
      "Community ID": 1000,
      Mo: "Clubview",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 693000
    },
    {
      Bid: "6771",
      Co: "Shoreview 72",
      "Community ID": 1000,
      Mo: "Camelot",
      "Model ID": 200,
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3203,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 720000
    },
    {
      Bid: "6771",
      Co: "Valri Forest",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 626000
    },
    {
      Bid: "6771",
      Co: "Valri Forest",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Valri Forest",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 450000
    },
    {
      Bid: "6771",
      Co: "Valri Forest",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Valri Forest",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 358000
    },
    {
      Bid: "6771",
      Co: "Epperson Signature",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 374000
    },
    {
      Bid: "6771",
      Co: "Epperson Signature",
      "Community ID": 1000,
      Mo: "Summerwood Grand",
      "Model ID": 200,
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 408000
    },
    {
      Bid: "6771",
      Co: "Epperson Signature",
      "Community ID": 1000,
      Mo: "Citrus Grove",
      "Model ID": 200,
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 416000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Wyndham",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 354000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Fox Hollow",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1846,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Summerwood",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Alexander",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1963,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 375000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Sienna",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2220,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 379000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Fifth Avenue",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 505000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Riverwalk",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Park Place",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 540000
    },
    {
      Bid: "6771",
      Co: "The Enclaves at Woodmont",
      "Community ID": 1000,
      Mo: "Citrus Grove",
      "Model ID": 200,
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 545000
    },
    {
      Bid: "6771",
      Co: "Epperson Bay",
      "Community ID": 1000,
      Mo: "McNair",
      "Model ID": 200,
      Str: "7913 Yale Harbor Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 344000
    },
    {
      Bid: "6771",
      Co: "Epperson Elite",
      "Community ID": 1000,
      Mo: "Citrus Grove",
      "Model ID": 200,
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Epperson Elite",
      "Community ID": 1000,
      Mo: "Sandhill",
      "Model ID": 200,
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3222,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6771",
      Co: "Epperson Elite",
      "Community ID": 1000,
      Mo: "Dockside Grand",
      "Model ID": 200,
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4274,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Epperson Elite",
      "Community ID": 1000,
      Mo: "Dockside",
      "Model ID": 200,
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 424000
    },
    {
      Bid: "6771",
      Co: "Magnolia Ranch",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "Magnolia Ranch",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Magnolia Ranch",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Magnolia Ranch",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 453000
    },
    {
      Bid: "6771",
      Co: "Magnolia Ranch",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 466000
    },
    {
      Bid: "6771",
      Co: "Magnolia Ranch",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 546000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Elite",
      "Community ID": 1000,
      Mo: "Astoria",
      "Model ID": 200,
      Str: "8316 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2223,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 327000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Elite",
      "Community ID": 1000,
      Mo: "Everly",
      "Model ID": 200,
      Str: "8316 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 352000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Elite",
      "Community ID": 1000,
      Mo: "Bloomfield",
      "Model ID": 200,
      Str: "8316 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 336000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Signature",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 281000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Signature",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 289000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Signature",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 319000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Signature",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 344000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Signature",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 383000
    },
    {
      Bid: "6771",
      Co: "Marion Ranch - Signature",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 315000
    },
    {
      Bid: "6771",
      Co: "Emory",
      "Community ID": 1000,
      Mo: "Grayton",
      "Model ID": 200,
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Emory",
      "Community ID": 1000,
      Mo: "Grayton Grand",
      "Model ID": 200,
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Emory",
      "Community ID": 1000,
      Mo: "Seacrest",
      "Model ID": 200,
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 439000
    },
    {
      Bid: "6771",
      Co: "Emory",
      "Community ID": 1000,
      Mo: "Seacrest Grand",
      "Model ID": 200,
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 439000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Garden",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 300000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Garden",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 315000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Classic",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 391000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Classic",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 410000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Garden",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Classic",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Classic",
      "Community ID": 1000,
      Mo: "Burbank",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 566000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Garden",
      "Community ID": 1000,
      Mo: "Maritime",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1927,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 385000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Garden",
      "Community ID": 1000,
      Mo: "Seaport",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2068,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 410000
    },
    {
      Bid: "6771",
      Co: "Harbor Isle Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 490000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Villa",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 315000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 339000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 341000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 391000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 402000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 408000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 476000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 483000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 493000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 507000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Echelon",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 560000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Villa",
      "Community ID": 1000,
      Mo: "Kendrick",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 312000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Del Webb BayView Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 350000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Villa",
      "Community ID": 1000,
      Mo: "Ellenwood",
      "Model ID": 200,
      Str: "8926 Coventina Way",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 316000
    },
    {
      Bid: "6771",
      Co: "Ancient Tree",
      "Community ID": 1000,
      Mo: "Clubview",
      "Model ID": 200,
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 600000
    },
    {
      Bid: "6771",
      Co: "Ancient Tree",
      "Community ID": 1000,
      Mo: "Nobility",
      "Model ID": 200,
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 1025000
    },
    {
      Bid: "6771",
      Co: "Ancient Tree",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 854000
    },
    {
      Bid: "6771",
      Co: "Ancient Tree",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 1001000
    },
    {
      Bid: "6771",
      Co: "Ancient Tree",
      "Community ID": 1000,
      Mo: "Camelot",
      "Model ID": 200,
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 1149000
    },
    {
      Bid: "6771",
      Co: "GC Everglades 67'",
      "Community ID": 1000,
      Mo: "Stonewater w/ Loft",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 537000
    },
    {
      Bid: "6771",
      Co: "GC Everglades 67'",
      "Community ID": 1000,
      Mo: "Pinnacle",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 567000
    },
    {
      Bid: "6771",
      Co: "GC Everglades 67'",
      "Community ID": 1000,
      Mo: "Stonewater",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 600000
    },
    {
      Bid: "6771",
      Co: "Terreno - Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 376000
    },
    {
      Bid: "6771",
      Co: "GC Everglades 67'",
      "Community ID": 1000,
      Mo: "Tangerly Oak",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 680000
    },
    {
      Bid: "6771",
      Co: "Terreno - Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 366000
    },
    {
      Bid: "6771",
      Co: "Terreno - Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 383000
    },
    {
      Bid: "6771",
      Co: "Terreno - Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 425000
    },
    {
      Bid: "6771",
      Co: "Terreno - Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 437000
    },
    {
      Bid: "6771",
      Co: "Terreno - Scenic",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 453000
    },
    {
      Bid: "6771",
      Co: "Terreno - Distinctive",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 468000
    },
    {
      Bid: "6771",
      Co: "Terreno - Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 511000
    },
    {
      Bid: "6771",
      Co: "Terreno - Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 559000
    },
    {
      Bid: "6771",
      Co: "Terreno - Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 601000
    },
    {
      Bid: "6771",
      Co: "Terreno - Echelon",
      "Community ID": 1000,
      Mo: "Layton",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 620000
    },
    {
      Bid: "6771",
      Co: "Terreno - Echelon",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 661000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Leisure",
      "Community ID": 1000,
      Mo: "Daylen",
      "Model ID": 200,
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 241000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Leisure",
      "Community ID": 1000,
      Mo: "Beachwood",
      "Model ID": 200,
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 226000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Leisure",
      "Community ID": 1000,
      Mo: "Candlewood",
      "Model ID": 200,
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 234000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Leisure",
      "Community ID": 1000,
      Mo: "Blackwell",
      "Model ID": 200,
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 226000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Leisure",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 241000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Leisure",
      "Community ID": 1000,
      Mo: "Crestwood",
      "Model ID": 200,
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 234000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 40'",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 347000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 40'",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 381000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 40'",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 400000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 400000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 400000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Browning",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 260000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 270000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 280000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Seabrook",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 303000
    },
    {
      Bid: "6772",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 310000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 410000
    },
    {
      Bid: "6771",
      Co: "North River Ranch 50'",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 436000
    },
    {
      Bid: "6771",
      Co: "Riversedge",
      "Community ID": 1000,
      Mo: "Garnet",
      "Model ID": 200,
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2166,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 405000
    },
    {
      Bid: "6771",
      Co: "Riversedge",
      "Community ID": 1000,
      Mo: "Imperial",
      "Model ID": 200,
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2564,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 434000
    },
    {
      Bid: "6771",
      Co: "Riversedge",
      "Community ID": 1000,
      Mo: "Coral Grand",
      "Model ID": 200,
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 448000
    },
    {
      Bid: "6771",
      Co: "Riversedge",
      "Community ID": 1000,
      Mo: "Ruby",
      "Model ID": 200,
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2886,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 451000
    },
    {
      Bid: "6771",
      Co: "Riversedge",
      "Community ID": 1000,
      Mo: "Scarlett",
      "Model ID": 200,
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3575,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 501000
    },
    {
      Bid: "6771",
      Co: "Riversedge",
      "Community ID": 1000,
      Mo: "Coral",
      "Model ID": 200,
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1935,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 391000
    },
    {
      Bid: "6772",
      Co: "Summers Cay",
      "Community ID": 1000,
      Mo: "Chapman",
      "Model ID": 200,
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 342000
    },
    {
      Bid: "6772",
      Co: "Summers Cay",
      "Community ID": 1000,
      Mo: "Hanover",
      "Model ID": 200,
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 353000
    },
    {
      Bid: "6772",
      Co: "Summers Cay",
      "Community ID": 1000,
      Mo: "Seabrook",
      "Model ID": 200,
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 362000
    },
    {
      Bid: "6772",
      Co: "Summers Cay",
      "Community ID": 1000,
      Mo: "Wakefield",
      "Model ID": 200,
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 406000
    },
    {
      Bid: "6772",
      Co: "Summers Cay",
      "Community ID": 1000,
      Mo: "Thompson",
      "Model ID": 200,
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 374000
    },
    {
      Bid: "6772",
      Co: "Summers Cay",
      "Community ID": 1000,
      Mo: "Browning",
      "Model ID": 200,
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1477,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 332000
    },
    {
      Bid: "6771",
      Co: "Windsong TH",
      "Community ID": 1000,
      Mo: "Navarre",
      "Model ID": 200,
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 272000
    },
    {
      Bid: "6771",
      Co: "Windsong TH",
      "Community ID": 1000,
      Mo: "Grayton",
      "Model ID": 200,
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 273000
    },
    {
      Bid: "6771",
      Co: "Windsong Estates 70'",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 460000
    },
    {
      Bid: "6771",
      Co: "Windsong Estates 70'",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1981,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 397000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2114,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 423000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 460000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 478000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 490000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 500000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 510000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 520000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 610000
    },
    {
      Bid: "6771",
      Co: "Highpointe",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Classic",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 345000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 405000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Classic",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 405000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Classic",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Classic",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 448000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 464000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 530000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Classic",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3415,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 530000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 573000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 609000
    },
    {
      Bid: "6771",
      Co: "Willow Ridge Estate",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "Blackstill Lake Rd and Fosgate Rd",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 620000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Signature",
      "Community ID": 1000,
      Mo: "Cresswind",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 233000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Regal",
      "Community ID": 1000,
      Mo: "Hamden",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Regal",
      "Community ID": 1000,
      Mo: "Mill Run",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 311000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Signature",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 313000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Regal",
      "Community ID": 1000,
      Mo: "Talon",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Signature",
      "Community ID": 1000,
      Mo: "Tilden",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 335000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Regal",
      "Community ID": 1000,
      Mo: "Whitmore",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Signature",
      "Community ID": 1000,
      Mo: "Winthrop",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Signature",
      "Community ID": 1000,
      Mo: "Yellowstone",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Silverlake - Signature",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "Co Rd 557 and Gum Rd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 259000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 60s",
      "Community ID": 1000,
      Mo: "Merlot Grand",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 565000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 40s",
      "Community ID": 1000,
      Mo: "Harvey",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 289000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 40s",
      "Community ID": 1000,
      Mo: "Williston",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 363000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 60s",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 60s",
      "Community ID": 1000,
      Mo: "Mahogany",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2547,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 357000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 60s",
      "Community ID": 1000,
      Mo: "Mahogany Grand",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3365,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 472000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 60s",
      "Community ID": 1000,
      Mo: "Merlot",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2635,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 369000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 60s",
      "Community ID": 1000,
      Mo: "Scarlett",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 534000
    },
    {
      Bid: "6771",
      Co: "Waterset Wolf Creek 40s",
      "Community ID": 1000,
      Mo: "Sonora",
      "Model ID": 200,
      Str: "Covington Garden Dr & Wolf Crk Dr.",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 322000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Hip",
      CovA: 378000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Hip",
      CovA: 488000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Monroe",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 524000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3415,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Hip",
      CovA: 581000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Hip",
      CovA: 630000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Medina",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 430000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Slate Tile",
      RS: "Hip",
      CovA: 484000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Slate Tile",
      RS: "Gable",
      CovA: 492000
    },
    {
      Bid: "6771",
      Co: "Savannah Palms",
      "Community ID": 1000,
      Mo: "Heston",
      "Model ID": 200,
      Str: "Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve TH's",
      "Community ID": 1000,
      Mo: "Foxtail",
      "Model ID": 200,
      Str: "Lot 156 TBD Sapling Sprout Dri",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve TH's",
      "Community ID": 1000,
      Mo: "Trailwood",
      "Model ID": 200,
      Str: "Lot 156 TBD Sapling Sprout Dri",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 100000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve 50's",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 333000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve 50's",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 370000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve 50's",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 408000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve 50's",
      "Community ID": 1000,
      Mo: "Spruce",
      "Model ID": 200,
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 421000
    },
    {
      Bid: "6771",
      Co: "Pinewood Reserve 50's",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2397,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 439000
    },
    {
      Bid: "6771",
      Co: "Stillmont Townhomes",
      "Community ID": 1000,
      Mo: "Evergreen",
      "Model ID": 200,
      Str: "Lowell Rd",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1451,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 310000
    },
    {
      Bid: "6771",
      Co: "Stillmont Townhomes",
      "Community ID": 1000,
      Mo: "Sycamore",
      "Model ID": 200,
      Str: "Lowell Rd",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1762,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Stillmont Townhomes",
      "Community ID": 1000,
      Mo: "Ashe",
      "Model ID": 200,
      Str: "Lowell Rd",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1818,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 350000
    },
    {
      Bid: "6771",
      Co: "Legacy Groves Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "Off Albee Farm Road",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Legacy Groves Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "Off Albee Farm Road",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 273000
    },
    {
      Bid: "6771",
      Co: "Legacy Groves Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "Off Albee Farm Road",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 292000
    },
    {
      Bid: "6771",
      Co: "Legacy Groves Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grande",
      "Model ID": 200,
      Str: "Off Albee Farm Road",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Legacy Groves Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "Off Albee Farm Road",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 234000
    },
    {
      Bid: "6771",
      Co: "Caymas",
      "Community ID": 1000,
      Mo: "Brixton",
      "Model ID": 200,
      Str: "Off Collier Blvd 2 Miles",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 332000
    },
    {
      Bid: "6771",
      Co: "Caymas",
      "Community ID": 1000,
      Mo: "Regency",
      "Model ID": 200,
      Str: "Off Collier Blvd 2 Miles",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "Caymas",
      "Community ID": 1000,
      Mo: "Brixton Grande",
      "Model ID": 200,
      Str: "Off Collier Blvd 2 Miles",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3318,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 465000
    },
    {
      Bid: "6771",
      Co: "Caymas",
      "Community ID": 1000,
      Mo: "Regency Grande",
      "Model ID": 200,
      Str: "Off Collier Blvd 2 Miles",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 534000
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Vista",
      "Community ID": 1000,
      Mo: "Sealine",
      "Model ID": 200,
      Str: "Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Townhomes",
      "Community ID": 1000,
      Mo: "Ivy",
      "Model ID": 200,
      Str: "Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 339000
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Townhomes",
      "Community ID": 1000,
      Mo: "Rowan",
      "Model ID": 200,
      Str: "Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 343000
    },
    {
      Bid: "6771",
      Co: "Avanti at Waterside - Vista",
      "Community ID": 1000,
      Mo: "Coastview",
      "Model ID": 200,
      Str: "Off Lakewood Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 362000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 232000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Distinctive",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 233000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 265000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 292000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Echelon",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 322000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Scenic",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 367000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grande",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 402000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Distinctive",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 406000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Distinctive",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 479000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Echelon",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 535000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Echelon",
      "Community ID": 1000,
      Mo: "Easley Grande",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 588000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Echelon",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 376000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Echelon",
      "Community ID": 1000,
      Mo: "Roseland",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 599000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Scenic",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: 309000
    },
    {
      Bid: "6771",
      Co: "Bonita Del Sol Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "Off Paradise Rd",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: 197000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Cedar",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 303000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Highgate",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 320000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 323000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 458000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 390000
    },
    {
      Bid: "6771",
      Co: "Rolling Hills",
      "Community ID": 1000,
      Mo: "Trailside Select",
      "Model ID": 200,
      Str: "Rolling Hills Drive",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 348000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Scenic",
      "Community ID": 1000,
      Mo: "Compass",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 295000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Scenic",
      "Community ID": 1000,
      Mo: "Hallmark",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 331000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Distinctive",
      "Community ID": 1000,
      Mo: "Palmary",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 387000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 390000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 416000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Echelon",
      "Community ID": 1000,
      Mo: "Stardom",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 454000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Echelon",
      "Community ID": 1000,
      Mo: "Stellar",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 497000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Echelon",
      "Community ID": 1000,
      Mo: "Reverence",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 534000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Echelon",
      "Community ID": 1000,
      Mo: "Renown",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 562000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Echelon",
      "Community ID": 1000,
      Mo: "Stellar Grand",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 622000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 378000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Scenic",
      "Community ID": 1000,
      Mo: "Contour",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 281000
    },
    {
      Bid: "6773",
      Co: "Del Webb at Viera - Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "Stadium Parkway",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 334000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Distinctive",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "SW 80th Avenue",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 283000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Distinctive",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "SW 80th Avenue",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 291000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Distinctive",
      "Community ID": 1000,
      Mo: "Mainstay",
      "Model ID": 200,
      Str: "SW 80th Avenue",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: 294000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Distinctive",
      "Community ID": 1000,
      Mo: "Prestige",
      "Model ID": 200,
      Str: "SW 80th Avenue",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 300000
    },
    {
      Bid: "6773",
      Co: "Stone Creek - Distinctive",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "SW 80th Avenue",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 385000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Morris",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 394000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Henley",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 409000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Estates",
      "Community ID": 1000,
      Mo: "Easley",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 530000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Estates",
      "Community ID": 1000,
      Mo: "Ashby Grand",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 579000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Estates",
      "Community ID": 1000,
      Mo: "Oakhurst",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 598000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Estates",
      "Community ID": 1000,
      Mo: "Easley Grand",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 627000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Classics",
      "Community ID": 1000,
      Mo: "Whitestone",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 462000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Classics",
      "Community ID": 1000,
      Mo: "Mystique Grand",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 492000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Drayton",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 373000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Classics",
      "Community ID": 1000,
      Mo: "Prosperity",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 334000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Classics",
      "Community ID": 1000,
      Mo: "Mystique",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 379000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Classics",
      "Community ID": 1000,
      Mo: "Yorkshire",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 514000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Classics",
      "Community ID": 1000,
      Mo: "Tower",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 440000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Estates",
      "Community ID": 1000,
      Mo: "Ashby",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 500000
    },
    {
      Bid: "6771",
      Co: "Serenoa Lakes - Gardens",
      "Community ID": 1000,
      Mo: "Trailside",
      "Model ID": 200,
      Str: "TBD Saw Palmetto Lane",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 610000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 40'",
      "Community ID": 1000,
      Mo: "Winstone",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 508000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 40'",
      "Community ID": 1000,
      Mo: "Lakeshore",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 540000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 40'",
      "Community ID": 1000,
      Mo: "Hideaway",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3127,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 600000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 40'",
      "Community ID": 1000,
      Mo: "Seashore",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3286,
      Sto: "2",
      Be: "7",
      Ba: "5.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 630000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 50'",
      "Community ID": 1000,
      Mo: "Clearwater",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "9",
      Ba: "6",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 810000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 50'",
      "Community ID": 1000,
      Mo: "Clearwater Grand",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "10",
      Ba: "8",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 850000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay 50'",
      "Community ID": 1000,
      Mo: "Baymont",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "8",
      Ba: "6",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 765000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay TH",
      "Community ID": 1000,
      Mo: "Castaway - Interior",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 330000
    },
    {
      Bid: "6771",
      Co: "Windsor Cay TH",
      "Community ID": 1000,
      Mo: "Castaway - End",
      "Model ID": 200,
      Str: "US 27 and Woodcrest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2276,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 340000
    },
    {
      Bid: "6771",
      Co: "Belle Haven",
      "Community ID": 1000,
      Mo: "Daylen",
      "Model ID": 200,
      Str: "US Hwy 27 and Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: 305000
    },
    {
      Bid: "6771",
      Co: "Belle Haven",
      "Community ID": 1000,
      Mo: "Mill Run",
      "Model ID": 200,
      Str: "US Hwy 27 and Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: 325000
    },
    {
      Bid: "6771",
      Co: "Belle Haven",
      "Community ID": 1000,
      Mo: "Talon",
      "Model ID": 200,
      Str: "US Hwy 27 and Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: 393000
    }
  ],
  id: "4407afb9-c0ea-45b1-b66c-80e7db2d8a5d",
  _rid: "TZxQAN2VotZ8AQAAAAAAAA==",
  _self: "dbs/TZxQAA==/colls/TZxQAN2VotY=/docs/TZxQAN2VotZ8AQAAAAAAAA==/",
  _etag: '"3603bd5b-0000-0100-0000-675c453d0000"',
  _attachments: "attachments/",
  _ts: 1734100285
}
