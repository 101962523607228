export const communityMasterDEV = {
  Client: "EMBARK",
  Table: "CommunityModelAddress",
  Rows: [
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Garden",
      Mo: "Candlewood",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Garden",
      Mo: "Daylen",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Garden",
      Mo: "Beachwood",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22690",
      Co: "Normandy Pines",
      Mo: "Landmark",
      Str: "10122 Deep Pine Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1526,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22690",
      Co: "Normandy Pines",
      Mo: "Charter",
      Str: "10122 Deep Pine Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Mystique",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "397000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 40s",
      Mo: "Trailside",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "569000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Mystique Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "636000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Whitestone",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "642000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 40s",
      Mo: "Westland",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "716000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Berkley",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "752000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 50s",
      Mo: "Yorkshire",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "784000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 60s",
      Mo: "Roseland",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "1034000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 60s",
      Mo: "Easley",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 60s",
      Mo: "Ashby",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 40s",
      Mo: "Seaport",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2068,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "438000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Monroe",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "752000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Ashby Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "808000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Easley Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4214,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "927000"
    },
    {
      Bid: "22689",
      Co: "Parkview Reserve - 70s",
      Mo: "Berkley Grand",
      Str: "10311 Park Estates Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "930000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Mystique",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Prestige",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Trailside",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "523000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Whitestone",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Ventana Pointe",
      Mo: "Yorkshire",
      Str: "10339 Ventana Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "598000"
    },
    {
      Bid: "22690",
      Co: "Estates of Lake Florence",
      Mo: "Hanover",
      Str: "1067 Michelangelo Lane",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 50'",
      Mo: "Mystique",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 40'",
      Mo: "Juniper",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 40'",
      Mo: "Monterey",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 60'",
      Mo: "Ashby",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 40'",
      Mo: "Windsor",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2627,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "526000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 60'",
      Mo: "Easley",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 50'",
      Mo: "Mystique Grand",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "574000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 50'",
      Mo: "Whitestone",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 50'",
      Mo: "Yorkshire",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "624000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 60'",
      Mo: "Ashby Grand",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "644000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 50'",
      Mo: "Valencia",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4342,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "656000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 60'",
      Mo: "Oakhurst",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "731000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 60'",
      Mo: "Upton",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "751000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona 60'",
      Mo: "Easley Grand",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "798000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona TH",
      Mo: "Foxtail",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Isles of Lake Nona TH",
      Mo: "Trailwood",
      Str: "10749 Pahokee Beach Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Classic",
      Mo: "Mainstay",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2095,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "419000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Estate",
      Mo: "Stardom",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Estate",
      Mo: "Stellar",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Classic",
      Mo: "Fifth Avenue",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Estate",
      Mo: "Reverence",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Estate",
      Mo: "Renown",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Estate",
      Mo: "Whitestone",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Estate",
      Mo: "Millstone",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3427,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "686000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Classic",
      Mo: "Prestige",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Classic",
      Mo: "Mystique",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2019,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "404000"
    },
    {
      Bid: "22689",
      Co: "Avondale at Avenir- Classic",
      Mo: "Riverwalk",
      Str: "10932 Stellar Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "536000"
    },
    {
      Bid: "22689",
      Co: "Parkview at Hillcrest - Executive Series",
      Mo: "Brookstream",
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1906,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "507000"
    },
    {
      Bid: "22689",
      Co: "Parkview at Hillcrest - Executive Series",
      Mo: "Raritan",
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "504000"
    },
    {
      Bid: "22689",
      Co: "Parkview at Hillcrest 45'",
      Mo: "Orleans",
      Str: "1101 Hillcrest Drive",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1825,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "447000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 50'",
      Mo: "Chapman",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "347000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 50'",
      Mo: "Hanover",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 40'",
      Mo: "McNair",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 50'",
      Mo: "Seabrook",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 50'",
      Mo: "Thompson",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "384000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 40'",
      Mo: "Tybee",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 50'",
      Mo: "Wakefield",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "Isles at BayView 40'",
      Mo: "Daniel",
      Str: "11014 Moonsail Drive",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "338000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Mystique",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Garden",
      Mo: "Compass",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Garden",
      Mo: "Hallmark",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Estate",
      Mo: "Stellar",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Palmary",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Garden",
      Mo: "Contour",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Prosperity",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Classic",
      Mo: "Prestige",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Villas",
      Mo: "Colton",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1322,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "186000"
    },
    {
      Bid: "22689",
      Co: "Costa Pointe Estate",
      Mo: "Stardom",
      Str: "1116 Haven Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32960,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Highgate",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "259000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Mystique",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Yorkshire",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Mystique Grand",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Cedar",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "233000"
    },
    {
      Bid: "22689",
      Co: "Addison Square",
      Mo: "Whitestone",
      Str: "11174 Addison Lake Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mystique Grand",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Garden",
      Mo: "Contour",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "269000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Garden",
      Mo: "Compass",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Garden",
      Mo: "Hallmark",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "346000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mainstay Grand",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "467000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Stellar",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "473000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Renown",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2710,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "488000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Stellar Grand",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "568000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Prosperity",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mystique",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "362000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Palmary",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "372000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Classic",
      Mo: "Mainstay",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Prestige",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "398000"
    },
    {
      Bid: "22691",
      Co: "Del Webb E Town Estate",
      Mo: "Stardom",
      Str: "11246 Town View Dr",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "436000"
    },
    {
      Bid: "22689",
      Co: "Esplanade Lake Club",
      Mo: "Ellenwood",
      Str: "11661 Venetian Lagoon Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Highgate",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "351000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Mystique",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "359000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Tower",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Whitestone",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Ashby",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "421000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Easley",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Signature",
      Mo: "Yorkshire",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Yorkshire",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Ashby Grand",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "491000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Oakhurst",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Easley Grand",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Cedarbrook Elite",
      Mo: "Roseland",
      Str: "12047 Orchid Ash Street",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Lakespur at Wellen Park",
      Mo: "Prosperity",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "234000"
    },
    {
      Bid: "22689",
      Co: "Lakespur at Wellen Park",
      Mo: "Palmary",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "273000"
    },
    {
      Bid: "22689",
      Co: "Lakespur at Wellen Park",
      Mo: "Prestige",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Lakespur at Wellen Park",
      Mo: "Whitestone",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Lakespur at Wellen Park",
      Mo: "Mystique",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Lakespur at Wellen Park",
      Mo: "Mystique Grand",
      Str: "12275 Mercado Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Belle Haven TH",
      Mo: "Marigold",
      Str: "1234 Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22689",
      Co: "Belle Haven TH",
      Mo: "Springdale",
      Str: "1234 Dunson Rd",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "279000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Villa",
      Mo: "Ellenwood",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "222000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Distinctive",
      Mo: "Prosperity",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Distinctive",
      Mo: "Mystique",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Distinctive",
      Mo: "Palmary",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Distinctive",
      Mo: "Prestige",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Ecehlon",
      Mo: "Stardom",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Ecehlon",
      Mo: "Stellar",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "368000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Ecehlon",
      Mo: "Reverence",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Distinctive",
      Mo: "Mystique Grande",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Distinctive",
      Mo: "Whitestone",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Ecehlon",
      Mo: "Renown",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Babcock TerraWalk Ecehlon",
      Mo: "Layton",
      Str: "12532 Quartz Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Henley",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "347000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Marina",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "296000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Canopy",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1972,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Driftwood",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Citrus Grove",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "397000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Trailside",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "411000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Tower",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Sandhill",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Palm",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Easley",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "487000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Dockside",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Crestwood",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Garden",
      Mo: "Tropic",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1565,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Highgate",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Whitestone",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Estates",
      Mo: "Ashby",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "459000"
    },
    {
      Bid: "22689",
      Co: "Heron Preserve Classic",
      Mo: "Cedar",
      Str: "12693 SW Captiva Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Daniel",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Chapman",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Hamden",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Hanover",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "McNair",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "347000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Seabrook",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Thompson",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Bay",
      Mo: "Tybee",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22690",
      Co: "Oakfield Lakes Heritage",
      Mo: "Wakefield",
      Str: "12713 Wanderlust Place",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Contour",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "268000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Compass",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Hallmark",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "351000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Garden",
      Mo: "Compass Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1984,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "356000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mystique",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "362000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Palmary",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "372000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mainstay",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Prestige",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "384000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Stardom",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "438000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mainstay Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Stellar",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Mystique Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "468000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Renown",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "473000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Classic",
      Mo: "Prosperity",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "319000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Estate",
      Mo: "Stellar Grand",
      Str: "128 Eureka Court",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "571000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Wildlight Villa",
      Mo: "Ellenwood",
      Str: "128 Eureka Court",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1529,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22691",
      Co: "DW @ Traditions Villas",
      Mo: "Cressida",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 52'",
      Mo: "Prosperity",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 52'",
      Mo: "Mainstay",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 52'",
      Mo: "Prestige",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 66'",
      Mo: "Stardom",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "452000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 66'",
      Mo: "Stellar",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 66'",
      Mo: "Reverence",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "534000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 66'",
      Mo: "Renown",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "562000"
    },
    {
      Bid: "22691",
      Co: "DW @ Traditions Villas",
      Mo: "Ellenwood",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 52'",
      Mo: "Mystique",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22691",
      Co: "DW @ Tradition 52'",
      Mo: "Palmary",
      Str: "12914 SW Gingerline Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Prosperity",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mystique",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Palmary",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mainstay",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Stardom",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Stellar",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Reverence",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "534000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mainstay Grand",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Renown",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Mystique Grand",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Echelon",
      Mo: "Stellar Grand",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Distinctive",
      Mo: "Prestige",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Oasis Villas",
      Mo: "Ellenwood",
      Str: "14001 Hartzog Rd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Stardom",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Stellar",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Mainstay Grand",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Renown",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "542000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Mystique Grand",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Stellar Grand",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Scenic",
      Mo: "Contour",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "281000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Scenic",
      Mo: "Compass",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "294000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Scenic",
      Mo: "Hallmark",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Prosperity",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Palmary",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Mainstay",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Prestige",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Distinctive",
      Mo: "Mystique",
      Str: "1411 Sunset Crest Way",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "456000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Minneola Echelon",
      Mo: "Reverence",
      Str: "1411 Sunset Crest Way",
      Ct: "Clermont",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "533000"
    },
    {
      Bid: "22690",
      Co: "Sawgrass at Coral Lakes",
      Mo: "Ivy",
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "240000"
    },
    {
      Bid: "22690",
      Co: "Sawgrass at Coral Lakes",
      Mo: "Alder",
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "266000"
    },
    {
      Bid: "22690",
      Co: "Sawgrass at Coral Lakes",
      Mo: "Rowan",
      Str: "1412 Weeping Willow Ct",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Ashby",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "421000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Easley",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Yorkshire",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Ashby Grand",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Oakhurst",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Roseland",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Hawks Reserve",
      Mo: "Easley Grand",
      Str: "15305 Boyette Road",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "729000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Monroe",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Mahogany Grand",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3129,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Merlot Grand",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3964,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "572000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Mahogany",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "476000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Merlot",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2635,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "493000"
    },
    {
      Bid: "22689",
      Co: "Hawks Grove",
      Mo: "Roseland",
      Str: "15405 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Highgate",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "393000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Mystique",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "399000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Tower",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Whitestone",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Ashby",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "466000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Mystique Grand",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Signature",
      Mo: "Yorkshire",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Easley",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "484000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Yorkshire",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Oakhurst",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Ashby Grand",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "547000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Easley Grand",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Hammock Crest Elite",
      Mo: "Roseland",
      Str: "16119 Boyette Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Aventura",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "532000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Regalia",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2713,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "543000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Layton",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "604000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Madeira",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "22689",
      Co: "Ardena",
      Mo: "Sanibel",
      Str: "16268 Verilyn Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34110,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "877000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      Mo: "Crestwood",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      Mo: "Drayton",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "316000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      Mo: "Cedar",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      Mo: "Highgate",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      Mo: "Mystique",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      Mo: "Morris",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 42'",
      Mo: "Trailside",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "442000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      Mo: "Ashby",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      Mo: "Easley",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      Mo: "Whitestone",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "549000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      Mo: "Yorkshire",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "581000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      Mo: "Oakhurst",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      Mo: "Roseland",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "701000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      Mo: "Upton",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "884000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 52'",
      Mo: "Mystique Grand",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Sapphire Point at Lakewood Ranch 62'",
      Mo: "Easley Grande",
      Str: "16411 Sapphire Point Drive",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club Villas",
      Mo: "Kendrick",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "302000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club Villas",
      Mo: "Ellenwood",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Summerwood",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Martin Ray",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1968,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Pinnacle",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Palm",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Dockside",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Stonewater",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "499000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 55'",
      Mo: "Abbeyville",
      Str: "16504 Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1671,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Medina",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Yellowstone",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Winthrop",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "677000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Cresswind",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Heston",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Classic",
      Mo: "Tilden",
      Str: "1697 Groveline Road",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "541000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Scenic",
      Mo: "Contour",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Scenic",
      Mo: "Compass",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Scenic",
      Mo: "Hallmark",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Prosperity",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Mystique",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Palmary",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Prestige",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "343000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Stardom",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "363000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Stellar",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Reverence",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Distinctive",
      Mo: "Mainstay",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "341000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Layton",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Layton Grande",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22691",
      Co: "Oak Creek- Echelon",
      Mo: "Renown",
      Str: "17520 Winding Oak Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "422000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Classic",
      Mo: "Highgate",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "314000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Classic",
      Mo: "Mystique",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Estate",
      Mo: "Ashby",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Estate",
      Mo: "Easley",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "443000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Estate",
      Mo: "Oakhurst",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "669000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Classic",
      Mo: "Spruce",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Classic",
      Mo: "Whitestone",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "507000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Classic",
      Mo: "Yorkshire",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "594000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch Estate",
      Mo: "Roseland",
      Str: "17528 Silverspur Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "748000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Ashby",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Easley",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Ashby Grand",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "532000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Easley Grand",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "692000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Estate",
      Mo: "Monroe",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "509000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Benton",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Camden",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "323000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Haddock",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Jasmine",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Elliot",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2344,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Mabel",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Schooner",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2565,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Amelia Groves - Bungalow",
      Mo: "Talbot",
      Str: "1756 Pine Grove Rd",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Ocean Cove",
      Mo: "Grayton",
      Str: "1822 SE Ocean Cove Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34996,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "Ocean Cove",
      Mo: "Seacrest",
      Str: "1822 SE Ocean Cove Way",
      Ct: "Stuart",
      St: "FL",
      Zip: 34996,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1814,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "363000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Scenic",
      Mo: "Contour",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Scenic",
      Mo: "Hallmark",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Prosperity",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "413000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Mystique",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "422000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Prestige",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "434000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Scenic",
      Mo: "Trailside",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "444000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Whitestone",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "524000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Executive",
      Mo: "Yorkshire",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "567000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Stardom",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "496000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Stellar",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Renown",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "522000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Layton Grande",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "1",
      Be: "4",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Roseland",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Upton",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "751000"
    },
    {
      Bid: "22689",
      Co: "Verdana Village Echelon",
      Mo: "Layton",
      Str: "18405 Parksville Drive",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "4",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Scenic",
      Mo: "Contour",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "197000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Scenic",
      Mo: "Compass",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "215000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Prosperity",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Mystique",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Palmary",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "273000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Mainstay",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Distinctive",
      Mo: "Prestige",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Stellar",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Reverence",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Renown",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "394000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Echelon",
      Mo: "Stardom",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Arbor Oaks Scenic",
      Mo: "Hallmark",
      Str: "1884 Pepper Grass Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "232000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Stonewater",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Stonewater w/ Loft",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Pinnacle",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 66'",
      Mo: "Tangerly Oak",
      Str: "19001 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Stonewater",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "514000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Summerwood",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Pinnacle",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue",
      Mo: "Tangerly Oak",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Wild Blue 52'",
      Mo: "Summerwood",
      Str: "19005 Aquashore Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Ashby Grand",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3284,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Ashby",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Easley",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1.5",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Renown",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Oakhurst",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Berkley",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Easley Grand",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Berkley Grand",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "593000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Roseland",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "609000"
    },
    {
      Bid: "22689",
      Co: "Sunset Preserve",
      Mo: "Upton",
      Str: "19055 Old Lake Pickett Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32820,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5336,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "748000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Contour",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Compass",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Hallmark",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Palmary",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Prestige",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 40'",
      Mo: "Trailside",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "468000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Whitestone",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "541000"
    },
    {
      Bid: "22689",
      Co: "Manatee Cove 50'",
      Mo: "Mystique",
      Str: "1959 Manatee Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Aventura",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Regalia",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2713,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "611000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Layton",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "5",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Oakley",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "743000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Sanibel",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "987000"
    },
    {
      Bid: "22689",
      Co: "Corkscrew Estates",
      Mo: "Madeira",
      Str: "19770 Panther Island Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Villas",
      Mo: "Ellenwood",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Prosperity",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Palmary",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "396000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mystique",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mainstay",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mainstay Grand",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Reserve Classic",
      Mo: "Mystique Grand",
      Str: "2004 Spring Shower Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "487000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Hamden",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Daniel",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "McNair",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Garden",
      Mo: "Tybee",
      Str: "2008 Canopy Terrace Blvd",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Spruce",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Tower",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Whitestone",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Highgate",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Canopy Terrace Classic",
      Mo: "Cedar",
      Str: "2008 Canopy Terrace Boulevard",
      Ct: "Deland",
      St: "FL",
      Zip: 32724,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Oakley",
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Steel / Metal",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Aventura",
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3796,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1100000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Madeira",
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3988,
      Sto: "2",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1200000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Layton",
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4020,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1210000"
    },
    {
      Bid: "22689",
      Co: "Bridgewater",
      Mo: "Livingston",
      Str: "20128 SE Bridgewater Drive",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5376,
      Sto: "2",
      Be: "6",
      Ba: "6.5",
      CT: "Frame",
      RT: "Steel / Metal",
      RS: "Hip",
      CovA: "1400000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Gardens",
      Mo: "Drayton",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1509,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Gardens",
      Mo: "Henley",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1720,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Gardens",
      Mo: "Morris",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2093,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Gardens",
      Mo: "Trailside",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "438000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes-Estates",
      Mo: "Ashby",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "474000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes-Estates",
      Mo: "Easley",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "516000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes-Estates",
      Mo: "Oakhurst",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes-Estates",
      Mo: "Ashby Grand",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3105,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes-Estates",
      Mo: "Easley Grand",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes-Estates",
      Mo: "Roseland",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4117,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "623000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Classics",
      Mo: "Prestige",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Classics",
      Mo: "Mystique",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "434000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Classics",
      Mo: "Trailside Select",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Classics",
      Mo: "Whitestone",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "486000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Classics",
      Mo: "Mystique Grand",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "502000"
    },
    {
      Bid: "22689",
      Co: "The Preserve at Bannon Lakes - Classics",
      Mo: "Yorkshire",
      Str: "205 Cedar Preserve Lane",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Morris",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "368000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Trailside",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "372000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Henley",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Drayton",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 40'",
      Mo: "Crestwood",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "201000"
    },
    {
      Bid: "22689",
      Co: "Arden Preserve 50'",
      Mo: "Mystique",
      Str: "20523 Monza Loop",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "299000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Daylen",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Mill Run",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Cresswind",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Sonora",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Heston",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Talon",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Medina",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Tilden",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "414000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Whitmore",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "443000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Ashby",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Winthrop",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "457000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Easley",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Signature",
      Mo: "Yellowstone",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "483000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Oakhurst",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Ashby Grand",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "544000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Easley Grand",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Elite",
      Mo: "Roseland",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Whispering Pines Regal",
      Mo: "Harvey",
      Str: "20876 Drake Elm Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Contour",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "296000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Compass",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1403,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "302000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Hallmark",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1581,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "312000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Prosperity",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1600,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Gardens",
      Mo: "Compass Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1984,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mystique",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Palmary",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "359000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mainstay",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1872,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "359000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Prestige",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mainstay Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "409000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Classics",
      Mo: "Mystique Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "414000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Stardom",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "429000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Stellar",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Renown",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "464000"
    },
    {
      Bid: "22689",
      Co: "Summer Bay Estates",
      Mo: "Stellar Grand",
      Str: "22 Myrtle Oak Court",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3339,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "487000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Classics",
      Mo: "Highgate",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "266000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Classics",
      Mo: "Spruce",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Classics",
      Mo: "Tower",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Classics",
      Mo: "Whitestone",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Classics",
      Mo: "Mystique",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Classics",
      Mo: "Yorkshire",
      Str: "220 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Enclave at Sherwood Park",
      Mo: "Mainstay",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "22689",
      Co: "Enclave at Sherwood Park",
      Mo: "Mystique",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "22689",
      Co: "Enclave at Sherwood Park",
      Mo: "Palmary",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "22689",
      Co: "Enclave at Sherwood Park",
      Mo: "Prestige",
      Str: "229 Geillis Path",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33445,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Spruce",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Cedar",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "318000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Highgate",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Tower",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "412000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Whitestone",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Grand Oaks",
      Mo: "Yorkshire",
      Str: "23 Myrtle Oak Ct.",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "477000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 53'",
      Mo: "Mystique",
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "476000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 53'",
      Mo: "Palmary",
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "489000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 53'",
      Mo: "Prestige",
      Str: "2325 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "524000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 67'",
      Mo: "Stardom",
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "653000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 67'",
      Mo: "Stellar",
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "713000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 67'",
      Mo: "Reverence",
      Str: "2340 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "768000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort TH",
      Mo: "Castaway - End",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2265,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "43400"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort TH",
      Mo: "Castaway - Interior",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2263,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "434000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Winstone",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2491,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Lakeshore",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "503000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Hideaway",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3117,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "592000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 40'",
      Mo: "Seashore",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3281,
      Sto: "2",
      Be: "7",
      Ba: "5.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 50'",
      Mo: "Baymont",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "8",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "721000"
    },
    {
      Bid: "22689",
      Co: "Windsor Island Resort - 50'",
      Mo: "Clearwater",
      Str: "2341 Luxor Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33897,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4398,
      Sto: "2",
      Be: "9",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 20' TH",
      Mo: "Seacrest",
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 24' TH",
      Mo: "Adirondack",
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 24' TH",
      Mo: "Leland",
      Str: "2347 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 40'",
      Mo: "Alexander",
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1961,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "432000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 40'",
      Mo: "Nelson",
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2168,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "478000"
    },
    {
      Bid: "22689",
      Co: "Oak Tree 40'",
      Mo: "Sienna",
      Str: "2350 Rollingwod Court",
      Ct: "Oakland Park",
      St: "FL",
      Zip: 33309,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2220,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes at River Ha",
      Mo: "Cedar",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "314000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes at River Ha",
      Mo: "Highgate",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes at River Ha",
      Mo: "Mystique",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes at River Ha",
      Mo: "Whitestone",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "22689",
      Co: "Hampton Lakes at River Ha",
      Mo: "Yorkshire",
      Str: "2401 River Hall Pkwy",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "584000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Cedar",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Highgate",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "314000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Mystique",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "326000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Ashby",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Tower",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "336000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Trailside Select",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Easley",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "371000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Whitestone",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Ashby Grand",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "478000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Yorkshire",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Oakhurst",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "554000"
    },
    {
      Bid: "22689",
      Co: "Bradley Creek",
      Mo: "Easley Grand",
      Str: "2429 Russell Road",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "612000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove at the Woodlands",
      Mo: "Highgate",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "288000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove at the Woodlands",
      Mo: "Mystique",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove at the Woodlands",
      Mo: "Trailside",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove at the Woodlands",
      Mo: "Whitestone",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove at the Woodlands",
      Mo: "Yorkshire",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22690",
      Co: "Cedar Grove at the Woodlands",
      Mo: "Cedar",
      Str: "2450 Charter Oak Dr",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22689",
      Co: "The Strand at Cedar Grove",
      Mo: "Cedar",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "233000"
    },
    {
      Bid: "22689",
      Co: "The Strand at Cedar Grove",
      Mo: "Mystique",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22689",
      Co: "The Strand at Cedar Grove",
      Mo: "Yorkshire",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "The Strand at Cedar Grove",
      Mo: "Whitestone",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "The Strand at Cedar Grove",
      Mo: "Trailside",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "The Strand at Cedar Grove",
      Mo: "Highgate",
      Str: "2540 Charter Oak Drive",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "259000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk by Manasota Key Vista",
      Mo: "Cascadia",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "279000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk by Manasota Key Scenic",
      Mo: "Contour",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk by Manasota Key Scenic",
      Mo: "Compass",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk by Manasota Key Scenic",
      Mo: "Hallmark",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Distinctive",
      Mo: "Mystique",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Distinctive",
      Mo: "Palmary",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Distinctive",
      Mo: "Prestige",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Echelon",
      Mo: "Stardom",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Echelon",
      Mo: "Stellar",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Echelon",
      Mo: "Reverence",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "539000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Echelon",
      Mo: "Renown",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Distinctive",
      Mo: "Prosperity",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22689",
      Co: "Beachwalk by Manasota Key Vista",
      Mo: "Seagrove",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "274000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Echelon",
      Mo: "Layton Grande",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "BeachWalk by Manasota Key Echelon",
      Mo: "Layton",
      Str: "26061 Seastone Drive",
      Ct: "Englewood",
      St: "FL",
      Zip: 34223,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Cypress Falls Classic",
      Mo: "Prosperity",
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22691",
      Co: "Cypress Falls Classic",
      Mo: "Prestige",
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "364000"
    },
    {
      Bid: "22691",
      Co: "Cypress Falls Classic",
      Mo: "Mystique",
      Str: "2635 Myrrh Place",
      Ct: "North Port",
      St: "FL",
      Zip: 34289,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Adelaide",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Benton",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Dylan",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Camden",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Haddock",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Hazel",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1906,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "347000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Garden",
      Mo: "Drayton",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "356000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Cedar",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Jasmine",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "369000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Garden",
      Mo: "Morris",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "382000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Highgate",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "398000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Mabel",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "398000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Palmary",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Mystique",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Bungalows",
      Mo: "Talbot",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Tower",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2383,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "427000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Whitestone",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "456000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Garden",
      Mo: "Trailside",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "461000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Classic",
      Mo: "Mystique Grand",
      Str: "2649 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "492000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Townhomes",
      Mo: "Orchid",
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1553,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Townhomes",
      Mo: "Dahlia",
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "298000"
    },
    {
      Bid: "22689",
      Co: "Tohoqua Townhomes",
      Mo: "Julep",
      Str: "2657 Blowing Breeze Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Cedar",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "296000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Highgate",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Spruce",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "321000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Tower",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Ashby",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Easley",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Timber Cove Classics",
      Mo: "Whitestone",
      Str: "2727 Ivy Post Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Solis Grande",
      Mo: "Rowan",
      Str: "27869 Solis Grande Dr",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "232000"
    },
    {
      Bid: "22689",
      Co: "Solis Grande",
      Mo: "Alder",
      Str: "27869 Solis Grande Dr",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "255000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Ashby",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Easley",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "467000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Yorkshire",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "502000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Ashby Grand",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "533000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Oakhurst",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Easley Grand",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4193,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Roseland",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Willowbrooke",
      Mo: "Mystique",
      Str: "2873 Buckhorn Forest Drive",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2056,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "403000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Cresswind",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Heston",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Medina",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Tilden",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Marwood",
      Mo: "Winthrop",
      Str: "2910 61st Drive East",
      Ct: "Ellenton",
      St: "FL",
      Zip: 34222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Bungalows",
      Mo: "Juniper",
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2038,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Bungalows",
      Mo: "Capri",
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1820,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Hawthorn Park at Wildlight Bungalows",
      Mo: "Monterey",
      Str: "292 Hawthorn Park Circle",
      Ct: "Wildlight",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2195,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22689",
      Co: "Grayson Square",
      Mo: "Marigold",
      Str: "2945 Clever Lane",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Grayson Square",
      Mo: "Springdale II",
      Str: "2945 Clever Lane",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1907,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Cassia Commons",
      Mo: "Cobalt",
      Str: "3010 Hibiscus Avenue",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1547,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Cassia Commons",
      Mo: "Latitude",
      Str: "3010 Hibiscus Avenue",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Fifth Avenue",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Hamden",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Hanover",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Thompson",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "473000"
    },
    {
      Bid: "22689",
      Co: "Cassia Estates",
      Mo: "Chapman",
      Str: "3014 Hibiscus Ave",
      Ct: "Lauderdale Lakes",
      St: "FL",
      Zip: 33311,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Hamden",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "285000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Chapman",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "292000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Hanover",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "301000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Oasis",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "307000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Seabrook",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Thompson",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22690",
      Co: "Wesbridge Signature",
      Mo: "Wakefield",
      Str: "30411 Marquette Avenue",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace THs",
      Mo: "Marigold",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace THs",
      Mo: "Springdale",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Heston",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Mill Run",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Medina",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Estate",
      Mo: "Bloomfield",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Talon",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "432000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Winthrop",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Whitmore",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3162,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "521000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Garden",
      Mo: "Daylen",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Tilden",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Estate",
      Mo: "Everly",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2703,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "446000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Yellowstone",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22689",
      Co: "Hartford Terrace Classic",
      Mo: "Cresswind",
      Str: "3054 Norcott Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 70'",
      Mo: "Stellar",
      Str: "3260 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "River Hall Country Club 70'",
      Mo: "Renown",
      Str: "3260 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "394000"
    },
    {
      Bid: "22689",
      Co: "Lakeview Preserve 50'",
      Mo: "Yorkshire",
      Str: "3276 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Lakeview Preserve 50'",
      Mo: "Whitestone",
      Str: "3276 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Easley",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "592000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Whitestone",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "612000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Renown",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "614000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Oakhurst",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "681000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Ashby Grand",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "688000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Roseland",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "716000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Easley Grand",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "716000"
    },
    {
      Bid: "22689",
      Co: "Estates at Lakeview Preserve",
      Mo: "Ashby",
      Str: "3279 Buoy Circle",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "583000"
    },
    {
      Bid: "22689",
      Co: "Forest Park at Wildlight",
      Mo: "Highgate",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Forest Park at Wildlight",
      Mo: "Spruce",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Forest Park at Wildlight",
      Mo: "Tower",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Forest Park at Wildlight",
      Mo: "Whitestone",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Forest Park at Wildlight",
      Mo: "Yorkshire",
      Str: "336 Salt Meadow Loop",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "482000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Cresswind",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Heston",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Medina",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2142,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Classics",
      Mo: "Tilden",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Easley",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2856,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Ashby Grand",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Ashby",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "Hyland Trail Estates",
      Mo: "Yorkshire",
      Str: "3372 Americana Drive",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3459,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Cedar",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Highgate",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Mystique",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "323000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Ashby",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Trailside Select",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "348000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Easley",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "364000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Whitestone",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Ashby Grand",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Yorkshire",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "458000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Oakhurst",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3692,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "517000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Estates",
      Mo: "Easley Grand",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4077,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "571000"
    },
    {
      Bid: "22689",
      Co: "Double Branch - Classics",
      Mo: "Mystique Grand",
      Str: "3495 Kindlewood Drive",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "413000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Hanover",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "305000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Seabrook",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "314000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Thompson",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Thompson",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "351000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Bayshore",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "356000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Wakefield",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Wakefield",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Yellowstone",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Medina",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "354000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Everly",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2703,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "379000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Sterling",
      Mo: "Arbor",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2223,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "353000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Medina",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "324000"
    },
    {
      Bid: "22690",
      Co: "North Park Isle Heritage",
      Mo: "Chapman",
      Str: "3508 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "294000"
    },
    {
      Bid: "22689",
      Co: "Shoreline",
      Mo: "Adirondack",
      Str: "354 Surfside Ave NE",
      Ct: "Saint Petersburg",
      St: "FL",
      Zip: 33716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "372000"
    },
    {
      Bid: "22689",
      Co: "Shoreline",
      Mo: "Leland",
      Str: "354 Surfside Ave NE",
      Ct: "Saint Petersburg",
      St: "FL",
      Zip: 33716,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Jasmine",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Estate",
      Mo: "Easley",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "433000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Estate",
      Mo: "Monroe",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "493000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Catamaran",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2296,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Camden",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Estate",
      Mo: "Easley Grand",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4214,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Schooner",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2565,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Benton",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Dylan",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Haddock",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "336000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Highgate",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Bungalow",
      Mo: "Mabel",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Tower",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Yorkshire",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "512000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Whitestone",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "506000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Coral Grand",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Imperial",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Coral",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1946,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Winding Meadows- Classic",
      Mo: "Scarlett",
      Str: "4021 Winding Meadows St",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3602,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Hanover",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "279000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Chapman",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Thompson",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22690",
      Co: "Lake Alfred Pines",
      Mo: "Wakefield",
      Str: "411 Pine Tree Blvd.",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Cresswind",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Heston",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "298000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Winthrop",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "357000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Medina",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "323000"
    },
    {
      Bid: "22689",
      Co: "Valleybrook",
      Mo: "Tilden",
      Str: "4143 Big Sky Drive",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34604,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Stonewater w Loft",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "752000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Stonewater",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "571000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Garden Vista",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "1538000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Isles",
      Mo: "Tangerly Oak",
      Str: "4177 W Indiantown Rd",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33478,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "518000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Trailside",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Trailside Select",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Whitestone",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "417000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Mystique Grand",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2949,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "442000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Yorkshire",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22689",
      Co: "Cordova Palms",
      Mo: "Mystique",
      Str: "42 Sandoval Way",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Classic",
      Mo: "Prosperity",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Classic",
      Mo: "Mystique",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "398000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Classic",
      Mo: "Palmary",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Classic",
      Mo: "Mainstay",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Classic",
      Mo: "Prestige",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Estate",
      Mo: "Stardom",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "478000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Villa",
      Mo: "Ellenwood",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "307000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Estate",
      Mo: "Stellar",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "493000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Estate",
      Mo: "Reverence",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "506000"
    },
    {
      Bid: "22689",
      Co: "Preserve at Waterway - Estate",
      Mo: "Renown",
      Str: "4236 Basket Oak Circle",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "512000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Prosperity",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Mystique",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "356000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Mainstay",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 52'",
      Mo: "Prestige",
      Str: "42387 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "369000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 42'",
      Mo: "Contour",
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 42'",
      Mo: "Hallmark",
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 42'",
      Mo: "Compass",
      Str: "42395 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Stardom",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Stellar",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "438000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Renown",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "471000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Edgewater 66'",
      Mo: "Reverence",
      Str: "42403 Edgewater Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "456000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch - Discovery",
      Mo: "Browning",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "290000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch - Discovery",
      Mo: "Thompson",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch - Discovery",
      Mo: "Chapman",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "299000"
    },
    {
      Bid: "22689",
      Co: "Northridge at Babcock Ranch - Discovery",
      Mo: "Hanover",
      Str: "42497 Cascade Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "324000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Summerwood",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "266000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Oasis",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Citrus Grove",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "404000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Palm",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "418000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Dockside",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "421000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Mariner",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Layton",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "569000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Woodview",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3411,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Parkside",
      Mo: "Summerwood Loft",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "649000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Arbordale",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1980,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "351000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Cobblestone",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2212,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22689",
      Co: "Babcock Ranch Lake Timber",
      Mo: "Oakview",
      Str: "42850 Crescent Loop",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3654,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Cedar",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Highgate",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Mystique",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Palmary",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "387000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Prestige",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 66'",
      Mo: "Prestige",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 66'",
      Mo: "Ashby",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 66'",
      Mo: "Easley",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Whitestone",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "22689",
      Co: "Veranda Gardens 52'",
      Mo: "Tower",
      Str: "432 SE Bancroft Drive",
      Ct: "Port Saint Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Oaks",
      Mo: "Augusta",
      Str: "4397 Sonoma Oaks Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1901,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "267000"
    },
    {
      Bid: "22689",
      Co: "Sonoma Oaks",
      Mo: "Easton",
      Str: "4397 Sonoma Oaks Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34119,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2018,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "283000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Classic",
      Mo: "Winthrop",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "433000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Classic",
      Mo: "Yellowstone",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Garden",
      Mo: "Candlewood",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Classic",
      Mo: "Cresswind",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Garden",
      Mo: "Daylen",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Classic",
      Mo: "Heston",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "311000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Garden",
      Mo: "Mill Run",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "343000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Classic",
      Mo: "Medina",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Verde at Babcock Ranch - Garden",
      Mo: "Talon",
      Str: "44252 Kelly Drive",
      Ct: "Babcock Ranch",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "367000"
    },
    {
      Bid: "22690",
      Co: "Cypress Cay",
      Mo: "Marigold",
      Str: "4511 Cypress Cay Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22690",
      Co: "Cypress Cay",
      Mo: "Springdale",
      Str: "4511 Cypress Cay Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Capri",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "284000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Juniper",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "301000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Monterey",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2195,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "308000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Windsor",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond Bungalows",
      Mo: "Hickory",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Cedar",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "329000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Highgate",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Spruce",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "351000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Tower",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Ashby",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "381000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Whitestone",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22689",
      Co: "Bradley Pond",
      Mo: "Easley",
      Str: "4588 Creekside Bluff Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "391000"
    },
    {
      Bid: "22689",
      Co: "The Fields 50'",
      Mo: "Summerwood",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Fox Hollow",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1901,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "The Fields 50'",
      Mo: "Canopy",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1972,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22689",
      Co: "The Fields 50'",
      Mo: "Pompeii",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2104,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Fifth Avenue",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Riverwalk",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "The Fields 45'",
      Mo: "Citrus Grove",
      Str: "4610 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "566000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Mystique",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Palmary",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Prestige",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 50'",
      Mo: "Whitestone",
      Str: "4628 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "579000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Morris",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "403000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Trailside",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Whitestone",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Driftwood",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2470,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Mystique",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2056,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "458000"
    },
    {
      Bid: "22689",
      Co: "Bexley",
      Mo: "Yorkshire",
      Str: "4644 Ballantrae Boulevard",
      Ct: "Land O' Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "The Fields 65'",
      Mo: "Pinnacle",
      Str: "4652 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "498000"
    },
    {
      Bid: "22689",
      Co: "The Fields 65'",
      Mo: "Stonewater",
      Str: "4652 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "571000"
    },
    {
      Bid: "22689",
      Co: "The Fields 24' TH",
      Mo: "Seacrest",
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "363000"
    },
    {
      Bid: "22689",
      Co: "The Fields 24' TH",
      Mo: "Adirondack",
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "The Fields 24' TH",
      Mo: "Leland",
      Str: "4661 Eventing Street",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "Emerald Pointe",
      Mo: "Springdale",
      Str: "482 Beryl Cove",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1699,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "328000"
    },
    {
      Bid: "22689",
      Co: "Emerald Pointe",
      Mo: "Marigold",
      Str: "482 Beryl Cove",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Mystique",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2140,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Ashby",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2204,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "412000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Trailside Select",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Easley",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2622,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Whitestone",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Ashby Grand",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Yorkshire",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3459,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "22689",
      Co: "Wingate Landing",
      Mo: "Easley Grand",
      Str: "4836 Shell Creek Trail",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32226,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4099,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Cedar",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Spruce",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "387000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Tower",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "401000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Whitestone",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 40'",
      Mo: "Daniel",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 40'",
      Mo: "McNair",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 40'",
      Mo: "Tybee",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "399000"
    },
    {
      Bid: "22689",
      Co: "Cypress Hammock 50'",
      Mo: "Highgate",
      Str: "4939 Royal Point Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "369000"
    },
    {
      Bid: "22689",
      Co: "Copperleaf 125",
      Mo: "Oakley",
      Str: "5048 SW Sensation",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3301,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "22689",
      Co: "Copperleaf 125",
      Mo: "Aventura",
      Str: "5048 SW Sensation",
      Ct: "Palm City",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3796,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "1140000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Cedar",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Ashby",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Easley",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Whitestone",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "507000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Yorkshire",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Oakhurst",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "673000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Roseland",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "748000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Valencia",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4342,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Upton",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5363,
      Sto: "2",
      Be: "6",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "912000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Creekview",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Mystique",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Highgate",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Layton",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Layton Grande",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "Avalon Park at Ave Maria",
      Mo: "Mystique Grand",
      Str: "5126 Ave Maria Blvd",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Summerwood",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Ellenwood",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Martin Ray",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1968,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Palmary",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Prosperity",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Mainstay",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "362000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Prestige",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Stardom",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "429000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Reverence",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Renown",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Stellar",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Mystique",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "396000"
    },
    {
      Bid: "22689",
      Co: "Lakes at Waterway 50'",
      Mo: "Serenity",
      Str: "5178 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1542,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Court",
      Mo: "Hanover",
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Court",
      Mo: "Chapman",
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "342000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Court",
      Mo: "Browning",
      Str: "5185 Kipper Way",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "326000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Ashby",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "322000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Easley",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "376000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Whitestone",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Ashby Grand",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Yorkshire",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "479000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Spruce",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "303000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Easley Grand",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "588000"
    },
    {
      Bid: "22689",
      Co: "Foothills Preserve",
      Mo: "Roseland",
      Str: "5305 Foothills Preserve Loop",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "599000"
    },
    {
      Bid: "22690",
      Co: "Wesley Reserve at Chapel Crossing",
      Mo: "Evergreen",
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1451,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "279000"
    },
    {
      Bid: "22690",
      Co: "Wesley Reserve at Chapel Crossing",
      Mo: "Sycamore",
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1762,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22690",
      Co: "Wesley Reserve at Chapel Crossing",
      Mo: "Ashe",
      Str: "5369 Elmview Crossing",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1818,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Distinctive",
      Mo: "Summerwood",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "261000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Scenic",
      Mo: "Kendrick",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "237000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Scenic",
      Mo: "Hallmark",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "244000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Echelon",
      Mo: "Stardom",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Scenic",
      Mo: "Contour",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "337000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Scenic",
      Mo: "Compass",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "339000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Echelon",
      Mo: "Stellar",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "348000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Echelon",
      Mo: "Reverence",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "374000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Echelon",
      Mo: "Renown",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "394000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Heron",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1457,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "100000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Egret",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1538,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "105000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Spoonbill",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "110000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples - Carriag",
      Mo: "Cormorant",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1868,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "115000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Distinctive",
      Mo: "Dolce",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1570,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "249000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Distinctive",
      Mo: "Prosperity",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "270000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Distinctive",
      Mo: "Mystique",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Distinctive",
      Mo: "Palmary",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "288000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Distinctive",
      Mo: "Prestige",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "292000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples- Villas",
      Mo: "Seagrove",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples- Villas",
      Mo: "Cascadia",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples- Villas",
      Mo: "Kendrick",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1543,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Echelon",
      Mo: "Layton",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Naples-Echelon",
      Mo: "Layton Grande",
      Str: "6055 Anthem Pkwy",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3970,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "556000"
    },
    {
      Bid: "22689",
      Co: "Ranchette Square",
      Mo: "Grayton",
      Str: "6100 Rancho Lane",
      Ct: "Green Acres",
      St: "FL",
      Zip: 33415,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "Ranchette Square",
      Mo: "Navarre",
      Str: "6100 Rancho Lane",
      Ct: "Green Acres",
      St: "FL",
      Zip: 33415,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Scenic",
      Mo: "Contour",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Scenic",
      Mo: "Compass",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "324000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Scenic",
      Mo: "Hallmark",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Prosperity",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mystique",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Palmary",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mainstay",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Prestige",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Stardom",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Stellar",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mainstay Grand",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2682,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "511000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Distinctive",
      Mo: "Mystique Grand",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "521000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Reverence",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Renown",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "551000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Echelon",
      Mo: "Stellar Grand",
      Str: "6200 Citrus Grove Court",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "612000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Sunbridge Villas",
      Mo: "Ellenwood",
      Str: "6209 Citrus Grove Ct",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks TH",
      Mo: "Orchid",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1553,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "332000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 34'",
      Mo: "Benton",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "339000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks TH",
      Mo: "Dahlia",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks TH",
      Mo: "Julep",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "353000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 34'",
      Mo: "Haddock",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 34'",
      Mo: "Caden",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "424000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Coral Grand",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3017,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Cardinal",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1958,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Coral",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2006,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "402000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Imperial",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Scarlett",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3643,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "647000"
    },
    {
      Bid: "22689",
      Co: "Silverleaf Oaks - 50'",
      Mo: "Ruby",
      Str: "6292 Tabebuia Parkway",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2883,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "523000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Scenic",
      Mo: "Contour",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "247000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Scenic",
      Mo: "Compass",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "251000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Scenic",
      Mo: "Hallmark",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "262000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Stellar Grand",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3361,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "471000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Stellar",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "348000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Renown",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "394000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Echelon",
      Mo: "Stardom",
      Str: "6320 SW 89th Court Rd",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Passport",
      Mo: "Highgate",
      Str: "6320 SW 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "276000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Passport",
      Mo: "Cresswind",
      Str: "6320 SW 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "272000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Passport",
      Mo: "Heston",
      Str: "6320 SW 89th Court Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "278000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Contour",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Vista",
      Mo: "Seagrove",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Compass",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Vista",
      Mo: "Cascadia",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "321000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Hallmark",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Mystique",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Palmary",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "389000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Prestige",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Morris",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "441000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Stardom",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Whitestone",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "522000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Scenic",
      Mo: "Trailside",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "523000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Reverence",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "534000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Renown",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "562000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Layton",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3020,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "604000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Echelon",
      Mo: "Stellar",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "497000"
    },
    {
      Bid: "22689",
      Co: "Talon Preserve Distinctive",
      Mo: "Prosperity",
      Str: "6400 Talon Preserve Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "334000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Bordeaux",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Carano",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2478,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "496000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Noir",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2877,
      Sto: "2",
      Be: "2",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "548000"
    },
    {
      Bid: "22689",
      Co: "Weslyn Park",
      Mo: "Carano II",
      Str: "6446 Rover Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "582000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Adelaide",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "261000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Benton",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1628,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "285000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Haddock",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1896,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Ember",
      Mo: "Caden",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "361000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Foxtail Exterior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "267000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Foxtail Interior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "267000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Cedar",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "287000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Beacon",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1648,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "294000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Trailwood Exterior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Aurora",
      Mo: "Trailwood Interior Unit",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "301000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Highgate",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Dylan",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1715,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "306000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Coral",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1935,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Kelsey",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2006,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "331000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Garnet",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2166,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "353000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Mahogany",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "377000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Mabel",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2341,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "387000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Merlot",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "423000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Haven",
      Mo: "Talbot",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2603,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Ruby",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2883,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "447000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Coral Grand",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "456000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Monroe",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "466000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Mahogany Grand",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3126,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "473000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Legacy",
      Mo: "Merlot Grand",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3938,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "596000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Imperial",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2563,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "411000"
    },
    {
      Bid: "22689",
      Co: "Everbe- Forever",
      Mo: "Scarlett",
      Str: "6546 Mossy Wood Ave",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3575,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "573000"
    },
    {
      Bid: "22691",
      Co: "DW Lakewood Ranch 65'",
      Mo: "Renown",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "628000"
    },
    {
      Bid: "22691",
      Co: "DW Lakewood Ranch 65'",
      Mo: "Stardom",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "511000"
    },
    {
      Bid: "22691",
      Co: "DW Lakewood Ranch 65'",
      Mo: "Reverence",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "601000"
    },
    {
      Bid: "22691",
      Co: "DW Lakewood Ranch 65'",
      Mo: "Stellar",
      Str: "6754 Alstead Circle",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "559000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Chapman",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Hanover",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "McNair",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2093,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Thompson",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Tybee",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22690",
      Co: "Wells Landing",
      Mo: "Wakefield",
      Str: "6825 Sandperch Street",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32244,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Villa",
      Mo: "Ellenwood",
      Str: "6918 Honey Bell Drive",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "222000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Browning",
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Chapman",
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Hamden",
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Carver Square",
      Mo: "Thompson",
      Str: "716 SW 2nd Court",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33444,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "438000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee Villas",
      Mo: "Ellenwood",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1529,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "263000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Stardom",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2179,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "436000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Stellar",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "477000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Renown",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2710,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "487000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 65's",
      Mo: "Stellar Grand",
      Str: "73 Glenhurst Ave",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3361,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "572000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Prosperity",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1599,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Mystique",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1809,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "362000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Palmary",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1858,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "372000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Mainstay",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Mystique Grand",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22691",
      Co: "Del Webb Nocatee 50's",
      Mo: "Prestige",
      Str: "73 Glenhurst Ave.",
      Ct: "Ponte Vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1989,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "443000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Summerwood",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2007,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "451000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Pinnacle",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Tangerly Oak",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "583000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Stonewater",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "642000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 66",
      Mo: "Stonewater",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "642000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Southampton",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2903,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "654000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Nobility",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Clubview",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "693000"
    },
    {
      Bid: "22689",
      Co: "Shoreview 72",
      Mo: "Camelot",
      Str: "7816 Grande Shores Drive",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3203,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Yellowstone",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "626000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Heston",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Winthrop",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Medina",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22689",
      Co: "Valri Forest",
      Mo: "Tilden",
      Str: "783 Little Cloud Place",
      Ct: "Valrico",
      St: "FL",
      Zip: 33594,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "358000"
    },
    {
      Bid: "22689",
      Co: "Epperson Signature",
      Mo: "Summerwood",
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "374000"
    },
    {
      Bid: "22689",
      Co: "Epperson Signature",
      Mo: "Summerwood Grand",
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "408000"
    },
    {
      Bid: "22689",
      Co: "Epperson Signature",
      Mo: "Citrus Grove",
      Str: "7905 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "416000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Wyndham",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1770,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "354000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Fox Hollow",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1846,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Summerwood",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "373000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Alexander",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1963,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Sienna",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2220,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "379000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Fifth Avenue",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2523,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Riverwalk",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2679,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Park Place",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22689",
      Co: "The Enclaves at Woodmont",
      Mo: "Citrus Grove",
      Str: "7910 NW 79th Terrace",
      Ct: "Tamarac",
      St: "FL",
      Zip: 33321,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2830,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "22689",
      Co: "Epperson Bay",
      Mo: "McNair",
      Str: "7913 Yale Harbor Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Citrus Grove",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Sandhill",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3222,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "454000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Dockside Grand",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4274,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "599000"
    },
    {
      Bid: "22689",
      Co: "Epperson Elite",
      Mo: "Dockside",
      Str: "7969 Olive Brook Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "424000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Cedar",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Highgate",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Mystique",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "378000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Trailside",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Whitestone",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "466000"
    },
    {
      Bid: "22689",
      Co: "Magnolia Ranch",
      Mo: "Yorkshire",
      Str: "810 Pintail Cove",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "546000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Elite",
      Mo: "Astoria",
      Str: "8316 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2223,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "327000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Elite",
      Mo: "Everly",
      Str: "8316 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2684,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "352000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Elite",
      Mo: "Bloomfield",
      Str: "8316 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "336000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Cresswind",
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "281000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Heston",
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "289000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Tilden",
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "319000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Winthrop",
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "344000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Yellowstone",
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Marion Ranch - Signature",
      Mo: "Medina",
      Str: "8322 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Grayton",
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Grayton Grand",
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Seacrest",
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Emory",
      Mo: "Seacrest Grand",
      Str: "8370 NW 7th Court",
      Ct: "Plantation",
      St: "FL",
      Zip: 33324,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "439000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Contour",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Compass",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Cedar",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "391000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Prestige",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Hallmark",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Highgate",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Burbank",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "566000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Maritime",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1927,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Garden",
      Mo: "Seaport",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2068,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22689",
      Co: "Harbor Isle Classic",
      Mo: "Whitestone",
      Str: "8371 Beagle Terrace",
      Ct: "Vero Beach",
      St: "FL",
      Zip: 32967,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Villa",
      Mo: "Ellenwood",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Scenic",
      Mo: "Contour",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "339000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Scenic",
      Mo: "Compass",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "341000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Distinctive",
      Mo: "Prosperity",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "391000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Distinctive",
      Mo: "Mystique",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "402000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Distinctive",
      Mo: "Mainstay",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "408000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Distinctive",
      Mo: "Mystique Grand",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "476000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Echelon",
      Mo: "Stardom",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "483000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Echelon",
      Mo: "Stellar",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "493000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Echelon",
      Mo: "Renown",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "507000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Echelon",
      Mo: "Stellar Grand",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Villa",
      Mo: "Kendrick",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "312000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Distinctive",
      Mo: "Prestige",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "416000"
    },
    {
      Bid: "22691",
      Co: "Del Webb BayView Scenic",
      Mo: "Hallmark",
      Str: "8816 Sky Sail Cove",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Villa",
      Mo: "Ellenwood",
      Str: "8926 Coventina Way",
      Ct: "Viera",
      St: "FL",
      Zip: 32940,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1579,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "316000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Clubview",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3078,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Nobility",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Pinnacle",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "854000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Stonewater",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "1001000"
    },
    {
      Bid: "22689",
      Co: "Ancient Tree",
      Mo: "Camelot",
      Str: "9128 Balsamo Drive",
      Ct: "West Palm Beach",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1149000"
    },
    {
      Bid: "22689",
      Co: "GC Everglades 67'",
      Mo: "Stonewater w/ Loft",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3834,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "537000"
    },
    {
      Bid: "22689",
      Co: "GC Everglades 67'",
      Mo: "Pinnacle",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2488,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "567000"
    },
    {
      Bid: "22689",
      Co: "GC Everglades 67'",
      Mo: "Stonewater",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2852,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Mystique",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "376000"
    },
    {
      Bid: "22689",
      Co: "GC Everglades 67'",
      Mo: "Tangerly Oak",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2589,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Scenic",
      Mo: "Contour",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "366000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Scenic",
      Mo: "Hallmark",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "383000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Palmary",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Prestige",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "437000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Scenic",
      Mo: "Trailside",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "453000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Distinctive",
      Mo: "Whitestone",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "468000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Stardom",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "511000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Stellar",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "559000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Renown",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "601000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Layton",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2970,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "22689",
      Co: "Terreno - Echelon",
      Mo: "Oakhurst",
      Str: "9252 Cormorant Drive",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "661000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Daylen",
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "241000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Beachwood",
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "226000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Candlewood",
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Blackwell",
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1256,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "226000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Drayton",
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "241000"
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Leisure",
      Mo: "Crestwood",
      Str: "9293 SW 60th Lane Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1433,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "234000"
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Wakefield",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "406000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 40'",
      Mo: "Drayton",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "347000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Highgate",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 40'",
      Mo: "Morris",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "381000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Cedar",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "333000"
    },
    {
      Bid: "22689",
      Co: "North River Ranch 40'",
      Mo: "Trailside",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Mystique",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Trailside",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Browning",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1447,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Chapman",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Hanover",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Seabrook",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "North River Ranch 50'",
      Mo: "Thompson",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Mystique",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Mystique Grand",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "North River Ranch 50'",
      Mo: "Whitestone",
      Str: "9304 Royal River Circle",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Garnet",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2166,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Imperial",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2564,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Coral Grand",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Ruby",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2886,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Scarlett",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3575,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Riversedge",
      Mo: "Coral",
      Str: "9545 Carr Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33569,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1935,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Chapman",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Hanover",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Seabrook",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Wakefield",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Thompson",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22690",
      Co: "Summers Cay",
      Mo: "Browning",
      Str: "9613 Harney Road",
      Ct: "Thonotosassa",
      St: "FL",
      Zip: 33592,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1477,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong TH",
      Mo: "Navarre",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong TH",
      Mo: "Grayton",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1637,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 70'",
      Mo: "Ashby",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsong Estates 70'",
      Mo: "Stellar",
      Str: "9674 Mosler Trl",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Mystique",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Palmary",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1981,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Prestige",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2114,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Ashby",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Tower",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Stellar",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Easley",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Renown",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Whitestone",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Oakhurst",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Highpointe",
      Mo: "Highgate",
      Str: "9845 SW MERIDIAN WAY",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Mystique",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Ashby",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Tower",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Easley",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Mystique Grand",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Whitestone",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Renown",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Ashby Grand",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Classic",
      Mo: "Yorkshire",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3415,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Oakhurst",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Easley Grand",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Willow Ridge Estate",
      Mo: "Roseland",
      Str: "16068 Winding Bluff Dr",
      Ct: "Montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Cresswind",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Hamden",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Mill Run",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Medina",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Talon",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Tilden",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Regal",
      Mo: "Whitmore",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Winthrop",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Yellowstone",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Silverlake - Signature",
      Mo: "Heston",
      Str: "917 Silverlake Blvd",
      Ct: "Lake Alfred",
      St: "FL",
      Zip: 33850,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Merlot Grand",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 40s",
      Mo: "Harvey",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 40s",
      Mo: "Williston",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Roseland",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Mahogany",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2547,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Mahogany Grand",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3365,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Merlot",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2635,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 60s",
      Mo: "Scarlett",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Waterset Wolf Creek 40s",
      Mo: "Sonora",
      Str: "5367 Wolf Creek Dr",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Mystique",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Mystique Grand",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Monroe",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3079,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Yorkshire",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3415,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Easley Grand",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Medina",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2230,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Tower",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Easley",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Whitestone",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Savannah Palms",
      Mo: "Heston",
      Str: "1330 Hancock Lone Palm Rd",
      Ct: "Orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve TH's",
      Mo: "Foxtail",
      Str: "Lot 156 TBD Sapling Sprout Dri",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1652,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve TH's",
      Mo: "Trailwood",
      Str: "Lot 156 TBD Sapling Sprout Dri",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1861,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Cedar",
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Highgate",
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Whitestone",
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2908,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Spruce",
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Pinewood Reserve 50's",
      Mo: "Tower",
      Str: "Lot 3 TBD Wooden Pine Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2397,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Stillmont Townhomes",
      Mo: "Evergreen",
      Str: "13036 Stillmont Place",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1451,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Stillmont Townhomes",
      Mo: "Sycamore",
      Str: "13036 Stillmont Place",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1762,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Stillmont Townhomes",
      Mo: "Ashe",
      Str: "13036 Stillmont Place",
      Ct: "Tampa",
      St: "FL",
      Zip: 33624,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1818,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Mystique",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Palmary",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Prestige",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Mystique Grande",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Legacy Groves Distinctive",
      Mo: "Prosperity",
      Str: "6918 Honeybell Dr",
      Ct: "Nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Brixton",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Regency",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2658,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Brixton Grande",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3318,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Caymas",
      Mo: "Regency Grande",
      Str: "9402 Caymas Terrace",
      Ct: "Naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Vista",
      Mo: "Sealine",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Townhomes",
      Mo: "Ivy",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1636,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Townhomes",
      Mo: "Rowan",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1657,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Avanti at Waterside - Vista",
      Mo: "Coastview",
      Str: "6528 Silverstar Dr",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34240,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Hallmark",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Cedar",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1662,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Mystique",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Prestige",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Ashby",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Trailside",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Mystique Grande",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Whitestone",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Distinctive",
      Mo: "Yorkshire",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Oakhurst",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Easley Grande",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Easley",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Echelon",
      Mo: "Roseland",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Morris",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Bonita Del Sol Scenic",
      Mo: "Contour",
      Str: "10133 Seaside Way",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Cedar",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1590,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Highgate",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Mystique",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2149,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Tower",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2264,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Yorkshire",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3266,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Whitestone",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2782,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Rolling Hills",
      Mo: "Trailside Select",
      Str: "25 Shell Crk Ct",
      Ct: "Saint Augustine",
      St: "FL",
      Zip: 32086,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Scenic",
      Mo: "Compass",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1471,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Scenic",
      Mo: "Hallmark",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1655,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Distinctive",
      Mo: "Palmary",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Distinctive",
      Mo: "Mainstay",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Distinctive",
      Mo: "Prestige",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Echelon",
      Mo: "Stardom",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Echelon",
      Mo: "Stellar",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Echelon",
      Mo: "Reverence",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Echelon",
      Mo: "Renown",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Echelon",
      Mo: "Stellar Grand",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Distinctive",
      Mo: "Mystique",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Scenic",
      Mo: "Contour",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Del Webb at Viera - Distinctive",
      Mo: "Prosperity",
      Str: "8926 Coventina Way",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Prosperity",
      Str: "9676 SW 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Mystique",
      Str: "9676 SW 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Mainstay",
      Str: "9676 SW 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1948,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Prestige",
      Str: "9676 SW 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22691",
      Co: "Stone Creek - Distinctive",
      Mo: "Mystique Grand",
      Str: "9676 SW 62nd Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Morris",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2207,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Henley",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1822,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Easley",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2685,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Ashby Grand",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3217,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Oakhurst",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3820,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Easley Grand",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4194,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Whitestone",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2894,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Mystique Grand",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2870,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Drayton",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Prosperity",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1670,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Mystique",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1889,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Yorkshire",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3416,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Classics",
      Mo: "Tower",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Estates",
      Mo: "Ashby",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Serenoa Lakes - Gardens",
      Mo: "Trailside",
      Str: "17715 Saw Palmetto Ave",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Winstone",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Lakeshore",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Hideaway",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3127,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 40'",
      Mo: "Seashore",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3286,
      Sto: "2",
      Be: "7",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 50'",
      Mo: "Clearwater",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "9",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 50'",
      Mo: "Clearwater Grand",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4382,
      Sto: "2",
      Be: "10",
      Ba: "8",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay 50'",
      Mo: "Baymont",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4034,
      Sto: "2",
      Be: "8",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay TH",
      Mo: "Castaway - Interior",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2269,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Windsor Cay TH",
      Mo: "Castaway - End",
      Str: "1217 Emerald Row ",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Townhome",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2276,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Belle Haven",
      Mo: "Daylen",
      Str: "2440 Belle Haven Way",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1580,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Masonry",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Belle Haven",
      Mo: "Mill Run",
      Str: "2440 Belle Haven Way",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2203,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22689",
      Co: "Belle Haven",
      Mo: "Talon",
      Str: "2440 Belle Haven Way",
      Ct: "Davenport",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stoneybrook Hills:Magnolia Gardens",
      Mo: "Inverness",
      Str: "6551 Stoneybrook Parkway",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Indies",
      Mo: "Victoria",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2002,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Belmont",
      Mo: "Hartford",
      Str: "10141 Count Fleet Dr",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Halle",
      Str: "610 NW 5th Lane",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Genoa",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1792,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Centuri",
      Str: "SW 147th Ave and SW 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1827,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills:Heritage Hills Villas",
      Mo: "St. Lucia",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "175000"
    },
    {
      Bid: "22680",
      Co: "Whaley's Creek:Estate Collection",
      Mo: "Boca Raton",
      Str: "3698 Vega Creek Drive",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34772,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Lorient",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CA SKYVIEW",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22680",
      Co: "Raintree The Grove II:The Grove II at Raintree",
      Mo: "207A - Vallejo",
      Str: "1602 SW 114th Avenue",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33025,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "LaBelle ADS",
      Mo: "Hartford",
      Str: "Archilles Terrace",
      Ct: "LaBelle",
      St: "FL",
      Zip: 33935,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Cantabria Townhomes",
      Mo: "Scheme I",
      Str: "FL64 and 48th Street Ct NE",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Dover",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Argento",
      Str: "SW 147th Ave and SW 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1732,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Olmeto",
      Str: "SW 147th Ave and SW 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Medallion",
      Str: "19384 SW 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Port Charlotte:Executive Homes",
      Mo: "Dover",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Cassia at Skye Ranch",
      Mo: "Atlanta",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Lincoln",
      Str: "2252 Windward Cay Lane",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "HALLE",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "CHARLE",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Mariner",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1516,
      Sto: "3",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Maple",
      Str: "SW 31st Ave and 50th St",
      Ct: "Dania Beach",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Manor Homes",
      Mo: "Tivoli",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Wyndham Lakes:Harrington Pointe Townhomes",
      Mo: "Marisol",
      Str: "3402 Rodrick Circle",
      Ct: "Orlando",
      St: "FL",
      Zip: 32824,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "TREVI",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Manors",
      Mo: "Maryland",
      Str: "8795 Captstone Ranch Drive",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes 40",
      Mo: "Harrisburg",
      Str: "Ingraham Blvd & Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Marsielle",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Ridley",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Magnolia at Pembroke Park",
      Mo: "Lily",
      Str: "SW 56th  Av and Hallandale Beach Blv",
      Ct: "Pembroke Park",
      St: "FL",
      Zip: 33023,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Dover",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Creekside@Twin Creeks:Creekside 63' Imperial Collection",
      Mo: "MEDALLION BONUS",
      Str: "236 Switchgrass Rd",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Trevi",
      Str: "610 NW 5th Lane",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Cassia at Skye Ranch",
      Mo: "Boston",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Dahlia",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1799,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "22680",
      Co: "STOF Lakeland",
      Mo: "Summerville II",
      Str: "Moore Road",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Sawgrass Estate",
      Mo: "Worthington II D",
      Str: "12207 Sabal Palmetto Place",
      Ct: "Orlando",
      St: "FL",
      Zip: 32824,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2937,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:Estate Key Collection",
      Mo: "Aura",
      Str: "1363 Blue Ash Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1343,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Tea Olive",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Centris:Diamond Collection",
      Mo: "Ruby",
      Str: "8259 SW 120 Terr.",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4152,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1585000"
    },
    {
      Bid: "22680",
      Co: "Arden Park North:Executive Collection",
      Mo: "Wekiva  (3 Car)",
      Str: "1618 Lake Sims Parkway",
      Ct: "Ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3711,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Littleton",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Tidewater",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1912,
      Sto: "3",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "The Promenade at Lake Park:Lake Park 70s",
      Mo: "BARRINGTON",
      Str: "18209 Pine Hammock Blvd",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5367,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "675000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Town Estates",
      Mo: "Verona",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2466,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Lincoln",
      Str: "Longbay Road",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "Cordoba:Cordoba Estates",
      Mo: "Avila",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3033,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:Estate Homes",
      Mo: "Oakmont II",
      Str: "Gasparilla Road",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Heathwood Reserve:Single-Family Homes",
      Mo: "ATLANTA",
      Str: "Virtual WHC",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33463,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Tarramor:Tarramor Estates",
      Mo: "Ventura",
      Str: "6162 Marsh Trail Drive",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5596,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "22680",
      Co: "Big Cypress Stof",
      Mo: "Ayla",
      Str: "Snake Road and I75",
      Ct: "Clewiston",
      St: "FL",
      Zip: 33440,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Eden",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Tidewater Preserve 50",
      Mo: "BORETTO",
      Str: "Tidewater Preserve Blvd",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Illinois",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Seminole Tribe of Florida TH",
      Mo: "Galveston",
      Str: "SR-7 and Sunset Dr",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Venetian:Carriage Homes",
      Mo: "TBD",
      Str: "130 Bella Vista Terrace Unit B",
      Ct: "North Venice",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Executive Homes",
      Mo: "Marsala",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Town Estates",
      Mo: "Marisol",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Copperspring:The Estates",
      Mo: "DO NOT USE MOVED TO 1528976",
      Str: "Emerald Spring Loop",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34653,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1410000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Fontana",
      Str: "29171 SW 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2648,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22680",
      Co: "Reserve at Minneola 70's",
      Mo: "4014B-SOMERSET",
      Str: "1020 Wax Myrtle Ave.",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2567,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Madeira",
      Mo: "TREVI",
      Str: "61 Pintoresco Drive",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Copperspring",
      Mo: "DO NOT USE MOVED TO 1528876",
      Str: "7119 Emerald Spring Loop",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34653,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Old Hickory:Horizon Collection",
      Mo: "Dawn",
      Str: "3971 Guernsey Bend",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34772,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Princeton",
      Str: "19384 SW 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Violet",
      Str: "11181 SW 92 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5122,
      Sto: "2",
      Be: "5",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2215000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Preston",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "SORENTO",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Indies",
      Mo: "Hartford",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Alabaster",
      Str: "5504 NW 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1441,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Leafside TH",
      Mo: "Windsor",
      Str: "Court St",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Indies",
      Mo: "Alexia",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1713,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Pollock",
      Str: "Selling offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3539,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Palm Meadows:Berkshire Collection",
      Mo: "6811B - YORKSHIRE",
      Str: "8203 Grand Prix Lane",
      Ct: "Boynton Beach",
      St: "FL",
      Zip: 33472,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3635,
      Sto: "1",
      Be: "4",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Cape Coral:Americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "AYLA",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "The Townes at Cross Creek",
      Mo: "Constitution",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1760,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:Liberty Village - Phase One",
      Mo: "TREVI",
      Str: "7934 SW 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Sawgrass Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2958,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Hawthorne Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "BellaSera:Bellisima Collection",
      Mo: "Majorca",
      Str: "1304 Brinely Place",
      Ct: "Royal Palm Beach",
      St: "FL",
      Zip: 33411,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3701,
      Sto: "2",
      Be: "5",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Kinkade",
      Str: "Selling offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3895,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Aspen",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1844,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Tarramor:Tarramor Premier",
      Mo: "Seabrook",
      Str: "6162 Marsh Trail Drive",
      Ct: "Odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4439,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "MEDALLION",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Bordeaux",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North Townhomes",
      Mo: "Lincoln",
      Str: "1672 Garden Grove Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Camellia",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1848,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Truman",
      Str: "Longbay Road",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Avalon Trails:Villas",
      Mo: "LARKSPUR",
      Str: "14660 Three Ponds Trail",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33446,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Carolina Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4521,
      Sto: "1",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "22680",
      Co: "Longbay Townhomes",
      Mo: "Truman",
      Str: "2252 Windward Cay Lane",
      Ct: "Middleburg",
      St: "FL",
      Zip: 32068,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes by Lennar",
      Mo: "Hartford",
      Str: "Ingraham Blvd and Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Phoenix",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Belmont:The Estates",
      Mo: "DO NOT USE MOVED TO 1517676",
      Str: "14373 Easy Goer St",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1375000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Town Estates",
      Mo: "Capri",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Cypress Mill:The Estates",
      Mo: "DO NOT USE MOVED TO 1519276",
      Str: "Cypress Village Blvd",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1365000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Brindley",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Dover",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "STOF Lakeland",
      Mo: "Sorrento",
      Str: "Moore Road",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:Liberty Village - Phase One",
      Mo: "TIVOLI",
      Str: "7934 SW 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Allentown",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:Aden South III",
      Mo: "Discovery",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Everbe 20 TH",
      Mo: "Palisade Elev A Craftsman",
      Str: "11309 RISING SUN ST",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Arlington",
      Str: "Orange Dr and Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3036,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CG SKYVIEW",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2374,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes by Lennar",
      Mo: "Harrisburg",
      Str: "Ingraham Blvd and Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Estancia:Cortona",
      Mo: "DO NOT USE MOVED TO 6651974",
      Str: "29242 Picana Lane",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills Villas",
      Mo: "St. Marten",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "190000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "SANTE FE",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North",
      Mo: "Charle",
      Str: "1151 Kendall Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Cape Coral:Americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Copperlefe 40",
      Mo: "Marsala",
      Str: "11209 Copperlefe Drive",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Seminole Tribe of Florida TH",
      Mo: "Lanai",
      Str: "SR-7 and Sunset Dr",
      Ct: "Hollywood",
      St: "FL",
      Zip: 33021,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Autumn Palm 18 TH",
      Mo: "Windsor",
      Str: "Autumn Palm Dr",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22680",
      Co: "Enclave at Aloma",
      Mo: "Anna Maria G",
      Str: "4100 Bear Gully Rd.",
      Ct: "Winter Park",
      St: "FL",
      Zip: 32792,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock Preserve Townhomes",
      Mo: "Truman",
      Str: "SE 31st St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34480,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Dijon",
      Str: "132 SW 185th Way",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Scottsdale",
      Str: "Orange Dr and Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3719,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1125000"
    },
    {
      Bid: "22680",
      Co: "Estancia:Savona at Estancia",
      Mo: "Crescent",
      Str: "4587 Tivoli Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2509,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Boston",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Shoreline",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Town Estates",
      Mo: "Palermo",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village SFH",
      Mo: "MAGNOLIA",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:Executive Homes",
      Mo: "Marsala",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "BridgeHaven at Citrus Park",
      Mo: "Liberty",
      Str: "6617 Citrus Creek Lane",
      Ct: "Tampa",
      St: "FL",
      Zip: 33625,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1807,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:Executive Collection",
      Mo: "Estero",
      Str: "1801 Rider Rain Lane",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Dover",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove by Lennar",
      Mo: "Sunset",
      Str: "8160 Wiltshire Drive",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Collier",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1440,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Lehigh Acres",
      Mo: "Hartford",
      Str: "4016 4th St. West",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "EverBe:Cottage Alley Collection",
      Mo: "Tahoe",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2071,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Executive Homes",
      Mo: "Venice",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Avesa",
      Mo: "Giada",
      Str: "10044 Corso Milano Dr",
      Ct: "Tampa",
      St: "FL",
      Zip: 33625,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2262,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Cordoba",
      Mo: "CARRINGTON",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3366,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "TIVOLI",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Port Charlotte:Executive Homes",
      Mo: "Hartford",
      Str: "22419 Peachland Blvd",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33954,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "River Strand:Terrace Condominiums",
      Mo: "Ashbury",
      Str: "6819 Grand Estuary Trail #106",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1121,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "84000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CE",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1605,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Cassia at Skye Ranch",
      Mo: "Annapolis",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:Estate Homes",
      Mo: "Sunset",
      Str: "Gasparilla Road",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "EverBe:Cottage Alley Collection",
      Mo: "Sonora",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1821,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "EverBe:Cottage Alley Collection",
      Mo: "Brookside",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village SFH",
      Mo: "ATLANTA",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Bordeaux",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Autumn Palm 18 TH",
      Mo: "Hampton",
      Str: "Autumn Palm Dr",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Cape Coral:Americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Augusta",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2084,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Pampas",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1797,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Manor",
      Str: "Orange Dr and Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1000000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Freedom",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Cordoba",
      Mo: "DO NOT USE MOVED TO 6653474",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1605000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CE SKYVIEW",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2116,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "The Townes at Fish Hawk",
      Mo: "Mulberry",
      Str: "5753 Hawks Town Wy",
      Ct: "Lithia",
      St: "FL",
      Zip: 33547,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1601,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "101000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Kowski",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1266,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Country Lane Estates",
      Mo: "Elan",
      Str: "610 NW 5th Lane",
      Ct: "Williston",
      St: "FL",
      Zip: 32696,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Ramsey",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Copperlefe 40",
      Mo: "Trevi",
      Str: "11209 Copperlefe Drive",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "ELAN",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Edlin",
      Str: "29171 SW 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Florent",
      Str: "SW 147th Ave and SW 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2183,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Newlin",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1474,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village TH",
      Mo: "C",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1832,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills:Heritage Hills Villas",
      Mo: "St. Marten",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CA",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Westview:Aden South II",
      Mo: "Atlanta",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Avignon",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Durbin",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1438,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "EverBe:Cottage Alley Collection",
      Mo: "Autumn",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "TREVI",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Arden Park:Estate Collection",
      Mo: "Inverness II",
      Str: "2022 Lake Sims Parkway",
      Ct: "Ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Estancia:Santeri Villas at Estancia",
      Mo: "Newport",
      Str: "4375 Barletta Court",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2117,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Manor Homes",
      Mo: "The Summerville II",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills Villas",
      Mo: "St. Lucia",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "175000"
    },
    {
      Bid: "22680",
      Co: "Westview:Aden South III",
      Mo: "Atlanta",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Westview:Aden South III",
      Mo: "Hartford",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "THE SUMMERVILLE II",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Bonita National:Coach Homes",
      Mo: "Bay Creek",
      Str: "28123 Wicklow Ct.",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2110,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Centris:Diamond Collection",
      Mo: "Niagara",
      Str: "8259 SW 120 Terr.",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4032,
      Sto: "2",
      Be: "5",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Blossom",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "McKinnon Townhomes",
      Mo: "Isabella",
      Str: "454 Oak Glen Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32534,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "Raintree The Preserve:The Preserve at Raintree",
      Mo: "Laguna",
      Str: "1602 SW 114th Avenue",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33025,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1799,
      Sto: "3",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "The Townes at Cross Creek",
      Mo: "Declaration",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1807,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "The Groves at Big Cypress",
      Mo: "The Hartford",
      Str: "Little Cypress Way and W Boundry Rd",
      Ct: "Big Cypress",
      St: "FL",
      Zip: 33440,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1949,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Rotonda Lakes by Lennar",
      Mo: "Dover",
      Str: "Ingraham Blvd and Antis Dr",
      Ct: "Rotonda West",
      St: "FL",
      Zip: 33947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 60' Classic Collection",
      Mo: "Serenata",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2512,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Epperson:The Townhomes",
      Mo: "Constitution",
      Str: "31819 Blue Passing Loop",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33545,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1760,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Reserve at Alaqua",
      Mo: "4511A - ALCOTT",
      Str: "3109 Players View Circle",
      Ct: "Longwood",
      St: "FL",
      Zip: 32779,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3267,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Caraway",
      Str: "29171 SW 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2086,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock Preserve Townhomes",
      Mo: "Lincoln",
      Str: "SE 31st St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34480,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Tuscan Springs",
      Mo: "Aurora",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33029,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1455,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Marbella - Sienna at Estancia",
      Mo: "Talbot",
      Str: "28739 Revaro Lane",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3649,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:Liberty Village - Phase One",
      Mo: "PRINCETON",
      Str: "7934 SW 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CC SKYVIEW",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "3",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Ohio",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2865,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Indies",
      Mo: "Providence",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Sandstone",
      Str: "5504 NW 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Mallard Landing",
      Mo: "Oxford",
      Str: "4603 Carlstrom Lane",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:Executive Homes",
      Mo: "Trevi",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "HARTFORD",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Executive Homes",
      Mo: "Trevi",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "South Gulf Cove by Lennar",
      Mo: "Oakmont II",
      Str: "8160 Wiltshire Drive",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "MEDALLION",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Sereno Grove",
      Mo: "Bougainvillea",
      Str: "14595 Nicholas Way",
      Ct: "Naples",
      St: "FL",
      Zip: 34109,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2800,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "TREVI",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "HALLE",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Centris:Diamond Collection",
      Mo: "Silverstone",
      Str: "8259 SW 120 Terr.",
      Ct: "Miami",
      St: "FL",
      Zip: 33156,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4336,
      Sto: "2",
      Be: "5",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1660000"
    },
    {
      Bid: "22680",
      Co: "Bonita National:Coach Homes",
      Mo: "Arrowhead",
      Str: "28123 Wicklow Ct.",
      Ct: "Bonita Springs",
      St: "FL",
      Zip: 34135,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1741,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Willow",
      Str: "29171 SW 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3468,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "22680",
      Co: "Smith Station",
      Mo: "Plan 1730",
      Str: "41 N Jefferson St  # 108",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32502,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1730,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Bridgewater at Viera:Classics",
      Mo: "Riviera",
      Str: "2371 Caravan Place",
      Ct: "Melbourne",
      St: "FL",
      Zip: 32940,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village SFH",
      Mo: "COMPASSO",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2104,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Lake Wilson Reserve",
      Mo: "Tea Olive",
      Str: "7628 Danube Road",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1674,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:Liberty Village - Phase One",
      Mo: "HALLE",
      Str: "7934 SW 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Islands @ Doral - Santorini",
      Mo: "C-Cayman (Discontinued)",
      Str: "11301 NW 74 St",
      Ct: "Doral",
      St: "FL",
      Zip: 33178,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1688,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Westview:Overlook Townhomes",
      Mo: "Amalfi",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "The Townes at Cross Creek",
      Mo: "Allegiance",
      Str: "10802 Fowlers Bluff Ct",
      Ct: "Tampa",
      St: "FL",
      Zip: 33647,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1597,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Marsielle",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:Executive Homes",
      Mo: "Venice",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Rialto Presidential",
      Mo: "The Valenti",
      Str: "163 Darby Island Place",
      Ct: "Jupiter",
      St: "FL",
      Zip: 33458,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4170,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "22680",
      Co: "Grand Park North",
      Mo: "Tivoli",
      Str: "19384 SW 77th Loop",
      Ct: "Dunnellon",
      St: "FL",
      Zip: 34432,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Marigold",
      Str: "5504 NW 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1816,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "2693",
      Str: "Selling offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "3",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "22680",
      Co: "Cordoba:Cordoba Estates",
      Mo: "Ventura",
      Str: "2519 Cordoba Ranch Blvd.",
      Ct: "Lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5596,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "22680",
      Co: "Estancia",
      Mo: "FLORENCE",
      Str: "4587 Tivoli Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2122,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Lennar at Port Charlotte:Executive Homes",
      Mo: "Hartford",
      Str: "1318 Fargo St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "PRINCETON",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Lynwood:The Estates",
      Mo: "DO NOT USE MOVED TO 6658376",
      Str: "5059 White Chicory Drive",
      Ct: "Apollo Beach",
      St: "FL",
      Zip: 33572,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Red Tail",
      Mo: "Briarbrooke Grande",
      Str: "32536 Hawks Lake Ln",
      Ct: "Sorrento",
      St: "FL",
      Zip: 32776,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3906,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Cape Coral:Americana Series",
      Mo: "Hartford",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Cassia at Skye Ranch",
      Mo: "Columbia",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Smith Station",
      Mo: "Plan 1850",
      Str: "41 N Jefferson St  # 108",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32502,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "EverBe:Cottage Alley Collection",
      Mo: "Westwood",
      Str: "6571 Eastwood Cross Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1647,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Shores",
      Mo: "Alexia",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1713,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Sanctuary Grande",
      Mo: "Magnolia",
      Str: "11181 SW 92 Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33176,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4296,
      Sto: "1",
      Be: "5",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "2080000"
    },
    {
      Bid: "22680",
      Co: "Belmont:Belmont Townhomes",
      Mo: "Riverview Lakes 6-Unit",
      Str: "9741 Lemon Drop Loop",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 11804,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1555000"
    },
    {
      Bid: "22680",
      Co: "Hunter's Run",
      Mo: "5510 A  - HASTINGS",
      Str: "4056 Longbow Dr.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2915,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:Executive Homes",
      Mo: "Capri",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1677,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Autumn Palm 18 TH",
      Mo: "Glenmoor",
      Str: "Autumn Palm Dr",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "22680",
      Co: "Crescent Ridge",
      Mo: "Wrangler",
      Str: "Orange Dr and Flamingo Rd",
      Ct: "Davie",
      St: "FL",
      Zip: 33330,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4080,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1150000"
    },
    {
      Bid: "22680",
      Co: "Westview:Overlook Townhomes",
      Mo: "Minori",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1834,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "CHARLE",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Sylvan Crossing",
      Mo: "Patriot",
      Str: "18825 Noble Caspian Dr",
      Ct: "Lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1597,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "EMERSON",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2152,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village SFH",
      Mo: "RADIANTE",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2707,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Atlanta",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Annapolis",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Azalea Isle",
      Mo: "Crimson",
      Str: "5504 NW 27th Place",
      Ct: "Lauderhill",
      St: "FL",
      Zip: 33313,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1684,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Georgia",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2725,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Touchstone",
      Mo: "DO NOT USE MOVED TO 1504176",
      Str: "4223 Globe Thistle Dr",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1250000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Shores",
      Mo: "Victoria",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2002,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Miramar III",
      Str: "Selling offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3993,
      Sto: "3",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22680",
      Co: "Inman Groves",
      Mo: "Boston",
      Str: "114 Inman Boulevard",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33881,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Harbor East:Executive Homes",
      Mo: "Trevi",
      Str: "Gasparilla Road",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33981,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Piani",
      Str: "SW 147th Ave and SW 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2987,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "22680",
      Co: "The Groves at Big Cypress",
      Mo: "The Ayla",
      Str: "Little Cypress Way and W Boundry Rd",
      Ct: "Big Cypress",
      St: "FL",
      Zip: 33440,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Reserve at Minneola 70's",
      Mo: "4013B-HAMPSHIRE",
      Str: "1020 Wax Myrtle Ave.",
      Ct: "Minneola",
      St: "FL",
      Zip: 34715,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2021,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Lake Markham Landings",
      Mo: "Champlain",
      Str: "Sold Out",
      Ct: "Sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4154,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "22680",
      Co: "Longleaf:Longleaf 50s",
      Mo: "TREVI",
      Str: "7644 Buck Hills Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32222,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Silverwood Estates",
      Mo: "Burgandy",
      Str: "5376 Hypoluxo Road",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33463,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2046,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "McKinnon Townhomes",
      Mo: "Oyster",
      Str: "454 Oak Glen Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32534,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1738,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Cypress Creek 18 TH",
      Mo: "6-Unit Common Building-ACC",
      Str: "Common Building 0113-0118",
      Ct: "Sun City Center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9618,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1205000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:Liberty Village - Phase One",
      Mo: "ELAN",
      Str: "7934 SW 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Nature's  Cove",
      Mo: "Plan 2337",
      Str: "1356 Areca Palm Drive",
      Ct: "Gulf Breeze",
      St: "FL",
      Zip: 32563,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2337,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Manor Homes",
      Mo: "The Princeton II",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Holly Cove Townhomes",
      Mo: "JADE",
      Str: "708 Holly Lakes Drive",
      Ct: "Orange Park",
      St: "FL",
      Zip: 32073,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1558,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CB",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1485,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22680",
      Co: "Econ Trails",
      Mo: "Sabal",
      Str: "9806 Red Eagle Drive",
      Ct: "Orlando",
      St: "FL",
      Zip: 32825,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1742,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Fontana",
      Str: "29171 SW 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2648,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Ibis Landing Golf and Country Club:Manor Homes",
      Mo: "The Summerville II",
      Str: "20800 Copperhead Drive",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33936,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Sedona Estates",
      Mo: "Mulberry",
      Str: "29171 SW 167th Avenue",
      Ct: "Miami",
      St: "FL",
      Zip: 33030,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2806,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CG",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1898,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village SFH",
      Mo: "LUXO",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Windsong:Estate Collection",
      Mo: "Allentown",
      Str: "26056 Maidencane Way",
      Ct: "Leesburg",
      St: "FL",
      Zip: 34748,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Holly",
      Str: "SW 31st Ave and 50th St",
      Ct: "Dania Beach",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22680",
      Co: "Urbana:2-Story Townhomes",
      Mo: "MODEL CC",
      Str: "8370 NW 49 St.",
      Ct: "Doral",
      St: "FL",
      Zip: 33166,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1596,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Dawn",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Townes",
      Mo: "Windsor II",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "90000"
    },
    {
      Bid: "22680",
      Co: "Kings Landing",
      Mo: "Drexel",
      Str: "3420 Mildred Way",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32254,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1626,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "STOF Lakeland",
      Mo: "Bellamy",
      Str: "Moore Road",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2085,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Oak Hammock:Estate Key Collection",
      Mo: "Bloom",
      Str: "1363 Blue Ash Lane",
      Ct: "Deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1487,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "The Townes at Fish Hawk",
      Mo: "Mulberry",
      Str: "5753 Hawks Town Wy",
      Ct: "Lithia",
      St: "FL",
      Zip: 33547,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1601,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "Juneau",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2954,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Southport",
      Mo: "Alabama",
      Str: "7704 S Kissimmee St",
      Ct: "Tampa",
      St: "FL",
      Zip: 33616,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Cape Coral:Americana Series",
      Mo: "The Summerville II",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Cassia at Skye Ranch",
      Mo: "Atlanta",
      Str: "Skye Ranch Blvd.",
      Ct: "Sarasota",
      St: "FL",
      Zip: 34241,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Townes",
      Mo: "Glenmoor II",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "94000"
    },
    {
      Bid: "22680",
      Co: "Harmony:Harmony Hawthorn and Cherry Hill",
      Mo: "Provincetown",
      Str: "3326 Grande Heron Dr.",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Westview:Overlook Townhomes",
      Mo: "Sienna",
      Str: "2406 Skyline Loop",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34758,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "99000"
    },
    {
      Bid: "22680",
      Co: "Bronson's Ridge:Cottage Alley Collection",
      Mo: "Danali",
      Str: "1801 Rider Rain Lane",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Lennar at Port Charlotte:Executive Homes",
      Mo: "Dover",
      Str: "1318 Fargo St",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Pratt's Preserve",
      Mo: "Columbia",
      Str: "7178 Sugar Pine Way",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Sunset Pines",
      Mo: "Lorient",
      Str: "15987 SW 2nd Place",
      Ct: "Pembroke Pines",
      St: "FL",
      Zip: 33027,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "South Creek:Estates I",
      Mo: "Hartford",
      Str: "10441 Alder Green Dr",
      Ct: "Riverview",
      St: "FL",
      Zip: 33578,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Flowing Well",
      Mo: "BELLAMY",
      Str: "300 Bigtown Clan Rd",
      Ct: "Okeechobee",
      St: "FL",
      Zip: 34974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2079,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Brentwood Village SFH",
      Mo: "LUXO",
      Str: "6085 Balboa Circle",
      Ct: "Boca Raton",
      St: "FL",
      Zip: 33433,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Belmont",
      Mo: "Virginia 2017",
      Str: "10141 Count Fleet Dr",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3773,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Montclair",
      Mo: "LAGUNA A - 303A",
      Str: "8895 N Military Trail  Suite 101B",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33410,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1799,
      Sto: "3",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Holly Cove Townhomes",
      Mo: "MIA",
      Str: "708 Holly Lakes Drive",
      Ct: "Orange Park",
      St: "FL",
      Zip: 32073,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1483,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Livingston Lakes 10 Plex",
      Mo: "Garden Homes 205",
      Str: "Palmer Lake Circle",
      Ct: "Naples",
      St: "FL",
      Zip: 34109,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1236,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22680",
      Co: "Salerno",
      Mo: "Lumio",
      Str: "SW 147th Ave and SW 160th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33187,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2439,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22680",
      Co: "Vineland Reserve",
      Mo: "Hartford",
      Str: "1000 Peach Creek Drive",
      Ct: "Osteen",
      St: "FL",
      Zip: 32764,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Lehigh Acres",
      Mo: "Dover",
      Str: "4016 4th St. West",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Bryant Square:The Townes",
      Mo: "Hampton II",
      Str: "3060 Hover Hall Ln",
      Ct: "New Port Richey",
      St: "FL",
      Zip: 34655,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "97000"
    },
    {
      Bid: "22680",
      Co: "Estancia",
      Mo: "MILAN",
      Str: "4587 Tivoli Drive",
      Ct: "Wesley Chapel",
      St: "FL",
      Zip: 33543,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Avila Bay",
      Mo: "Shoreline",
      Str: "5572 Mercado Drive",
      Ct: "Port Richey",
      St: "FL",
      Zip: 34668,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Mill Creek North Townhomes",
      Mo: "Truman",
      Str: "1672 Garden Grove Court",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Lehigh Acres",
      Mo: "Dover",
      Str: "4016 4th St. West",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33971,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Cantabria Townhomes",
      Mo: "Scheme III",
      Str: "FL64 and 48th Street Ct NE",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Pearl Estates",
      Mo: "McGinnis",
      Str: "18238 Pearl View Place",
      Ct: "Lutz",
      St: "FL",
      Zip: 33548,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3230,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Cherokee Cove",
      Mo: "The Egret",
      Str: "11148 Cherokee Cove Drive",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Shoreline",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Venetian:Carriage Homes",
      Mo: "MONACO",
      Str: "130 Bella Vista Terrace Unit B",
      Ct: "North Venice",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Dania Preserve",
      Mo: "Oak",
      Str: "SW 31st Ave and 50th St",
      Ct: "Dania Beach",
      St: "FL",
      Zip: 33312,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1854,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22680",
      Co: "River Strand:The Villas at the Sanctuary",
      Mo: "Orchid",
      Str: "7133 Quiet Creek Drive",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34212,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "22680",
      Co: "Lost Key Townhomes",
      Mo: "Sabal Palm",
      Str: "14178 Sable Palm Way",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Biscayne Landing:Executive Homes",
      Mo: "Trevi",
      Str: "15992 Honeysuckle Street",
      Ct: "Port Charlotte",
      St: "FL",
      Zip: 33953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Tidewater Preserve 50",
      Mo: "ASTI",
      Str: "Tidewater Preserve Blvd",
      Ct: "Bradenton",
      St: "FL",
      Zip: 34208,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "PRINCETON",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Lost Key Townhomes",
      Mo: "2BR Townhome Upload",
      Str: "14000 Perdido Key Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32507,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1500,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: "235000"
    },
    {
      Bid: "22680",
      Co: "Harmony:Harmony Hawthorn and Cherry Hill",
      Mo: "Eastham",
      Str: "3326 Grande Heron Dr.",
      Ct: "Harmony",
      St: "FL",
      Zip: 34773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2054,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Seagrove:The Shores",
      Mo: "Ashley",
      Str: "Virtual Welcome Home Center",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34946,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Liberty Village:Liberty Village - Phase Two",
      Mo: "HALLE",
      Str: "7934 SW 74th Loop",
      Ct: "Ocala",
      St: "FL",
      Zip: 34481,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Blue Cypress Cove",
      Mo: "TIVOLI",
      Str: "University Blvd N",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32277,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Leafside TH",
      Mo: "Hampton",
      Str: "Court St",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "22680",
      Co: "Heritage Hills:Heritage Hills Villas",
      Mo: "St. Lucia",
      Str: "3195 Heritage Hills Blvd.",
      Ct: "Clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1400,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "175000"
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Dijon",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33170,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Venetian:Carriage Homes",
      Mo: "CORSICA",
      Str: "130 Bella Vista Terrace Unit B",
      Ct: "North Venice",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1710,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "22680",
      Co: "New Homes in Cape Coral:Americana Series",
      Mo: "Dover",
      Str: "1400 South Gator Circle",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33909,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Dora Parc",
      Mo: "Anna Maria",
      Str: "Selling offsite",
      Ct: "Mount Dora",
      St: "FL",
      Zip: 32757,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22680",
      Co: "Palm River Townhomes",
      Mo: "Shoreline",
      Str: "4726 Pickering Harbor Pl",
      Ct: "Tampa",
      St: "FL",
      Zip: 33619,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Everbe 20 TH",
      Mo: "Telluride Elev A Craftsman",
      Str: "11309 RISING SUN ST",
      Ct: "Orlando",
      St: "FL",
      Zip: 32829,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1807,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 50s",
      Mo: "TREVI",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "BRIO",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "CHARLE II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "PRINCETON II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "Trevi II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "TIVOLI II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 60s",
      Mo: "TIVOLI",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 60s",
      Mo: "MEDALLION",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 50s",
      Mo: "CHARLE",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 40s",
      Mo: "ALEXIA",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 60s",
      Mo: "TIVOLI II",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 40s",
      Mo: "BOONE",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 60s",
      Mo: "PRINCETON",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "CHARLE II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "TIVOLI",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "MEDALLION II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "TIVOLI",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 60s",
      Mo: "PRINCETON II",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "MEDALLION",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "TREVI",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "CHARLE",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "HALLE",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "PRINCETON",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "Trevi II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Edenbrooke at Hyland Trail:Edenbrooke at Hyland Trail 50s",
      Mo: "TREVI II",
      Str: "3108 Tupelo Loop",
      Ct: "Greencove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 50s",
      Mo: "HALLE II",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 40s",
      Mo: "HAILEY",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1950,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Granary Park:Granary Park 60s",
      Mo: "MEDALLION",
      Str: "2717 Pointed Leaf Rd",
      Ct: "Green Cove Springs",
      St: "FL",
      Zip: 32043,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "BRIO",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Boone",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Spirit II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "ALEXIA II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Sierra II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2257,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Charle II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Brio II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "HALLE II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "HALLE",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Sierra",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Alexia",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "2.5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Alexia",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Sierra",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "TREVI",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Hailey",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Sierra w/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Chloe",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Chloe- MODEL ONLY HS 69",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "TREVI",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "ALEXIA II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Hailey",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "CHARLE",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Sierra II w/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "BOONE II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2089,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "HAILEY II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Chloe",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "CHARLE",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 40S",
      Mo: "Boone",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Elan II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Trevi II",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "HALLE",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "St Augustine Lakes:St Augustine Lakes 50S",
      Mo: "Sierra w/ Bonus",
      Str: "25 Old Bull Bay Ct",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32084,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "TIVOLI",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Falls 60s at SilverLeaf",
      Mo: "Princeton",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "PATRIOT",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3004,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "Trailmark",
      Mo: "CHARLE",
      Str: "74 Bloomfield Way",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 60s",
      Mo: "Medallion Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "MEDALLION BONUS",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Traditional Townhomes",
      Mo: "Truman",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater 24ft Townhomes",
      Mo: "Osprey",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1494,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "120000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "MEDALLION",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2654,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Traditional Townhomes",
      Mo: "Truman",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:SilverFalls 40s at SilverLeaf",
      Mo: "BOONE",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 53s",
      Mo: "SIERRA",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 60s",
      Mo: "Kingsley III",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Affordable Luxury Townhomes",
      Mo: "Lincoln",
      Str: "391 Rosemont Drive",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 53s",
      Mo: "CHLOE-- HS 549 ONLY",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "TrailMark - 50s",
      Mo: "TREVI",
      Str: "74 Bloomfield Way",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 50s",
      Mo: "SIERRA BONUS",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 50s",
      Mo: "TREVI",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Royal Collection",
      Mo: "Trevi",
      Str: "28 Bluffton Ct",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "PATRIOT",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3004,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Traditional Townhomes",
      Mo: "Lincoln",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "KINGSLEY III",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 50s",
      Mo: "SIERRA",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "PRINCETON",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 60s",
      Mo: "Barton",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:SilverFalls 40s at SilverLeaf",
      Mo: "KATE",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 50s",
      Mo: "CHARLE",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:SilverFalls 40s at SilverLeaf",
      Mo: "ALEXIA",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Single Family",
      Mo: "SIERRA",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 53s",
      Mo: "SIERRA BONUS",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "TIVOLI",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Single Family",
      Mo: "CHARLE",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 60s",
      Mo: "Medallion",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater Townhomes",
      Mo: "Lincoln - Affordable Housing",
      Str: "27 Appleton Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Traditional Townhomes",
      Mo: "Lincoln",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Falls 50s at SilverLeaf",
      Mo: "TREVI",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "KINGSLEY III",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Single Family",
      Mo: "SIERRA",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "KATALINA",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Single Family",
      Mo: "TREVI",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Falls 60s at SilverLeaf",
      Mo: "Kingsley III",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3381,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:SilverFalls 40s at SilverLeaf",
      Mo: "BOONE",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:SilverFalls 40s at SilverLeaf",
      Mo: "ALEXIA",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "KATALINA BONUS",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22680",
      Co: "TrailMark - 50s",
      Mo: "CHARLE",
      Str: "74 Bloomfield Way",
      Ct: "St. Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 60s",
      Mo: "Tivoli",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Single Family",
      Mo: "BRIO",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:SilverFalls 40s at SilverLeaf",
      Mo: "KATE",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Shearwater:Shearwater - Single Family",
      Mo: "SIERRA BONUS",
      Str: "395 Belfort Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Landing 63s",
      Mo: "PRINCETON",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22680",
      Co: "SilverLeaf:Silver Meadows 60s",
      Mo: "Medallion Bonus",
      Str: "104 Silver Willow Court",
      Ct: "St Augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "1",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 60's",
      Mo: "MEDALLION BONUS",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 60's",
      Mo: "PRINCETON",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "CHARLE",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "TREVI",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Tributary Royal Collection",
      Mo: "Princeton II",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Tributary Royal Collection",
      Mo: "Tivoli II",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "SIERRA",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 60's",
      Mo: "PRINCETON",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "TREVI",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 60's",
      Mo: "MEDALLION",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Tributary Royal Collection",
      Mo: "Medallion II",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Tributary Royal Collection",
      Mo: "Medallion Bonus II",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3016,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Tributary Royal Collection",
      Mo: "Princeton II",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "HALLE",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "HALLE",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 60's",
      Mo: "MEDALLION",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 60's",
      Mo: "TIVOLI",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Tributary:Lakeview at Tributary 50's",
      Mo: "ELAN",
      Str: "75725 Lily Pond Ct",
      Ct: "Yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Estate Collection",
      Mo: "Providence",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Estate Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Estate Collection II",
      Mo: "Freedom",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Estate Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Grand Collection",
      Mo: "Dawn",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Legacy Collection",
      Mo: "Annapolis",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Estate Collection",
      Mo: "Dawn",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1313,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Estate Collection",
      Mo: "Dover",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Manor Collection",
      Mo: "Columbia",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Manor Collection",
      Mo: "Annapolis",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Manor Collection",
      Mo: "Boston",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Grand Collection",
      Mo: "Vista",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Manor Collection",
      Mo: "Concord",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Grand Collection",
      Mo: "Durham",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Preserve at LPGA:Grand Collection",
      Mo: "Boston *Model Home*",
      Str: "1185 Sand Trap Court",
      Ct: "Daytona Beach",
      St: "FL",
      Zip: 32124,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Grand Collection",
      Mo: "Hartford",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Majestic Oaks",
      Mo: "Freedom",
      Str: "438 Three Oaks Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Grand Collection",
      Mo: "Radiance",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Grand Collection",
      Mo: "Dover",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Grand Collection",
      Mo: "Hartford",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Chateau Collection",
      Mo: "Newcastle",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Grand Collection",
      Mo: "Miramar II",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22680",
      Co: "Majestic Oaks",
      Mo: "Hartford",
      Str: "438 Three Oaks Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Grand Collection",
      Mo: "Freedom",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Chateau Collection",
      Mo: "Phoenix",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "The Parks at Edgewater:Chateau Collection",
      Mo: "Lincoln",
      Str: "3989 Willowbrook Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Majestic Oaks",
      Mo: "Dover",
      Str: "438 Three Oaks Drive",
      Ct: "Edgewater",
      St: "FL",
      Zip: 32141,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "HALLE",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "TREVI",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "CHARLE",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "TIVOLI- MODEL ONLY HS 2",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 60S",
      Mo: "PRINCETON",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "CHARLE",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "BRIO",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 60S",
      Mo: "MEDALLION",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 60S",
      Mo: "TIVOLI",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "HALLE",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "BRIO",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 60S",
      Mo: "BARTON",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "ELAN",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 60S",
      Mo: "TIVOLI",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Wexford Reserve:Wexford Reserve 50S",
      Mo: "TREVI",
      Str: "2189 Raglan Circle",
      Ct: "Ormond Beach",
      St: "FL",
      Zip: 32174,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Boone",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Kate",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Chloe",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Alexia",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Boone",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Townhome Collection",
      Mo: "Lincoln",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Alexia",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Classic Collection",
      Mo: "Chloe",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Townhome Collection",
      Mo: "Truman",
      Str: "7277 Powers Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "240000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Townhome Collection",
      Mo: "Truman",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "22680",
      Co: "Hardwick Farms:Hardwick Farms - Townhome Collection",
      Mo: "Lincoln",
      Str: "6808 Golden Monarch Ave",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32217,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "HALLE II",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Halle",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "TIVOLI II",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Brio",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Tivoli",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "BRIO II",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Princeton",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Halle",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Trevi- MODEL HS 0003 ONLY",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "CHARLE II",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Tivoli",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Princeton",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Princeton",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Halle",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Medallion",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "TREVI II",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Brio",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Trevi",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Charle",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "Medallion",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Charle",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 50s",
      Mo: "Trevi",
      Str: "1705 Pecan Park Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Seaton Creek:Seaton Creek 60s",
      Mo: "MEDALLION II",
      Str: "1418 Gwinnett Lane",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32218,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Serenata",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2512,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "TREVI II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "TIVOLI II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 40s",
      Mo: "ALEXIA",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "BRIO",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "HALLE",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Tivoli",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Tivoli",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "PRINCETON II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "CHARLE",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "HALLE",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Medallion II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Princeton",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 40s",
      Mo: "CHLOE",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "BRIO II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Princeton",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "TREVI",
      Str: "9431 Feagle Farm Road",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 40s",
      Mo: "BOONE",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "HALLE- HOMESITE 69 ONLY",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 40s",
      Mo: "BOONE",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "CHARLE",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "CHARLE II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "HALLE II",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 60s",
      Mo: "Serenata",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2512,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Oaks:Saddle Oaks 50s",
      Mo: "TREVI",
      Str: "9524 Gaynor Circle",
      Ct: "Jacksonville",
      St: "FL",
      Zip: 32219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Celestina:Celestina Royal Collection",
      Mo: "Stratford",
      Str: "20 Lunetta Ct",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2617,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (50s) - Royal Collection",
      Mo: "ELAN",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "KATE",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater - Villas",
      Mo: "SIENNA",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1623,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:Stillwater (40s) - Royal Collection",
      Mo: "KATE",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "HAILEY",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1950,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "JESSICA",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (50s) - Royal Collection",
      Mo: "CHARLE",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:Stillwater (40s) - Royal Collection",
      Mo: "ALEXIA",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:Stillwater - Villas",
      Mo: "SIENNA",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1623,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:Stillwater - Villas",
      Mo: "TOPAZ",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (50s) - Royal Collection",
      Mo: "TREVI II",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "ALEXIA II",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "ALEXIA",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater - Villas",
      Mo: "TOPAZ",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:Stillwater (50s) - Royal Collection",
      Mo: "ELAN",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "HAILEY II",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (50s) - Royal Collection",
      Mo: "HALLE",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Stillwater:Stillwater (40s) - Royal Collection",
      Mo: "HAILEY",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1950,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Celestina:Celestina Elite Collection",
      Mo: "HADDINGTON",
      Str: "20 Lunetta Ct",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3592,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22680",
      Co: "Celestina:Celestina Elite Collection",
      Mo: "CRAWFORD",
      Str: "20 Lunetta Ct",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "22680",
      Co: "Stillwater | Active Adult 55+:Stillwater (40s) - Royal Collection",
      Mo: "KATE II",
      Str: "64 Round Robin Run",
      Ct: "St. Johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1744,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Hailey",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1899,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Charle",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Halle",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "22680",
      Co: "Stagecoach",
      Mo: "Plan 1815",
      Str: "801 West Romana Suite A",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Sierra",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2257,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Halle",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Trevi",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Ramsey",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Oxford",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "250000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Hailey",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1899,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Littleton",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1891,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Elan",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Saddle Ridge",
      Mo: "Newlin",
      Str: "6494 Paso Fino Drive",
      Ct: "Pensacola",
      St: "FL",
      Zip: 32526,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1474,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Elan",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Trevi",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Hailey",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1899,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Elan",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Halle",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1735,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Charle",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Sierra",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2257,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Halle",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Emerson",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1660,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Elan",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Trevi",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22680",
      Co: "Windsor Ridge",
      Mo: "Hudson",
      Str: "5344 Cornwall Dr",
      Ct: "Milton",
      St: "FL",
      Zip: 32583,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Medallion",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Trevi",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Brio",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 60s",
      Mo: "Patriot II",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2967,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Halle",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 60s",
      Mo: "Princeton",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 60s",
      Mo: "TIVOLI II",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "San Felasco 60s",
      Mo: "Medallion",
      Str: "San Felasco Pkwy",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Princeton",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 60s",
      Mo: "Trevi",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Charle",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 60s",
      Mo: "Medallion",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 60s",
      Mo: "Tivoli",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "San Felasco 50s",
      Mo: "Trevi",
      Str: "San Felasco Pkwy",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Trailhead Landing:Trailhead Landing 50s",
      Mo: "Tivoli",
      Str: "13525 NW Convergence Blvd",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "San Felasco 50s",
      Mo: "Halle",
      Str: "San Felasco Pkwy",
      Ct: "Alachua",
      St: "FL",
      Zip: 32615,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Estate Collection",
      Mo: "Joshua",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2130,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Legacy Collection",
      Mo: "Summerlin",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3174,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Estates Alley Collection",
      Mo: "Aurora",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Estates Alley Collection",
      Mo: "Glenwood",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2462,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Estate Collection",
      Mo: "Allison",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2781,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Autumn",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Estate Collection",
      Mo: "Allison",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2781,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Estates Alley Collection",
      Mo: "Harmony",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2671,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Estate Collection",
      Mo: "Douglas",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Estate Collection",
      Mo: "Aspen",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Cascade",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Legacy Collection",
      Mo: "Riverview",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Grove",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Legacy Collection",
      Mo: "Largo",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2359,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "22680",
      Co: "Rhett's Ridge:Estates Alley Collection",
      Mo: "Aurora",
      Str: "3036 Country Side Drive",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Grove",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22680",
      Co: "Golden Orchard:Cottage Collection",
      Mo: "Cascade",
      Str: "4113 Jespersen Street",
      Ct: "Apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Greythorne",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Bloomfield",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Monte Carlo",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Monroe III",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Alexander Palm",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Monaco",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2441,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Sawgrass",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2935,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Delray",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Majesty Palm",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "6-Unit Shell",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 12957,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Marina",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar/Mid Florida",
      Mo: "Sabal Palm",
      Str: "6750 Forum Drive  Suite 310",
      Ct: "Orlando",
      St: "FL",
      Zip: 32821,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Manor Collection",
      Mo: "Rio",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2634,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Cottages",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Cottages",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Independence II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3951,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Provincetown II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Manors",
      Mo: "Delray",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Bourne",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1971,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Manors",
      Mo: "Delray",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Eagle Creek Village L P-Load",
      Mo: '0067A - MESSINA END "A"',
      Str: "10197 Eagle Creek Center Blvd  FL 32832",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1702,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Cottages",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Cottage Collection",
      Mo: "Lexington",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Estates",
      Mo: "Mashpee II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Estates",
      Mo: "Peabody",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3288,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Peabody",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3288,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Riviera",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Executive Collection",
      Mo: "Greythorne",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Wesley",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3321,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Harwich II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3957,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Lexington (40' Model)",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Oaks at Moss Park:Estates",
      Mo: "Provincetown",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2946,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Manor Collection",
      Mo: "Delray",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Estates",
      Mo: "Eastham II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 60",
      Mo: "Marina",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Parking Site",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Hemingway Townhomes",
      Mo: "Wilshire",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Manors",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Manors",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:The Hemingway Townhomes",
      Mo: "Landcaster",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Peabody",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Manors",
      Mo: "Rio",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2634,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Harwich II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3903,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Cottages",
      Mo: "Lexington",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Cottage Collection",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Harwich II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3903,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Bourne II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Storey Park Manors",
      Mo: "Rio",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2634,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Executive Collection",
      Mo: "Bloomfield",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Executive Collection",
      Mo: "Independence II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3931,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Mashpee II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Moss Park 50",
      Mo: "Lexington (40' Model)",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Oaks at Moss Park:Estates",
      Mo: "Eastham II",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Independence II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3931,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Greythorne",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Eastham II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Bourne II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Hemingway Townhomes",
      Mo: "Montara",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Provincetown II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Cottage Collection",
      Mo: "Raleigh",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2198,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Manor Collection",
      Mo: "Asheville",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Oaks at Moss Park:The Oaks at Moss Park Executives",
      Mo: "Marina",
      Str: "9743 Pecan Hickory",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Executive Collection",
      Mo: "Bloomfield",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Innovation Estate Collection",
      Mo: "Eastham II",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:Hemingway Townhomes",
      Mo: "Landcaster",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Park:The Hemingway Townhomes",
      Mo: "Montara",
      Str: "10914 History Avenue",
      Ct: "Orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Shoals Collection",
      Mo: "ATLANTA",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Shoals Collection",
      Mo: "ANNAPOLIS",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "HARTFORD",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "Annapolis",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Isles Collection",
      Mo: "FREEPORT",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2182,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Shoals Collection",
      Mo: "ANNAPOLIS",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "DAHLIA",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1826,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Edgewood at Everlands:Edgewood",
      Mo: "Carlisle",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2629,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Woods Collection",
      Mo: "VICTORIA",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1955,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "DAHLIA",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1826,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Woods Collection",
      Mo: "KATE",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Woods Collection",
      Mo: "HAILEY",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1963,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "CAMELLIA",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "RALEIGH",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "HARTFORD",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Shoals Collection",
      Mo: "BOSTON",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2218,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Grand Collection",
      Mo: "SUMMERVILLE II",
      Str: "2213 Antarus Drive NWW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2468,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Woods Collection",
      Mo: "ALEXIA",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1713,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Shoals Collection",
      Mo: "CONCORD",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Woods Collection",
      Mo: "ASHLEY",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1835,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Isles Collection",
      Mo: "KEY LARGO",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "CARLISLE",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2629,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Shoals Collection",
      Mo: "COLUMBIA",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Grand Collection",
      Mo: "TREVISO",
      Str: "2213 Antarus Drive NWW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "CAMELLIA",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Isles Collection",
      Mo: "SOPHIA",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2040,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Edgewood at Everlands:Edgewood",
      Mo: "Hartford",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "Columbia",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Edgewood at Everlands:Edgewood",
      Mo: "Camellia",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Timbers at Everlands:The Isles Collection",
      Mo: "FREEPORT",
      Str: "2213 Antarus Drive NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2182,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Riverwood at Everlands:The Angler Collection",
      Mo: "Atlanta",
      Str: "767 Veridian Circle NW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32907,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Providence",
      Str: "1845 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:The Palms",
      Mo: "Boston",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2218,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:The Palms",
      Mo: "Columbia",
      Str: "C-8 & C-9",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:The Palms",
      Mo: "Annapolis",
      Str: "C-8 & C-9",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Dover",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:The Palms",
      Mo: "COLUMBIA",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:The Palms",
      Mo: "Annapolis",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Hartford",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1845 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Providence",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Harrisburg",
      Str: "1845 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Columbia",
      Str: "1845 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tillman Lakes:Heritage at Tillman Lakes",
      Mo: "Denver",
      Str: "1815 Diablo Circle SW",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32908,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Vence",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Ivy",
      Str: "14800 SW 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Fontaine Collection",
      Mo: "Palmdale",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3221,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Casis",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Bandol",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Lotus",
      Str: "SW 129th Ave and Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1878,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Lotus",
      Str: "14800 SW 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1878,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Vence",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Monte Carlo",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Ivy",
      Str: "14800 SW 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Bandol",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Casis",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Avignon",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Monte Carlo",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Adora Collection",
      Mo: "Reserve",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Dijon",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Marsielle",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Clover",
      Str: "SW 129th Ave and Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Orchid",
      Str: "14800 SW 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Fontaine Collection",
      Mo: "Ayla",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1827,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Orchid",
      Str: "SW 129th Ave and Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Harmony Parc",
      Mo: "Ivy",
      Str: "14800 SW 264th Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Juniper",
      Str: "SW 129th Ave and Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1654,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Siena Reserve:Del Mesa Collection",
      Mo: "Lorient",
      Str: "12705 SW 232nd St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Avalon Square",
      Mo: "Ivy",
      Str: "SW 129th Ave and Coconut Palm Dr",
      Ct: "Miami",
      St: "FL",
      Zip: 33032,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1561,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Evergreen Collection",
      Mo: "Lorient",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Vence",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Bandol",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Evergreen Collection",
      Mo: "Dijon",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Vence",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Vista:Carmel Collection",
      Mo: "Monroe",
      Str: "SW 288th ST and 132nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1944,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Evergreen Collection",
      Mo: "Marseille",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Bandol",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Reserve",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Casis",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Vista:Pasadena Collection",
      Mo: "Dijon",
      Str: "SW 288th ST and 132nd Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Cypress Collection",
      Mo: "Monte Carlo",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cedar Pointe:Evergreen Collection",
      Mo: "Lorient",
      Str: "28805 SW 163 Ct.",
      Ct: "Miami",
      St: "FL",
      Zip: 33033,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Horizon Collection",
      Mo: "Azure",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1330,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Andalucia Collection",
      Mo: "Vence",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Andalucia Collection",
      Mo: "Casis",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Malaga Collection",
      Mo: "Voyage",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3105,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Iris",
      Str: "SW 172nd Ave and SW 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1671,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Andalucia Collection",
      Mo: "Vence",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Granada Collection",
      Mo: "Lucena",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1875,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Seaview Collection",
      Mo: "Surfside",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1813,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Castellon Collection",
      Mo: "Avignon",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Carnation",
      Str: "SW 172nd Ave and SW 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Seaview Collection",
      Mo: "Neptune",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1815,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Andalucia Collection",
      Mo: "Monte Carlo",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Andalucia Collection",
      Mo: "Bandol",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Castellon Collection",
      Mo: "Bordeaux",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Granada Collection",
      Mo: "Zamora",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Malaga Collection",
      Mo: "Starfish",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2915,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Malaga Collection",
      Mo: "Harbor",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2527,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Castellon Collection",
      Mo: "Marsielle",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Horizon Collection",
      Mo: "Azure",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1330,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Castellon Collection",
      Mo: "Lorient",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Horizon Collection",
      Mo: "Sunglow",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Granada Collection",
      Mo: "Avila",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Poppy",
      Str: "SW 172nd Ave and SW 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1746,
      Sto: "2",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Malaga Collection",
      Mo: "Compass",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2217,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Terra Sol",
      Mo: "Water Lilly",
      Str: "SW 172nd Ave and SW 336th St",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Granada Collection",
      Mo: "Toledo",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2399,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Castellon Collection",
      Mo: "Dijon",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Granada Collection",
      Mo: "Oviedo",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2161,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Seaview Collection",
      Mo: "Breezeway",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1338,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Seaview Collection",
      Mo: "Hurley",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1694,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Malaga Collection",
      Mo: "Anchor",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1982,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Granada Collection",
      Mo: "Zamora",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Altamira:Malaga Collection",
      Mo: "Ballast",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Horizon Collection",
      Mo: "Sunglow",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Del Mar:Horizon Collection",
      Mo: "Magenta",
      Str: "35605 SW 180th Ave",
      Ct: "Miami",
      St: "FL",
      Zip: 33034,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano at The Riviera",
      Mo: "Olmeto",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Riviera:Rio Collection",
      Mo: "Bordeaux",
      Str: "1532 SE 27th Terrace",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Freesia",
      Str: "SW 344th St and SW 167th Av",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1446,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano at The Riviera",
      Mo: "Florent",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2183,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Tulip",
      Str: "SW 344th St and SW 167th Av",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1633,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano at The Riviera",
      Mo: "Piani",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2987,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Le Jardine",
      Mo: "Aster",
      Str: "SW 344th St and SW 167th Av",
      Ct: "Florida City",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1376,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano at The Riviera",
      Mo: "Argento",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1732,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano at The Riviera",
      Mo: "Centuri",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1827,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Positano at The Riviera",
      Mo: "Lumio",
      Str: "SW 336 St and SE 12 Ave",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2439,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Riviera:Rio Collection",
      Mo: "Avignon",
      Str: "1532 SE 27th Terrace",
      Ct: "Homestead",
      St: "FL",
      Zip: 33035,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Althea",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1362,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Rose",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1961,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Parkland Bay:Classic Collection",
      Mo: "Willow",
      Str: "8665 Parkland Bay Drive Ste 101",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4328,
      Sto: "2",
      Be: "4",
      Ba: "5.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Gardenia",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1794,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Watercrest Meridian",
      Mo: "WILMINGTON A",
      Str: "11201 Watercrest Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3417,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Cassia",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1465,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Rose",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1974,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Daphne",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1594,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Freesia",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1706,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Cassia",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1465,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Watercrest Vista",
      Mo: "4510A - KENSINGTON",
      Str: "11201 Watercrest Circle West",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2397,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Parkland Bay:Classic Collection",
      Mo: "Outrigger",
      Str: "8665 Parkland Bay Drive Ste 101",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4309,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Gardenia",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1794,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Daphne",
      Str: "4610 NW 120th Way",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1594,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Cassia",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1465,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Trails",
      Mo: "Freesia",
      Str: "Wiles Rd & Coral Ridge Drive",
      Ct: "Coral Springs",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1706,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Monte Carlo",
      Str: "2361 NW 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1489,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Bordeaux",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1443,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Marsielle",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Lorient",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Avignon",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Avignon",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Reserve",
      Str: "2361 NW 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Vence",
      Str: "2361 NW 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Reserve",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1545,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Casis",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Casis",
      Str: "2361 NW 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1414,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Avignon",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Lorient",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1828,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Vence",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1668,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Dijon",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Dijon",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Bandol",
      Str: "2361 NW 125th St",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Nantucket Collection",
      Mo: "Dijon",
      Str: "2361 NW 125th St.",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Westview:Provence Collection",
      Mo: "Bandol",
      Str: "2601 NW 119 Street",
      Ct: "Miami",
      St: "FL",
      Zip: 33167,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1334,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Almeria",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore at The Fountains",
      Mo: "CHESTNUT",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1819,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore at The Fountains",
      Mo: "BANYAN",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1754,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore at The Fountains",
      Mo: "BANYAN",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1754,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia:The Coastal Collection",
      Mo: "Cadiz",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3004,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore at The Fountains",
      Mo: "CHESTNUT",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1819,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshores at The Fountains",
      Mo: "PLAN B",
      Str: "4476 Fountains Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1819,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Ibiza",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3401,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshore at The Fountains",
      Mo: "HAWTHORNE",
      Str: "4258 Lagoon Shore Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Majorca",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3676,
      Sto: "2",
      Be: "4",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Andalucia",
      Mo: "Alicante",
      Str: "8926 Kingsmoor Way",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2420,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshores at The Fountains",
      Mo: "PLAN A",
      Str: "4476 Fountains Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1754,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakeshores at The Fountains",
      Mo: "PLAN C",
      Str: "4476 Fountains Drive",
      Ct: "Lake Worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Broadleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3112,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Acacia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Arcadia Collection",
      Mo: "DAISY",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Flora",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3444,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Basil",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2566,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Rosemary",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Sage",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2648,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Hart",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3730,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Rosemary",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Tarragon",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Lyonia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Arcadia Collection",
      Mo: "LILI",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2379,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Cocoplum",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2760,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Broadleaf",
      Str: "992 Castaway Ct",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3112,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Lotus",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4003,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Lyonia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Arcadia Collection",
      Mo: "Sunflower",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2973,
      Sto: "1",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Arcadia Collection",
      Mo: "PERIWINKLE",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2677,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Coriander",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2200,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Holly",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3325,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Providence Collection",
      Mo: "Sage",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Acacia",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2631,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Arcadia Collection",
      Mo: "Tulip",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Broadleaf",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3112,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Hart",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3730,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Lotus",
      Str: "992 Castaway Ct",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4003,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Arcadia Collection",
      Mo: "DAFFODIL",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1947,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Stanton Collection",
      Mo: "Holly",
      Str: "19427 Broad Shore Walk",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arden:The Waterford Collection",
      Mo: "Flora",
      Str: "992 Castaway Ct",
      Ct: "Loxahatchee",
      St: "FL",
      Zip: 33470,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3444,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Villas",
      Mo: "ORCHID",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1305,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Woods",
      Mo: "WILLOW",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Woods",
      Mo: "MAGNOLIA",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Woods",
      Mo: "BEECH",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1410,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Woods",
      Mo: "CYPRESS",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Villas",
      Mo: "HIBISCUS",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Woods",
      Mo: "SYCAMORE",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2345,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails SFH 45s",
      Mo: "JACARANDA",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Villas",
      Mo: "LARKSPUR",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails SFH 45s",
      Mo: "WILLOW",
      Str: "5750 Phoenix Palm Ct",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2483,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Delray Trails:The Woods",
      Mo: "JACARANDA",
      Str: "5692 Spacious Skies Way",
      Ct: "Delray Beach",
      St: "FL",
      Zip: 33484,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Columbia",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Manors",
      Mo: "Atlanta",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Richmond II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Annapolis II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Manors",
      Mo: "Naples II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1528,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Vanderbilt",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Boston II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Manors",
      Mo: "Boston",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Harrisburg",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Concord II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Trenton II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Manors",
      Mo: "Annapolis",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Hartford II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Raleigh II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Manors",
      Mo: "Madrid II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1764,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Trenton",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Providence II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Manors",
      Mo: "Vanderbilt II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Providence",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Atlanta",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Executives",
      Mo: "Columbia",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Townhomes",
      Mo: "Hampton  II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Townhomes",
      Mo: "Glenmoor II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Manors",
      Mo: "Concord",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Manors",
      Mo: "Vanderbilt II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2073,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Townhomes",
      Mo: "Hampton  II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Dover",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Manors",
      Mo: "Columbia",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Raleigh",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Columbia II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Manors",
      Mo: "Naples II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1528,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Hartford",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Manors",
      Mo: "Madrid II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1764,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Atlanta II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Two Rivers:The Estates",
      Mo: "Richmond",
      Str: "35127 Brackett Bend Rd",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Abbott Square:The Estates",
      Mo: "Annapolis II",
      Str: "36690 Garden Wall Way",
      Ct: "Zephyrhills",
      St: "FL",
      Zip: 33541,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Harrisburg",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East 40s",
      Mo: "Boston",
      Str: "3518 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Lincoln",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Providence",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Phoenix",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East 40s",
      Mo: "Concord",
      Str: "3518 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Richmond",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Dover",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East 40s",
      Mo: "Columbia",
      Str: "3518 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Lincoln",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Columbia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives",
      Mo: "Olympia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:The Manors",
      Mo: "Annapolis",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Harrisburg",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Santa Fe",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Olympia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:The Manors",
      Mo: "Columbia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:The Manors",
      Mo: "Concord",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Trenton",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates",
      Mo: "Dover",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates",
      Mo: "Hartford",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Olympia",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:The Manors",
      Mo: "Atlanta",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Park East:The Manors",
      Mo: "Boston",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Raleigh",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Estates II",
      Mo: "Richmond",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Park Isle:The Executives II",
      Mo: "Phoenix",
      Str: "3520 N Maryland Ave",
      Ct: "Plant City",
      St: "FL",
      Zip: 33565,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "DO NOT USE MOVED TO 727874",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Pembroke Bay",
      Mo: "Sandestin II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Southshore Pointe",
      Mo: "Glenmoor II",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Venice II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Southshore Pointe",
      Mo: "Hampton II",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Sandestin II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Pembroke Bay",
      Mo: "Sandestin II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Pembroke Bay",
      Mo: "Biscayne II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Spencer Creek:The Estates",
      Mo: "Dover",
      Str: "Tiger Tooth Place",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Sandestin II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2875,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Venice II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Caladesi II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2501,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Pembroke Bay",
      Mo: "Caladesi II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2501,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Southshore Pointe",
      Mo: "Windsor II",
      Str: "222 Tulip Banded Way",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hawks Glen",
      Mo: "Albany 2020",
      Str: "24th St NE Rd",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1407,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Biscayne II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "SouthShore Yacht Club:Somerset Preserve",
      Mo: "Anna Maria II",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2544,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Yacht Club:The Townes",
      Mo: "Mainsail",
      Str: "3079 Bermuda Sloop Circle",
      Ct: "Ruskin",
      St: "FL",
      Zip: 33570,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2572,
      Sto: "4",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Boston",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Trenton II",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Sunrise II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Richmond II",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Estates",
      Mo: "Halos",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Atlanta",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Morningtide II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Dayspring II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Estates",
      Mo: "Daybreak II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Executives",
      Mo: "Palermo",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Executives",
      Mo: "Palermo",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Concord",
      Str: "31826 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Estates",
      Mo: "Halos  II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Estates",
      Mo: "St. Kitts II",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Dayspring II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Raleigh II",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Dayspring II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Sunburst",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Townhomes",
      Mo: "Hampton",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Hartford",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Splendor II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Annapolis",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Harrisburg II",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Townhomes",
      Mo: "Glenmoor",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Estates",
      Mo: "Argent II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Sunrise II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Richmond",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Concord",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Executives",
      Mo: "Capri",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Dawning II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Estates",
      Mo: "Eventide II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Trenton",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Columbia",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Aurora II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Sunrise II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Splendor II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Estates II",
      Mo: "Hartford II",
      Str: "32109 Hawthorne Cottage Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Townhomes",
      Mo: "Hampton",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Splendor",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Sunburst II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Aurora II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Manors",
      Mo: "Meridian II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Estates",
      Mo: "St. Thomas II",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Manors II",
      Mo: "Columbia",
      Str: "31826 Cannon Rush Dr",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Aurora II*",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Townhomes",
      Mo: "Windsor",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Estates",
      Mo: "St. Kitts II",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Estates",
      Mo: "St. Thomas II",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada Active Adult:Active Adult Villas",
      Mo: "Sunrise II",
      Str: "31800 Cannon Rush Dr.",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Executives",
      Mo: "Marisol",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Mirada:The Town Executives",
      Mo: "Verona",
      Str: "32076 Pop Ash Place",
      Ct: "San Antonio",
      St: "FL",
      Zip: 33576,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2466,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:Sunstone Ridge Executives",
      Mo: "Santa Fe",
      Str: "11711 Sunburst Marble Rd",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates",
      Mo: "Harrisburg",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Executives",
      Mo: "Blackburn",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Executives",
      Mo: "Whitney",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3629,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Concord",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates II",
      Mo: "Blackburn",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates",
      Mo: "Providence",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates",
      Mo: "Dover",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates II",
      Mo: "Harrisburg",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Atlanta",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Executives",
      Mo: "Symphony",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Concord",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Executives",
      Mo: "Rainer",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3336,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates",
      Mo: "Hartford",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates",
      Mo: "Trenton",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:Stone Walk Executive I",
      Mo: "Providence",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Executives",
      Mo: "Denali",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Columbia",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates II",
      Mo: "Trenton",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:Stone Walk Executive I",
      Mo: "Santa Fe",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Columbia",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Boston",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:Stone Walk Executive I",
      Mo: "Lincoln",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Manors",
      Mo: "Annapolis",
      Str: "12678 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "South Fork:Sunstone Ridge Estates",
      Mo: "DO NOT USE MOVED TO 724076",
      Str: "11711 Sunburst Marble Rd.",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 9999,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Triple Creek:The Estates",
      Mo: "Raleigh",
      Str: "12768 Tripoli Ave",
      Ct: "Riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Bay:The Townes",
      Mo: "Windsor",
      Str: "HWY 301",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Dover",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Concerto",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Sunburst",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Richmond II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Manors",
      Mo: "Baltimore II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1486,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Richmond II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Grand Estates",
      Mo: "Ray II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2785,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Hartford II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Executives",
      Mo: "Concerto",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Estates",
      Mo: "Eventide II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Providence II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Providence",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates II",
      Mo: "Providence II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Providence",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Estates",
      Mo: "Argent II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Villas",
      Mo: "Sunrise- Do Not Use",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1396,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Harrisburg",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Cheyenne",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Harrisburg II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Trenton II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Richmond",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Manors",
      Mo: "Chicago II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Raleigh II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Raleigh II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Grand Estates",
      Mo: "Daybreak",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Villas",
      Mo: "Sunrise II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1395,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Hartford II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Dover II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Sunburst II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Manors",
      Mo: "Seattle II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Hartford",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Manors",
      Mo: "Dallas II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2217,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Olympia",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Dawning II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Grand Estates",
      Mo: "Soleil II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2761,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Santa Fe",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Raleigh",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Executives",
      Mo: "Santa Fe",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Dover II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Lincoln",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates II",
      Mo: "Harrisburg II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Harrisburg II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Sunburst II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates II",
      Mo: "Hartford II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Manors",
      Mo: "Houston II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Hartford II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Trenton",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Estates",
      Mo: "Daybreak II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Dover II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Executives",
      Mo: "Phoenix",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Dawning",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Providence II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Meridian II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Helena",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2999,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Executives",
      Mo: "Lincoln",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Bay:The Townes",
      Mo: "Glenmoor",
      Str: "HWY 301",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Estates",
      Mo: "Halos",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Hartford",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Estates",
      Mo: "Trenton II",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Balm Grove:The Executives",
      Mo: "Phoenix",
      Str: "13183 Violet Flame Ave",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista Bay:The Townes",
      Mo: "Hampton",
      Str: "HWY 301",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Manors",
      Mo: "Chicago II",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Estates",
      Mo: "Trenton",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Morningtide II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Estates",
      Mo: "Halos II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Dawning",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Berry Bay:The Executives",
      Mo: "Cheyenne",
      Str: "4970 Eagle Rock Dr",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southshore Bay Active Adult:Active Adult Manors",
      Mo: "Splendor II",
      Str: "16910 Scuba Crest St",
      Ct: "Wimauma",
      St: "FL",
      Zip: 33598,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Columbus III",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1677,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Devonshire II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1930,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Canterbury",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1559,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Santa Cruz",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Freedom",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1956,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Alexander Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Royale Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Beach Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Stockton Grande",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3090,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Harrington",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2051,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Hamilton II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Manchester",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1796,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monte Carlo",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Siesta Key",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "St. Croix (Rear)",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1371,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Bridgeport",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2157,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Brentwood",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1837,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Bourne",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1971,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monroe II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Berkshire",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1178,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Martinique",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monroe II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "St. John",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "2",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Bonita II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1866,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Alexander Palm",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2683,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Solana",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2186,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Hawthorne",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1895,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Prestwick II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1582,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Hamilton",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Alameda",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Kennedy II",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2277,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Monaco",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2441,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Brandywine",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4259,
      Sto: "2",
      Be: "6",
      Ba: "3.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar Tampa",
      Mo: "Fontana",
      Str: "4600 West Cypress Street Suite 200",
      Ct: "Tampa",
      St: "FL",
      Zip: 33607,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1697,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates I",
      Mo: "Columbia",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Dover",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates I",
      Mo: "Hartford",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Providence",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates I",
      Mo: "Dover",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates I",
      Mo: "Raleigh",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Providence",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Raleigh",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Trenton",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Hartford",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Columbia",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Saddle Creek Preserve:The Estates II",
      Mo: "Harrisburg",
      Str: "2230 Old Mining Rd",
      Ct: "Lakeland",
      St: "FL",
      Zip: 33801,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Hartford",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Hartford",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Ridge",
      Mo: "Freedom",
      Str: "112 Pershing Street",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Trenton",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Ridge",
      Mo: "Harrisburg",
      Str: "112 Pershing Street",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Liberty Ridge",
      Mo: "Harrisburg",
      Str: "112 Pershing Street",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Raleigh",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Harrisburg",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Providence",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wind Meadows South:The Estates",
      Mo: "Dover",
      Str: "2803 Red Egret Dr",
      Ct: "Bartow",
      St: "FL",
      Zip: 33830,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Drayton Woods Executives",
      Mo: "Kennedy",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Providence",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Chateau at Astonia",
      Mo: "Minori",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1834,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Legacy Collection",
      Mo: "Columbia",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Grand Collection",
      Mo: "Vista",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Lakeside",
      Mo: "Monte Carlo",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence Camden Park",
      Mo: "Freedom",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Chateau at Astonia",
      Mo: "Amalfi",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Estate Collection",
      Mo: "Independence",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3385,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Lakeside",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Grand Collection",
      Mo: "Hartford",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Manor Collection",
      Mo: "Concord",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Horse Creek at Crosswinds",
      Mo: "Atlanta",
      Str: "Selling offsite",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Legacy Collection",
      Mo: "Annapolis",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Chateau Collection",
      Mo: "Estero",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Legacy Collection",
      Mo: "Concord",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Manor Collection",
      Mo: "Atlanta",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Grand Collection",
      Mo: "Freedom",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Ridge Estates",
      Mo: "Freedom",
      Str: "280 Taft Drive",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Horse Creek at Crosswinds",
      Mo: "Concord",
      Str: "Selling offsite",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Chateau Collection",
      Mo: "Summerlin",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Lakeside",
      Mo: "Normandy",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1909,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Estate Collection",
      Mo: "Freedom",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Allentown",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Chateau Collection",
      Mo: "Summerlin",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Chateau at Astonia",
      Mo: "Sienna",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Chateau at Astonia",
      Mo: "Sienna",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Estate Collection",
      Mo: "Peabody",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Chateau Collection",
      Mo: "Inverness",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Manor Collection",
      Mo: "Columbia",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Grand Collection",
      Mo: "Dover",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Chateau Collection",
      Mo: "Riviera",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Horse Creek at Crosswinds",
      Mo: "Columbia",
      Str: "Selling offsite",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Lakeside",
      Mo: "Monaco",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2441,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence Camden Park",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Legacy Collection",
      Mo: "Columbia",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Estate Collection",
      Mo: "Peabody",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Chateau Collection",
      Mo: "Bonita",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cascades:Legacy Collection",
      Mo: "Atlanta",
      Str: "2011 Punch Bowl Avenue",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Cortland Woods",
      Mo: "Hamilton",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Allentown",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Chateau at Astonia",
      Mo: "Amalfi",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1689,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Camden Park Executive",
      Mo: "Newcastle",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Garden Hills Estate Collection",
      Mo: "Hamilton",
      Str: "2214 Barrington Lp",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Providence:Cortland Woods",
      Mo: "Monte Carlo",
      Str: "2214 Barrington Loop",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3210,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Freedom",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Astonia:Estate Collection",
      Mo: "Dover",
      Str: "2704 Pierr Street",
      Ct: "Davenport",
      St: "FL",
      Zip: 33837,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Atlanta",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Concord",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2575,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Columbia",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Vista",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Annapolis",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Atlanta",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Annapolis",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Freedom",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Hartford",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Boston",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Legacy Collection",
      Mo: "Columbia",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Ranches at Lake McLeod:Estates Collection",
      Mo: "Dover",
      Str: "836 Timberland Drive",
      Ct: "Eagle Lake",
      St: "FL",
      Zip: 33839,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cypress Park Estates",
      Mo: "Dover",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Magnolia Park",
      Mo: "Providence",
      Str: "Selling offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Discovery",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cypress Park Estates",
      Mo: "Hartford",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Manor Collection",
      Mo: "Columbia",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Manor Collection",
      Mo: "Annapolis",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Discovery",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Dover",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Manor Collection",
      Mo: "Atlanta",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Harrisburg",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Cypress Park Estates",
      Mo: "Providence",
      Str: "Selling Offsite",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lawson Dunes:Estate Collection",
      Mo: "Hartford",
      Str: "101 Kayak Avenue",
      Ct: "Haines City",
      St: "FL",
      Zip: 33844,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Grand Collection",
      Mo: "Hartford",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leomas Landing:Leomas Landing 45s",
      Mo: "Atlanta",
      Str: "Highway 27 and Chalet Suzanne Rd",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Grand Collection",
      Mo: "Dover",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Grand Collection",
      Mo: "Hartford",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Legacy Collection",
      Mo: "Boston",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Legacy Collection",
      Mo: "Annapolis",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Legacy Collection",
      Mo: "Annapolis",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Grand Collection",
      Mo: "Atlanta",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leomas Landing:Leomas Landing 55s",
      Mo: "Hartford",
      Str: "Highway 27 and Chalet Suzanne Rd",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Grand Collection",
      Mo: "Harrisburg",
      Str: "669 Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Leoma's Landing:Legacy Collection",
      Mo: "Atlanta",
      Str: "669 Bell Prairie Loop",
      Ct: "Lake Wales",
      St: "FL",
      Zip: 33859,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Traditions:Estates",
      Mo: "Prestwick II",
      Str: "3019 Caneel Street.",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1582,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Grand Collection",
      Mo: "Dover",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Grand Collection",
      Mo: "Dover",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Grand Collection",
      Mo: "Radiance",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Boston",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Grand Collection",
      Mo: "Freedom",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Traditions:Executive",
      Mo: "Devonshire II",
      Str: "3019 Caneel Street",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1930,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Haven",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1053,
      Sto: "1",
      Be: "3",
      Ba: "1",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Boston",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Atlanta",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Atlanta",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Grand Collection",
      Mo: "Hartford",
      Str: "1104 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Annapolis",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Peace Creek Reserve:Legacy Collection",
      Mo: "Inspire",
      Str: "1108 Tyler Loop",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1287,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Traditions:Executive",
      Mo: "Monroe III",
      Str: "3019 Caneel Street",
      Ct: "Winter Haven",
      St: "FL",
      Zip: 33884,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2615,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Luxury Resort Townhomes",
      Mo: "Sabal Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Flat",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Luxury Resort Townhomes",
      Mo: "Royale Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Flat",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Fox North 40's Resort",
      Mo: "Alexander Palm",
      Str: "Westside Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Luau",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3491,
      Sto: "2",
      Be: "7",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Fox North 40's Resort",
      Mo: "Alexander Palm",
      Str: "Westside Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:Champions Pointe Townhomes",
      Mo: "Azalea - Unit A",
      Str: "240 Nine Iron Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Maui",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vistas 4 Story Condo",
      Mo: "Brookside",
      Str: "1400 Masters BLvd",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1232,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Bali",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Manors",
      Mo: "Charlestown",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1960,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club",
      Mo: "Sand Dollar",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2460,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Fiji",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3348,
      Sto: "2",
      Be: "6",
      Ba: "6",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club",
      Mo: "Freedom",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:Champions Pointe Townhomes",
      Mo: "Nandina - Unit D",
      Str: "240 Nine Iron Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1988,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:Champions Pointe Townhomes II",
      Mo: "Nandina",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1988,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club",
      Mo: "Newcastle",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2599,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Luxury Villas",
      Mo: "Alexander Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Luxury Villas",
      Mo: "Majesty Palm",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:Champions Pointe Townhomes II",
      Mo: "Firethorn - Unit B",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1954,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club II",
      Mo: "Bourne",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1971,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Maui",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Retreat Single Family",
      Mo: "Buchanan",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3976,
      Sto: "2",
      Be: "7",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club II",
      Mo: "Eastham",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2065,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:Champions Pointe Townhomes",
      Mo: "Firethorn - Unit B",
      Str: "240 Nine Iron Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1954,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Bali",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2767,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:The Retreat",
      Mo: "Maui",
      Str: "9029 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Champions Pointe:Champions Pointe Townhomes II",
      Mo: "Azalea - Unit A",
      Str: "240 Nine Iron Drive",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1864,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate 2 40 Resort",
      Mo: "Majesty Palm",
      Str: "Barry Road",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club II",
      Mo: "Hideaway",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Fox North 40's Resort",
      Mo: "Majesty Palm",
      Str: "Westside Blvd",
      Ct: "Championsgate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "ChampionsGate:Country Club II",
      Mo: "Nicholas",
      Str: "9025 Sand Trap Drive",
      Ct: "ChampionsGate",
      St: "FL",
      Zip: 33896,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Venice",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Trevi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Marsala",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "Sorrento",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "Tivoli",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "The Summerville II",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Amalfi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "Tivoli",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Amalfi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pebblebrook at Verandah:Coach Homes",
      Mo: "Osprey",
      Str: "11730 Arborwood Preserve Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1798,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "The Summerville II",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "Sorrento",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Manor homes",
      Mo: "The Princeton II",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Venice",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Marsala",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Trevi",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Monte Carlo",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Portico:Executive homes",
      Mo: "Monte Carlo",
      Str: "14019 Aledo Court",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Lakeside",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Arborwood Preserve 35",
      Mo: "Milan",
      Str: "11730 Arborwood Preserve Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Manor Homes",
      Mo: "The Summerville II",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Lantana",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3542,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Manor Homes",
      Mo: "Sorrento",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Baneberry",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3178,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Laurel",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3659,
      Sto: "2",
      Be: "3",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Executive Homes",
      Mo: "Amalfi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Executive Homes",
      Mo: "Monte Carlo",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Iris",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2964,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Manor Homes",
      Mo: "The Summerville II",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Villas",
      Mo: "Orchid",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Executive Homes",
      Mo: "Trevi",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista WildBlue:Executive Homes",
      Mo: "Catalina",
      Str: "13464 Blue Bay Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2805,
      Sto: "2",
      Be: "3",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Estate Homes",
      Mo: "The National",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Lakeside",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Vista WildBlue:Executive Homes",
      Mo: "Maria",
      Str: "13464 Blue Bay Circle",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Estate Homes",
      Mo: "Sunset",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Manor Homes",
      Mo: "Sorrento",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Manor Homes",
      Mo: "Richmond",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2724,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Estate Homes",
      Mo: "Wynwood",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Estate Homes",
      Mo: "Sunset",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Timber Creek:Executive Homes",
      Mo: "1450 plan for 615 only",
      Str: "11581 Timber Creek Drive",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "WildBlue",
      Mo: "Iris",
      Str: "18117 Wildblue Blvd",
      Ct: "Fort Myers",
      St: "FL",
      Zip: 33913,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2964,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing",
      Mo: "Harrisburg",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Townhomes",
      Mo: "Avery",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Townhomes",
      Mo: "Bennett",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Executive Homes",
      Mo: "Harrisburg",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 50",
      Mo: "Santa Fe",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2606,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing",
      Mo: "Hartford",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Trevi",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Patio Homes",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Manor Homes",
      Mo: "Helena",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2985,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "The Summerville II",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Executive Homes",
      Mo: "Hartford",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Manor Homes",
      Mo: "Phoenix",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2485,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonehill Manor",
      Mo: "The Summerville II",
      Str: "10508 MINERAL CT",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar at Burnt Store Lakes",
      Mo: "The Summerville II",
      Str: "4204 Villa Rapallo Way",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 50",
      Mo: "Helena",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2985,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar at Cape Coral",
      Mo: "Hartford",
      Str: "4204 Villa Rapallo Way",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1941,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing Townhomes",
      Mo: "Bennett",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 30",
      Mo: "Atlanta",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Manor Homes",
      Mo: "Sante Fe",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2606,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bayshore Ranch",
      Mo: "Sorrento",
      Str: "17758 Saddleback Loop",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:Manor Homes",
      Mo: "Sorrento",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Executive Homes",
      Mo: "Dover",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 30",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 50",
      Mo: "Cheyenne",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3246,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:Manor Homes",
      Mo: "Tivoli",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:Executive Homes",
      Mo: "Marsala",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonehill Manor",
      Mo: "Sorrento",
      Str: "10508 MINERAL CT",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "1",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Patio Homes",
      Mo: "Columbia",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing Townhomes",
      Mo: "Avery",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Patio Homes",
      Mo: "Atlanta",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing 30",
      Mo: "Boston",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Patio Homes",
      Mo: "Boston",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar at Cape Coral",
      Mo: "Dover",
      Str: "4204 Villa Rapallo Way",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1551,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Executive Homes",
      Mo: "Trenton",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brightwater Lagoon:Executive Homes",
      Mo: "Venice",
      Str: "18807 Marlin Kite Lane",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Executive Homes",
      Mo: "Raleigh",
      Str: "17782 Monte Isola Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Patio Homes",
      Mo: "Annapolis",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lennar at Cape Coral",
      Mo: "The Summerville II",
      Str: "4204 Villa Rapallo Way",
      Ct: "Cape Coral",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Crane Landing:Manor Homes",
      Mo: "Cheyenne",
      Str: "4204 Villa Rapallo Way",
      Ct: "North Fort Myers",
      St: "FL",
      Zip: 33917,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3246,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes at River Hall",
      Mo: "Venice",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "The Princeton II",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "The Summerville II",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes at River Hall",
      Mo: "Trevi",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "Marsala",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "Tivoli",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club",
      Mo: "Marsala",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club",
      Mo: "The Summerville II",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes at River Hall",
      Mo: "Monte Carlo",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes at River Hall",
      Mo: "Marsala",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "Tivoli",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Hampton Lakes at River Hall",
      Mo: "Amalfi",
      Str: "3268 Hampton Blvd",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "Trevi",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "River Hall Country Club:Executive Homes",
      Mo: "Venice",
      Str: "3101 East Windsor Drive",
      Ct: "Alva",
      St: "FL",
      Zip: 33920,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Marsala",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Agostino II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2796,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Doral",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2553,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Marsala",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Villa Homes",
      Mo: "Magnolia",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Villa Homes",
      Mo: "Orchid",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Sunset",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "Tivoli",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "The Summerville II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "Richmond",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2724,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Venice",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Wynwood",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Amalfi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Monte Carlo",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Sunset",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "The Princeton II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "The Princeton II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Oakmont II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "Wynwood",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "The Summerville II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Estate Homes",
      Mo: "The National",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Venice",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Executive Homes",
      Mo: "Trevi",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "The Summerville II",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "Richmond",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2724,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verdana Village:Manor Homes",
      Mo: "Sorrento",
      Str: "18389 Parksville Dr",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Place at Corkscrew:Executive Homes",
      Mo: "Monte Carlo",
      Str: "17067 Ashcomb Way",
      Ct: "Estero",
      St: "FL",
      Zip: 33928,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Manor Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "The Summerville II",
      Str: "4204 Villa Rapallo Way",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Estate Homes",
      Mo: "Bougainvillea II",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Estate Homes",
      Mo: "Napoli II",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Willow:Patio Homes",
      Mo: "Boston",
      Str: "11575 Willowleaf Blvd",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Executive Homes",
      Mo: "Isabella",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Burnt Store Lakes",
      Mo: "Trevi",
      Str: "4204 Villa Rapallo Way",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Executive Homes",
      Mo: "Maria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Executive Homes",
      Mo: "Angelina",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Estate Homes",
      Mo: "Bougainvillea II",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Executive Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Executive Homes",
      Mo: "Victoria",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Estate Homes",
      Mo: "Agostino II",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2796,
      Sto: "3",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Estate Homes",
      Mo: "SUNSET",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heritage Landing:Estate Homes",
      Mo: "Napoli Grande",
      Str: "14701 Heritage Landing Blvd.",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33955,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Patio Homes",
      Mo: "Columbia",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Executive Homes",
      Mo: "Trenton",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "1",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Executive Homes",
      Mo: "Harrisburg",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1791,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Executive Homes",
      Mo: "Hartford",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1941,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Maplewood Park",
      Mo: "Hartford",
      Str: "Martineau Circle",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1941,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Maplewood Park",
      Mo: "Harrisburg",
      Str: "Martineau Circle",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1791,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Executive Homes",
      Mo: "Harrisburg",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1791,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Patio Homes",
      Mo: "Atlanta",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Executive Homes",
      Mo: "Raleigh",
      Str: "12265 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2889,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Patio Homes",
      Mo: "Annapolis",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Patio Homes",
      Mo: "Boston",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Savanna Lakes:Patio Homes",
      Mo: "Annapolis",
      Str: "12275 Savanna Lakes Blvd",
      Ct: "Lehigh Acres",
      St: "FL",
      Zip: 33974,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Executive Homes",
      Mo: "Sylvester",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Executive Homes",
      Mo: "Areca",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Executive Homes",
      Mo: "Sago",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3357,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Terrace Condominiums",
      Mo: "Birkdale (End 4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Manor Homes",
      Mo: "Palmetto",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Manor Homes",
      Mo: "Majestica",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Manor Homes",
      Mo: "Montgomery",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Villas",
      Mo: "Bismark",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Terrace Condominiums",
      Mo: "Arbor (4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1120,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Terrace Condominiums",
      Mo: "Arbor (4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1120,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Estate Homes",
      Mo: "Aster Grande",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Veranda Condominiums",
      Mo: "Arabella II",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1569,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge at Babcock Ranch:Executive homes",
      Mo: "Sylvester",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Veranda Condominiums",
      Mo: "Bromelia II",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Executive Homes",
      Mo: "Foxtail",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:Executive Homes",
      Mo: "Remington",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Executive Homes",
      Mo: "Calusa",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:Estate Homes",
      Mo: "Westwind II",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Coach Homes",
      Mo: "Arrowhead",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1741,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge at Babcock Ranch:Villas",
      Mo: "Queen",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Veranda Condominiums",
      Mo: "Bromelia II",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Veranda Condominiums",
      Mo: "Diangelo II",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1366,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Executive Homes",
      Mo: "Coconut",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Executive Homes",
      Mo: "Remington",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:Estate Homes",
      Mo: "Aster",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Executive Homes",
      Mo: "Cypress",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Coach Homes",
      Mo: "Bay Creek",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2110,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Terrace Condominiums",
      Mo: "Carolina",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1301,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge at Babcock Ranch:Executive homes",
      Mo: "Foxtail",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Manor Homes",
      Mo: "Washingtonia",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Executive Homes",
      Mo: "Alexander",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Veranda Condominiums",
      Mo: "Diangelo II",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1366,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:Executive Homes",
      Mo: "Calusa",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Estate Homes",
      Mo: "Westwind II",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge at Babcock Ranch:Manor homes",
      Mo: "Majestica",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trail's Edge at Babcock Ranch:Manor homes",
      Mo: "Palmetto",
      Str: "17709 Wayside Bend",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:Executive Homes",
      Mo: "Cypress",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Executive Homes",
      Mo: "Azalea",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Babcock National:Executive Homes",
      Mo: "Cypress",
      Str: "15991 Leaning Pine",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Estate Homes",
      Mo: "Lakeside",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tucker's Cove:Villas",
      Mo: "Queen",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Webbs Reserve:Terrace Condominiums",
      Mo: "Birkdale (End 4th Floor)",
      Str: "16540 Kingwood Lane",
      Ct: "Punta Gorda",
      St: "FL",
      Zip: 33982,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Executive Homes",
      Mo: "Monte Carlo",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Independence",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3357,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Executive Homes",
      Mo: "Trevi",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Executive Homes",
      Mo: "Trevi",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Executive Homes",
      Mo: "Venice",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Manor homes",
      Mo: "Sorrento",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves Villa",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Manor homes",
      Mo: "The Summerville II",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Executive Homes",
      Mo: "Marsala",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Executive Homes",
      Mo: "Amalfi",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Executive Homes",
      Mo: "Independence",
      Str: "2318 Avocado Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3357,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Villas",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Amalfi",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Villas",
      Mo: "Magnolia",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "La Morada:Coach Homes",
      Mo: "Bay Creek",
      Str: "2371 Montserrat Ln #102",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2110,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Manor homes",
      Mo: "Liberation",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Executive Homes",
      Mo: "Monte Carlo",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Executive Homes",
      Mo: "Trevi",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Monte Carlo",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Villas",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Ranch:Executive Homes",
      Mo: "Trevi",
      Str: "2374 Tangerine Lane",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Villas",
      Mo: "Magnolia",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves:Villas",
      Mo: "Orchid",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Orange Blossom Groves 40",
      Mo: "Trevi",
      Str: "Oil Well Road",
      Ct: "Naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Executive Homes",
      Mo: "Isabella",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2246,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Bougainvillea II",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Bougainvillea II",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Executive Homes",
      Mo: "Angelina",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Bougainvillea II",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Executive Homes",
      Mo: "Maria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Bougainvillea",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2800,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Napoli Grande",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Executive Homes",
      Mo: "Victoria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "National",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Napoli II",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Lakeside",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2689,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Estate Homes",
      Mo: "Napoli II",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The National Golf and Country Club:Executive Homes",
      Mo: "Maria",
      Str: "6098 Artisan Ct",
      Ct: "Ave Maria",
      St: "FL",
      Zip: 34142,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:Estate Homes",
      Mo: "Oakmont II",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2361,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Venice",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora at Lakewood Ranch:Patio Homes",
      Mo: "Columbia",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2382,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Trevi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Venice",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora at Lakewood Ranch:Patio Homes",
      Mo: "Atlanta",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1871,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Polo Run:Manor homes",
      Mo: "Sorrento",
      Str: "17013 Polo Trail",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Villas",
      Mo: "Orchid",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora at Lakewood Ranch:Townhomes",
      Mo: "Bennett",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1347,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:Estate Homes",
      Mo: "Sunset",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Monte Carlo",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora at Lakewood Ranch:Townhomes",
      Mo: "Avery",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora at Lakewood Ranch:Patio Homes",
      Mo: "Boston",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Manor Homes",
      Mo: "The Summerville II",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Marsala",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Villas",
      Mo: "Magnolia",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:Estate Homes",
      Mo: "Sunset",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:Estate Homes",
      Mo: "Agostino II",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2796,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Trevi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Villas",
      Mo: "Orchid",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Villas",
      Mo: "Magnolia",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Manor Homes",
      Mo: "Richmond",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2725,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Trevi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:Estate Homes",
      Mo: "National",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3473,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Executive Homes",
      Mo: "Amalfi",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Manor Homes",
      Mo: "Sorrento",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3283,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes:Estate Homes",
      Mo: "Wynwood",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Villas",
      Mo: "Orchid",
      Str: "15232 Lyla Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Manor Homes",
      Mo: "Liberation",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Manor Homes",
      Mo: "The Princeton II",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Aurora at Lakewood Ranch:Patio Homes",
      Mo: "Annapolis",
      Str: "14165 Lilac Sky Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1450,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lorraine Lakes at Lakewood Ranch:Manor Homes",
      Mo: "The Princeton II",
      Str: "16321 Tradewind Terrace",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Polo Run:Manor homes",
      Mo: "Liberation",
      Str: "17013 Polo Trail",
      Ct: "Lakewood Ranch",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3867,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Boston",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Townhomes",
      Mo: "Abacos",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Annapolis",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Columbia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Manors",
      Mo: "Morningtide",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Villas",
      Mo: "Malibu",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1486,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Atlanta",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Townhomes",
      Mo: "Cozumel",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2162,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Manors",
      Mo: "Splendor",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Estates",
      Mo: "Eventide",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Executives",
      Mo: "Cheyenne",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Trenton",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Townhomes",
      Mo: "Bimini",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1993,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Harrisburg",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Enclave at Twin Rivers:The Manors",
      Mo: "Dawning",
      Str: "14330 17TH Ct E",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Annapolis",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Estates",
      Mo: "Argent",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Columbia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Trenton",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Executives",
      Mo: "Santa Fe",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Manors",
      Mo: "Meridian",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Estates",
      Mo: "Daybreak",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Executives",
      Mo: "Phoenix",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Providence",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Manors",
      Mo: "Sunburst",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Hartford",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Concord",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Raleigh",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Manors",
      Mo: "Meridian",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Estates",
      Mo: "Dawning",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Executives",
      Mo: "Lincoln",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Concord",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Manors",
      Mo: "Dawning",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2141,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Manors",
      Mo: "Boston",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes Active Adult:Active Adult Estates",
      Mo: "Halos",
      Str: "11938 Lilac Pearl Lane",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Executives",
      Mo: "Olympia",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Executives",
      Mo: "Concerto",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Prosperity Lakes:The Estates",
      Mo: "Dover",
      Str: "13627 Sunset Sapphire Ct",
      Ct: "Parrish",
      St: "FL",
      Zip: 34219,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Denali",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Patterson",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3068,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Manors",
      Mo: "Greylock",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Manors",
      Mo: "Stowe",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2492,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Estates",
      Mo: "Preston",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Town Estates",
      Mo: "St. Kitts II",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Estates",
      Mo: "Aspen",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1844,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Preston",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3282,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Estates",
      Mo: "Brindley",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Estates",
      Mo: "Augusta",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2084,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:The Estates",
      Mo: "Atlanta",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Rainer",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3336,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:The Manors",
      Mo: "Concord",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Whitney",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3629,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Blackburn",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Executives",
      Mo: "Symphony",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3868,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:The Estates",
      Mo: "Trenton",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Stonegate Preserve:The Manors",
      Mo: "Vail",
      Str: "11306 71st Ter E",
      Ct: "Palmetto",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2776,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Seaire:The Estates",
      Mo: "Harrisburg",
      Str: "9032 Bay Leaf Dr",
      Ct: "Parrish",
      St: "FL",
      Zip: 34221,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake at Coco Bay:Executive Homes",
      Mo: "Venice",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake at Coco Bay:Estate Homes",
      Mo: "Napoli II",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Executive Homes",
      Mo: "Venice",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake at Coco Bay:Executive Homes",
      Mo: "Marsala",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Executive Homes",
      Mo: "Marsala",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Manor Homes",
      Mo: "The Summerville II",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Coco Bay 40",
      Mo: "Trevi",
      Str: "Placida Road",
      Ct: "Engelwood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Villas",
      Mo: "Magnolia",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake at Coco Bay:Estate Homes",
      Mo: "Sunset",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Coco Bay Villas",
      Mo: "Magnolia",
      Str: "Placida Road",
      Ct: "Engelwood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1417,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Manor Homes",
      Mo: "Tivoli",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Manor Homes",
      Mo: "The Princeton II",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2244,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake at Coco Bay:Villas",
      Mo: "Orchid",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1564,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Palm Lake at Coco Bay:Executive Homes",
      Mo: "Trevi",
      Str: "8885 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Island Lakes at Coco Bay:Executive Homes",
      Mo: "Trevi",
      Str: "8893 Coco Bay Blvd",
      Ct: "Englewood",
      St: "FL",
      Zip: 34224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Marsala",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Marsala",
      Str: "19611 Tortuga Cay Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Marsala",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Venice",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Venice",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf and Country Club:Terrace Condominiums",
      Mo: "Birkdale (End)",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:Executive Homes",
      Mo: "Victoria",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Marsala",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2201,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:Estate Homes",
      Mo: "Napoli II",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2395,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:Executive Homes",
      Mo: "Maria",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Trevi",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sarasota National 35",
      Mo: "Coquina",
      Str: "10373 CROOKED CREEK DRIVE",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1761,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sarasota National 35",
      Mo: "Nautilus",
      Str: "10373 CROOKED CREEK DRIVE",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1903,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf and Country Club:Terrace Condominiums",
      Mo: "Birkdale (End)",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:Executive Homes",
      Mo: "Maria",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2247,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Venice",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf & Country Club:Estate Homes",
      Mo: "Sunset",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Venice",
      Str: "19611 Tortuga Cay Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1849,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sarasota National 35",
      Mo: "Medina",
      Str: "10373 CROOKED CREEK DRIVE",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1593,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Trevi",
      Str: "11490 Burgundy Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Monte Carlo",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3231,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Antigua at Wellen Park",
      Mo: "Trevi",
      Str: "19611 Tortuga Cay Drive",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellen Park Golf and Country Club:Terrace Condominiums",
      Mo: "Birkdale (End)",
      Str: "17121 Jadestone Court",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1154,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Trevi",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2032,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Lakespur @ Wellen Park",
      Mo: "Amalfi",
      Str: "West Villages Parkway",
      Ct: "Venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 40's",
      Mo: "ALEXIA II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 40's",
      Mo: "BOONE II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2089,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 60's",
      Mo: "MEDALLION II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 40's",
      Mo: "CHLOE",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch Townhomes",
      Mo: "Truman",
      Str: "SW 82nd Place Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:Pioneer Ranch 60's",
      Mo: "MEDALLION II",
      Str: "SW 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 50s",
      Mo: "CHARLE II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2106,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 60's",
      Mo: "TIVOLI II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 60's",
      Mo: "PRINCETON II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2226,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 50s",
      Mo: "TREVI II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 50s",
      Mo: "HALLE II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:Phase One",
      Mo: "Medallion",
      Str: "9332 SW 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:Phase One",
      Mo: "Tivoli",
      Str: "9332 SW 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2267,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:Pioneer Ranch 60's",
      Mo: "TIVOLI II",
      Str: "SW 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2173,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:Phase One",
      Mo: "TREVI",
      Str: "9332 SW 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch Townhomes",
      Mo: "Lincoln",
      Str: "SW 82nd Place Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 40's",
      Mo: "HAILEY II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1897,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:Pioneer Ranch 50's",
      Mo: "BRIO II",
      Str: "SW 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Marion Ranch:Marion Ranch 50s",
      Mo: "BRIO II",
      Str: "8319 SW 46th Ave",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:Pioneer Ranch 50's",
      Mo: "TREVI II",
      Str: "SW 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1943,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:Phase One",
      Mo: "CHARLE",
      Str: "9332 SW 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Freedom Crossings Preserve:Phase One",
      Mo: "BRIO",
      Str: "9332 SW 58th Circle",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pioneer Ranch:Pioneer Ranch 50's",
      Mo: "HALLE II",
      Str: "SW 95th Street Road",
      Ct: "Ocala",
      St: "FL",
      Zip: 34476,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "MEDALLION",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2657,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "RIDLEY",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1964,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "ELAN",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "HALLE",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "CHLOE",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "PRINCETON",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "TIVOLI",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "TREVI",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "DREXEL",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1626,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "BOONE",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "BRIO",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "ELAN",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "TREVI",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "KOWSKI",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1266,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "RIDLEY",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1964,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "BRIO",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "PRINCETON",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Meadows",
      Mo: "DREXEL",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1626,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "COLLIER",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1440,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "TIVOLI",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "BOONE",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2088,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Millwood:Millwood Estates - The Enclave",
      Mo: "HALLE",
      Str: "3545 NE 35th St",
      Ct: "Ocala",
      St: "FL",
      Zip: 34479,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "HAILEY",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Meadows",
      Mo: "TREVI",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "JESSICA",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "BRIO",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Meadows",
      Mo: "HALLE",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "ELAN",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1544,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Meadows",
      Mo: "CHARLE",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "TREVI",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2028,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "HALLE II",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1703,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "ALEXIA",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "BRIO II",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2360,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Meadows",
      Mo: "HAILEY",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1951,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Meadows",
      Mo: "ALEXIA",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1714,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "HALLE",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Meadows",
      Mo: "BRIO",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2358,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Heath Preserve:Heath Preserve - The Enclave",
      Mo: "CHARLE",
      Str: "1875 NW 44th Court",
      Ct: "Ocala",
      St: "FL",
      Zip: 34482,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2124,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Manors",
      Mo: "Argent",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Manors",
      Mo: "Beacon",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Manors",
      Mo: "Solstice",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3890,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Manors",
      Mo: "Halos",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Manors",
      Mo: "Daybreak",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Cottages",
      Mo: "Sunburst",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Cottages",
      Mo: "Morningtide",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Cottages",
      Mo: "Meridian",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Southern Hills:Southern Hills Cottages",
      Mo: "Dawning",
      Str: "19850 Southern Hills Blvd",
      Ct: "Brooksville",
      St: "FL",
      Zip: 34601,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Manors",
      Mo: "Annapolis",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Manors",
      Mo: "Concord",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Estates",
      Mo: "Richmond II",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Manors",
      Mo: "Boston",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "The Isle of Avalon:The Estates",
      Mo: "Trenton",
      Str: "154 Argyll Dr",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Manors",
      Mo: "Columbia",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Manors",
      Mo: "Atlanta",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Estates",
      Mo: "Columbia",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Estates",
      Mo: "Harrisburg II",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Estates",
      Mo: "Providence II",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Estates",
      Mo: "Raleigh II",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Verano:The Estates",
      Mo: "Hartford II",
      Str: "11459 Lavender Loop",
      Ct: "Spring Hill",
      St: "FL",
      Zip: 34609,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Hartford",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Trenton",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Townhomes",
      Mo: "Juniper",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1851,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates II",
      Mo: "McGinnis",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3230,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Townhomes",
      Mo: "Mulberry",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1601,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Providence",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Manors",
      Mo: "Boston",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2215,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates II",
      Mo: "Preston",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3282,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Executives II",
      Mo: "Whitney",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3629,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Dover",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Big Connerton 50 ADS",
      Mo: "Trenton",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3326,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Manors",
      Mo: "Concord",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Richmond",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Executives II",
      Mo: "Rainer",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3336,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Raleigh",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Manors",
      Mo: "Annapolis",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Manors",
      Mo: "Atlanta",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1870,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates II",
      Mo: "Brindley",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Manors",
      Mo: "Columbia",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Executives II",
      Mo: "Blackburn",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Estates",
      Mo: "Harrisburg",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Connerton:The Executives II",
      Mo: "Denali",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Big Connerton 50 ADS",
      Mo: "Harrisburg",
      Str: "20421 Rose Cottage Way",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34637,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Lake Thomas:The Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Eventide II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Daybreak II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Estates",
      Mo: "Annapolis",
      Str: "State Route 52",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bexley 2B TH",
      Mo: "Wilshire",
      Str: "4464 Slipstream Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1620,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Town Executives",
      Mo: "Capri II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Daybreak",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2775,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Villas",
      Mo: "Sunrise II*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Dawning II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Morningtide II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Halos II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2350,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Villas",
      Mo: "Sunrise II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1398,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Villas",
      Mo: "Aurora II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Villas",
      Mo: "Dayspring II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Argent II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Townhomes",
      Mo: "Hampton II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Eventide",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2611,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Town Executives",
      Mo: "Marisol II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2319,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Sunburst II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1959,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Lake Thomas:The Townhomes",
      Mo: "Hampton",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Town Executives",
      Mo: "Verona II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2466,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Townhomes",
      Mo: "Windsor II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1513,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Splendor II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1783,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Morningtide",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1683,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Lake Thomas:The Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Townhomes",
      Mo: "Glenmoor II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Villas",
      Mo: "Aurora II*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1747,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Villas",
      Mo: "Dayspring II*",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Lake Thomas:The Townhomes",
      Mo: "Glenmoor",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Little Lk Thoma 28",
      Mo: "Capri",
      Str: "Little Lake Thomas Rd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1801,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Town Executives",
      Mo: "Palermo II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Town Estates",
      Mo: "St. Kitts II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Town Estates",
      Mo: "St. Thomas II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Townes at Lake Thomas:The Townhomes",
      Mo: "Hampton",
      Str: "21415 Darter Road",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline:The Townhomes",
      Mo: "Glenmoor II",
      Str: "17516 Nectar Flume Drive",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1541,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Estates",
      Mo: "Argent",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2216,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Meridian II",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Meridian",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Angeline Active Adult:Active Adult Manors",
      Mo: "Dawning",
      Str: "11233 Banyan Breeze Blvd",
      Ct: "Land O Lakes",
      St: "FL",
      Zip: 34638,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2145,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Legacy Collection",
      Mo: "Atlanta",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1879,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Douglas",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Chateau Collection",
      Mo: "Summrlin",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3174,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Manor Collection",
      Mo: "Delray",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Manor Collection",
      Mo: "Delray",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Chateau Collection",
      Mo: "Inverness",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Trail Townhomes",
      Mo: "Landcaster",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Discovery",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Trail Townhomes",
      Mo: "Wilshire",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Cottage Collection",
      Mo: "Cascade",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Overlook Townhomes",
      Mo: "Amalfi",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2027,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Weston",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2992,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Douglas",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Chateau Collection",
      Mo: "Estero",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sawgrass Bay",
      Mo: "Allentown",
      Str: "4582 Tahoe Circle",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Legacy Collection",
      Mo: "Columbia",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Cottage Collection",
      Mo: "Brookside",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1782,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Overlook Townhomes",
      Mo: "Minori",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1834,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Aspen",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Chateau Collection",
      Mo: "Bloomfield",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Independence",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3385,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Manor Collection",
      Mo: "Solona II",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2186,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Cottage Collection",
      Mo: "Autumn",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Aspen",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Chateau Collection",
      Mo: "Summrlin",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3174,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Trail Townhomes",
      Mo: "Montara",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Estates Collection",
      Mo: "Allison",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2781,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Cottage Collection",
      Mo: "Westwood",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1647,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Legacy Collection",
      Mo: "Annapolis",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Manor Collection",
      Mo: "Rio",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2643,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Wellness Ridge:Overlook Townhomes",
      Mo: "Sienna",
      Str: "2786 Fitness Street",
      Ct: "Clermont",
      St: "FL",
      Zip: 34714,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bellevue at Estates at Cherry Lake",
      Mo: "Dover",
      Str: "945 Laurel View Way",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Providence",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2584,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Hartford",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Allentown",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Allentown",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Executive Collection",
      Mo: "Kennedy III",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2359,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Executive Collection",
      Mo: "Allentown (Model Use Only)",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Freedom",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Executive Collection",
      Mo: "Inverness II",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2911,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Dover",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Estate Collection",
      Mo: "Harrisburg",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Trinity Lakes:Executive Collection",
      Mo: "Peabody",
      Str: "7113 Dilly Lake Ave",
      Ct: "Groveland",
      St: "FL",
      Zip: 34736,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Eastham II",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Cottage Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Cottages Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Townhome Collection",
      Mo: "Landcaster",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Peabody",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Cottages Collection",
      Mo: "Autumn",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Bourne II",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "North Point 60",
      Mo: "Buckingham",
      Str: "4316 Summer Breeze Way",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3711,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Cottages Collection",
      Mo: "Cascade",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Eastham II",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Townhome Collection",
      Mo: "Montara",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estate Collection",
      Mo: "Miramar",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Cottages Collection",
      Mo: "Autumn",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Townhome Collection",
      Mo: "Wilshire",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Townhome Collection",
      Mo: "Landcaster",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Freedom",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Bourne II",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Townhome Collection",
      Mo: "Montara",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estate Collection",
      Mo: "Eastham II",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Townhome Collection",
      Mo: "Wilshire",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Cottages Collection",
      Mo: "Sierra",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2522,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estate Collection",
      Mo: "Freedom",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tohoqua:Estates Collection",
      Mo: "Simmitano",
      Str: "2396 Volunteer Avenue",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34744,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2795,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections: Manor Homes",
      Mo: "Sheffield",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections Executive Homes",
      Mo: "Thornebrook",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3907,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Heathcliff",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Providence",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Dover",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:The Cove Resort Townhomes",
      Mo: "Beach TH Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Vacation Townhomes",
      Mo: "Beach Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Columbia",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:The Cove Resort Single Family",
      Mo: "Gatsby",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3795,
      Sto: "2",
      Be: "6",
      Ba: "6",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Durham",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections Executive Homes",
      Mo: "Bloomfield",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Vacation Villas",
      Mo: "Alexander Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3014,
      Sto: "2",
      Be: "5",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Sheffield",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Sheffield",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2334,
      Sto: "1",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Vacation Townhomes",
      Mo: "Beach Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Hartford",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Vacation Townhomes",
      Mo: "Royale Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Annapolis",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1444,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:The Cove Resort Single Family",
      Mo: "Santiago",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4305,
      Sto: "2",
      Be: "8",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Alexander",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "1",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections: Manor Homes",
      Mo: "Charlestown",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1960,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Durham",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2896,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:The Cove Resort Townhomes",
      Mo: "Sabal Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections Executive Homes",
      Mo: "Marina",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Hartford",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Columbia",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections Estate Homes",
      Mo: "Bourne II",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Dover",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1555,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections: Manor Homes",
      Mo: "Plymouth",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2074,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Oakley",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2082,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Reflections Estate Homes",
      Mo: "Eastham II",
      Str: "4693 Fairy Tale Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2139,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Vacation Townhomes",
      Mo: "Sabal Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1914,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Linden",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2169,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Hartridge",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Janeway",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1263,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Knightsbridge",
      Mo: "Laurel",
      Str: "4807 Worchester Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Freedom",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Estate Collection",
      Mo: "Allentown",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1853,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Lake:Vacation Villas",
      Mo: "Majesty Palm",
      Str: "4715 Kings Castle Circle",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "6",
      Ba: "5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Creek:Manor Collection",
      Mo: "Albany",
      Str: "2009 Myrtle Pine Street",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34746,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1267,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Estate Collection",
      Mo: "Miramar",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Manor Alley Collection",
      Mo: "Harmony",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2671,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Estate Collection",
      Mo: "Discovery",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2109,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Cottage Alley Collection",
      Mo: "Sierra",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2522,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Estate Collection",
      Mo: "Peabody",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Manor Alley Collection",
      Mo: "Delray",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Manor Alley Collection",
      Mo: "Aurora",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Executive Collection",
      Mo: "Inverness",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2847,
      Sto: "1",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Estate Collection",
      Mo: "Miramar",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2571,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Estate Collection",
      Mo: "Aspen",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2199,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Manor Alley Collection",
      Mo: "Glenwood",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Cottage Alley Collection",
      Mo: "Autumn",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Manor Alley Collection",
      Mo: "Aurora",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2052,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Manor Alley Collection",
      Mo: "Glenwood",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2455,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Executive Collection",
      Mo: "Eastham",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2061,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Cottage Alley Collection",
      Mo: "Cascade",
      Str: "2740 Pine Sap Lane",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Manor Alley Collection",
      Mo: "Solana II",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2186,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Executive Collection",
      Mo: "Bonita",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Executive Collection",
      Mo: "Bloomfield",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Executive Collection",
      Mo: "Bloomfield",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3791,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Pine Glen:Chateau Collection",
      Mo: "Independence",
      Str: "2740 Pine Sap Lane",
      Ct: "St Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3385,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Grove",
      Mo: "DESOTO B",
      Str: "1420 Benevento Street",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2686,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bridgewalk:Manor Alley Collection",
      Mo: "Autumn",
      Str: "3012 Ella Way",
      Ct: "Saint Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Estate Collection",
      Mo: "Simmitano",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Manor Collection",
      Mo: "Residence 3",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Manor Collection",
      Mo: "Residence 1",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1795,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Manor Collection",
      Mo: "Residence 2",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Estate Collection",
      Mo: "Simmitano",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2754,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Independence:Independence Estates Rear",
      Mo: "Alameda",
      Str: "14827 Bridgewater Crossings Blvd.",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3152,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Townhome Collection",
      Mo: "Montara",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Summerlake:Summerlake Estate Homes",
      Mo: "Harwich II",
      Str: "14891 Winter Stay Drive",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3957,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Estate Collection",
      Mo: "Douglas",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3332,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Townhome Collection",
      Mo: "Wilshire",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Manor Collection",
      Mo: "Sage",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2502,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Estate Collection",
      Mo: "Orleans II",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2727,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Townhome Collection",
      Mo: "Landcaster",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1755,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Townhome Collection",
      Mo: "Wilshire",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1615,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Manor Collection",
      Mo: "Residence 2",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2023,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Storey Grove:Townhome Collection",
      Mo: "Montara",
      Str: "15518 Water Spring Blvd",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2000,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Meadows Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Meadows Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Denver",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Concord",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Meadows Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Columbia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "6",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Meadows Collection",
      Mo: "Concord",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2580,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Jefferson",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2463,
      Sto: "2",
      Be: "6",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Meadows Collection",
      Mo: "Harrisburg",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Bloom",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1487,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Meadows Collection",
      Mo: "Columbia",
      Str: "1016 Bent Creek Dr.",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Bent Creek:The Gardens Collection",
      Mo: "Georgia",
      Str: "1016 Bent Creek Dr",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34947,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "GIULIA",
      Str: "Virtual WHC",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2236,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:Twin Homes",
      Mo: "AZALEA",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1757,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles East",
      Mo: "KEY LARGO",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand East",
      Mo: "PARIS",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2324,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand East",
      Mo: "VENICE",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2807,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "ANGELO",
      Str: "Virtual WHC",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2013,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand",
      Mo: "Key Largo - MODEL ONLY",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles",
      Mo: "Jacaranda - MODEL ONLY",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand",
      Mo: "Paris",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2325,
      Sto: "1",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand",
      Mo: "Treviso",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles",
      Mo: "Freeport",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2181,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Landing",
      Mo: "HONEY",
      Str: "870 SE GROVEBUSH LN",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1820,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles East",
      Mo: "SOPHIA",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2040,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles East",
      Mo: "MAGNOLIA",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Landing",
      Mo: "COCCO",
      Str: "870 SE GROVEBUSH LN",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1635,
      Sto: "2",
      Be: "3",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand East",
      Mo: "SUMMERVILLE II",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2468,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles East",
      Mo: "JACARANDA",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles",
      Mo: "Magnolia - MODEL ONLY",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1695,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand East",
      Mo: "RIVIERA",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles",
      Mo: "Sophia",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2040,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "POPPI",
      Str: "Virtual WHC",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3066,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Isles",
      Mo: "Key Largo",
      Str: "726 E Woodbine Rd",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2389,
      Sto: "1",
      Be: "3",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Veranda Preserve:The Grand East",
      Mo: "TREVISO",
      Str: "726 SE Woodbine Road",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2583,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "LUCCA",
      Str: "Virtual WHC",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2624,
      Sto: "2",
      Be: "4",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Tesoro Club",
      Mo: "VITO",
      Str: "Virtual WHC",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34984,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3198,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "RALEIGH",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "SANTE FE",
      Str: "12190 SW Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2576,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "CHEYENNE",
      Str: "12190 SW Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3252,
      Sto: "2",
      Be: "5",
      Ba: "3.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "WYOMING",
      Str: "12190 SW Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "ALEXIA",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1719,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Palms Collection",
      Mo: "ANNAPOLIS",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1448,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "COLUMBIA",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "HARRISBURG",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1817,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Palms Collection",
      Mo: "CONCORD",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2587,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Palms Collection",
      Mo: "ALEXIA",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1719,
      Sto: "1",
      Be: "2",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "PROVIDENCE",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "PROVIDENCE",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "HELENA",
      Str: "12190 SW Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3007,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Heritage Collection",
      Mo: "Hartford",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Palms Collection",
      Mo: "ATLANTa",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1873,
      Sto: "2",
      Be: "4",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Central Park",
      Mo: "LINCOLN",
      Str: "12190 SW Roma Cir",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Brystol at Wylder:The Palms Collection",
      Mo: "COLUMBIA",
      Str: "6205 Sweetwood Drive",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "5",
      Ba: "2.5",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Copper Creek:Premier Collection",
      Mo: "Cheyenne",
      Str: "12667 Copper Creek Dr.",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3252,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "22680",
      Co: "Sunset Grove",
      Mo: "DESOTO B",
      Str: "1420 Benevento Street",
      Ct: "St. Cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2686,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: ""
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "638 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "1619 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Oakton",
      Str: "12324 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3467,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "630 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "207 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "250 preserve trail north",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "2006 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "121000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 50s",
      Mo: "Tobago",
      Str: "10663 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 60s",
      Mo: "Flora",
      Str: "9312 cantal circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "560000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7718 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2129,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "640 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Valencia II",
      Str: "2337 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "11915 vivar run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "222 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "Aspire at East Lake",
      Mo: "Stetson ",
      Str: "2961 se union park drive",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1666,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7620 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2223 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1991,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "1410 gopher terrace",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "144000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1995 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "103000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "248 preserve trail north",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1663,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Xavier",
      Str: "10551 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "3",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St Lucie",
      Mo: "Palmera",
      Str: "2431 sw brescia st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1610 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Cerelia",
      Str: "9019 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "223 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "144000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7720 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2432,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "735 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons Orlando",
      Mo: "Emerie",
      Str: "1908 flora pass way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "225000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "2104 antilles club drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Ocoee Landings",
      Mo: "San Andres",
      Str: "1896 ibis bay court",
      Ct: "ocoee",
      St: "FL",
      Zip: 34761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3000,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Emerald",
      Str: "5218 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7645 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5161 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1623 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1634 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Dupont",
      Str: "6774 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Other",
      Str: "8839 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2641,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Flora",
      Str: "8874 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "2038 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "114000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "2574 sw cameo blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s on 70s",
      Mo: "Flora",
      Str: "9409 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2525,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1626 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1699 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 32s",
      Mo: "Suncrest",
      Str: "16001 micelli drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "641 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "651 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "200000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "213 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2135,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "8956 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Lelia",
      Str: "5109 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Wheatley",
      Str: "8480 nw 39th ct",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3345,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "12155 bastille circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando",
      Mo: "Sandpiper",
      Str: "7644 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons of Orlando",
      Mo: "St Lucia",
      Str: "2051 flora pass place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1967,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5114 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "240000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8848 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Tessa",
      Str: "720 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 50s",
      Mo: "Grayson",
      Str: "10508 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 60s",
      Mo: "Cerelia",
      Str: "11909 cantal circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Ivanhoe",
      Str: "36838 precita terrace",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "1",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "215 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Azure",
      Str: "8985 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1965,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "8867 bastille circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "685000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2024 raulerson court",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "3984 sw laffite st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "614 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "190000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "11886 vivar run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada",
      Str: "12107 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "1137 sw east louise cir",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1659 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "616 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5162 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "11795 leon circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2417,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1683 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada II",
      Str: "12285 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "224 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7716 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "1663 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 32s",
      Mo: "Tessa",
      Str: "15943 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "1486 sw herder rd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Sola Vista",
      Mo: "null",
      Str: "5367 jubiloso dr",
      Ct: "st cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3636,
      Sto: "2",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "570000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "1717 sw alberca ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Suncrest",
      Str: "731 firerock lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Hawks Ridge",
      Mo: "Fullerton V",
      Str: "668 ne waters edge ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "113000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "1117 sw kapok ave",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12112 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12172 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada II EXTRA SUITE",
      Str: "12127 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4633 sw savona blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1675 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "280000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12124 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Ashmere",
      Str: "6741 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Palmera",
      Str: "6762 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton III",
      Str: "234 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1986,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7744 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "6701",
      Co: "Aspire at the Links of Calusa Springs",
      Mo: "Dupont",
      Str: "6879 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando",
      Mo: "Balfour",
      Str: "7793 four seasons blvd",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1599,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont Aspire",
      Str: "2574 sw cameo blvd",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "3414 sw princeton st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Everett",
      Str: "11806 vivar run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "620 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Other",
      Str: "2159 limestone trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Suncrest",
      Str: "843 terrapin drive",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s on 70s",
      Mo: "Flora",
      Str: "9137 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2385,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1687 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Sola Vista",
      Mo: "Sherrington",
      Str: "2175 brillante drive",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2278,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "226 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 32s",
      Mo: "Suncrest",
      Str: "15959 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "4549 sw scope st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7641 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "208 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "138000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "220 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "7600 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "616 cheval lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "1408 gopher terrace",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "155000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Thomas",
      Str: "1578 whitewood court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Odessa",
      Str: "2046 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6701",
      Co: "Aspire at the Links of Calusa Springs",
      Mo: "Ivanhoe",
      Str: "6825 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2743,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Tessa",
      Str: "803 firerock lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2810,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "221 preserve trail south",
      Ct: "stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "229 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Dominica",
      Str: "7740 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2355,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St Lucie",
      Mo: "Other",
      Str: "642 sw tulip blvd",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4598 sw scope st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Tobago",
      Str: "15327 gilligan court",
      Ct: "winter gardens",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Dupont",
      Str: "6744 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay",
      Mo: "Other",
      Str: "15975 pantuso alley",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando",
      Mo: "Tobago",
      Str: "7764 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Sola Vista",
      Mo: "Alvarez II",
      Str: "2183 brillante dr",
      Ct: "st cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2762,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s on 70s",
      Mo: "Flora",
      Str: "8912 bastille circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2370,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1695 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "635 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "230000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "642 bayhawk street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "106000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "7739 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Emerald",
      Str: "36826 precita terrace",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2321,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "654 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 50s",
      Mo: "Tompkins II",
      Str: "10496 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 70s",
      Mo: "Verna",
      Str: "9072 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3615,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Lucia",
      Str: "1570 whitewood court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Tobago",
      Str: "1590 whitewood court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2445,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian Reserve",
      Mo: "NULL",
      Str: "2058 emerald springs",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Fullerton II",
      Str: "236 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2057,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6701",
      Co: "Winding Bay II 50s",
      Mo: "Xavier",
      Str: "10532 petrillo way",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3652,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "8995 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Eden",
      Str: "5169 armina place",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1616,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Vitale",
      Str: "9134 nw 39th street",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3229,
      Sto: "2",
      Be: "4",
      Ba: "4",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St Lucia",
      Mo: "Palmera",
      Str: "1525 sw paar dr",
      Ct: "port st lucia",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "624 bayern lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Stella",
      Str: "7765 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2118,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1630 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "105000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Peabody",
      Str: "647 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1533,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "260000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7612 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Coral Lago",
      Mo: "Other",
      Str: "3811 nw 87th way",
      Ct: "coarl springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2778,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6701",
      Co: "Coral Logo",
      Mo: "Edinburgh",
      Str: "8520 nw 39th ct",
      Ct: "coral springs",
      St: "FL",
      Zip: 33065,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "Enclave at Boca Dunes",
      Mo: "NULL",
      Str: "9476 kinley pl",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2475,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Everett",
      Str: "11748 fortress run",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2368,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6701",
      Co: "Enclave at Boca Dunes",
      Mo: "Bonaire II",
      Str: "10161 brickhill dr",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8824 leon circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Thomas",
      Str: "7733 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2189,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Flat",
      CovA: "465000"
    },
    {
      Bid: "6701",
      Co: "Aspire at East Lake SF",
      Mo: "Stetson",
      Str: "2364 se union park dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1612,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Hartwell",
      Str: "12256 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "1025000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Valencia II",
      Str: "2045 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2516,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "1667 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1650 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Aspire at The Links at Calusa Springs",
      Mo: "Ashmere",
      Str: "6817 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1464,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "220000"
    },
    {
      Bid: "6701",
      Co: "Enclave at Boca Dunes",
      Mo: "Other",
      Str: "10222 akenside dr",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "Rivington",
      Mo: "Suncrest",
      Str: "742 costa lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2419,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Dupont",
      Str: "4000 sw mccrory st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1830,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 70s",
      Mo: "Coral",
      Str: "9233 porto way",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Parkland",
      Mo: "Coral",
      Str: "12130 bastile circle east",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2957,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Lucena Loft",
      Str: "2069 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera EXTRA SUITE PLUS",
      Str: "1470 sw cellini ave",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Cerelia",
      Str: "8899 leon circle west",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2308,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Saint Thomas",
      Str: "7753 four seasons boulevard",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2029,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Nicola",
      Str: "780 middleton st",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Paloma",
      Str: "2181 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2873,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6701",
      Co: "Enclave at Boca Dunes",
      Mo: "Bonaire II",
      Str: "9424 kinley place",
      Ct: "boca raton",
      St: "FL",
      Zip: 33428,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Nicola",
      Str: "716 moca lane",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6701",
      Co: "Rivington TH",
      Mo: "Loyola",
      Str: "639 belgrove street",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1276,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "205000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Corbera",
      Str: "2217 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2294,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "7608 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian",
      Mo: "Paloma",
      Str: "2355 emerald springs drive",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2873,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Palmera",
      Str: "4454 sw idlewild st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Lelia",
      Str: "5125 armina pl",
      Ct: "fort pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "Rivington SF",
      Mo: "Lila",
      Str: "726 rivington ave",
      Ct: "debary",
      St: "FL",
      Zip: 32713,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1536,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "230 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Elsinore",
      Str: "12120 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2854,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6701",
      Co: "The Preserve at Avonlea",
      Mo: "Arabella II",
      Str: "206 preserve trail south",
      Ct: "city of stuart",
      St: "FL",
      Zip: 34994,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1664,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "245000"
    },
    {
      Bid: "6701",
      Co: "Links at Calusa Springs",
      Mo: "Palmera",
      Str: "6978 shelby lynn way",
      Ct: "zephyrhills",
      St: "FL",
      Zip: 33542,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2127,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "San Sebastian Reserve",
      Mo: "Andorra",
      Str: "2012 raulerson ct",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons At Orlando",
      Mo: "NULL",
      Str: "7649 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "",
      Ba: "",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "1651 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "235000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Sandpiper",
      Str: "2042 key bay trail",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1583,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Other",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "7624 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando Villas",
      Mo: "Emerie",
      Str: "7628 sand pierre court",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1435,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "215000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Lucerne",
      Str: "12296 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3276,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Rosada II EXTRA SUITE",
      Str: "12131 waterstone circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4124,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6701",
      Co: "Marion Oaks",
      Mo: "Other",
      Str: "230 marion oaks trail",
      Ct: "ocala",
      St: "FL",
      Zip: 34473,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1282,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 60s",
      Mo: "Vera",
      Str: "11925 cantal circle south",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2604,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6701",
      Co: "La Terre at Avenir",
      Mo: "Other",
      Str: "12300 Waterstone Circle",
      Ct: "Palm Beach Gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "FS at Wylder 50s",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "FS at Wylder duplex villas",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "FS at Wylder 40s",
      Mo: "Other",
      Str: "10378 Dreamweaver Road",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at East Lake SF",
      Mo: "Other",
      Str: "2388 SE Union Park Drive",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34952,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Victoria Parc",
      Mo: "Other",
      Str: "SW Village Parkway and",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 50s",
      Mo: "Other",
      Str: "Loxahatchee Rd",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "FS at Parkland 70s",
      Mo: "Other",
      Str: "Loxahatchee Rd",
      Ct: "Parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Four Seasons at Orlando 50s",
      Mo: "Other",
      Str: "2117 Antilles Club Drive",
      Ct: "Kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Osprey Ranch 50s",
      Mo: "Other",
      Str: "15096 Field Daisy Dr",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Waterstone",
      Mo: "Other",
      Str: "Waterstone Boulevard",
      Ct: "Fort Pierce",
      St: "FL",
      Zip: 34951,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Horizon Isle",
      Mo: "Other",
      Str: "15221 Blue Peacock Lane",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Osprey Ranch 32s",
      Mo: "Other",
      Str: "15096 Field Daisy Dr",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Salerno Reserve SF",
      Mo: "Other",
      Str: "6791 SE Lost Pine Dr.",
      Ct: "Stuart",
      St: "FL",
      Zip: 34997,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Ivy Trail 60s",
      Mo: "Other",
      Str: "828 Green Eng St.",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Boatman Hammock",
      Mo: "Other",
      Str: "Boatman Street",
      Ct: "Village of Palm Springs",
      St: "FL",
      Zip: 33461,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Port St. Lucie",
      Mo: "Other",
      Str: "2961 SW Savona Boulevard",
      Ct: "Port St. Lucie",
      St: "FL",
      Zip: 34953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Palm Bay",
      Mo: "Other",
      Str: "1834 Emerson Dr SE",
      Ct: "Palm Bay",
      St: "FL",
      Zip: 32905,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Armen Groves",
      Mo: "Other",
      Str: "124 Smith Rd",
      Ct: "Merritt Island",
      St: "FL",
      Zip: 32953,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Osprey Ranch THs",
      Mo: "Other",
      Str: "Avalon Road",
      Ct: "Winter Garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Marion Oaks",
      Mo: "Other",
      Str: "SW 132nd Place",
      Ct: "Ocala",
      St: "FL",
      Zip: 34473,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Ivy Trail 50s",
      Mo: "Other",
      Str: "828 Green Eng St.",
      Ct: "Apopka",
      St: "FL",
      Zip: 32703,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Palm Coast",
      Mo: "Other",
      Str: "370 Parkview Drive",
      Ct: "Palm Coast",
      St: "FL",
      Zip: 32164,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Aspire at Hawks Ridge",
      Mo: "Other",
      Str: "678 NE Waters Edge Lane",
      Ct: "Port St Lucie",
      St: "FL",
      Zip: 34983,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Vdara",
      Mo: "Other",
      Str: "273 Severn Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32803,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Townes at Vdara",
      Mo: "Other",
      Str: "273 Severn Way",
      Ct: "Orlando",
      St: "FL",
      Zip: 32803,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: "0"
    },
    {
      Bid: "6701",
      Co: "Co",
      Mo: "Other",
      Str: "",
      Ct: "",
      St: "",
      Zip: 0,
      Pt: "",
      Ot: "",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: ""
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "1833 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "865 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Sunstone at Wellen Park 30' TH",
      Mo: "Topsail",
      Str: "12497 somatic ct",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Emery",
      Mo: "Aspen",
      Str: "10729 sw estella ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11716 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "226 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "902 honey petal lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3296 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Bonavie Cove Villas",
      Mo: "Largo",
      Str: "10350 bonavie cove drive",
      Ct: "fort myers",
      St: "FL",
      Zip: 33966,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11660 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1042 boardwalk place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22374",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan B",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 917,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "22374",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "12720 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Volanti 24' TH",
      Mo: "Venice",
      Str: "4221 cloud hopper way",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2574,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8927 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "741 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3263 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8903 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "22374",
      Co: "TestCommunity2",
      Mo: "TestModel",
      Str: "27 ocala street",
      Ct: "orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3735 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Mallard",
      Str: "237 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2302,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7410 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7456  estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11747 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Topsail",
      Str: "3753 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Tradition-Emery",
      Mo: "Yarrow",
      Str: "10700 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2279,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2234 satin leaf street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22374",
      Co: "Emery",
      Mo: "Briar",
      Str: "12640 eleanor drive",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Halo at Emery 50's",
      Mo: "ROWAN",
      Str: "12816 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3765 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Lake County",
      Mo: "Redwood",
      Str: "2981 amber sweet pl",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11618 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "22374",
      Co: "Parkview at Long Lake 18' TH",
      Mo: "Marianna",
      Str: "2599 everglade way",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2109 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3737 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 60s",
      Mo: "Braden",
      Str: "122 sydney cove",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1082 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "12672 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  60's",
      Mo: "Jubilee",
      Str: "5783 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2214 satin leaf street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2681 spider lily ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan D",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 968,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "210000"
    },
    {
      Bid: "22374",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "10724 sw gloriana st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Volanti 24' TH",
      Mo: "Venice",
      Str: "26063 woven wicker bend",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek TH 20s",
      Mo: "Cove",
      Str: "11972 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "255000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11818 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Glades",
      Str: "1189 lakeshore breeze place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "811 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3244 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3276 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Vireo",
      Str: "23 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22374",
      Co: "Sunstone at Wellen Park 50's",
      Mo: "Pinnacle",
      Str: "18081 grand prosperity dr",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "22374",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "12023 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Sebastian",
      Str: "143 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "908 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Tradition -Emery",
      Mo: "Briar Coastal",
      Str: "12792 sw eleanor dr",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  60's",
      Mo: "Jubilee",
      Str: "8681 sundance loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "1",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13124 holsinger boulevard",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Egret",
      Str: "215 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1869,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "968 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Gardens North RL 50s",
      Mo: "Beecher",
      Str: "892 orange branch trail",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1859,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8516 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Meridale 60s",
      Mo: "TestModel",
      Str: "3707 cheyney park drive",
      Ct: "charlotte",
      St: "FL",
      Zip: 28269,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11674 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11535 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "10 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8913 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "1799 tropical palms circle",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10733 sw gloriana",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Cadence at Tradition 50'",
      Mo: "Cascades",
      Str: "14135 sw safi ter",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8919 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3272 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove Townhomes RL 20s",
      Mo: "Rialta",
      Str: "3492 oystercatcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1595,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Randal Walk - R22",
      Mo: "Marabel III",
      Str: "10003 randal walk st",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1721,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Sunglow at Emery 35's",
      Mo: "ASPEN",
      Str: "10717 sw gloriana st",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1723,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "1052 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "1070 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Volanti 20' TH",
      Mo: "Ormond",
      Str: "25976 woven wicker bend",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1888,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 50s",
      Mo: "Linden",
      Str: "12016 cabana road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2696,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8518 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8925 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2653 spider lily ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Wellen Park Sunstone",
      Mo: "Seabranch",
      Str: "18046 grand prosperity drive",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1908,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8508 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "198 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "999 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Redwood",
      Str: "2093 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2326,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3280 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 20' TH",
      Mo: "Ormond",
      Str: "8520 primrose willow pl",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "11724 sw aquila way",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina II",
      Str: "11639 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3787 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Island Village",
      Mo: "Celebration - Aleutian",
      Str: "2204 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Aurora",
      Str: "11927 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina II",
      Str: "11746 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2680 spider lily ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Forest Park at Wildlight",
      Mo: "Bristol",
      Str: "425 sweetgum street",
      Ct: "yulee",
      St: "FL",
      Zip: 32097,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Newbury",
      Str: "11986 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2311,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle Palms ",
      Mo: "Venice",
      Str: "710 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1716,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "22374",
      Co: "Tradition - Emery",
      Mo: "Briar",
      Str: "10744 sw estella ln",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Wellen Park, Sunstone",
      Mo: "Anclote",
      Str: "12405 asana court",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove",
      Mo: "Destan",
      Str: "3498 oyster catcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "290000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 60's",
      Mo: "Nantucket II",
      Str: "20750 ovid lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Gateway",
      Str: "2971 ambersweet place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22374",
      Co: "Cadence at Tradition 50'",
      Mo: "Caledon",
      Str: "14143 sw safi ter",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Aurora",
      Str: "11963 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Topsail",
      Str: "3769 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1765,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Manor 70s",
      Mo: "Cannon II",
      Str: "52 terra oaks dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2734,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "734 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Cadence at Tradition 50'",
      Mo: "Gateway",
      Str: "10246 sw orana dr",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Meridale 60s",
      Mo: "TestModel",
      Str: "54 e. sycamore st.",
      Ct: "sarasota",
      St: "FL",
      Zip: 34231,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11890 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2043,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Whitney",
      Str: "250 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "10162 sw corvus ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Nirvana",
      Str: "11590 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1376,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "993 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Triple Creek Classic - 50's",
      Mo: "Pelican",
      Str: "11935 streambed drive",
      Ct: "riverview",
      St: "FL",
      Zip: 33579,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek TH 20s",
      Mo: "Spring",
      Str: "11971 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hatteras",
      Str: "7430 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 50s",
      Mo: "Aspen",
      Str: "11990 cabana road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1776,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Compass Landing",
      Mo: "Banyan",
      Str: "3387 pilot circle",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2422,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22374",
      Co: "Olde Mandarin Estates 70s",
      Mo: "Clarke",
      Str: "4629 quilting cir",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32257,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2532,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8917 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Dahlia",
      Str: "12777 sw eleanor dr",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2300,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2952 wild mulberry drive",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Compass Landing  50's",
      Mo: "Admiral",
      Str: "3415 pilot circle",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1994,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Egmont",
      Str: "3726 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1506,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Parkview at Long Lake 18' TH",
      Mo: "Marianna",
      Str: "2325 audubon preserve lane",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1680,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Sunglow at Emery 35's",
      Mo: "BRIAR",
      Str: "12744 sw eleanor dr",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3288 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "1106 boardwalk place",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Seabranch",
      Str: "3750 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1908,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - D50",
      Mo: "Talbot",
      Str: "8578 compass point ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2051,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hatteras",
      Str: "2264 celebration blvd",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Aurora II",
      Str: "20440 revival lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2098,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "950 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Tradition - Emery",
      Mo: "Briar",
      Str: "10645 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "RiverTown WaterSong 55s",
      Mo: "Bridge",
      Str: "265 pinellas way",
      Ct: "st. johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2210,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11599 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks",
      Mo: "Aurora",
      Str: "11562 buoy point place",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "265000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove Townhomes RL 20s",
      Mo: "Rialta",
      Str: "3490 oystercatcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1595,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Tradition",
      Mo: "Telaro",
      Str: "11717 sw aquila way",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Anastasia",
      Str: "1863 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1775,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22374",
      Co: "Harmony 20's-TH",
      Mo: "Seaside II End",
      Str: "11928 sky acres terrace",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1720,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 55s",
      Mo: "Wales",
      Str: "291 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3279,
      Sto: "2",
      Be: "5",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "753 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "2232 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Fremont",
      Str: "11920 sunsail ave",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2037,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2673 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Barron",
      Str: "12060 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Whitney",
      Str: "12072 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Manor 70s",
      Mo: "Saxon II",
      Str: "326 terra oaks dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3564,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8947 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "22374",
      Co: "Volanti 20' TH",
      Mo: "Ormond",
      Str: "25972 woven wicker bend",
      Ct: "lutz",
      St: "FL",
      Zip: 33559,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2089 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20703 ovid lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "10218 sw corvus ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Tranquility at Telaro 50'",
      Mo: "Clarion",
      Str: "11927 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2351,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 18' TH",
      Mo: "Marianna",
      Str: "8345 wildflower glen ave",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1667,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2061 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "31 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2549,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3292 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Amelia",
      Str: "1859 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Riviera II",
      Str: "20472 bandera place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11606 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "921 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Gateway",
      Str: "2101 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2209,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2113 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Anna Maria",
      Str: "2216 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1845,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8923 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Whitney",
      Str: "11994 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2369,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Dawn II",
      Str: "20655 ovid lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Whitney",
      Str: "133 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2380,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8901 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  50's",
      Mo: "Dawn",
      Str: "5746 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Harmony 20's-TH",
      Mo: "Marina II",
      Str: "11936 sky acres terrace",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1549,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - D50",
      Mo: "Pinnacle",
      Str: "7793 clove hitch way",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2373,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "22374",
      Co: "Parkview at Long Lake 50's",
      Mo: "Myrtle",
      Str: "19756 blue pond drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2983,
      Sto: "2",
      Be: "5",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3264 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina",
      Str: "11655 founders street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Solara Resort",
      Mo: "Malibu II",
      Str: "8931 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34737,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3570,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "1034 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Telaro at Tradition",
      Mo: "Eden",
      Str: "11671 sw viridian blvd",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Heron",
      Str: "7418 barrier cove way",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2409,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Sebastian",
      Str: "182 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "759 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Tradition - Emery",
      Mo: "Rowan",
      Str: "3472 sw coquina cove way",
      Ct: "palm city",
      St: "FL",
      Zip: 34990,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3272,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2654 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "13149 dunwick road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Amelia",
      Str: "2208 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "1570 mermaid cove way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "728 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Celebration _ Island Village",
      Mo: "Other",
      Str: "7413 levee lane",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2600,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "22374",
      Co: "Compass Landing  50's",
      Mo: "Admiral",
      Str: "3565 pilot circle",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1994,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Martina",
      Str: "11782 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1659,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks",
      Mo: "Other",
      Str: "11770 globe st",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1476,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "265000"
    },
    {
      Bid: "22374",
      Co: "Parkview at Long Lake 24' TH",
      Mo: "Sebring",
      Str: "2334 audubon preserve lane",
      Ct: "lutz",
      St: "FL",
      Zip: 33558,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2243,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Cadence at Tradition 50'",
      Mo: "Caledon",
      Str: "14055 sw safi ter",
      Ct: "port saint lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1651,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks R21",
      Mo: "Catalina",
      Str: "11538 bouy point place",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "8907 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3543,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Captiva",
      Str: "944 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1611,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Tranquility at Telaro 50'",
      Mo: "Sage",
      Str: "11935 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Harmony 30's-TH",
      Mo: "Driftwood II END",
      Str: "5540 coachwood cove",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1729,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22374",
      Co: "Sunstone at Wellen Park 30' TH",
      Mo: "Anclote",
      Str: "12394 asana ct",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 40s",
      Mo: "Barron",
      Str: "174 meadow creek dr",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "RiverTown The Haven 60s",
      Mo: "Braden",
      Str: "129 sydney cove",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10709 sw gloriana st",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "RiverTown WaterSong 45s",
      Mo: "Trail",
      Str: "465 pinellas way",
      Ct: "st. johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1563,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Soleil - D50",
      Mo: "Shenandoah",
      Str: "9051 coral cape street",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2582,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "1977 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "22374",
      Co: "Pablo Cove Townhomes RL 20s",
      Mo: "Destan",
      Str: "3510 oystercatcher way",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32224,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "11639 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8929 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  50's",
      Mo: "Riviera II",
      Str: "5742 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2601 royal jasmine ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Barron",
      Str: "12096 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Celebration - D55",
      Mo: "Marathon",
      Str: "7573 estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2909,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3743 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "883 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Glades",
      Str: "2105 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Malibu II",
      Str: "1609 tropical palms circle",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3544,
      Sto: "2",
      Be: "7",
      Ba: "6",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3749 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Vireo",
      Str: "68 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2802,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "8909 coconut breeze drive",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3009,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D60",
      Mo: "Sarasota",
      Str: "3297 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3325,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "22374",
      Co: "Emory",
      Mo: "Dalia",
      Str: "12840 sw elenor drive",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2300,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Pelican",
      Str: "3748 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1787,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Venice",
      Str: "1058 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1707,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - R20",
      Mo: "Selena III",
      Str: "2129 emil jahna road",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1690,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Island Village Celebration",
      Mo: "Hatteras",
      Str: "7452 estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "22374",
      Co: "Celebration - R40",
      Mo: "Hayden",
      Str: "7569 estuary lake loop",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2330,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 30' TH",
      Mo: "Anclote",
      Str: "3759 laughing dove ave",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1501,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "22374",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20646 galileo place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "RiverTown WaterSong 45s",
      Mo: "Court",
      Str: "385 pinellas way",
      Ct: "st. johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1686,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11631 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "22374",
      Co: "Tranquility at Telaro 50'",
      Mo: "Sage",
      Str: "11711 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1969,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "Solara - D40",
      Mo: "Laguna II",
      Str: "1566 mermaid cove way",
      Ct: "kissimmee",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3021,
      Sto: "2",
      Be: "6",
      Ba: "4",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Florence",
      Str: "992 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "La Paloma Preserve 40's",
      Mo: "Egmont",
      Str: "3730 maxwell park dr",
      Ct: "sun city center",
      St: "FL",
      Zip: 33573,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1506,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R21",
      Mo: "Catalina II",
      Str: "11752 globe street",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1678,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2650 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "2252 celebration blvd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "747 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "Addison Landing - D60",
      Mo: "Oakleaf",
      Str: "867 asterfield lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2794,
      Sto: "1",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "22374",
      Co: "Addison Landing - D50",
      Mo: "Cascades",
      Str: "899 asterfield lane",
      Ct: "deland",
      St: "FL",
      Zip: 32720,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1894,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "330000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Gardens North RL 50s",
      Mo: "Oak",
      Str: "442 footbridge rd",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "2",
      Be: "4",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "10170 sw corvus ln",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "11582 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  50's",
      Mo: "Dawn",
      Str: "8536 september sky court",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "22374",
      Co: "TestCommunity3",
      Mo: "TestModel2",
      Str: "27 ocala street",
      Ct: "orlando",
      St: "FL",
      Zip: 32809,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Eden",
      Str: "11703 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1876,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Sunstone",
      Mo: "Glades",
      Str: "17953 grand prosperity dr",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2005,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek TH 20s",
      Mo: "Cove",
      Str: "11975 calvesta st",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1281,
      Sto: "2",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "270000"
    },
    {
      Bid: "22374",
      Co: "Harmony 30's-TH",
      Mo: "Driftwood END",
      Str: "5557 coachwood cove",
      Ct: "bradenton",
      St: "FL",
      Zip: 34211,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1333,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "2662 mandevilla ct",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "927 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 50s",
      Mo: "Aspen",
      Str: "12062 cabana road",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1776,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Celebration - T24",
      Mo: "Aleutian",
      Str: "1829 coastal court",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "22374",
      Co: "Citron Grove 20' TH",
      Mo: "Ormond",
      Str: "8331 wildflower glen ave",
      Ct: "odessa",
      St: "FL",
      Zip: 33556,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "22374",
      Co: "Renaissance Villas",
      Mo: "Largo",
      Str: "20640 galileo place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1431,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Wells Creek 40s",
      Mo: "Sebastian",
      Str: "12078 elderbank drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "22374",
      Co: "RiverTown Arbors 45s",
      Mo: "Sandhill",
      Str: "223 silkgrass pl",
      Ct: "st johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2540,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "22374",
      Co: "Saddlewood Workforce Housing",
      Mo: "Plan A",
      Str: "8878 lake worth rd",
      Ct: "lake worth",
      St: "FL",
      Zip: 33467,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 779,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "165000"
    },
    {
      Bid: "22374",
      Co: "Celebration - Island Village",
      Mo: "Heron",
      Str: "7405 levee lane",
      Ct: "celebration",
      St: "FL",
      Zip: 32747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2437,
      Sto: "2",
      Be: "4",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "22374",
      Co: "Spring Isle - T20",
      Mo: "Capri",
      Str: "986 spring palms loop",
      Ct: "orlando",
      St: "FL",
      Zip: 32828,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1473,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  50's",
      Mo: "Dayspring",
      Str: "8717 sundance loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "22374",
      Co: "Meridian Parks - R40",
      Mo: "Belmont",
      Str: "tbd",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  50's",
      Mo: "Dawn",
      Str: "5737 long shore loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1843,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "22374",
      Co: "Tranquility at Telaro 50'",
      Mo: "Haven",
      Str: "11951 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2134,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "22374",
      Co: "Renaissance 50's",
      Mo: "Riviera II",
      Str: "20470 revival lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1937,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "22374",
      Co: "Tradition Emery",
      Mo: "Briar",
      Str: "10708 sw gloriana",
      Ct: "port st lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1997,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - R20",
      Mo: "Francesca III",
      Str: "2125 emil jahna road",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1631,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Serenity at Telaro 35'",
      Mo: "Felicity",
      Str: "11519 sw viridian blvd",
      Ct: "port st. lucie",
      St: "FL",
      Zip: 34987,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1634,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - D50",
      Mo: "Voyageur",
      Str: "2110 valencia blossom street",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3247,
      Sto: "2",
      Be: "5",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "22374",
      Co: "Island village at celebration ",
      Mo: "Anna Maria ",
      Str: "1823 beach ridge rd",
      Ct: "celebration",
      St: "FL",
      Zip: 34747,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1745,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "22374",
      Co: "Sunrise JV -  50's",
      Mo: "Dayspring",
      Str: "8725 sundance loop",
      Ct: "sarasota",
      St: "FL",
      Zip: 34238,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2295,
      Sto: "1",
      Be: "2",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "22374",
      Co: "Waterbrooke - S35",
      Mo: "Tuscany",
      Str: "3248 canna lily place",
      Ct: "clermont",
      St: "FL",
      Zip: 34711,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1850,
      Sto: "2",
      Be: "3",
      Ba: "3",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "22374",
      Co: "Co",
      Mo: "Other",
      Str: "",
      Ct: "",
      St: "",
      Zip: 0,
      Pt: "",
      Ot: "",
      Yr: 2024,
      Sft: 0,
      Sto: "",
      Be: "",
      Ba: "",
      CT: "",
      RT: "",
      RS: "",
      CovA: ""
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14825 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1490000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18527 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Flora",
      Str: "12794 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "12732 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "TB at Bella Collina-Vista",
      Mo: "Cabris",
      Str: "15954 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1500000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6955 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1270000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17121 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "970000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper at Deerfield Beach Community",
      Mo: "Conquina",
      Str: "1853 spoonbill ct",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Glen",
      Str: "3131 vanguard court",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4092,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "166 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "14925 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1465000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "27 arum court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14850 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Jules",
      Str: "14985 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1345000"
    },
    {
      Bid: "6703",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17135 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "317 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Anna Maria",
      Str: "110 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3632,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8757 st. lucia",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3228,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18535 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Delmore Elite",
      Str: "224 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2902,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Rowen",
      Str: "54 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2947,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12016 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1650000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Captiva Elite",
      Str: "310 gulf stream way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "311 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17110 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 8002,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1070000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6895 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1135000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9668 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1120000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8754 st.lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Parkland Golf & CC-Monogr 65'",
      Mo: "Anzi",
      Str: "10373 sweet bay manor",
      Ct: "parkland",
      St: "FL",
      Zip: 33076,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4465,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Reserve at the Ranches",
      Mo: "Villa Divina",
      Str: "17140 reserve court",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7577,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1165000"
    },
    {
      Bid: "6703",
      Co: "Westhaven at Ovation-Singles",
      Mo: "Grenada",
      Str: "0117 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3237,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Laurel Pnt Lk Nona-Mosaic",
      Mo: "Varen",
      Str: "9028 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1295000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "50 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Rowen",
      Str: "388 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2931,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8791 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Flora",
      Str: "12787 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Stonebrook",
      Str: "84 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2951,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "14955 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1895000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trial",
      Mo: "Willet",
      Str: "2544 cobalt shores ln",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3395,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Roseberry",
      Str: "28 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Villa Divina",
      Str: "15070 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7578,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "2240000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling Elite",
      Str: "12815 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Varen",
      Str: "12051 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2645000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Pelican",
      Str: "12829 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "749 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Dolcetto",
      Str: "9226 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir - Palms Collection",
      Mo: "Joliet",
      Str: "9948 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2400,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "402 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anastasia Elite",
      Str: "286 barbados drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3292,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17140 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7544,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1160000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Barbera",
      Str: "9636 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1210000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Knollwood",
      Str: "571 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2306,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9683 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1190000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10111 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2517,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling Elite",
      Str: "12857 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17110 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1360000"
    },
    {
      Bid: "6703",
      Co: "Shores At Lake Whippoorwill â€“ Estates",
      Mo: "Bordeaux",
      Str: "12028 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2976,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper at Deerfield Beach Community",
      Mo: "Conquina",
      Str: "523 spoonbill ter",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Shores @Whippoorwill Lake-Esta",
      Mo: "Corbeil",
      Str: "12040 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "The Isles at Lakewood Ranch",
      Mo: "Avery Elite",
      Str: "8130 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate ",
      Mo: "Massiano",
      Str: "8914 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2251,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Jules",
      Str: "6894 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3601,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1480000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Santangelo II",
      Str: "6925 s. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3554,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1030000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Julington",
      Str: "591 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4231,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Abington",
      Str: "17111 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4910,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10062 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Built Up",
      RS: "Flat",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "18635 midsummer place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Larue",
      Str: "6354 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2816,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "186 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Greenwood",
      Str: "126 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3031,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "695 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Dolcetto",
      Str: "9004 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1090000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "51 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2888,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Callahan",
      Str: "6924 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1185000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore - Executive Collection",
      Mo: "Madeira",
      Str: "8228 topsail pl",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "Coquina",
      Str: "1853 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1833,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "233 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18484 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "276 gardiners bay drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4824,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12028 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Westhaven@Ovation-Bungalows",
      Mo: "Montpelier",
      Str: "7 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2142,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore",
      Mo: "Madeira",
      Str: "15742 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Stonebrook",
      Str: "138 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2444,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Shores @Whippoorwill Lake-Esta",
      Mo: "Corbeil",
      Str: "12058 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1085000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Corbeil",
      Str: "12052 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Sollas",
      Str: "18645 midsummer place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Venice Woodlands",
      Mo: "Flora Elite",
      Str: "608 mistiflower cir",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Varen",
      Str: "12061 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2100000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Aviemore",
      Str: "12756 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "THE ISLES AT LAKEWOOD RANCH CAPTIVA COLLECTION",
      Mo: "Sandpiper",
      Str: "8138 nevis run",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Davey",
      Str: "6443 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3437,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Sig",
      Mo: "Cabris",
      Str: "12041 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2200000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18543 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "Augustus",
      Str: "12912 morning mist place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Weslyn Park in Sunbridge",
      Mo: "Glen",
      Str: "6394 trailblaze bend",
      Ct: "saint cloud",
      St: "FL",
      Zip: 34771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4042,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Ambassador",
      Mo: "Roseberry",
      Str: "603 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Barbera",
      Str: "12010 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1195000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Robin",
      Str: "316 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17120 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1245000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18508 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10042 florence circle",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2378,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "San Giorgio",
      Str: "8770 st.lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "356 port charlotte drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4213,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Westhaven at Ovation-Towns",
      Mo: "Crowley",
      Str: "20 tbd",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1863,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Delmonico",
      Str: "827 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4466,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Pelican",
      Str: "12770 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "Westwind Island Colonial",
      Str: "546 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1995,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Flat",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "TB at Bella Collina-Vista",
      Mo: "Montech",
      Str: "15938 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1855000"
    },
    {
      Bid: "6703",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17811 rolling oaks estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 8014,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1180000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Franklin",
      Str: "96 cape may ave",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Rinaldi",
      Str: "850 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe",
      Mo: "Unknown",
      Str: "1859 sandpiper pointe pl",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Summit",
      Mo: "Sanderling",
      Str: "12830 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Davenport",
      Str: "114 cape may avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2191,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Sonoma",
      Str: "17131 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4536,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Barbera",
      Str: "12022 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1420000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Corbeil",
      Str: "9328 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1410000"
    },
    {
      Bid: "6703",
      Co: "Magnolia Estates",
      Mo: "Villa Divina",
      Str: "17130 magnolia estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1230000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee ",
      Mo: "Rose",
      Str: "59 ficus ln",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2263,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Delmore",
      Str: "28 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2461,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "157 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3854,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Anna Maria",
      Str: "54 anthem circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3233,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10099 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10038 florence circle",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Millstone Ranches",
      Mo: "Callahan",
      Str: "14895 millstone ranches drive",
      Ct: "davie",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4705,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1390000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "432 port charlotte drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4373,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "212 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Solstice@Wellen Park-Sunbeam",
      Mo: "CastleBay",
      Str: "12705 pinnacle lane",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "TB at Bella Collina-Vista",
      Mo: "Cabris",
      Str: "15650 pendio dr",
      Ct: "montverde",
      St: "FL",
      Zip: 34756,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4283,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1545000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Mandigo",
      Str: "41 cayman cove",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2225,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17816 rolling oaks estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Dunnet",
      Str: "18492 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1804,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Dalenna",
      Str: "8787 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pointe at Deerfield Beach",
      Mo: "La Isla",
      Str: "1847 sandpiper pointe pl",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1883,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Catalina (FL)",
      Str: "193 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4335,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Rolling Oaks Estates",
      Mo: "Villa Divina",
      Str: "17803 rolling oaks estates drive",
      Ct: "southwest ranches",
      St: "FL",
      Zip: 33331,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6959,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "875000"
    },
    {
      Bid: "6703",
      Co: "Laurel Point Lk Nona-Collage",
      Mo: "Barbera",
      Str: "9667 english laurel avenue",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4454,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Serino",
      Str: "10129 florence cir",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2100,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10031 florence circle",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2245,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Stillwater Shores",
      Mo: "Dalenna II",
      Str: "6834 n. stillwater shores dr.",
      Ct: "davie",
      St: "FL",
      Zip: 33314,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1235000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anastasia",
      Str: "324 barbados drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Heritage",
      Mo: "Anna Maria",
      Str: "298 gulfstream way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Settlement at Twenty Mile",
      Mo: "Braydon",
      Str: "219 parkbluff circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2888,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Laurel Pnt Lk Nona-Mosaic",
      Mo: "Varen",
      Str: "9076 santorini drive",
      Ct: "orlando",
      St: "FL",
      Zip: 32827,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5801,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1340000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "94 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Sandpiper",
      Str: "8795 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Ambassador",
      Mo: "San Tropez",
      Str: "613 mahi drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3514,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Shores @Whippoorwill Lake-Esta",
      Mo: "Grenache",
      Str: "12034 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3667,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Signature",
      Mo: "Westbrook",
      Str: "203 old bluff drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4263,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Shores@Whippoorwill Lk-Est",
      Mo: "Grenache",
      Str: "12064 sprite lane",
      Ct: "orlando",
      St: "FL",
      Zip: 32832,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3667,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Solstice @ Wellen Park-Villa",
      Mo: "Rosehall",
      Str: "18516 dayspring place",
      Ct: "venice",
      St: "FL",
      Zip: 34293,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1602,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Crosswater",
      Mo: "Julington",
      Str: "34 revere circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3926,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8777 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4379,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Palazzo at Naples",
      Mo: "Massiano",
      Str: "10070 palazzo drive",
      Ct: "naples",
      St: "FL",
      Zip: 34119,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3330,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Preserve at Beacon Lake",
      Mo: "Annabella",
      Str: "92 daymark lane",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3056,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Signat",
      Mo: "Aragon",
      Str: "8799 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Coastal Oaks-Legacy",
      Mo: "Cumberland",
      Str: "104 cape may ave",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2125,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2581 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2528 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2548 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling",
      Str: "2593  ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2568 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling",
      Str: "2575 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling Elite",
      Str: "2594 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Sanderling Elite",
      Str: "2564 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2575 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2572 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2587 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2588  cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2560 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2556 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2569 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Newell",
      Str: "2583 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2584 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1050000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2557 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2563 ocean breeze lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2532 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Pelican",
      Str: "2579 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Aspen Trail",
      Mo: "Willet",
      Str: "2520 cobalt shores lane",
      Ct: "clearwater",
      St: "FL",
      Zip: 33761,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14672 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14728 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14755 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14756 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14847 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypoint Coastal",
      Str: "14818 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "285000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14839 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14854 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14588 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14632 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14822 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14621 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14647 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14846 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14628 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14664 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14797 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14636 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14604 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14768 kingfsher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14608 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14676 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14752 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14684 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14747 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14831 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14667 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14600 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14652 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14842 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14668 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14772 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14640 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1500,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14867 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14612 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14740 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2240,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14708 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14835 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14818 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14834 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14624 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14875 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14692 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14680 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14625 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14644 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14851 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14826 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14751 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14629 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14855 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14759 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14648 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14895 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14859 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14704 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14688 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14830 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14807 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14601 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14732 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14815 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14656 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14712 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14819 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14635 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14620 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14748 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14814 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14655 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14850 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14744 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Heron",
      Str: "14760 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14605 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14811 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14613 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14736 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14572 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14871 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14643 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill",
      Str: "14838 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1638,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14716 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14843 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14616 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Sandhill Elite",
      Str: "14803 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Stillwater",
      Str: "14810 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe Elite",
      Str: "14660 kingfisher loop",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14891 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Abaco Pointe",
      Mo: "Baypointe",
      Str: "14863 loggerhead drive",
      Ct: "naples",
      St: "FL",
      Zip: 34120,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "249 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Barkley",
      Str: "96 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "83 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "204 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4773,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "178 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "259 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "76 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "77 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "52 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "224 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4461,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "115 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "167 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3999,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "210 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "63 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "41 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "33 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "320 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "217 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "59 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3821,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "179 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "107 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "61 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3498,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "99 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4833,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "240 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "199 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "850000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "289 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "203 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "68 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "74 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4201,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "32 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "40 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "101 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "172 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "111 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "114 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4333,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "82 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Barkley",
      Str: "26 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "86 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "210 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4333,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "29 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "93 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "157 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "183 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "40 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4074,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "162 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "41 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3445,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "46 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "216 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "137 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "67 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "70 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "75 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "312 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "62 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3483,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "195 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "30 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4310,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "204 hickory ranch dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4159,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "129 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "121 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "69 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3498,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "295 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "248 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "114 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "102 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "47 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "90 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4701,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "313 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "53 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "272 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4315,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore Elite",
      Str: "91 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "199 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Canyon",
      Str: "58 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "194 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2366,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "159 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4701,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "219 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "191 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "96 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "134 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "323 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "140 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4768,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "105 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "160 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "49 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3744,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "146 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "249 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "57 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4310,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "150 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Lilac",
      Str: "42 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2284,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "124 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4470,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "56 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4790,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "82 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "115 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "205 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4296,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Canyon",
      Str: "212 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3630,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "151 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4387,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "25 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Westbrook",
      Str: "175 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3896,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "105 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4759,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "34 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "233 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "53 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "117 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5126,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "128 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4710,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "54 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4387,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "213 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4767,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "128 butler ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "75 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "304 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "57 sunset ridge court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "245 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4831,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1095000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "14 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "35 red rock lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "31 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4737,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore",
      Str: "87 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Dakota",
      Str: "58 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "114 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Julington",
      Str: "288 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Spoonbill",
      Str: "26 hillendale circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "93 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3472,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saratoga",
      Str: "24 tradesman lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Bartram",
      Str: "275 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3920,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmonico",
      Str: "256 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3999,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "25 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4521,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Delmore",
      Str: "130 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2457,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "230 morning mist lane",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Saddlebrook",
      Str: "198 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Graystone",
      Str: "91 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3354,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Abigail",
      Str: "161 ranch land circle",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5007,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1065000"
    },
    {
      Bid: "6703",
      Co: "Bartram Ranch",
      Mo: "Hillcrest",
      Str: "217 hickory ranch drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10127 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4856,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1205000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10528 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4005,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10529 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4542,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10086 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4006,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10499 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10133 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10103 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10564 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4617,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10522 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3896,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10366 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3958,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10558 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10331 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4366,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10091 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10475 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4293,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10085 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10505 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4527,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10570 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Edison",
      Str: "10348 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4081,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10469 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4786,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10552 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10323 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4289,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10523 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5024,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "905000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10158 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4252,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10540 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10485 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4167,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10547 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10511 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4789,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10535 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4697,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10541 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4849,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10110 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10092 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1010000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10463 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4340,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10546 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10073 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4861,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10104 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4248,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10074 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4632,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10256 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4593,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "11289 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4487,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10420 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4008,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Clarkson",
      Str: "10176 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4954,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10311 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4526,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10493 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10534 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4828,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Westbrook",
      Str: "10079 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3871,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10111 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4542,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1005000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10119 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4320,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Abigail",
      Str: "10098 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4459,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "910000"
    },
    {
      Bid: "6703",
      Co: "Edison-Estates",
      Mo: "Delmonico",
      Str: "10080 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4265,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13026 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12996 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13077 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13042 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13050 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13078 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12980 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12969 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12977 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13010 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12948 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12952 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13009 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13046 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13005 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13049 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13001 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12953 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13065 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12944 pembroke dr",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12985 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13057 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Seagrape",
      Str: "13022 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1905,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12940 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2064,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13025 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12968 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13073 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13053 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13002 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "12976 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13081 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Buttonwood",
      Str: "13058 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13033 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13034 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12973 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13045 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1973,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13061 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13037 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12984 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13013 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13086 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12972 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12965 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13054 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Hibiscus",
      Str: "13066 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2214,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Buttonwood",
      Str: "12993 pembroke dr",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2033,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13030 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12988 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13041 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "13082 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Ficus",
      Str: "12961 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1926,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "13029 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "13062 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12957 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Banyan",
      Str: "12992 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1933,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Mangrove",
      Str: "12997 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2024,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Hamilton Place",
      Mo: "Seagrape",
      Str: "12989 pembroke dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34105,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1905,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3947 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3843 corona court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3831 corona court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3975 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Underwood",
      Str: "3653 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2506,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3955 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Bowersett",
      Str: "3943 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "245000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3980 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3983 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Doyal",
      Str: "3619 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Doyal",
      Str: "3968 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3972 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3960 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3847 corona court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3657 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3939 lightning court",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Wagner",
      Str: "3605 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Parkview Place",
      Mo: "Blackwell",
      Str: "3623 voyager lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32773,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2083,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3603 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3631 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3578 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3671 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "971 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3647 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3582 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3397 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3611 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3381 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3385 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3433 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3639 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3396 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3691 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3100,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "980 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3421 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "992 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3377 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3635 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3586 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3675 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "975 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3594 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "987 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3429 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3552 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3560 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3389 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3623 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3425 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3405 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "968 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3409 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3393 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3537 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3469 mclain preserve point",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot Craftsman",
      Str: "3586 stonebriar ln",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "976 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "979 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "972 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3417 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3607 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3536 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3615 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3465 mclain preserve point",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "984 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3404 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3590 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3622 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "988 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3627 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3388 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3401 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Bridgton",
      Str: "3606 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3473 mclain preserve point",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "991 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3556 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3179,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3412 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "964 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "967 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3392 bluff oak lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3123,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "983 fiddle leaf way",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3702 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Brookton",
      Str: "3568 stonebriar lane",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Welsford",
      Str: "3691 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Wilmot",
      Str: "3614 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2238,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Riverside Oaks",
      Mo: "Ferncroft",
      Str: "3619 arbordale loop",
      Ct: "sanford",
      St: "FL",
      Zip: 32771,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "10178 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10146 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11409 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2662,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10009 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11361 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2776,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "11355 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2879,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11343 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2986,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10141 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9968 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10086 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10149 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10110 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11433 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3097,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10003 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10092 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3084,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11374 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10040 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11320 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11314 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2995,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10062 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3219,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10056 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9962 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11379 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10034 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11445 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10010 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2990,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10152 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11326 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10165 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2859,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10050 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "11412 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2709,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10022 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11439 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10166 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11397 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11405 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11427 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "9956 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2866,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10134 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10107 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9950 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2669,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "11385 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10068 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2943,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11331 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10104 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2709,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11380 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3274,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10157 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10185 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9957 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "10123 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2805,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10021 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2866,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11417 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10080 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2916,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "9981 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2826,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "9951 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2759,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "9980 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11403 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11319 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "11406 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10044 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "9945 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "9963 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10046 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10160 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10028 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Captiva",
      Str: "9974 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2726,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10140 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3197,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10128 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10179 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11337 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11418 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "9944 exhibition circle",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "11350 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2586,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10116 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3113,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10172 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2966,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10095 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2665,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11424 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "10074 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11338 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2644,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11423 dell court",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3041,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10098 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Anastasia",
      Str: "11325 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Mandigo Elite",
      Str: "10173 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2613,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Edison-Heritage",
      Mo: "Terrano",
      Str: "10122 illumination drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2966,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Caladesi",
      Str: "19732 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Ballast",
      Str: "2368 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19752 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19776 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19855 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19896 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Ballast",
      Str: "19858 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Caladesi",
      Str: "19756 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19850 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19884 tbd tbd",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2332 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19714 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2354 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4650,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19766 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "2340 white dogwood loop",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19804 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19865 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19722 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19873 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19838 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19740 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19792 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19892 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5092,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Hixon",
      Str: "19704 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19829 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19868 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1080000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19890 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19746 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Vinoy",
      Str: "19837 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3866,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Livingston Grove",
      Mo: "Dexter",
      Str: "19883 augusta preserve drive",
      Ct: "lutz",
      St: "FL",
      Zip: 33549,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4257,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11351 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11338 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11398 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11405 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11363 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2500,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11390 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11378 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11384 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11367 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11357 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11370 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11358 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11360 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11366 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11382 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11396 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11389 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11380 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11392 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11350 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11393 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11409 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11342 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11371 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11344 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11394 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11403 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11372 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11340 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11369 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11336 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11368 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11356 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11391 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11365 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11354 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11395 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11352 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11412 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11334 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11407 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Ibis",
      Str: "11355 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2261,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Macaw",
      Str: "11353 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2041,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Newton-Townhomes",
      Mo: "Lark",
      Str: "11414 newtonian blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2394,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10053 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9969 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10071 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10005 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10065 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9939 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9951 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10029 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9933 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10017 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9644 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10023 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9963 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9993 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9891 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9999 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9897 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10059 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "9927 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9903 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10083 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9909 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9638 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10041 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10077 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10047 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "10095 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9981 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "10011 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret Elite",
      Str: "9945 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Cardinal Elite",
      Str: "10035 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9987 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9603 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9921 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Villa",
      Mo: "Egret",
      Str: "9957 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10306 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10414 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3967,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10510 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10247 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10384 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10324 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10445 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "11475 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10415 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10277 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3532,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10288 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11428 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11470 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3725,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10379 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10480 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10486 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "10294 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3445,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10498 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10235 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10312 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3417,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "10241 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10433 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11464 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3177,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10456 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10169 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10402 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10516 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "11476 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "10421 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10378 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11446 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10270 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11434 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11499 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4262,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10403 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3360,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10390 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3436,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11487 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "810000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11500 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10468 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3402,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11493 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4413,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "915000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10439 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10457 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10474 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3595,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11458 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3303,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10264 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10462 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Roseberry Elite",
      Str: "11506 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3418,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10067 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11440 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Roseberry Elite",
      Str: "10391 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3188,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11481 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3199,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10385 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10504 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10427 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Captiva Elite",
      Str: "10492 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3775,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10408 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "10055 koster street",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Julington",
      Str: "11463 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10397 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "11457 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3249,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "San Tropez",
      Str: "11469 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3399,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10223 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3310,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10396 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "11452 madelynn drive",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anastasia Elite",
      Str: "10451 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3298,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison-Ambassador",
      Mo: "Anna Maria",
      Str: "10187 silverbrook trail",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15613 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5909,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1000000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15517 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15522 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Teresina",
      Str: "15619 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3108,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Maranello",
      Str: "15468 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5317,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Bramante",
      Str: "15625 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4798,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Bramante",
      Str: "15631 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4229,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Avanti",
      Str: "15511 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Maranello",
      Str: "15481 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4264,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15669  panther lake drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5071,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15607 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5354,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15612 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4771,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15469 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5781,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15523 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5054,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Estates",
      Mo: "Santa Bella",
      Str: "15606 giant foxtail court",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6245,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "250 settlers landing dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "419 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "194 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "49 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3007,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "59 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "190 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "50 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "223 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "140 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "184 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2906,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "375 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "425 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "133 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "161 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "176 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "181 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "230 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "147 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "222 settlers landing dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "124 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Sparrow Elite",
      Str: "62 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "169 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "93 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "311 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "238 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Edgeport",
      Str: "206 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2464,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "265 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Petunia",
      Str: "155 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "85 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "395 sawyer bridge tr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "212 settlers landing dr.",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Settler's Landing",
      Mo: "Caspian",
      Str: "198 sawyer bridge trail",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2899,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "56 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "70 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "50 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "45 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "33 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "51 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "27 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "47 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "19 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "29 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "39 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "82 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "64 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "65 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "88 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "32 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "100 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "106 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "34 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "45 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "21 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "35 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "39 ficus lane",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2256,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "53 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "42 egan way",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Custom",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "31 silk oak court",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "55 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "54 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "94 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "114 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Lily",
      Str: "74 white cypress drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Oakwood at Nocatee",
      Mo: "Rose",
      Str: "41 sugar magnolia drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Sabrina",
      Str: "12696 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12704 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Sabrina ",
      Str: "12404 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12488 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12680 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12716 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12428 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12614 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "3 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Sabrina",
      Str: "12538 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2561,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12700 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2900,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12432 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12672 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12712 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2959,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12626 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12561 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3019,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12553 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12720 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12707 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Sabrina ",
      Str: "9373 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12545 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12581 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1000000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12688 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12534 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12541 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12420 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "2 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12657 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2991,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12504 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12656 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12471 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12499 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12508 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12715 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12412 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12475 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "12483 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Sabrina",
      Str: "12557 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12692 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Roland",
      Str: "5 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3157,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12480 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2868,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "McKenna",
      Str: "12468 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12602 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Edmonton",
      Str: "12668 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Windgate at Avenir",
      Mo: "Torrance",
      Str: "12594 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9204 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9146 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9117 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "875000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9113 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9320 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9225 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9233 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1115000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9350 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "850000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9198 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9261 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1095000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9217 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9257 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "970000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9109 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9240 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1075000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9179 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9374 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9334 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9186 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9265 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9194 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "965000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9346 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Shirley",
      Str: "9178 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3559,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9205 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1015000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9209 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9116 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9248 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1015000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9228 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9170 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9216 crestview cir",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9108 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1055000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9120 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9232 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9264 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1045000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9237 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1170000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9229 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "875000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9292 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9342 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Maddington",
      Str: "9354 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9300 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9245 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3960,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "1055000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9221 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Clearwater",
      Str: "9338 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3952,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "840000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9366 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9362 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9182 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Watermark at Avenir",
      Mo: "Jennings",
      Str: "9158 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3802,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15582 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8252 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2662,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15713 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "15731 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8186 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15748 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "8253 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15707 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Robellini",
      Str: "15624 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15618 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8277 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15724 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira",
      Str: "15730 cutter sail pl",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3943,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8246 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8258 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4226,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8331 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15834 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8192 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "15743 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15725 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "15719 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "15655 sylvester palm drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8259 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8157 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4157,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8198 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8264 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8247 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4019,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sabel",
      Str: "8818 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2221,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "15738 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15119 canoe place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8234 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4137,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8228 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8180 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15606 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8222 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4116,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8806 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4019,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sanibel",
      Str: "8289 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8295 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15732 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8216 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2885,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15754 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8174 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15718 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "8271 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15858 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8830 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15742 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3945,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15712 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3884,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Robellini",
      Str: "15612 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Bavaro",
      Str: "8265 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15588 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "8812 lakeshore pointe drive",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4205,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Robellini",
      Str: "15737 cutter sail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4334,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "8270 topsail place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Madeira (FL)",
      Str: "15130 canoe place",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3812,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15600 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Gardenia",
      Str: "15535 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4095,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Lakeshore-Executives",
      Mo: "Sebastian",
      Str: "15810 shorebird lane",
      Ct: "winter garden",
      St: "FL",
      Zip: 34787,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9986 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "10026 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9910 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "10010 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9568 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9998 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9978 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9567 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9585 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Finch",
      Str: "9934 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9819 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9926 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9970 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "10018 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Finch",
      Str: "9918 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9831 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9543 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9837 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9843 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9879 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9825 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9950 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9849 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9586 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9813 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9807 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9555 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Finch",
      Str: "9885 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2015,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9873 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9867 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9942 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9861 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9544 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9580 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9956 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow",
      Str: "9561 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2105,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9855 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Petunia",
      Str: "9962 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2268,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Sparrow Elite",
      Str: "9562 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2609,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Edison East-Executive",
      Mo: "Lavender",
      Str: "9573 filament blvd",
      Ct: "jacksonville",
      St: "FL",
      Zip: 32256,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2640,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "673 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "77 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "688 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "90 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "62 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "78 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore Elite",
      Str: "661 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "726 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "105 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "608 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3063,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "65 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "637 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "39 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "630 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "47 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "41 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "52 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "676 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "105 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "611 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "37 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "471 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "53 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2457,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "90 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "664 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "685 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore",
      Str: "18 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "134 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2650,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore Elite",
      Str: "59 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2920,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "703 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "135 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "124 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "89 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "78 cinnamon teal way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "652 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "125 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "618 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "698 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "710 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Dakota",
      Str: "34 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2882,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "640 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "25 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Delmore Elite",
      Str: "73 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2890,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "625 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Barkley",
      Str: "115 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2965,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "562 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "68 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "79 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "718 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saddlebrook",
      Str: "78 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2618,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "66 oak knoll court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3093,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "649 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Lakeview at Grand Oaks",
      Mo: "Saratoga",
      Str: "19 natureland circle",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3195,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Arianna",
      Str: "10421 angel oak court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10691 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10335 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10631 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Sebastian",
      Str: "10715 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10522 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Arianna",
      Str: "10397 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10147 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4106,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10571 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10829 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10511 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10329 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2919,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10323 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4018,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10553 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4468,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Sebastian",
      Str: "10709 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10415 angel oak court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Sabel",
      Str: "10324 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2298,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10558 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3084,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Madeira (FL)",
      Str: "10498 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3811,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10317 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2309,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Gardenia",
      Str: "10480  royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10703 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4528,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10140 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Wynngate",
      Str: "10570 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2827,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10306 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4514,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Massiano",
      Str: "10492 royal cypress way",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2309,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Robellini",
      Str: "10116 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4468,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Royal Cypress Preserve",
      Mo: "Lexia",
      Str: "10134 royal island court",
      Ct: "orlando",
      St: "FL",
      Zip: 32836,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3390,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "108 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "577 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "549 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "604 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "112 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "596 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "548 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "580 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling",
      Str: "541 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "612 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "609 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "569 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "136 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling",
      Str: "109 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "564 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "568 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "553 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora Elite",
      Str: "573 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "561 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling",
      Str: "601 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Sanderling Elite",
      Str: "441 baneberry court",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "572 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "589 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Flora",
      Str: "545 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "TB at Venice Woodlands",
      Mo: "Pelican",
      Str: "585 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2473 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2521 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2616 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2576 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2610 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2803 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2640 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2520 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2569 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Jessup",
      Str: "2514 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2572 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2485 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2695 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2736 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2701 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2762 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2491 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2208,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2508 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2545 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2587 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3571,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2599 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2443 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2551 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2431 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2497 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2646 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Jessup",
      Str: "2437 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1917,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2491 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2509 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2556 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2467 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2671 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2635 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2611 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2659 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2454 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2653 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2629 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2665 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2658 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2563 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2665 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2809 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2419 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2581 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2461 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2533 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2562 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2575 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2676 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2425 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2593 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2677 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2682 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2634 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2580 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2797 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2761 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2509 cedar rose street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2515 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2539 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2594 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2628 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3515,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2647 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2472 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2478 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2604 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2413 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Bridgton",
      Str: "2584 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2080,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Welsford",
      Str: "2617 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2895,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2605 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2588 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Brookton",
      Str: "2568 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2453,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Ferncroft",
      Str: "2785 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3316,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Oaks at Kelly Park",
      Mo: "Holden",
      Str: "2641 park ridge street",
      Ct: "apopka",
      St: "FL",
      Zip: 32712,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2233,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "65 anthem circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "411 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3779,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "230 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "460 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3700,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry",
      Str: "256 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2608,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "471 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3926,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "501 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "387 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry",
      Str: "69 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2550,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "141 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3107,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "195 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4115,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "501 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "31 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "377 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "58 revere circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "43 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "59 revere circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "83 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "178 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "483 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "164 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "471 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "72 anthem circle",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3955,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "64 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Roseberry Elite",
      Str: "474 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "416 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3657,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Anna Maria",
      Str: "242 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "164 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "San Tropez",
      Str: "324 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3569,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Anthem Ridge at Nocatee",
      Mo: "Julington",
      Str: "377 anthem ridge drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3779,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12724 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12436 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12458 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2996,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12424 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12407 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3019,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12492 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2821,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "McKenna",
      Str: "12471 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2537,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Sabrina",
      Str: "12444 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2351,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12415 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12728 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12712 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2959,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12432 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2705,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12704 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12431 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12715 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12692 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3142,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12483 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12496 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2777,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12707 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "McKenna",
      Str: "12491 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2534,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Sabrina",
      Str: "12408 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2339,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Torrance",
      Str: "12462 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3051,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12500 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2789,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12472 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3164,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Edmonton",
      Str: "12495 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2717,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "Roland",
      Str: "12476 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3157,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Avenir A3-Windgate-50's",
      Mo: "McKenna",
      Str: "12423 nautilus circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9994 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9987 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "9960 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10049 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9965 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9897 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9964 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9998 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Samantha",
      Str: "9917 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10042 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10111 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "10070 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9969 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "9925 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "10006 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "12408 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Joliet",
      Str: "9948 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2391,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "12424 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9949 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Samantha",
      Str: "10058 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "9961 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9901 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10083 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "9913 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "10073 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9940 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "McKenna",
      Str: "10010 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "12412 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Maya",
      Str: "9957 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Jesalin",
      Str: "9956 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms",
      Mo: "Samantha",
      Str: "12404 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "270 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "750 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "65 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "315 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "293 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "55 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "46 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "285 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "804 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "726 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "278 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "782 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "208 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "79 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "80 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "96 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "137 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "718 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "306 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "292 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "322 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "85 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "101 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "298 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "45 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "117 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret",
      Str: "774 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1806,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "766 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "744 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "185 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "262 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "812 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "734 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "708 nocatee village drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "84 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "100 skycrest drive",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal Elite",
      Str: "216 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2128,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "277 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Cardinal",
      Str: "314 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1503,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "West End at Town Center",
      Mo: "Egret Elite",
      Str: "307 cool springs avenue",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2490,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9366 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9213 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3330,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9204 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9120 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9174 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9225 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9171 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9142 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9104 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9167 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9353 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Shirley",
      Str: "9121 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3556,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9113 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3810,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9154 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9166 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9124 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9378 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Maddington",
      Str: "9175 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3214,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Avenir A1-Watermark-70's",
      Mo: "Jennings",
      Str: "9358 crestview circle",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3784,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7369 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "1075000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7488 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5424,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1755000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7389 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7409 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7430 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4641,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7122 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1670000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2411 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7439 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1435000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "2704 northwest 71st blvd",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4929,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1900000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2570 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7502 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5279,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6920 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1315000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2550 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "6930 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1360000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7230 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7347,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7310 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7324,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1950000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6903 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "2740 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2235000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2755 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7309 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2510 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2540 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7459 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2580 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7321 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4841,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7239 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4682,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7420 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3715,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7352 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6950 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2640 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3655,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7080 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7609,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1465000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7359 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4594,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "2725 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4704,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2720 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1720000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7322 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Lucia (FL)",
      Str: "6970 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4041,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7312 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4727,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7449 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4537,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7342 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4198,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6940 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6966 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1175000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "2540 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4894,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2500 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "7229 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4740,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "925000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Ternbridge II",
      Str: "2710 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4795,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1295000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7302 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5002,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6960 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1070000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7329 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4634,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7440 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5236,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1615000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "7440 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3836,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7330 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5400,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1695000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2750 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1100000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2723 northwest 70th blvd",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4642,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7480 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5274,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2010000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Lucia (FL)",
      Str: "6910 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4459,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1590000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Milano",
      Str: "7460 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5391,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1040000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7479 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2531 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2520 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "7219 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4642,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1285000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Villa Divina",
      Str: "7380 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7380,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2220000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2650 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "2730 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2530 northwest 71st street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4689,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2780 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4694,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2045000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "San Giorgio",
      Str: "6911 northwest 28th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3925,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "2760 northwest 69th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1615000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7360 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5370,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1520000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Callahan",
      Str: "6910 northwest 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4615,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "2511 northwest 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5062,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "Dalenna II",
      Str: "7450 northwest 25th terrace",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4841,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Heritage",
      Mo: "McIntosh II",
      Str: "7220 northwest 26th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "184 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "165 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "296 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "176 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "157 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "290 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "44 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "251 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "105 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "64 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "20 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "20 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "45 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "93 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "204 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "71 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "214 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "44 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "117 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "54 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "165 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "274 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "242 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "201 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "57 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "37 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "59 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "104 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "164 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "203 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "272 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "26 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "36 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "32 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "124 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "175 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "164 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "55 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "131 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "146 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "145 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "186 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "123 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "155 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "131 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "44 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "264 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "63 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "47 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "181 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "84 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "32 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "47 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "45 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "83 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "194 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "54 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "74 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "139 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ella",
      Str: "29 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "67 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "21 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Ana",
      Str: "31 waterwheel ct",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2156,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Liam",
      Str: "124 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Meadows",
      Mo: "Penelope",
      Str: "191 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1918,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "2709 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6982,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1830000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "2703 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4928,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1550000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7405 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7425,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1305000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7503 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7396,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1840000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Milano",
      Str: "7506 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1610000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7300 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4929,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7300 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7360 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6964,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1690000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7207 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7342,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1745000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Lago",
      Str: "2703 nw 71st boulevard",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7268,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1275000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Cosenza II",
      Str: "2705 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6284,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1755000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "2701 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7480 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5112,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1955000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "2708 nw 71st boulevard",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7420,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "3235000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7420 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7419,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1885000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Lago",
      Str: "7404 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1385000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "2710 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4713,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1440000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7510 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6983,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1280000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7206 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7473,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1815000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "2707 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5700,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7320 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7420,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2035000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "7330 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4729,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1660000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "2700 nw 71st boulevard",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1845000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7409 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7496,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1450000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7131 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "2385000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "McIntosh II",
      Str: "7310 nw 28th way",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 5153,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1750000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Lago",
      Str: "7208 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7368,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1700000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7303 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7861,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1800000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7403 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 7766,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1510000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Callahan",
      Str: "2711 nw 75th street",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4758,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1355000"
    },
    {
      Bid: "6703",
      Co: "Royal Palm Polo-Signature",
      Mo: "Villa Divina",
      Str: "7402 nw 27th avenue",
      Ct: "boca raton",
      St: "FL",
      Zip: 33496,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 6905,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "1105000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "130 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "156 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "140 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "311 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "126 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "261 bridgeton street",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "240 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "267 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "56 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "120 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "94 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "257 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "190 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "93 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "328 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "306 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "147 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "113 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "171 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "129 wye road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "154 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "214 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "103 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "207 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "168 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "318 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "252 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "80 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "100 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "59 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "71 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "94 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "42 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "110 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "202 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "277 gap creek drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "180 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "112 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "226 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "317 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "75 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "87 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "76 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "146 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Hannah",
      Str: "35 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "23 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "48 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "135 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "710000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "101 elm branch road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Alice",
      Str: "81 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3254,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Egan",
      Str: "329 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2855,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "68 gap creek dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2666,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Mill Creek Forest-Magnolia",
      Mo: "Robin",
      Str: "58 valley falls way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2670,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "42 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "151 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Seabrooke",
      Str: "137 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Anastasia",
      Str: "57 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Seabrooke",
      Str: "54 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2769,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Seabrooke",
      Str: "74 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2768,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Captiva",
      Str: "94 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "404 windley drive",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2843,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "163 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2977,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "66 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "432 windley drive",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "82 grady court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "79 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Captiva",
      Str: "175 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Heritage",
      Mo: "Crestview",
      Str: "116 anclote way",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "204 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "395 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "230 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "106 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "78 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "92 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "105 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "66 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "195 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "480 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "89 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "336 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "106 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "411 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "35 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "201 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "352 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3681,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "42 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "192 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "484 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "62 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "143 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "67 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "136 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "425 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4198,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "300 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4303,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "110 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "156 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "388 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "372 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "88 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "63 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "32 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "50 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "117 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4104,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "49 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4308,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "456 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "242 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "98 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "116 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "95 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "472 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3686,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "53 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3646,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "490 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "159 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "440 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "115 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "141 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "54 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "194 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3789,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "770000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "188 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "46 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "422 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "173 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "92 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3681,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "25 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "111 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4156,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "845000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "111 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "254 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3517,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "445 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "39 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "73 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "120 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4067,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "127 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3193,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "129 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "172 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3156,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "81 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "105 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "82 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3213,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "156 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "187 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "95 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke",
      Str: "268 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "168 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3250,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "284 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3460,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Spencer",
      Str: "379 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3322,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "77 awlleaf court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4322,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "500 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4090,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "94 hydrilla court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4308,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "182 snowbell court",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Luke Elite",
      Str: "406 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Roseberry Elite",
      Str: "318 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3460,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "The Landing at Beacon Lake",
      Mo: "Edison",
      Str: "209 loosestrife way",
      Ct: "st augustine",
      St: "FL",
      Zip: 32095,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9138 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9084 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9056 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9224 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Stillwater",
      Str: "9162 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9044 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9175 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9158 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill",
      Str: "9154 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9068 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9016 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9044 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9186 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill Elite",
      Str: "9194 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9060 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9220 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9036 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9179 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9048 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9052 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "670000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9174 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9060 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9072 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9209 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill Elite",
      Str: "9076 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9166 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Stillwater",
      Str: "9170 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9056 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9182 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9084 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9228 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9213 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill",
      Str: "9040 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9040 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9052 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9150 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9204 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9178 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe Elite",
      Str: "9080 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9012 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9048 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9142 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9064 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Baypointe",
      Str: "9068 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lks-Villa",
      Mo: "Sandhill",
      Str: "9088 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9104 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9120 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1060000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena",
      Str: "9089 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9092 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1095000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena",
      Str: "9096 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9081 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1170000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9109 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9121 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9117 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1355000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9097 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "910000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena",
      Str: "9023 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Athena Elite",
      Str: "9112 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver",
      Str: "9051 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1025000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver",
      Str: "9085 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver",
      Str: "9019 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Piper",
      Str: "9088 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9116 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1030000"
    },
    {
      Bid: "6703",
      Co: "Azure@Hacienda Lks-Heritage",
      Mo: "Carver Elite",
      Str: "9060 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "995000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9253 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3480,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1060000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9277 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9257 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9113 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4302,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1040000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9089 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2553,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "835000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9085 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2456,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1080000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9114 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2486,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "975000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9093 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "905000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Stefano",
      Str: "9094 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Stefano",
      Str: "9102 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2960,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9105  montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9261 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2659,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9110 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9098 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1130000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9245 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9109 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9249 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Sabel",
      Str: "9106 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2075,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9265 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9241 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2392,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Massiano",
      Str: "9281 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Ests",
      Mo: "Serino",
      Str: "9090 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2552,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "198 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "74 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "218 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "235 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "111 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "165 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "176 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "182 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "143 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "164 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "150 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "226 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "252 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "179 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "242 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "247 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "193 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "58 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "115 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "89 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "203 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2668,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "170 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "158 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "256 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "149 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "139 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "204 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "185 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "119 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "211 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "212 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "97 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "190 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "248 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "105 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "157 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2682,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Rose",
      Str: "66 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "255 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Tulip",
      Str: "217 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square @ Crosswater",
      Mo: "Lily",
      Str: "223 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8331 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "17709 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1100000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "17705 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "18220 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4481,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "18236 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4272,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "955000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "7928 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8307 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "995000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8409 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1260000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8330 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "915000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell",
      Str: "8448 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1065000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "18208 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper Elite",
      Str: "7948 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "8272 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4600,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1250000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "17713 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "930000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8326 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8417 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4541,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8314 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "7924 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "8239 nevis run",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2906,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "8408 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "900000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "8342 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "18228 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1135000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8306 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8437 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1020000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell",
      Str: "8432 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8444 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "945000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "San Giorgio",
      Str: "8268 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3933,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1035000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Sandpiper",
      Str: "8451 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8452 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Solana",
      Str: "8252 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "960000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell Elite",
      Str: "8222 nevis run",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1325000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "18212 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1110000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Aragon",
      Str: "8323 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Maxwell",
      Str: "8440 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Captiva",
      Mo: "Fullerton",
      Str: "8424 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8167 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8063 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8137 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Palacios",
      Str: "8213 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8110 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8134 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "7995 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "965000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena Elite",
      Str: "8170 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "750000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8086 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver Elite",
      Str: "8019 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8205 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8050 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8118 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena Elite",
      Str: "8114 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8162 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne Elite",
      Str: "7849 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "810000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8042 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "795000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8046 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8054 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "880000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8066 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne Elite",
      Str: "8098 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver",
      Str: "8062 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "680000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8209 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8078 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8126 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8147 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena Elite",
      Str: "8171 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8094 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "935000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "7837 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8074 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "920000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8122 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "17695 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8143 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8082 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery Elite",
      Str: "8175 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8090 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8154 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Palacios",
      Str: "8018 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Athena",
      Str: "8070 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "765000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Carver Elite",
      Str: "8059 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "940000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8195 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Biscayne",
      Str: "8150 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Palacios",
      Str: "8038 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Isles @ Lakewood Rch-Sanibel",
      Mo: "Avery",
      Str: "8010 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "281 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia",
      Str: "91 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "495 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "460 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3608,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "557 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "331 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2891,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia",
      Str: "549 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Mandigo",
      Str: "436 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva",
      Str: "530 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "470 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "25 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "81 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3290,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "558 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3221,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Mandigo Elite",
      Str: "527 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2612,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Terrano",
      Str: "71 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3268,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "517 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4161,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "820000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "433 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3402,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "424 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia",
      Str: "484 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "353 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3547,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "507 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "560000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "416 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3304,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "343 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3660,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "539 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4043,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "365 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "68 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "552 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Mandigo",
      Str: "90 pine blossom trail",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Captiva Elite",
      Str: "293 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3484,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "520 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anastasia Elite",
      Str: "457 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58s-Heritage",
      Mo: "Anna Maria",
      Str: "383 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "168 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4149,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "245 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4777,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "385 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "27 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "223 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "399 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4221,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Delmonico",
      Str: "250 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4158,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "374 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4777,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "90 pavati point",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4057,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Delmonico",
      Str: "101 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4375,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Delmonico",
      Str: "93 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4078,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Westbrook",
      Str: "118 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4226,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "234 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "90 pavati point",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4055,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Julington",
      Str: "93 pavati point",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 80's-Estates",
      Mo: "Abigail",
      Str: "203 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4660,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Captiva Elite",
      Str: "612 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3992,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "30 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4241,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "865000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Captiva Elite",
      Str: "42 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Edgewater",
      Str: "158 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3529,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "675000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "703 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Edgewater",
      Str: "186 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3222,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "172 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3194,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "775000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Edgewater",
      Str: "132 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "717 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Captiva Elite",
      Str: "303 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3753,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Cassia",
      Str: "729 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "691 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3147,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "39 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4113,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "945000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "277 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4204,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "755 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "792 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "Julington",
      Str: "144 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3594,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Julington Lks 68s-Ambassador",
      Mo: "San Tropez",
      Str: "741 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3356,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9966 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "890000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9975 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9939 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9981 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9978 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "9944 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2627,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "10031 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "740000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9946 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "9986 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "720000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9936 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9961 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "860000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9985 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "830000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9972 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2988,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9968 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "990000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9930 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9982 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "985000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9976 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9945 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2740,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9965 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "760000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9932 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9935 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9942 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9958 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "855000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9943 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "785000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9912 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "930000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9970 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Kaley",
      Str: "9973 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Salerno",
      Str: "9980 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "825000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9938 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "780000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9940 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Isabella",
      Str: "9908 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9962 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "9964 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2515,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "755000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Lewiston",
      Str: "9934 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "700000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds",
      Mo: "Shelby",
      Str: "10027 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "725000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "553 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "596 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "590 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "585 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "532 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "531 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "610 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "543 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "542 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "544 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "536 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "563 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "1823 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "534 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "541 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "559 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "539 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "555 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "1827 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "632 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "636 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "618 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "594 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "589 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "536 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "616 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "653 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "563 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "1829 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "655 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "537 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "540 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "620 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "538 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "530 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "533 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "587 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "565 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "600 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "612 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "657 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "542 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "540 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "1819 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "1825 sanderling drive",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "583 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "592 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "538 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "569 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "598 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "534 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "561 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "567 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "551 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "614 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "532 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Westwind",
      Str: "561 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "La Isla",
      Str: "530 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Coquina",
      Str: "557 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "535 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "SandpiperPointe@DeerfieldBch",
      Mo: "Seabreeze",
      Str: "634 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "131 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "36 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "140 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2977,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "78 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "25 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "217 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3130,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Julington",
      Str: "231 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4187,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "98 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "205 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3150,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "73 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2992,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "175 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3523,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "88 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3242,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "159 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Julington",
      Str: "160 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "22 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3522,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anastasia Elite",
      Str: "116 permit court",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Anna Maria",
      Str: "105 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3307,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "119 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2864,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Shearwater, TB at-Ambassador",
      Mo: "Crestview",
      Str: "191 bowery avenue",
      Ct: "saint augustine",
      St: "FL",
      Zip: 32092,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2843,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Built Up",
      RS: "Flat",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2062 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2058 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2142 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2054 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2257 valentine aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "330000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2154 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2430 addalia aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2383 brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2377brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Sampson",
      Str: "2353 brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1388,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2158 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2066 packing district way",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Nova Elite",
      Str: "2422 addalia aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1975,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2146 whitney marsh aly",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "The Brix@thePacking District",
      Mo: "Citrine",
      Str: "2359 brix st",
      Ct: "orlando",
      St: "FL",
      Zip: 32804,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1724,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9016 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8982 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9075 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8893 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9047 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9032 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8905 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8979 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8833 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "9087 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8821 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "685000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8829 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9020 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "305000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9008 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9083 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9003 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "9020 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9130 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8983 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8958 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9091 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9028 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9024 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9095 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill Elite",
      Str: "9076 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2305,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9096 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9024 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8889 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9031 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9004 mayreau way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9008 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "295000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9012 saint lucia dr.",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9088 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "8986 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1575,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Custom",
      CovA: "285000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8971 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "300000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9118 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Heron",
      Str: "8943 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "280000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Heron",
      Str: "9080 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1499,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9122 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Stillwater",
      Str: "8825 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1649,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9092 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "310000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9036 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9126 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8931 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Gable",
      CovA: "275000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9146 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9035 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Baypointe",
      Str: "9039 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1576,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "8991 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Villa",
      Mo: "Sandhill",
      Str: "9079 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1643,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip",
      Str: "76 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "67 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "19 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "108 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "47 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "90 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip-Traditional",
      Str: "76 liberty bell road",
      Ct: "ponte verde",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "other",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip",
      Str: "94 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "98 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "84 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "36 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "42 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "31 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "98 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "83 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "39 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2820,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Tulip",
      Str: "59 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2031,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "25 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Begonia",
      Str: "53 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2405,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "75 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "90 liberty bell rd",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Rose",
      Str: "118 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2266,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "50 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Franklin Square at Crosswater",
      Mo: "Lily",
      Str: "28 carousel dr",
      Ct: "ponte vedra",
      St: "FL",
      Zip: 32081,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "57 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3447,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "376 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "119 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3502,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "585000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "15 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "319 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3245,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Mandigo Elite",
      Str: "49 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2799,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "445 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3180,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "45 placid place",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4024,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "59 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3143,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "306 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "388 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2924,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Mandigo",
      Str: "90 pine blossom trail",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "382 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2690,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "162 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3172,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "494 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3099,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "573 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "293 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3484,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "408 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "402 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2961,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "215 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2721,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "269 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2647,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "131 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "508 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "281 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3173,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "383 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3075,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "326 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2610,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "565 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2998,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "549 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2607,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "225 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3294,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "542 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "469 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3406,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "580 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3245,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "572 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3687,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Terrano",
      Str: "235 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3066,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "564 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3109,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "71 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3421,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anna Maria",
      Str: "191 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3359,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "520 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3291,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "448 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva Elite",
      Str: "68 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3383,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "22 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2656,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "368 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2693,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "37 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia",
      Str: "57 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2718,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Captiva",
      Str: "338 pine haven drive",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2675,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Mandigo",
      Str: "28 pine blossom trail",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2239,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 58's-Heritage",
      Mo: "Anastasia Elite",
      Str: "56 pine haven dr",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3353,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9268 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2437,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9069 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9248 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "535000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9101 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2352,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9260 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9284 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2503,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9264 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9297 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9256 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9050 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9273 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2403,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9288 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9272 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9070 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2487,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8906 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2404,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9066 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2188,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9073 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Sabel",
      Str: "9269 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2075,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "8778 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3780,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1315000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9086 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9296 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2224,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Sabel",
      Str: "9078 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2192,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9047 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "505000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9063 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9074 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2934,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9293 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2376,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "9059 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9109 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "705000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9046 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2285,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9077 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9043 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2548,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8969 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8920 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9280 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2352,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Cayman",
      Str: "8945 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "355000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Massiano",
      Str: "9292 cayman drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2260,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9058 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2578,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Stefano",
      Str: "9055 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2971,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "9062 montserrat drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8782 saint lucia drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2614,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1190000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Estate",
      Mo: "Serino",
      Str: "8976 mustique lane",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2282,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9047 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Kingsdale",
      Str: "9002 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "8995 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "640000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "8990 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9046 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Carver Elite",
      Str: "9015 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9006 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "410000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Carver",
      Str: "9035 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9027 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9030 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "9105 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "950000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena",
      Str: "9007 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9043 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9067 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9011 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "8986 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9108 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9050 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "9039 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9031 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Athena Elite",
      Str: "9093 caicos way",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Avery",
      Str: "9056 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2447,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Azure at Hacienda Lakes-Herita",
      Mo: "Piper",
      Str: "9071 redonda drive",
      Ct: "naples",
      St: "FL",
      Zip: 34114,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2504,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8315 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Steel/Metal",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8428 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8264 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "8432 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1125000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell Elite",
      Str: "7908 redonda loop",
      Ct: "bradenton",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4028,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "790000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "17773 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "7944 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3293,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "665000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Sandpiper Elite",
      Str: "8429 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "815000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8404 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8264 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8322 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3273,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "8440 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "980000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell Elite",
      Str: "18216 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4047,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "730000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8276 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Sandpiper Elite",
      Str: "8335 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3313,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "17729 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8248 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8346 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3202,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Maxwell",
      Str: "8436 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3597,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "620000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "17769 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3574,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Fullerton",
      Str: "8424 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4345,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "860000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Sandpiper",
      Str: "8338 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2872,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Custom",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "17717 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8337 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3777,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8417 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3912,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "800000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "17737 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "18220 cayo largo place",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3832,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "885000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "17721 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8416 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3566,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8443 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8267 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8412 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "San Giorgio",
      Str: "8437 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3663,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "1015000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8408 cane bay court",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3434,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "895000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "17725 lucaya drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "625000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Solana",
      Str: "8252 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3362,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "530000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Captiv",
      Mo: "Aragon",
      Str: "8323 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3208,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "735000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "7841 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "805000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "7853 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "7861 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver Elite",
      Str: "8221 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "580000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver Elite",
      Str: "8376 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17625 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17679 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17629 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "7832 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8187 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8142 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "8350 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Hip",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8149 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "660000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Biscayne Elite",
      Str: "7849 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3728,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "8078 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "8130 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Kingsdale",
      Str: "8217 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3190,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "595000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery Elite",
      Str: "7836 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3131,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "7865 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17626 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8354 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "8134 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8166 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2497,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Palacios",
      Str: "8213 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2275,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "7856 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Biscayne",
      Str: "8158 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17642 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2543,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena",
      Str: "17661 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2842,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "7845 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2493,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Kingsdale",
      Str: "17621 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3199,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver Elite",
      Str: "17696 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3431,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "615000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "17671 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8114 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "610000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8146 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8082 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Athena Elite",
      Str: "8138 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3797,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "8090 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2443,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "635000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Carver",
      Str: "8191 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2948,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "525000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Biscayne",
      Str: "8066 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3025,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Palacios",
      Str: "7857 redonda loop",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2280,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Isles at Lakewood Ranch-Sanibe",
      Mo: "Avery",
      Str: "17638 azul drive",
      Ct: "lakewood ranch",
      St: "FL",
      Zip: 34202,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2489,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Clay Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "768 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "128 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "717 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2944,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "389 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3255,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "460000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "281 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3823,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "653 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "600000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "281 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3821,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "104 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3223,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "80 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "116 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "462 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "142 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3849,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "575000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "741 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3356,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "448 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2869,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "142 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3851,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "90 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4435,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "655000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "263 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "384 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3854,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "653 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "495000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "462 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "778 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "186 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "341 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "700 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "329 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "118 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "532 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3407,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "54 lake mist court",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4085,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "679 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Asphalt Fiberglass Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "90 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4437,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "590000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "364  lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4134,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "545000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "315 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3985,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "745000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "102 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3297,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "364  lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4132,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "570000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "485 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4158,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "118 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3749,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "361 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3569,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "593 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3244,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "605 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3311,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "515000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "289 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "540000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "463 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3828,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "520000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "353 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3974,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "500000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "665 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2990,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "San Tropez",
      Str: "250 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3187,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "470000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "600 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3389,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "328  lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Cassia",
      Str: "508 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "400000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Edgewater",
      Str: "674 honey blossom road",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3453,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "116 lakeview pass way",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 4087,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "550000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Captiva Elite",
      Str: "349 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3783,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Julington Lakes 68's-Ambassado",
      Mo: "Julington",
      Str: "341 shadow cove",
      Ct: "saint johns",
      St: "FL",
      Zip: 32259,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 3751,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Joliet",
      Str: "9973 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2378,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9937 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "650000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "McKenna",
      Str: "9941 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9994 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "630000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Joliet",
      Str: "12420 hammock way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2388,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "McKenna",
      Str: "10006 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2414,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "690000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "McKenna",
      Str: "9945 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2413,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9905 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2314,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "380000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Samantha",
      Str: "9909 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Jesalin",
      Str: "9953 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Jesalin",
      Str: "9929 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Maya",
      Str: "9901 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2327,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "605000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Samantha",
      Str: "9917 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1886,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "555000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Palms Collec",
      Mo: "Jesalin",
      Str: "9921 regency way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2205,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Salerno",
      Str: "9986 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2626,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "715000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Salerno",
      Str: "9916 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Isabella",
      Str: "9954 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Lewiston",
      Str: "9942 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "645000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Lewiston",
      Str: "9962 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2712,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "565000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Isabella",
      Str: "9940 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "870000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Isabella",
      Str: "9925 seagrass way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2858,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9957 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2740,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9974 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9926 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2742,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Shelby",
      Str: "9950 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2529,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "695000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Salerno",
      Str: "9977 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2616,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "430000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9922 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "490000"
    },
    {
      Bid: "6703",
      Co: "Regency at Avenir-Tradewinds C",
      Mo: "Kaley",
      Str: "9900 timber creek way",
      Ct: "palm beach gardens",
      St: "FL",
      Zip: 33412,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2748,
      Sto: "1",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1823 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1832 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1826 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "550 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1830 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1824 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1828 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1837 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "556 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "574 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1835 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "563 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "577 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "579 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "553 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1831 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1835 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "562 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "510000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1825 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "534 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "557 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "558 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "573 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "385000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "551 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "395000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1857 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "576 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1867 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1820 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1827 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1844 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1822 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "552 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1855 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1836 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "582 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "578 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1831 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "350000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1821 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "540 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "390000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "571 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "532 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "425000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1837 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "550 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1829 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "575 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1833 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1829 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1853 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "570 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "558 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "580 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1840 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1845 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "320000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1865 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1827 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1851 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "572 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "554 sanderling circle",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "365000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "556 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1839 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "340000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1861 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1859 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "345000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "La Isla",
      Str: "1847 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1892,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "325000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "554 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "552 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Westwind",
      Str: "1819 sandpiper pointe place",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1936,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Seabreeze",
      Str: "1863 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1838 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "1842 spoonbill court",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "335000"
    },
    {
      Bid: "6703",
      Co: "Sandpiper Pnte @ Deerfield Bea",
      Mo: "Coquina",
      Str: "560 parsons way",
      Ct: "deerfield beach",
      St: "FL",
      Zip: 33442,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 1831,
      Sto: "2",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Architectural Shingles",
      RS: "Gable",
      CovA: "370000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "560 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "613 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "124 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "420000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "616 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "592 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "465000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "584 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Starling",
      Str: "116 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2160,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "360000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "617 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "576 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora Elite",
      Str: "608 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Gable",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "540 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "556 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "435000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "552 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "455000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "101 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora Elite",
      Str: "593 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "475000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora Elite",
      Str: "128 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2892,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "445000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "557 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "440000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling Elite",
      Str: "605 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2993,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "450000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Sanderling",
      Str: "105 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2596,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "485000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "588 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "375000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "120 daylily boulevard",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "405000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Pelican",
      Str: "581 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2808,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "480000"
    },
    {
      Bid: "6703",
      Co: "Toll Brothers at Venice Woodla",
      Mo: "Flora",
      Str: "597 mistiflower circle",
      Ct: "nokomis",
      St: "FL",
      Zip: 34275,
      Pt: "Single Family",
      Ot: "Primary",
      Yr: 2024,
      Sft: 2386,
      Sto: "3",
      Be: "3",
      Ba: "2",
      CT: "Frame",
      RT: "Concrete Tile",
      RS: "Hip",
      CovA: "415000"
    }
  ],
  id: "b7c8db48-8808-4086-ae45-ea3955747a0a",
  _rid: "3UVSAJeSPf4XCQAAAAAADg==",
  _self: "dbs/3UVSAA==/colls/3UVSAJeSPf4=/docs/3UVSAJeSPf4XCQAAAAAADg==/",
  _etag: '"7319286c-0000-0100-0000-675be90e0000"',
  _attachments: "attachments/",
  _ts: 1734076686
}
